/**
 * View component for /admin/share-links/:shareLinkId/update
 *
 * Updates a single shareLink from a copy of the selcted shareLink
 * as defined in the shareLink reducer
 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

import * as shareLinkActions from '../../shareLinkActions'

import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

import AdminShareLinkLayout from '../components/AdminShareLinkLayout.js.jsx'
import Loading from 'desktop/src/app/components/loading'
import * as quickTaskActions from '../../../quickTask/quickTaskActions'
import { DateTime } from 'luxon'
import _cloneDeep from 'lodash/cloneDeep'
import { FeedbackMessage } from '../../../../global/components/helpers/FeedbackMessage.js'
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'

const AdminUpdateShareLink = ({
  shareLinkStore,
  match,
  dispatch,
  location,
  quickTaskStore,
}) => {
  const feedbackMessage = useRef()
  const { shareLinkId } = match.params

  useEffect(() => {
    dispatch(shareLinkActions.fetchSingleIfNeeded(shareLinkId)).then(res => {
      if (res.item._quickTask) {
        dispatch(quickTaskActions.fetchSingleIfNeeded(res.item._quickTask))
      }
    })
  }, [dispatch, shareLinkId])

  const isFetching =
    !shareLinkStore.selected.id ||
    shareLinkStore.selected.isFetching ||
    quickTaskStore.selected.isFetching

  const selectedQuickTask = quickTaskStore.selected.getItem()
  const selectedShareLink = shareLinkStore.selected.getItem()

  const setVisibility = visibility => {
    let newQuickTask = _cloneDeep(quickTaskStore.selected.getItem())
    newQuickTask.visibility = visibility
    dispatch(quickTaskActions.sendUpdateQuickTask(newQuickTask))
  }

  const handleSignatureReminder = () => {
    dispatch(
      quickTaskActions.sendSignatureReminder(selectedQuickTask._id),
    ).then(() => {
      feedbackMessage.current.showSuccess('Reminder email sent.')
    })
  }

  const handleRequestFileReminder = () => {
    dispatch(
      quickTaskActions.sendRequestFileReminder(selectedQuickTask._id),
    ).then(() => {
      feedbackMessage.current.showSuccess('Reminder email sent.')
    })
  }

  const toggleShareLinkStatus = () => {
    dispatch(
      shareLinkActions.sendUpdateShareLink({
        ...selectedShareLink,
        expireDate: selectedShareLink.expireDate ? null : new Date(),
      }),
    )
  }

  if (isFetching) {
    return <Loading />
  }

  if (!selectedQuickTask) {
    return (
      <AdminShareLinkLayout>
        <Helmet>
          <title>Admin Update Share Link</title>
        </Helmet>
        <Breadcrumbs links={location.state.breadcrumbs} />
        <Box
          sx={{
            opacity: isFetching ? 0.5 : 1,
            backgroundColor: 'white',
            p: 3,
          }}
        >
          <Container>
            <Typography variant="body1">
              Share link type: {selectedShareLink.type}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
            >
              Status: {selectedShareLink.expireDate ? 'Expired' : 'Active'}
            </Typography>
            <Button
              variant="outlined"
              color="success"
              size="small"
              sx={{ mt: 1 }}
              onClick={toggleShareLinkStatus}
            >
              {selectedShareLink.expireDate
                ? 'Activate share link'
                : 'Disable share link'}
            </Button>
          </Container>
        </Box>
      </AdminShareLinkLayout>
    )
  }

  return (
    <AdminShareLinkLayout>
      <Helmet>
        <title>Admin Update Share Link</title>
      </Helmet>
      <Breadcrumbs links={location.state.breadcrumbs} />
      <Box
        sx={{
          opacity: isFetching ? 0.5 : 1,
          backgroundColor: 'white',
          p: 3,
        }}
      >
        <Container>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
          >
            <IconButton>
              <i className="fas fa-file-signature fa-2x"></i>
            </IconButton>
            <Typography
              variant="body1"
              component="span"
              ml={2}
            >
              {selectedQuickTask.type === 'signature'
                ? 'Signature Request'
                : 'File Request'}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              ml={2}
            >
              {DateTime.fromISO(selectedQuickTask.created_at).toFormat(
                'LLL d yyyy',
              )}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            gutterBottom
          >
            Status: {selectedQuickTask.visibility}
          </Typography>

          {selectedQuickTask.type === 'signature' && (
            <>
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  ml={1}
                >
                  Completion: {selectedQuickTask._returnedFiles.length}/
                  {selectedQuickTask.signingLinks.length} signatures
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )}

          {selectedQuickTask.signingLinks.length > 0 && (
            <>
              <Typography variant="body2">Recipients:</Typography>
              {selectedQuickTask.signingLinks.map((link, i) => (
                <Typography
                  variant="body2"
                  key={link.signatoryEmail + i}
                >
                  {link.signatoryEmail}
                </Typography>
              ))}
            </>
          )}

          <Divider sx={{ my: 2 }} />
          <Box>
            {selectedQuickTask.status === 'open' && (
              <Button
                onClick={
                  selectedQuickTask.type === 'signature'
                    ? handleSignatureReminder
                    : handleRequestFileReminder
                }
                variant="outlined"
                color="success"
                size="small"
                sx={{ mr: 1 }}
              >
                Send Reminder
              </Button>
            )}
            <Button
              onClick={() =>
                setVisibility(
                  selectedQuickTask.visibility === 'active'
                    ? 'archived'
                    : 'active',
                )
              }
              variant="outlined"
              color={
                selectedQuickTask.visibility === 'active'
                  ? 'default'
                  : 'success'
              }
              size="small"
            >
              {selectedQuickTask.visibility === 'active'
                ? 'Archive'
                : 'Activate'}
            </Button>
            {selectedQuickTask._client && (
              <Button
                variant="outlined"
                color="success"
                size="small"
                sx={{ ml: 1 }}
                onClick={() =>
                  window.open(
                    `/firm/${selectedQuickTask._firm}/workspaces/${selectedQuickTask._client}/quick-tasks/quick-view/${selectedQuickTask._id}`,
                  )
                }
              >
                See share link in firm
              </Button>
            )}
          </Box>
        </Container>
      </Box>
      <FeedbackMessage ref={feedbackMessage} />
    </AdminShareLinkLayout>
  )
}

AdminUpdateShareLink.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  return {
    shareLinkStore: store.shareLink,
    quickTaskStore: store.quickTask,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminUpdateShareLink))
