/**
 * View component for /admin/users
 *
 * Displays a paginated list of all users in the system.
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

// import actions
import * as userActions from '../../userActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'

// import utilities
import routeUtils from '../../../../global/utils/routeUtils'

// import user components
import AdminUserLayout from '../components/AdminUserLayout.js.jsx'
import AdminUserListItem from '../components/AdminUserListItem.js.jsx'
import { CheckboxInput, TextInput } from '../../../../global/components/forms'

class AdminUserList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      queryText: this.props.query || '',
      perPage: 50,
      searchQuery: {
        searchPerPage: 50,
        searchPageNumber: 1,
        searchSortName: 'updated_at',
        searchSortAsc: 'desc',
      },
      email: '',
      name: '',
      enableEmail: false,
      enableName: false,
    }
    this._bind(
      '_handleQuery',
      '_setPerPage',
      '_handleSetPagination',
      '_handleDispatch',
      '_handleSort',
      '_handleSearch',
      '_handleKeyDown',
      '_changeHeaderSearch',
      '_renderSearchInput',
      '_toggleHeaderSearch',
    )

    this.current = null
  }

  componentWillReceiveProps(nextProps) {
    const nextSearch = nextProps.location.search
    const currentSearch = this.props.location.search
    if (!nextSearch && currentSearch) {
      const searchQuery = {
        searchPerPage: 50,
        searchPageNumber: 1,
        searchSortName: 'updated_at',
        searchSortAsc: 'desc',
      }
      this._handleDispatch(searchQuery)
    }
  }

  componentDidMount() {
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    const page = query.get('page') || 1
    const perPage = query.get('per') || 50
    const searchQuery = _.cloneDeep(this.state.searchQuery)
    searchQuery.searchPageNumber = page
    searchQuery.searchPerPage = perPage
    this._handleDispatch(searchQuery)
  }

  _handleDispatch(searchQuery) {
    const { dispatch } = this.props

    this.setState(
      {
        searchQuery,
      },
      () => {
        dispatch(
          userActions.fetchListIfNeededV2(
            searchQuery,
            ...routeUtils.listArgsFromObject(searchQuery),
          ),
        )
      },
    )
  }

  _setPerPage(per) {
    this.props.history.push({
      search: `?page=1&per=${per}`,
    })
    const searchQuery = _.cloneDeep(this.state.searchQuery)
    searchQuery.searchPageNumber = 1
    searchQuery.searchPerPage = per
    this._handleDispatch(searchQuery)
  }

  _handleSetPagination(newPagination) {
    const searchQuery = _.cloneDeep(this.state.searchQuery)
    searchQuery.searchPageNumber = newPagination.page
    searchQuery.searchPerPage = newPagination.per
    this._handleDispatch(searchQuery)
  }

  _handleQuery(e) {
    const { dispatch } = this.props
    this.setState({ queryText: e.target.value.toLowerCase() })
  }

  _handleFilter(sortBy) {}

  _handleSort(sortBy) {
    const searchQuery = _.cloneDeep(this.state.searchQuery)
    if (sortBy === searchQuery.searchSortName) {
      searchQuery.searchSortAsc =
        searchQuery.searchSortAsc === 'asc' ? 'desc' : 'asc'
    } else {
      searchQuery.searchSortAsc = 'desc'
      searchQuery.searchSortName = sortBy
    }
    this._handleDispatch(searchQuery)
  }

  _handleSearch() {
    this.props.history.push({
      search: `?page=1&per=50`,
    })
    const searchQuery = _.cloneDeep(this.state.searchQuery)
    searchQuery.searchPageNumber = 1
    searchQuery.searchPerPage = 50
    searchQuery.name = this.state.enableName ? this.state.name : undefined
    searchQuery.email = this.state.enableEmail ? this.state.email : undefined

    this._handleDispatch(searchQuery)
  }

  _handleKeyDown(e) {
    console.log('e.key', e.key)
    if (e.key === 'Enter') {
      console.log('do validate')
      console.log('keydown press')
      this._handleSearch()
    }
  }

  _changeHeaderSearch(name, value) {
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  _toggleHeaderSearch(name) {
    this.setState(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  _renderSearchInput(name, placeholder) {
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)

    return (
      <div className="-table-header-search">
        <CheckboxInput
          name={`enable${capitalizedName}`}
          value={this.state[`enable${capitalizedName}`]}
          checked={true}
          change={() => this._toggleHeaderSearch(`enable${capitalizedName}`)}
        />
        <TextInput
          change={e => this._changeHeaderSearch(name, e.target.value)}
          name={name}
          value={this.state[name]}
          disabled={!this.state[`enable${capitalizedName}`]}
          placeholder={placeholder}
          onKeyDown={this._handleKeyDown}
        />
      </div>
    )
  }

  render() {
    const { userStore } = this.props
    /**
     * NOTE: Regarding isEmpty, when the app loads, all "product lists"
     * are null objects. They exist only after we create them.
     */

    const searchQuery = _.cloneDeep(this.state.searchQuery)
    const argsSearchQuery = routeUtils.listArgsFromObject(searchQuery)
    let utilFileStore = userStore.util.getSelectedStore(...argsSearchQuery)
    let sortedAndFilteredList = userStore.util.getList(...argsSearchQuery) || []

    const isFetching = utilFileStore.isFetching
    const isEmpty = sortedAndFilteredList && sortedAndFilteredList.length === 0

    if (isEmpty && isFetching && this.current) {
      utilFileStore = this.current.utilFileStore
      sortedAndFilteredList = this.current.sortedAndFilteredList
    } else if (!isEmpty && !isFetching) {
      this.current = {
        utilFileStore,
        sortedAndFilteredList,
      }
    }

    return (
      <AdminUserLayout>
        <Helmet>
          <title>Admin User List</title>
        </Helmet>
        <h3> All Registered Users</h3>
        <hr />
        <p className="large">
          Here you can create, edit, and add permissions to users
        </p>
        <div style={{ opacity: isFetching ? 0.5 : 1 }}>
          <div className="yt-toolbar">
            <div className="yt-tools right">
              <div
                className="search"
                style={{ display: 'inline-flex', marginRight: '10px' }}
              >
                <button
                  className="yt-btn x-small rounded info"
                  style={{ marginLeft: '10px' }}
                  onClick={this._handleSearch}
                >
                  Apply Filter
                </button>
              </div>
              <Link
                class="yt-btn small u-pullRight"
                to={{ pathname: '/admin/users/new', state: { searchQuery } }}
              >
                {' '}
                NEW USER{' '}
              </Link>
            </div>
          </div>
          <table className="yt-table striped">
            <caption>
              <PageTabber
                totalItems={
                  (utilFileStore &&
                    utilFileStore.pager &&
                    utilFileStore.pager.totalItems) ||
                  0
                }
                totalPages={
                  (utilFileStore &&
                    utilFileStore.pager &&
                    utilFileStore.pager.totalPages) ||
                  0
                }
                pagination={
                  (utilFileStore &&
                    utilFileStore.pager &&
                    utilFileStore.pager) ||
                  {}
                }
                setPagination={this._handleSetPagination}
                setPerPage={this._setPerPage}
                viewingAs="top"
                itemName="adminUser"
                isChanged={true}
              />
            </caption>
            <thead>
              <tr>
                <th>
                  <span
                    className="-title sortable"
                    onClick={this._handleSort.bind(this, 'firstname')}
                  >
                    Name
                    {searchQuery.searchSortName === 'firstname' ? (
                      searchQuery.searchSortAsc === 'asc' ? (
                        <i className="fad fa-sort-up"></i>
                      ) : (
                        <i className="fad fa-sort-down"></i>
                      )
                    ) : (
                      <i className="fad fa-sort"></i>
                    )}
                  </span>
                  {this._renderSearchInput('name', 'Filter by name')}
                </th>
                <th>
                  <span
                    className="-title sortable"
                    onClick={this._handleSort.bind(this, 'username')}
                  >
                    Email
                    {searchQuery.searchSortName === 'username' ? (
                      searchQuery.searchSortAsc === 'asc' ? (
                        <i className="fad fa-sort-up"></i>
                      ) : (
                        <i className="fad fa-sort-down"></i>
                      )
                    ) : (
                      <i className="fad fa-sort"></i>
                    )}
                  </span>
                  {this._renderSearchInput('email', 'Filter by email')}
                </th>
                <th className="-title sortable">Roles</th>
                <th
                  className="-title sortable"
                  onClick={this._handleSort.bind(this, 'enable_2fa')}
                >
                  2FA
                  {searchQuery.searchSortName === 'enable_2fa' ? (
                    searchQuery.searchSortAsc === 'asc' ? (
                      <i className="fad fa-sort-up"></i>
                    ) : (
                      <i className="fad fa-sort-down"></i>
                    )
                  ) : (
                    <i className="fad fa-sort"></i>
                  )}
                </th>
                <th className="-title sortable">Is Active</th>
                <th
                  className="numbers sortable"
                  onClick={this._handleSort.bind(this, 'updated_at')}
                >
                  Last Modified
                  {searchQuery.searchSortName === 'updated_at' ? (
                    searchQuery.searchSortAsc === 'asc' ? (
                      <i className="fad fa-sort-up"></i>
                    ) : (
                      <i className="fad fa-sort-down"></i>
                    )
                  ) : (
                    <i className="fad fa-sort"></i>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredList.map((user, i) => (
                <AdminUserListItem
                  key={i}
                  user={user}
                />
              ))}
            </tbody>
          </table>
        </div>
      </AdminUserLayout>
    )
  }
}

AdminUserList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  return {
    userStore: store.user,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminUserList))
