import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// import actions
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'
import * as folderTemplateActions from '../../folderTemplateActions'

// import global components
import YTRoute from '../../../../global/components/routing/YTRoute.js.jsx'
import routeUtils from '../../../../global/utils/routeUtils'

// import resource components
import PracticeFirmLayout from '../../../firm/practice/components/PracticeFirmLayout.js.jsx'
import PracticefolderTemplateList from '../components/PracticefolderTemplateList.js.jsx'
import CreateFolderTemplateForm from '../components/CreateFolderTemplateForm.js.jsx'

const PracticeFolderTemplates = props => {
  const {
    dispatch,
    match,
    folderTemplateStore,
    staffStore,
    userStore,
    location,
  } = props
  const isArchive = Boolean(match.url && match.url.indexOf('archived') > -1)
  const [page, setPage] = useState(1)
  const [per, setPer] = useState(50)
  const [query, setQuery] = useState('')
  const [folderTemplatesListArgsObj, setFolderTemplatesListArgsObj] = useState({
    _firm: match.params.firmId,
    status: isArchive ? 'archived' : 'active',
  })

  useEffect(() => {
    setFolderTemplatesListArgsObj({
      _firm: match.params.firmId,
      status: isArchive ? 'archived' : 'active',
    })
  }, [match.params.firmId, isArchive])

  useEffect(() => {
    const folderTemplatesListArgs = routeUtils.listArgsFromObject(
      folderTemplatesListArgsObj,
    )
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId))
    dispatch(
      folderTemplateActions.fetchListIfNeeded(...folderTemplatesListArgs),
    ).then(json => {
      // setFolderTemplateListItems(json.list)
    })
    dispatch(folderTemplateActions.setQuery('', ...folderTemplatesListArgs))
    handleSetPagination({ page: 1, per: 50 })
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
  }, [match.params.firmId, folderTemplatesListArgsObj])

  const handleSetPagination = newPagination => {
    dispatch(
      folderTemplateActions.setPagination(
        newPagination,
        ...routeUtils.listArgsFromObject(folderTemplatesListArgsObj),
      ),
    )
  }

  const handleQuery = e => {
    let pagination = { page: 1, per }
    handleSetPagination(pagination)
    dispatch(
      folderTemplateActions.setQuery(
        e.target.value.toLowerCase(),
        ...routeUtils.listArgsFromObject(folderTemplatesListArgsObj),
      ),
    )
    setQuery(e.target.value.toLowerCase())
  }

  const setPerPage = per => {
    let newPagination = { per: parseInt(per), page: 1 }
    handleSetPagination(newPagination)
    setPer(newPagination.per)
  }

  const folderTemplateList = useMemo(() => {
    return folderTemplateStore.util.getListInfo(
      ...routeUtils.listArgsFromObject(folderTemplatesListArgsObj),
    )
  }, [
    folderTemplatesListArgsObj,
    folderTemplateStore.util.getListInfo(
      ...routeUtils.listArgsFromObject(folderTemplatesListArgsObj),
    ),
  ])

  const folderTemplateListItems = useMemo(() => {
    return folderTemplateStore.util.getList(
      ...routeUtils.listArgsFromObject(folderTemplatesListArgsObj),
    )
  }, [
    folderTemplatesListArgsObj,
    folderTemplateStore.util.getList(
      ...routeUtils.listArgsFromObject(folderTemplatesListArgsObj),
    ),
  ])

  // const [folderTemplateListItems, setFolderTemplateListItems] = useState([])


  const filteredFolderTemplateListItems = useMemo(
    () =>
      folderTemplateListItems
        ? folderTemplateListItems.filter(data => data !== undefined)
        : [],
    [folderTemplateListItems],
  )

  const isEmpty = useMemo(() => {
    return (
      !folderTemplateList ||
      !folderTemplateList.items ||
      folderTemplateStore.selected.didInvalidate ||
      userStore.selected.didInvalidate ||
      staffStore.selected.didInvalidate
    )
  }, [
    folderTemplateList,
    folderTemplateStore.selected.didInvalidate,
    userStore.selected.didInvalidate,
    staffStore.selected.didInvalidate,
  ])
  const isFetching = useMemo(() => {
    return (
      !folderTemplateStore ||
      folderTemplateStore.selected.isFetching ||
      userStore.selected.isFetching ||
      staffStore.selected.isFetching
    )
  }, [
    folderTemplateStore.selected.isFetching,
    userStore.selected.didInvalidate,
    staffStore.selected.didInvalidate,
  ])

  return (
    <PracticeFirmLayout>
      {isEmpty ? (
        isFetching ? (
          <h2>Loading...</h2>
        ) : (
          <h2>Empty.</h2>
        )
      ) : (
        <div style={{ opacity: isFetching ? 0.5 : 1 }}>
          <div className="file-list-wrapper">
            <div className="yt-toolbar">
              <div className="yt-tools space-between">
                <div className="-left"></div>
                <div className="yt-tools -right">
                  {isArchive ? (
                    <Link
                      className="yt-btn x-small info -filename"
                      to={`/firm/${match.params.firmId}/settings/folder-templates`}
                    >
                      View Active
                    </Link>
                  ) : (
                    <>
                      <Link
                        className="yt-btn x-small info -filename"
                        to={`/firm/${match.params.firmId}/settings/folder-templates/archived`}
                      >
                        View Archive
                      </Link>
                      <Link
                        className="yt-btn x-small info -filename"
                        to={`${match.url}/new`}
                      >
                        New template
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr
              className="-mobile-yt-hide"
              style={{ margin: 0 }}
            />
            <PracticefolderTemplateList
              setPerPage={setPerPage}
              folderTemplateList={folderTemplateList}
              folderTemplateListItems={filteredFolderTemplateListItems}
              setPagination={handleSetPagination}
              handleQuery={handleQuery}
              isArchive={isArchive}
            />
          </div>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="slide-from-right"
              timeout={300}
            >
              <Switch location={location}>
                <YTRoute
                  breadcrumbs={[]}
                  exact
                  path="/firm/:firmId/settings/folder-templates/new"
                  staff={true}
                  component={CreateFolderTemplateForm}
                />
                <YTRoute
                  breadcrumbs={[]}
                  exact
                  path="/firm/:firmId/settings/folder-templates/:folderTemplateId/update"
                  staff={true}
                  component={CreateFolderTemplateForm}
                />
                <Route render={() => <div />} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      )}
    </PracticeFirmLayout>
  )
}

PracticeFolderTemplates.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    folderTemplateStore: store.folderTemplate,
    staffStore: store.staff,
    userStore: store.user,
    userMap: store.user.byId,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticeFolderTemplates))
