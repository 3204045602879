/**
 * View component for /firm/:firmId/settings/advanced
 *
 * Displays firm's settings and lets the owner use edit them.
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'
import _uniqueId from 'lodash/uniqueId'
import _isEqual from 'lodash/isEqual'
import { Helmet } from 'react-helmet'

// import actions
import * as firmActions from '../../firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import ISReactDraftEditor from '../../../../global/components/forms/ISReactDraftEditor.js.jsx'
import { FeedbackMessage } from '../../../../global/components/helpers/FeedbackMessage.js.jsx'
import LoadingBiscuit from '../../../../global/components/helpers/LoadingBiscuit.js.jsx'
import SelectFromObject from '../../../../global/components/forms/SelectFromObject.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import ToggleSwitchInput from '../../../../global/components/forms/ToggleSwitchInput.js.jsx'

// import layout components
import PracticeFirmLayout from '../components/PracticeFirmLayout.js.jsx'

// import configuration
import brandingName from '../../../../global/enum/brandingName.js.jsx'

// import api utility
import apiUtils from '../../../../global/utils/api'
import NumberInput from '../../../../global/components/forms/NumberInput.js'
import htmlSanitizeUtils from '../../../../global/utils/htmlSanitizeUtils'
import WatermarkModal from '../../../../global/components/helpers/WatermarkModal'

const API_GET_SETTINGS = firmId => {
  return `/api/firms/${firmId}/settings`
}
const API_UPDATE_SETTINGS = firmId => {
  return `/api/firms/${firmId}/settings`
}

function isEmptyHTML(content) {
  const trimmedContent = content.trim();

  return /<p>\s*<\/p>/.test(trimmedContent) || trimmedContent === '';
}

class PracticeAdvanceSettings extends Binder {
  constructor(props) {
    super(props)
    this.feedbackMessage = React.createRef()
    this.MAX_SECRET_QUESTION_LENGTH = 256
    this.state = {
      isInEditMode: false,
      savedSettings: {}, // object containing the firm settings stored in the db to check if the state on the screen has changed or not
      editedSettings: {},
      archiveFile: null,
      expireShareFile: null,
      expireRequestFile: null,
      expireSignatureRequest: null,
      authDefault: null,
      initialSecretQuestions: {},
      secretQuestions: {},
      newSecretQuestion: '',
      counter: 0,
      allowCreateFolder: '',
      allowDeleteFiles: '',
      allowMoveFiles: '',
      allowRenameFiles: '',
      zipFilesDownload: '',
      draggingStart: null,
      tcContents: '',
      showNewLabel: true,
      showEmail: false,
      showCompany: false,
      fileVersionType: '',
      default_file_status: '',
      allowAddRecipientFileRequest: '',
      enable_pdftron: '',
      email_useLoggedInUserInfo: true,
      email_fromName: null,
      email_replyTo: null,
      isFetching: false,
      allowRequiredRecipient: '',
      allowChangeAuthScheme: '',
      showStaffFolder: '',
      allowNonContactSigner: true,
      showGeneralFiles: '',
      showTermsAndConditions: false,
      force_2fa: false,
      enableAutoLogout: false,
      inactivityLogoutTime: 15,
      enableContactBannerMessage: false,
      contactBannerMessage: '',
      enableWatermarks: false,
      watermarks: [],
      watermarksModal: {
        isOpen: false,
        type: "create",
        selectedWatermark: undefined
      }
    }
    this._bind(
      'onInputElementChange',
      'onCancel',
      'onUpdate',
      'onAddSecretQuestion',
      'onDeleteSecretQuestion',
      'onPreviewWatermark',
      'onAddWatermark',
      'onEditWatermark',
      'onDeleteWatermark',
      'parseQuestions',
      'onRTEChange',
      'holdSettings',
    )

    this.selectedFirm = {}
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId)).then(
      firmRes => {
        if (firmRes.success && firmRes.item) {
          this.selectedFirm = _cloneDeep(firmRes.item)
        }
      }
    )
    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firm', match.params.firmId)) // fetches clientUser/contacts
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId)) // fetches staff
    this.setState({ isFetching: true }, () => {
      apiUtils
        .callAPI(API_GET_SETTINGS(match.params.firmId), 'GET')
        .then(json => {
          this.setState({ isFetching: false }, () => {
            if (json.success === true) {
              const firmSettings = json.firmSettings
              const secretQuestions = this.parseQuestions(
                firmSettings.secretQuestions
              )
              this.holdSettings(firmSettings, secretQuestions)
            } else {
              this.feedbackMessage.current.showError(json.message)
            }
          })
        })
        .catch(err => {
          this.setState({ isFetching: false })
          this.feedbackMessage.current.showError(err)
        })
    })
  }

  holdSettings(firmSettings, secretQuestions) {
    const firmName = this.selectedFirm.name
    let expireShareFile = firmSettings.expireLinks
    let expireRequestFile = firmSettings.expireLinks
    let expireSignatureRequest = firmSettings.expireLinks


    // if expireShareFile and expireRequestFile and expireSignatureRequest is still null get the value of expireLinks
    if (firmSettings.expireShareFile) {
      expireShareFile = firmSettings.expireShareFile
    }

    if (firmSettings.expireRequestFile) {
      expireRequestFile = firmSettings.expireRequestFile
    }

    if (firmSettings.expireSignatureRequest) {
      expireSignatureRequest = firmSettings.expireSignatureRequest
    }

    this.setState({
      isInEditMode: false,
      archiveFile: firmSettings.archiveFile,
      expireShareFile,
      expireRequestFile,
      expireSignatureRequest,
      authDefault: firmSettings.authDefault,
      initialSecretQuestions: _cloneDeep(secretQuestions),
      secretQuestions: secretQuestions,
      allowCreateFolder: firmSettings.allowCreateFolder,
      allowDeleteFiles: firmSettings.allowDeleteFiles,
      allowMoveFiles: firmSettings.allowMoveFiles,
      allowRenameFiles: firmSettings.allowRenameFiles,
      zipFilesDownload: firmSettings.zipFilesDownload,
      tcContents: firmSettings.tcContents,
      showNewLabel: firmSettings.showNewLabel,
      showEmail: firmSettings.showEmail,
      showCompany: firmSettings.showCompany,
      fileVersionType: firmSettings.fileVersionType,
      default_file_status: firmSettings.default_file_status,
      allowAddRecipientFileRequest: firmSettings.allowAddRecipientFileRequest,
      enable_pdftron: firmSettings.enable_pdftron,
      email_useLoggedInUserInfo: firmSettings.email_useLoggedInUserInfo,
      email_fromName: firmSettings.email_fromName
        ? firmSettings.email_fromName
        : firmName,
      email_replyTo: firmSettings.email_replyTo,
      savedSettings: firmSettings,
      allowRequiredRecipient: firmSettings.allowRequiredRecipient,
      allowChangeAuthScheme: firmSettings.allowChangeAuthScheme,
      allowNonContactSigner: firmSettings.allowNonContactSigner,
      showStaffFolder: firmSettings.showStaffFolder,
      showGeneralFiles: firmSettings.showGeneralFiles,
      showTermsAndConditions: firmSettings.showTermsAndConditions,
      force_2fa: firmSettings.force_2fa,
      enableAutoLogout: firmSettings.enableAutoLogout,
      inactivityLogoutTime: firmSettings.inactivityLogoutTime,
      enableContactBannerMessage: firmSettings.enableContactBannerMessage,
      contactBannerMessage: firmSettings.contactBannerMessage,
      enableWatermarks: firmSettings.enableWatermarks,
      watermarks: firmSettings.watermarks ? firmSettings.watermarks : []
    })
  }

  onInputElementChange(e) {
    let val

    if (e.target.name==="newSecretQuestion" && e.target.value.toString().length>=this.MAX_SECRET_QUESTION_LENGTH) {
      this.feedbackMessage.current.showWarning(`The secret question can't be longer than ${this.MAX_SECRET_QUESTION_LENGTH} characters`, "topCenter", true, true, 5)
    }

    if (e.target.name == 'zipFilesDownload') {
      val = e.target.value.toString() == 'true'
    } else {
      val = e.target.value
    }

    let newState = _update(this.state, e.target.name, function () {
      return val
    })
    this.setState({ newState })
  }

  onCancel() {
    const savedSettings = _cloneDeep(this.state.savedSettings)
    const secretQuestions = this.parseQuestions(savedSettings.secretQuestions)
    this.setState({ isInEditMode: false, secretQuestions })
  }

  parseQuestions(secretQuestions) {
    secretQuestions = secretQuestions ? secretQuestions : '{}'
    const tempSQs =
      typeof secretQuestions === 'string'
        ? JSON.parse(secretQuestions)
        : secretQuestions
    let newSQs = {}
    for (const [key, value] of Object.entries(tempSQs)) {
      const uid = _uniqueId('ssn')
      const sq = {
        display: value.display,
        prompt: value.prompt,
        val: uid,
      }
      newSQs = { ...newSQs, [uid]: sq }
    }
    return newSQs
    //savedSettings.secretQuestions = newSQs;
    //this.setState({savedSettings});
  }

  onAddSecretQuestion() {
    const { secretQuestions, newSecretQuestion, counter } = this.state
    const newId = _uniqueId(`ssn`)

    if (!newSecretQuestion) return

    if (!secretQuestions[newId]) {
      const questionsContent = {
        display: newSecretQuestion,
        prompt: newSecretQuestion,
        val: newId,
      }
      this.setState({
        secretQuestions: { ...secretQuestions, [newId]: questionsContent },
        newSecretQuestion: '',
      })
    }
  }

  onAddWatermark() {
    this.setState({
      watermarksModal: {
        isOpen: true,
        type: "create",
        selectedWatermark: undefined
      }
    })
  }

  onEditWatermark(watermark, index) {
    this.setState({
      watermarksModal: {
        isOpen: true,
        type: "edit",
        selectedWatermark: watermark,
        watermarkIndex: index
      }
    })
  }

  onDeleteWatermark(id, index) {
    let updatedWatermarks = _cloneDeep(this.state.watermarks)
    updatedWatermarks[index] = {...updatedWatermarks[index], status: "deleted"}
    this.setState({
      watermarks: updatedWatermarks
    })
  }

  onPreviewWatermark(watermark) {
    this.setState({
      watermarksModal: {
        isOpen: true,
        type: "preview",
        selectedWatermark: watermark
      }
    })
  }

  onDeleteSecretQuestion(id) {

    let updatedSq = this.state.secretQuestions
    if (updatedSq[id]) delete updatedSq[id]
    this.setState({ secretQuestions: updatedSq })
  }

  onUpdate() {
    const {dispatch} = this.props
    let updatedSettings = _cloneDeep(this.state.savedSettings)
    const {
      tcContents,
      archiveFile,
      expireShareFile,
      expireRequestFile,
      expireSignatureRequest,
      authDefault,
      secretQuestions,
      allowCreateFolder,
      allowDeleteFiles,
      allowMoveFiles,
      allowRenameFiles,
      zipFilesDownload,
      fileVersionType,
      showNewLabel,
      showEmail,
      showCompany,
      default_file_status,
      allowAddRecipientFileRequest,
      enable_pdftron,
      email_useLoggedInUserInfo,
      email_fromName,
      email_replyTo,
      allowRequiredRecipient,
      allowChangeAuthScheme,
      showStaffFolder,
      allowNonContactSigner,
      showGeneralFiles,
      showTermsAndConditions,
      force_2fa,
      enableAutoLogout,
      inactivityLogoutTime,
      enableContactBannerMessage,
      contactBannerMessage,
      enableWatermarks,
      watermarks
    } = this.state

    updatedSettings.archiveFile =
      archiveFile == 0 ? 'None' : isNaN(archiveFile) ? 'None' : archiveFile
    updatedSettings.expireShareFile =
      expireShareFile == 0
        ? 'None'
        : isNaN(expireShareFile)
        ? 'None'
        : expireShareFile
    updatedSettings.expireRequestFile =
      expireRequestFile == 0
        ? 'None'
        : isNaN(expireRequestFile)
        ? 'None'
        : expireRequestFile
    updatedSettings.expireSignatureRequest =
      expireSignatureRequest == 0
        ? 'None'
        : isNaN(expireSignatureRequest)
        ? 'None'
        : expireSignatureRequest
    updatedSettings.authDefault = authDefault
      ? authDefault
      : updatedSettings.authDefault
    updatedSettings.secretQuestions = JSON.stringify(secretQuestions)
    updatedSettings.allowCreateFolder = allowCreateFolder
    updatedSettings.allowDeleteFiles = allowDeleteFiles
    updatedSettings.allowMoveFiles = allowMoveFiles
    updatedSettings.allowRenameFiles = allowRenameFiles
    updatedSettings.zipFilesDownload = zipFilesDownload
    updatedSettings.fileVersionType = fileVersionType
    updatedSettings.tcContents = htmlSanitizeUtils.sanitizeString(tcContents)
    updatedSettings.showNewLabel = showNewLabel
    updatedSettings.showEmail = showEmail
    updatedSettings.showCompany = showCompany
    updatedSettings.default_file_status = default_file_status
    updatedSettings.allowAddRecipientFileRequest = allowAddRecipientFileRequest
    updatedSettings.enable_pdftron = enable_pdftron
    updatedSettings.email_useLoggedInUserInfo = email_useLoggedInUserInfo
    updatedSettings.email_fromName = email_useLoggedInUserInfo
      ? ''
      : email_fromName
    updatedSettings.email_replyTo = email_useLoggedInUserInfo
      ? ''
      : email_replyTo
    updatedSettings.allowRequiredRecipient = allowRequiredRecipient
    updatedSettings.allowChangeAuthScheme = allowChangeAuthScheme
    updatedSettings.showStaffFolder = showStaffFolder
    updatedSettings.allowNonContactSigner = allowNonContactSigner
    updatedSettings.showGeneralFiles = showGeneralFiles
    updatedSettings.showTermsAndConditions = showTermsAndConditions
    updatedSettings.force_2fa = force_2fa

    updatedSettings.enableAutoLogout = enableAutoLogout
    updatedSettings.inactivityLogoutTime = inactivityLogoutTime

    updatedSettings.enableContactBannerMessage = enableContactBannerMessage
    updatedSettings.contactBannerMessage = htmlSanitizeUtils.sanitizeString(contactBannerMessage)
    updatedSettings.enableWatermarks = enableWatermarks
    updatedSettings.watermarks = watermarks

    this.setState({ isFetching: true }, () => {
      apiUtils
        .callAPI(
          API_UPDATE_SETTINGS(this.props.match.params.firmId),
          'PUT',
          updatedSettings
        )
        .then(json => {
          this.setState({ isFetching: false }, () => {
            if (json.success === true) {
              const firmSettings = json.firmSettings
              const secretQuestions = this.parseQuestions(
                firmSettings.secretQuestions
              )
              this.holdSettings(firmSettings, secretQuestions)
              this.feedbackMessage.current.showSuccess(
                'Firm advance settings updated successfully.'
              )
            } else {
              this.feedbackMessage.current.showError(json.message)
            }
          })
          dispatch(firmActions.fetchSingleFirmById(this.props.match.params.firmId))//update the store after updating the settings
        })
        .catch(err => {
          this.setState({ isFetching: false })
          this.feedbackMessage.current.showError(err)
        })
    })
  }

  onRTEChange(value) {
    this.setState({ tcContents: value })
  }

  onDragStart(ev, type, data) {
    if (type === 'sq') {
      const newData = JSON.stringify(data)
      ev.dataTransfer.setData('sq', newData)
      if (document.querySelector(`.-sq-index-${data.id}`)) {
        ev.dataTransfer.setDragImage(
          document.querySelector(`.-sq-index-${data.id}`),
          20,
          20
        )
      }
      this.setState({ draggingStart: data.id })
    }
  }

  onDragOver(ev) {
    ev.preventDefault()
  }

  onDrop(ev, type, data) {
    /**
     * name and swap variable is the index of signers state
     */

    const { secretQuestions, isInEditMode } = this.state
    if (type === 'sq' && isInEditMode && secretQuestions) {
      let oldData = ev.dataTransfer.getData('sq')
      oldData = JSON.parse(oldData)
      const tmp = _cloneDeep(data)

      data.id = oldData.id
      data.val = oldData.val
      oldData.id = tmp.id
      oldData.val = tmp.val

      secretQuestions[tmp.id] = oldData
      secretQuestions[data.id] = data

      this.setState({ secretQuestions, draggingStart: null })
    }
  }

  render() {
    const { firmStore, staffStore, match } = this.props

    const {
      isInEditMode,
      savedSettings,
      archiveFile,
      expireShareFile,
      expireRequestFile,
      expireSignatureRequest,
      authDefault,
      allowCreateFolder,
      allowDeleteFiles,
      allowMoveFiles,
      allowRenameFiles,
      initialSecretQuestions,
      newSecretQuestion,
      secretQuestions,
      zipFilesDownload,
      draggingStart,
      tcContents,
      showNewLabel,
      showEmail,
      showCompany,
      fileVersionType,
      default_file_status,
      allowAddRecipientFileRequest,
      enable_pdftron,
      email_useLoggedInUserInfo,
      email_fromName,
      email_replyTo,
      allowRequiredRecipient,
      allowChangeAuthScheme,
      showStaffFolder,
      allowNonContactSigner,
      showGeneralFiles,
      showTermsAndConditions,
      force_2fa,
      enableAutoLogout,
      inactivityLogoutTime,
      enableContactBannerMessage,
      contactBannerMessage,
      enableWatermarks,
      watermarks
    } = _cloneDeep(this.state)


    const isEmpty = !savedSettings || !savedSettings._firm

    const isFetching = firmStore.selected.isFetching

    const sqArray = []
    for (const [key, value] of Object.entries(secretQuestions)) {
      const sq = {
        display: value.display,
        prompt: value.prompt,
        val: key,
        id: key,
      }
      sqArray.push(sq)
    }

    const keyAuthOptions = [
      { value: 'Direct', name: 'Direct' },
      { value: 'QA', name: 'Question and Answer' },
    ]
    const filesDownloadOptions = [
      { value: false, name: 'Individual files' },
      { value: true, name: '.zip files' },
    ]
    const fileVersionOptions = [
      { value: 'disable', name: 'Disable file versioning' },
      { value: 'enable', name: 'Enable file versioning' },
    ]

    const fileStatus = [
      {
        value: 'hidden',
        name: 'Hidden',
      },
      {
        value: 'visible',
        name: 'Visible',
      },
    ]

    return (
      <PracticeFirmLayout>
        <FeedbackMessage ref={this.feedbackMessage} />
        <LoadingBiscuit isVisible={isFetching} />
        <Helmet>
          <title>Firm Settings</title>
        </Helmet>
        {isEmpty ? (
          <div className="-loading-hero hero">
            <div className="u-centerText">
              <div className="loading"></div>
            </div>
          </div>
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="yt-row ">
              <div className="yt-col">
                <div className="-practice-content">
                  <div
                    className={`-advance-setting-form ${
                      isInEditMode ? '-update-form' : '-display-form'
                    }`}
                  >
                    <div className="box">
                      <div className="heading">Files</div>

                      <div className="space-between">
                        <div>
                          <div>Enable PDF Editing (using PDFtron)</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {enable_pdftron ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  enable_pdftron: !enable_pdftron,
                                })
                              }
                              rounded={true}
                              value={enable_pdftron}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Default Status of Newly Uploaded Files</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {default_file_status
                              ? fileStatus.filter(
                                  a => a.value === default_file_status,
                                )[0].name
                              : fileStatus[1].name}
                          </div>
                          <div className="-to-update">
                            <SelectFromObject
                              change={this.onInputElementChange}
                              name="default_file_status"
                              items={fileStatus}
                              placeholder="Select default file upload status..."
                              required={false}
                              value="value"
                              selected={default_file_status}
                              display="name"
                              // helpText='Help: explain the changes of action, right here...'
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>File Versioning</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {savedSettings
                              ? fileVersionOptions.filter(
                                  a =>
                                    a.value === savedSettings.fileVersionType,
                                )[0]?.name
                              : null}
                          </div>
                          <div className="-to-update">
                            <SelectFromObject
                              change={this.onInputElementChange}
                              name="fileVersionType"
                              items={fileVersionOptions}
                              placeholder=""
                              required={false}
                              value="value"
                              selected={fileVersionType}
                              display="name"
                              helpText="Help: explain the changes of action, right here..."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Show 'New' Label on the Newly Uploaded Files
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {showNewLabel ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({ showNewLabel: !showNewLabel })
                              }
                              rounded={true}
                              value={showNewLabel}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Archive Files Automatically after (Days)</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {archiveFile}
                          </div>
                          <div className="-to-update">
                            <TextInput
                              change={this.onInputElementChange}
                              label=""
                              helpText='Enter number per day (0 or blank is equivalent to "None")'
                              name="archiveFile"
                              required={false}
                              value={isNaN(archiveFile) ? '0' : archiveFile}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Download Multiple Files as</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {savedSettings
                              ? filesDownloadOptions.filter(
                                  a =>
                                    a.value === savedSettings.zipFilesDownload,
                                )[0].name
                              : null}
                          </div>
                          <div className="-to-update">
                            <SelectFromObject
                              change={this.onInputElementChange}
                              name="zipFilesDownload"
                              items={filesDownloadOptions}
                              placeholder=""
                              required={false}
                              value="value"
                              selected={zipFilesDownload.toString()}
                              display="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="space-between">
                        <div>
                          <div>Show General Files for All users</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {showGeneralFiles ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  showGeneralFiles: !showGeneralFiles,
                                })
                              }
                              rounded={true}
                              value={showGeneralFiles}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="space-between">
                        <div>
                          <div>Show staff folders for All users</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {showStaffFolder ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  showStaffFolder: !showStaffFolder,
                                })
                              }
                              rounded={true}
                              value={showStaffFolder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box">
                      <div className="heading">Links</div>

                      <div className="space-between">
                        <div>
                          <div>Show Terms and Conditions</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {showTermsAndConditions ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  showTermsAndConditions:
                                    !showTermsAndConditions,
                                })
                              }
                              rounded={true}
                              value={showTermsAndConditions}
                            />
                          </div>
                        </div>
                        {isEmptyHTML(this.state.tcContents) &&
                          showTermsAndConditions && (
                            <div
                              className="alert-message warning -left -small"
                              style={{ marginBottom: '16px' }}
                            >
                              <p>
                                <small>
                                  <strong>Note: </strong>Terms & Conditions will
                                  not be displayed if Terms & Conditions Text
                                  Field is not filled
                                </small>
                              </p>
                            </div>
                          )}
                      </div>

                      <div
                        className="space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <div>
                          <div className="-option-name">
                            Terms & Conditions Option on Sharefiles Link
                            Creation
                          </div>
                          <div
                            style={
                              isInEditMode
                                ? {}
                                : { opacity: '0.5', pointerEvents: 'none' }
                            }
                          >
                            <ISReactDraftEditor
                              onChange={this.onRTEChange}
                              defaultValue={this.state.tcContents}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Show Email Address Input for Guest Users on Request
                            Link
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {showEmail ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({ showEmail: !showEmail })
                              }
                              rounded={true}
                              value={showEmail}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Show Company Name Input for Guest Users on Request
                            Link
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {showCompany ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({ showCompany: !showCompany })
                              }
                              rounded={true}
                              value={showCompany}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Allow the User to Add a Recipient in the File
                            Request Link
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowAddRecipientFileRequest ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowAddRecipientFileRequest:
                                    !allowAddRecipientFileRequest,
                                })
                              }
                              rounded={true}
                              value={allowAddRecipientFileRequest}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Make recipient required in Request Link</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowRequiredRecipient ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowRequiredRecipient:
                                    !allowRequiredRecipient,
                                })
                              }
                              rounded={true}
                              value={allowRequiredRecipient}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Expire Share File Links Automatically after (Days)
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {expireShareFile}
                          </div>
                          <div className="-to-update">
                            <TextInput
                              change={this.onInputElementChange}
                              label=""
                              helpText='Enter number per day (0 or blank is equivalent to "None")'
                              name="expireShareFile"
                              required={false}
                              value={
                                isNaN(expireShareFile) ? '0' : expireShareFile
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Expire Request File Links Automatically after (Days)
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {expireRequestFile}
                          </div>
                          <div className="-to-update">
                            <TextInput
                              change={this.onInputElementChange}
                              label=""
                              helpText='Enter number per day (0 or blank is equivalent to "None")'
                              name="expireRequestFile"
                              required={false}
                              value={
                                isNaN(expireRequestFile)
                                  ? '0'
                                  : expireRequestFile
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Expire Signature Request Links Automatically after
                            (Days)
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {expireSignatureRequest}
                          </div>
                          <div className="-to-update">
                            <TextInput
                              change={this.onInputElementChange}
                              label=""
                              helpText='Enter number per day (0 or blank is equivalent to "None")'
                              name="expireSignatureRequest"
                              required={false}
                              value={
                                isNaN(expireSignatureRequest)
                                  ? '0'
                                  : expireSignatureRequest
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Default Authentication Scheme for Links</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {savedSettings
                              ? authDefault === 'QA'
                                ? 'Question and Answer'
                                : authDefault
                              : null}
                          </div>
                          <div className="-to-update">
                            <SelectFromObject
                              change={this.onInputElementChange}
                              name="authDefault"
                              items={keyAuthOptions}
                              placeholder=""
                              required={false}
                              value="value"
                              selected={authDefault}
                              display="name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>
                            Allow Users to change default authentication scheme
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowChangeAuthScheme ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowChangeAuthScheme: !allowChangeAuthScheme,
                                })
                              }
                              rounded={true}
                              value={allowChangeAuthScheme}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Allow Users to use Non-contact signers</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowNonContactSigner ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowNonContactSigner: !allowNonContactSigner,
                                })
                              }
                              rounded={true}
                              value={allowNonContactSigner}
                            />
                          </div>
                        </div>
                      </div>

                      {isInEditMode && (
                        <div className="space-between">
                          <TextInput
                            autoComplete="off"
                            change={this.onInputElementChange}
                            label="Secret Question"
                            placeholder="Type your Secret Question"
                            name="newSecretQuestion"
                            required={false}
                            value={newSecretQuestion}
                            maxLength={this.MAX_SECRET_QUESTION_LENGTH}
                          />
                          <div>
                            <div>
                              <div></div>
                              <button
                                className="yt-btn x-small info"
                                onClick={this.onAddSecretQuestion}
                                disabled={
                                  !newSecretQuestion ||
                                  newSecretQuestion.length < 1
                                }
                              >
                                Add Secret Question
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      <table
                        style={{
                          marginTop: '10px',
                          display: 'inline-grid',
                          padding: '0px 20px',
                        }}
                        className="yt-table firm-table -workspace-table -auto-width"
                      >
                        <thead>
                          <th></th>
                          <th style={{ paddingLeft: '0' }}>
                            List of Questions
                          </th>
                          {isInEditMode ? <th></th> : null}
                        </thead>
                        <tbody>
                          {sqArray.map((item, i) => (
                            <tr
                              key={item.id}
                              style={{ borderBottom: 'none' }}
                            >
                              {isInEditMode ? (
                                <td
                                  style={{ minWidth: '35px' }}
                                  draggable={isInEditMode}
                                  onDragOver={e => this.onDragOver(e)}
                                  onDrop={e => this.onDrop(e, 'sq', item)}
                                  onDragStart={e =>
                                    this.onDragStart(e, 'sq', item)
                                  }
                                >
                                  <div
                                    style={
                                      isInEditMode &&
                                      draggingStart &&
                                      draggingStart != item.id
                                        ? { color: 'rgb(83 205 240)' }
                                        : {}
                                    }
                                  >
                                    <i className="far fa-long-arrow-alt-up"></i>
                                    <i className="far fa-long-arrow-alt-down"></i>
                                  </div>
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td
                                style={{
                                  paddingLeft: '0',
                                  width: '100%',
                                  overflowWrap: 'break-word',
                                }}
                                className={`-sq-index-${item.id}`}
                                draggable={isInEditMode}
                                onDragOver={e => this.onDragOver(e)}
                                onDrop={e => this.onDrop(e, 'sq', item)}
                                onDragStart={e =>
                                  this.onDragStart(e, 'sq', item)
                                }
                              >
                                {`${i + 1}. ${item.display}`}
                              </td>
                              {isInEditMode ? (
                                <td>
                                  <button
                                    className="yt-btn link x-small danger"
                                    onClick={e => {
                                      this.onDeleteSecretQuestion(item.id)
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="box">
                      <div className="heading">Watermarks</div>
                      <div className="space-between">
                        <div>
                          <div>
                            Allow users to apply watermarks on files shared
                            through Share Links.
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {enableWatermarks ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  enableWatermarks: !enableWatermarks,
                                })
                              }
                              rounded={true}
                              value={enableWatermarks}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="space-between">
                        <div style={{ width: '50%' }}>
                          <div>
                            Define available watermarks to apply them on files
                            shared through Share Links.
                          </div>
                          {isInEditMode && (
                            <div>
                              <button
                                className="yt-btn x-small info"
                                onClick={this.onAddWatermark}
                              >
                                Add Watermark
                              </button>
                            </div>
                          )}
                          <table
                            style={{
                              marginTop: '10px',
                              padding: '0px 20px',
                            }}
                            className="yt-table firm-table -workspace-table -auto-width"
                          >
                            <thead>
                              <th style={{ paddingLeft: '0' }}>
                                List of Watermarks
                              </th>
                              {isInEditMode ? <th></th> : null}
                            </thead>
                            <tbody>
                              {watermarks.map((item, i) => item.status==="visible" && (
                                <tr
                                  key={item.id}
                                  style={{ borderBottom: 'none' }}
                                >
                                  <td
                                    style={{
                                      paddingLeft: '0',
                                      width: '100%',
                                      overflowWrap: 'break-word',
                                    }}
                                    className={`-sq-index-${item.id}`}
                                  >
                                    {`${item.text}`}
                                  </td>
                                  <td>
                                    <button
                                      className="yt-btn link x-small danger"
                                      onClick={e => {
                                        this.onPreviewWatermark(item)
                                      }}
                                    >
                                      Preview
                                    </button>
                                  </td>
                                  {isInEditMode ? (
                                    <>
                                      <td>
                                        <button
                                          className="yt-btn link x-small danger"
                                          onClick={e => {
                                            this.onEditWatermark(item, i)
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          className="yt-btn link x-small danger"
                                          onClick={e => {
                                            this.onDeleteWatermark(item, i)
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </>
                                  ) : null}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {
                          this.state.watermarksModal.isOpen && 
                          <WatermarkModal
                            type={this.state.watermarksModal.type}
                            watermarkToUpdate={this.state.watermarksModal.selectedWatermark}
                            handleSubmit={(item, index) => {
                              const watermark = { ...item, _firm: parseInt(this.props.match.params.firmId) };
                              let updatedWatermarks = [...this.state.watermarks];

                              if (!watermark._id) {
                                delete watermark._id
                                if (index!==undefined) {
                                  updatedWatermarks[index] = watermark;
                                } else {
                                  updatedWatermarks.push(watermark);
                                }
                              } else {
                                updatedWatermarks = updatedWatermarks.map((w) => w._id === watermark._id ? watermark : w);
                              }

                              this.setState({
                                watermarks: updatedWatermarks,
                                watermarksModal: {
                                  isOpen: false,
                                  type: "create",
                                  selectedWatermark: undefined,
                                },
                              });
                            }}
                            handleClose={() => {
                              this.setState({
                                watermarksModal: {
                                  isOpen: false,
                                  type: "create",
                                  selectedWatermark: undefined,
                                },
                              });
                            }}
                            watermarkIndex={this.state.watermarksModal.watermarkIndex}
                          />
                        }
                      </div>
                    </div>

                    <div className="box">
                      <div className="heading">Contact User</div>

                      <div className="space-between">
                        <div>
                          <div>Allow Contacts to Create New Folders</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowCreateFolder ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowCreateFolder: !allowCreateFolder,
                                })
                              }
                              rounded={true}
                              value={allowCreateFolder}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Allow Contacts to Delete Files</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowDeleteFiles ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowDeleteFiles: !allowDeleteFiles,
                                })
                              }
                              rounded={true}
                              value={allowDeleteFiles}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Allow Contacts to Rename Files</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowRenameFiles ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowRenameFiles: !allowRenameFiles,
                                })
                              }
                              rounded={true}
                              value={allowRenameFiles}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-between">
                        <div>
                          <div>Allow Contacts to Move Files</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {allowMoveFiles ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  allowMoveFiles: !allowMoveFiles,
                                })
                              }
                              rounded={true}
                              value={allowMoveFiles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box">
                      <div className="heading">Email Personalization</div>

                      <div className="space-between">
                        <div>
                          <div>Use Logged-In User Name & Email Address</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {email_useLoggedInUserInfo
                              ? 'Yes'
                              : 'Use the following information instead:'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  email_useLoggedInUserInfo:
                                    !email_useLoggedInUserInfo,
                                })
                              }
                              rounded={true}
                              value={email_useLoggedInUserInfo}
                            />
                          </div>
                        </div>
                      </div>
                      {!email_useLoggedInUserInfo ? (
                        <div className="space-between">
                          <div>
                            <div>From Name</div>
                            <div className="-to-display">
                              <i className="fal fa-angle-right"></i>
                              {email_fromName}
                            </div>
                            <div className="-to-update">
                              <TextInput
                                change={this.onInputElementChange}
                                label=""
                                helpText='Name to be shown to the recipients before the "From" email address'
                                name="email_fromName"
                                required={!email_useLoggedInUserInfo}
                                value={email_fromName ? email_fromName : ''}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {!email_useLoggedInUserInfo ? (
                        <div className="space-between">
                          <div>
                            <div>Reply-To Email Address</div>
                            <div className="-to-display">
                              <i className="fal fa-angle-right"></i>
                              {email_replyTo}
                            </div>
                            <div className="-to-update">
                              <TextInput
                                change={this.onInputElementChange}
                                label=""
                                helpText={`Email address to receive emails in, from the clients when they reply to emails generated by ${brandingName.title}`}
                                name="email_replyTo"
                                required={!email_useLoggedInUserInfo}
                                value={email_replyTo ? email_replyTo : ''}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="space-between">
                        <div>
                          <div>Use Firm Wide Inactivity Logout Time</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {enableAutoLogout ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  enableAutoLogout: !enableAutoLogout,
                                })
                              }
                              rounded={true}
                              value={enableAutoLogout}
                            />
                          </div>
                        </div>
                        <div>
                          <div>Inactivity Logout Time(Minutes)</div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {inactivityLogoutTime}
                          </div>
                          <div className="-to-update flex-row">
                            <div className="col-6">
                              <NumberInput
                                change={this.onInputElementChange}
                                helpText="Enter the number of minutes after which to log out an inactive staff member."
                                name="inactivityLogoutTime"
                                required={enableAutoLogout}
                                value={
                                  isNaN(inactivityLogoutTime) ||
                                  inactivityLogoutTime < 1
                                    ? 1
                                    : inactivityLogoutTime
                                }
                                disabled={!enableAutoLogout}
                                firefoxDisplayFix={true}
                                min="1"
                                step="1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box">
                      <div className="heading">Contacts Banner Message</div>
                      <div className="space-between">
                        <div>
                          <div>
                            Show a banner message to all Contacts of the Firm
                            Clients
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {enableContactBannerMessage ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  enableContactBannerMessage:
                                    !enableContactBannerMessage,
                                })
                              }
                              rounded={true}
                              value={enableContactBannerMessage}
                            />
                          </div>
                        </div>
                        <div>
                          <div>Banner Message Text</div>
                          <div
                            style={
                              isInEditMode
                                ? {}
                                : { opacity: '0.5', pointerEvents: 'none' }
                            }
                          >
                            <ISReactDraftEditor
                              onChange={value => {
                                this.setState({ contactBannerMessage: value })
                              }}
                              defaultValue={
                                contactBannerMessage ? contactBannerMessage : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box">
                      <div className="heading">Account Security</div>

                      <div className="space-between">
                        <div>
                          <div>
                            Force members to enable Two Factor Authentication
                          </div>
                          <div className="-to-display">
                            <i className="fal fa-angle-right"></i>
                            {force_2fa ? 'Yes' : 'No'}
                          </div>
                          <div className="-to-update">
                            <ToggleSwitchInput
                              name=""
                              change={() =>
                                this.setState({
                                  force_2fa: !force_2fa,
                                })
                              }
                              rounded={true}
                              value={force_2fa}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="actions"
                      style={{ display: 'block' }}
                    >
                      <button
                        className="yt-btn x-small success -to-display"
                        onClick={() => this.setState({ isInEditMode: true })}
                      >
                        Edit
                      </button>
                      <button
                        className="yt-btn x-small link -to-update"
                        onClick={this.onCancel}
                        style={{ marginRight: 20 }}
                      >
                        Cancel
                      </button>
                      <button
                        className="yt-btn x-small success -to-update"
                        disabled={
                          archiveFile ==
                            (savedSettings
                              ? savedSettings.archiveFile
                              : 'None') &&
                          expireShareFile ===
                            (savedSettings
                              ? savedSettings.expireShareFile
                              : 'None') &&
                          expireRequestFile ===
                            (savedSettings
                              ? savedSettings.expireRequestFile
                              : 'None') &&
                          expireSignatureRequest ===
                            (savedSettings
                              ? savedSettings.expireSignatureRequest
                              : 'None') &&
                          authDefault ===
                            (savedSettings
                              ? savedSettings.authDefault
                              : 'Direct') &&
                          !(
                            authDefault != 'Direct' &&
                            JSON.stringify(secretQuestions).length !=
                              savedSettings.secretQuestions.length
                          ) &&
                          allowCreateFolder ===
                            (savedSettings
                              ? savedSettings.allowCreateFolder
                              : false) &&
                          allowDeleteFiles ===
                            (savedSettings
                              ? savedSettings.allowDeleteFiles
                              : false) &&
                          zipFilesDownload ===
                            (savedSettings
                              ? savedSettings.zipFilesDownload
                              : false) &&
                          allowMoveFiles ===
                            (savedSettings
                              ? savedSettings.allowMoveFiles
                              : false) &&
                          allowRenameFiles ===
                            (savedSettings
                              ? savedSettings.allowRenameFiles
                              : false) &&
                          tcContents ===
                            (savedSettings
                              ? savedSettings.tcContents
                              : false) &&
                          showNewLabel ===
                            (savedSettings
                              ? savedSettings.showNewLabel
                              : true) &&
                          showEmail ===
                            (savedSettings ? savedSettings.showEmail : true) &&
                          showCompany ===
                            (savedSettings
                              ? savedSettings.showCompany
                              : true) &&
                          fileVersionType ===
                            (savedSettings
                              ? savedSettings.fileVersionType
                              : false) &&
                          default_file_status ===
                            (savedSettings
                              ? savedSettings.default_file_status
                              : false) &&
                          allowAddRecipientFileRequest ===
                            (savedSettings
                              ? savedSettings.allowAddRecipientFileRequest
                              : false) &&
                          enable_pdftron ===
                            (savedSettings
                              ? savedSettings.enable_pdftron
                              : false) &&
                          email_useLoggedInUserInfo ===
                            (savedSettings
                              ? savedSettings.email_useLoggedInUserInfo
                              : true) &&
                          email_fromName ===
                            (savedSettings
                              ? savedSettings.email_fromName
                              : null) &&
                          email_replyTo ===
                            (savedSettings
                              ? savedSettings.email_replyTo
                              : null) &&
                          _isEqual(initialSecretQuestions, secretQuestions) &&
                          allowRequiredRecipient ===
                            (savedSettings
                              ? savedSettings.allowRequiredRecipient
                              : false) &&
                          allowChangeAuthScheme ===
                            (savedSettings
                              ? savedSettings.allowChangeAuthScheme
                              : false) &&
                          allowNonContactSigner ===
                            (savedSettings
                              ? savedSettings.allowNonContactSigner
                              : false) &&
                          showStaffFolder ===
                            (savedSettings
                              ? savedSettings.showStaffFolder
                              : false) &&
                          showGeneralFiles ===
                            (savedSettings
                              ? savedSettings.showGeneralFiles
                              : false) &&
                          showTermsAndConditions ===
                            (savedSettings
                              ? savedSettings.showTermsAndConditions
                              : false) &&
                          force_2fa ===
                            (savedSettings ? savedSettings.force_2fa : false)
                        }
                        onClick={this.onUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PracticeFirmLayout>
    )
  }
}

PracticeAdvanceSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    firmStore: store.firm,
    staffStore: store.staff,
    userStore: store.user,
  }
}
export default withRouter(connect(mapStoreToProps)(PracticeAdvanceSettings))
