/**
 * Helper component to handle pagination UI on any lists
 */

// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'react-router-dom/Link';
import { Link } from 'react-router-dom';
// import third-party libraries
import classNames from 'classnames';

// import components
import Binder from '../Binder.js.jsx';
import SearchInput from '../forms/SearchInput.js.jsx';

class PageTabber extends Binder {
  constructor(props) {
    super(props);
    this.state = {}
    this._bind(
      '_handleNext'
      , '_handlePrevious'
      , '_jumpToPage'
      , '_handleKeyDown'
    )
  }

  _handlePrevious() {
    var newPagination = this.props.pagination;
    newPagination.page--;
    this.props.setPagination(newPagination);
  }

  _handleNext() {
    var newPagination = this.props.pagination;
    newPagination.page++;
    this.props.setPagination(newPagination);
  }

  _jumpToPage(page) {
    var newPagination = this.props.pagination;
    newPagination.page = parseInt(page);
    this.props.setPagination(newPagination);
  }

  _handleKeyDown(e) {
    if (e.key === 'Enter' && this.props.handleSearch) {
      this.props.handleSearch();
    }
  }

  render() {
    const { 
      pagination
      , totalPages
      , totalItems
      , setPerPage
      , viewingAs
      , isFiltered
      , query
      , handleQuery
      , searchText
      , showSearch
      , firmId
      , clientId
      , userId
      , folderId
      , isChanged
      , staffId
      , displayAll
      , enableSearch
      , handleSearch
    } = this.props;
    let itemName = this.props.itemName;

    const urlQuery = new URLSearchParams(location.search);

    let before;
    let after;
    let currentPage = urlQuery.get('page') || pagination.page || 1;
    currentPage = parseInt(currentPage);
    let pageSize = urlQuery.get('per') || pagination.per || 50;
    pageSize = parseInt(pageSize);
    let startEntry = ((currentPage - 1) * pageSize) + (totalItems > 0 ? 1 : 0);
    let endEntry = Math.min((pageSize - 1 + startEntry), totalItems);
    if (currentPage >= 1 && currentPage <= 3) {
      before = 1;
      after = Math.min(5, totalPages);
    } else if ((currentPage+2) > totalPages) {
      after = Math.min(currentPage+2, totalPages);
      before = currentPage - ((totalPages-currentPage) === 0 ? 4 : 3);
    } else {
      before = currentPage - 2;
      after = Math.min(currentPage+2, totalPages);
    }

    after++;
    const arrayPages = _.range(before, after);

    var prevClass = classNames(
      'prev-page'
      , {'disabled': currentPage === 1 }
    )

    var nextClass = classNames(
      'next-page'
      , {'disabled': (currentPage === totalPages || totalPages < 1) }
    )

    startEntry = isNaN(startEntry) ? 0 : startEntry;
    endEntry = isNaN(endEntry) ? 0 : endEntry;

    let url; 

    if (itemName === 'archived files') {
      if (window.location.pathname === `/firm/${firmId}/workspaces/${clientId}/files/archived`) {
        url = `/firm/${firmId}/workspaces/${clientId}/files/archived`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${clientId}/workspace/archived`) {
        url = `/firm/${firmId}/files/${clientId}/workspace/archived`
      }
      if (window.location.pathname === `/firm/${firmId}/files/public/archived`) {
        url = `/firm/${firmId}/files/public/archived`
      }
      if (window.location.pathname === `/firm/${firmId}/files/public/${folderId}/folder/archived`) {
        url = `/firm/${firmId}/files/public/${folderId}/folder/archived`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${userId}/personal/archived`) {
        url = `/firm/${firmId}/files/${userId}/personal/archived`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${userId}/personal/${folderId}/folder/archived`) {
        url = `/firm/${firmId}/files/${userId}/personal/${folderId}/folder/archived`
      }
    } else if (itemName === 'archived clients') {
      if (window.location.pathname === `/firm/${firmId}/clients/archived`) {
        url = `/firm/${firmId}/clients/archived`
      }
    } else if (itemName === "workspaces") {
      if (
        [`/firm/${firmId}/workspaces`, `/firm/${firmId}/workspaces/`].includes(
          window.location.pathname,
        )
      ) {
        url = `/firm/${firmId}/workspaces`
      } else if (window.location.pathname === `/firm/${firmId}/workspaces/archived`) {
        url = `/firm/${firmId}/workspaces/archived`
      }
    } else if (itemName === "files") {
      if (window.location.pathname === `/firm/${firmId}/files/${clientId}/workspace/archived`) {
        url = `/firm/${firmId}/files/${clientId}/workspace/archived`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${clientId}/workspace/${folderId}/folder`) {
        url = `/firm/${firmId}/files/${clientId}/workspace/${folderId}/folder`
      }
      if (window.location.pathname === `/firm/${firmId}/workspaces/${clientId}/files`) {
        url = `/firm/${firmId}/workspaces/${clientId}/files`
      }
      if (window.location.pathname === `/firm/${firmId}/workspaces/${clientId}/files/${folderId}/folder`) {
        url = `/firm/${firmId}/workspaces/${clientId}/files/${folderId}/folder`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${clientId}/workspace`) {
        url = `/firm/${firmId}/files/${clientId}/workspace`
      }
      if (window.location.pathname === `/firm/${firmId}/files/public`) {
        url = `/firm/${firmId}/files/public`
      }
      if (window.location.pathname === `/firm/${firmId}/files/public/${folderId}/folder`) {
        url = `/firm/${firmId}/files/public/${folderId}/folder`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${userId}/personal`) {
        url = `/firm/${firmId}/files/${userId}/personal`
      }
      if (window.location.pathname === `/firm/${firmId}/files/${userId}/personal/${folderId}/folder`) {
        url = `/firm/${firmId}/files/${userId}/personal/${folderId}/folder`
      }
    } else if (itemName === "folders") {
      if (window.location.pathname === `/firm/${firmId}/files`) {
        url = `/firm/${firmId}/files`
      }     
      if (window.location.pathname === `/firm/${firmId}/files/personal`) {
        url = `/firm/${firmId}/files/personal`
      }
    } else if (itemName === "clients") {
      if (window.location.pathname === `/firm/${firmId}/clients`) {
        url = `/firm/${firmId}/clients`
      }   
    } else if (itemName === "contacts") {
      if (window.location.pathname === `/firm/${firmId}/contacts`) {
        url = `/firm/${firmId}/contacts`
      }
      if (window.location.pathname === `/firm/${firmId}/workspaces/${clientId}/contacts/archived`) {
        url = `/firm/${firmId}/workspaces/${clientId}/contacts/archived`
      }
    } else if (itemName === "signatures") {
      if (window.location.pathname === `/firm/${firmId}/signatures`) {
        url = `/firm/${firmId}/signatures`
      }
    } else if (itemName === "staff") {
      if (window.location.pathname === `/firm/${firmId}/settings/staff`) {
        url = `/firm/${firmId}/settings/staff`
      }
    } else if (itemName === 'assigned staff') {
      if (window.location.pathname === `/firm/${firmId}/clients/${clientId}/staff`) {
        url = `/firm/${firmId}/clients/${clientId}/staff`
      }
    } else if (itemName === 'portal') {
      url = `/portal/${clientId}/files`
      
      if (folderId) {
        url += `/folder/${folderId}`;
      }
    } else if (itemName === "adminUser") {
      url = '/admin/users';
      itemName = 'users';
    }

    return (
      <div
        className={`pagination -table-pagination py-4 px-table-edge ${viewingAs === 'bottom' ? '-tp-bottom' : '-tp-top'}`}
      >
        <div className="flex-col sm:flex-row items-center justify-between gap-3" style={{flexWrap: "wrap"}}>
          <div className="flex-row items-center justify-start -mobile-yt-hide">
            <div style={{ paddingLeft: 0 }}>
              {isFiltered ? (
                <label className="flex-row items-center justify-start gap-1">{`Matching ${startEntry}-${endEntry} of ${totalItems} ${itemName}`}</label>
              ) : (
                <label className="flex-row items-center justify-start gap-1">{`Showing ${startEntry}-${endEntry} of ${totalItems} ${itemName}`}</label>
              )}
            </div>
          </div>
          {setPerPage ? (
            <div className="flex-row items-center justify-start -mobile-yt-hide">
              <div className="flex-row items-center justify-center">|</div>
            </div>
          ) : null}
          {setPerPage ? (
            <div className="flex-row items-center justify-start">
              <div className="flex-row items-center justify-start gap-1">
                <label>
                  <span className="-mobile-yt-hide">Showing per page:</span>{' '}
                </label>
                <select
                  name="numPerPage"
                  onChange={e => setPerPage(e.target.value)}
                  value={pageSize}
                >
                  <option value={25}> 25 </option>
                  <option value={50}> 50 </option>
                  <option value={100}> 100 </option>
                  {displayAll ? <option value={totalItems}>ALL</option> : null}
                </select>
              </div>
            </div>
          ) : null}
          {enableSearch && handleQuery && viewingAs === 'top' ? (
            <div className="-pc-content-column -search-bar -mobile-yt-hide">
              <div
                className="search w-12 flex-row justify-end items-center"
                style={{ display: 'inline-flex' }}
              >
                <SearchInput
                  name="query"
                  value={query}
                  change={handleQuery}
                  placeholder={searchText}
                  required={false}
                  classNames={'ls:w-full'}
                  keydown={this._handleKeyDown}
                />
                {handleSearch && (
                  <button
                    className="yt-btn x-small rounded info"
                    style={{
                      marginLeft: '10px',
                      fontSize: '12px',
                      padding: '3px 15px',
                    }}
                    onClick={handleSearch}
                  >
                    Apply Filter
                  </button>
                )}
              </div>
            </div>
          ) : null}
          <div
            className="-pagination-tabber m-0 is:pb-1"
            style={
              enableSearch && handleQuery && viewingAs === 'top'
                ? {}
                : { marginLeft: 'auto' }
            }
          >
            <ul>
              {itemName !== 'users' ? (
                <Link
                  to={`${url}?page=${currentPage - (currentPage == 1 ? 0 : 1)}&per=${pageSize}`}
                  onClick={e => (currentPage > 1 ? null : e.preventDefault())}
                >
                  <li onClick={currentPage > 1 ? this._handlePrevious : null}>
                    <a className={prevClass}>
                      <i className="fa fa-angle-double-left" />
                      <span className="-mobile-yt-hide"> Previous</span>
                    </a>
                  </li>
                </Link>
              ) : (
                <li onClick={currentPage > 1 ? this._handlePrevious : null}>
                  <a className={prevClass}>
                    <i className="fa fa-angle-double-left" />
                    <span className="-mobile-yt-hide"> Previous</span>
                  </a>
                </li>
              )}
              {arrayPages.map(page =>
                isChanged ? (
                  <Link
                    onClick={() => this._jumpToPage(page)}
                    to={`${url}?page=${page}&per=${pageSize}`}
                  >
                    <li key={page}>
                      <a
                        className={
                          page == currentPage ? 'current-page' : 'page-num'
                        }
                      >
                        {page}
                      </a>
                    </li>
                  </Link>
                ) : (
                  <li
                    key={page}
                    onClick={() => this._jumpToPage(page)}
                  >
                    <a
                      className={
                        page == currentPage ? 'current-page' : 'page-num'
                      }
                    >
                      {page}
                    </a>
                  </li>
                ),
              )}
              {itemName !== 'users' ? (
                <Link
                  to={`${url}?page=${currentPage + (currentPage < totalPages ? 1 : 0)}&per=${pageSize}`}
                  onClick={e =>
                    currentPage < totalPages ? null : e.preventDefault()
                  }
                >
                  <li
                    onClick={currentPage < totalPages ? this._handleNext : null}
                  >
                    <a className={nextClass}>
                      <span className="-mobile-yt-hide">Next </span>
                      <i className="fa fa-angle-double-right" />
                    </a>
                  </li>
                </Link>
              ) : (
                <li
                  onClick={currentPage < totalPages ? this._handleNext : null}
                >
                  <a className={nextClass}>
                    <span className="-mobile-yt-hide">Next </span>
                    <i className="fa fa-angle-double-right" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

PageTabber.propTypes = {
  pagination: PropTypes.object.isRequired
  , totalPages: PropTypes.number.isRequired
  , setPagination: PropTypes.func.isRequired
  , totalItems: PropTypes.number.isRequired
  , itemName: PropTypes.string
  , showSearch: PropTypes.bool
  , displayAll: PropTypes.bool
  , enableSearch: PropTypes.bool
}

PageTabber.defaultProps = {
  pagination: {
    page: 1
    , per: 50
  }
  , totalPages: 1
  , itemName: "items"
  , searchText: "Search..."
  , showSearch: false
  , displayAll: false
  , enableSearch: false
  , clientId: null
}

export default PageTabber;
