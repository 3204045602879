/**
 * View component for /admin/phone-numbers/new
 *
 * Creates a new phoneNumber from a copy of the defaultItem in the phoneNumber reducer
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as phoneNumberActions from '../../phoneNumberActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminPhoneNumberForm from '../components/AdminPhoneNumberForm.js.jsx'
import AdminPhoneNumberLayout from '../components/AdminPhoneNumberLayout.js.jsx'

class AdminCreatePhoneNumber extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: _cloneDeep(this.props.defaultPhoneNumber.obj),
      formHelpers: {},
    }
    this._bind('_handleFormChange', '_handleFormSubmit')
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(phoneNumberActions.fetchDefaultPhoneNumber())
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      phoneNumber: _cloneDeep(nextProps.defaultPhoneNumber.obj),
    })
  }
  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(this.state, e.target.name, () => {
      return e.target.value
    })
    this.setState({ newState })
  }

  _handleFormSubmit(e) {
    const { dispatch, history } = this.props
    e.preventDefault()
    dispatch(
      phoneNumberActions.sendCreatePhoneNumber(this.state.phoneNumber)
    ).then(phoneNumberRes => {
      if (phoneNumberRes.success) {
        dispatch(phoneNumberActions.invalidateList())
        history.push(`/admin/phone-numbers/${phoneNumberRes.item._id}`)
      } else {
        alert('ERROR - Check logs')
      }
    })
  }

  render() {
    const { location, match } = this.props
    const { phoneNumber, formHelpers } = this.state
    const isEmpty =
      !phoneNumber ||
      phoneNumber.name === null ||
      phoneNumber.name === undefined
    return (
      <AdminPhoneNumberLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        {isEmpty ? (
          <h2> Loading...</h2>
        ) : (
          <AdminPhoneNumberForm
            phoneNumber={phoneNumber}
            cancelLink="/admin/phone-numbers"
            formHelpers={formHelpers}
            formTitle="Create Phone Number"
            formType="create"
            handleFormChange={this._handleFormChange}
            handleFormSubmit={this._handleFormSubmit}
          />
        )}
      </AdminPhoneNumberLayout>
    )
  }
}

AdminCreatePhoneNumber.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    defaultPhoneNumber: store.phoneNumber.defaultItem,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminCreatePhoneNumber))
