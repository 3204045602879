import sanitizeHtml from 'sanitize-html'

const options = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
    allowedAttributes: {
      '*': [ 'style' ],
      'img': [ 'src' ],
    },
    allowedSchemesByTag: {
      img: [ 'http', 'https', 'data' ]
    },
    transformTags: {
      '*': (tagName, attribs) => {
        if (attribs.style) {
          // Remove 'url()' and 'expression()' from style attributes
          attribs.style = attribs.style.replace(/url\s*\([^)]+\)/gi, '');
          attribs.style = attribs.style.replace(/expression\s*\([^)]+\)/gi, '');
        }
        return { tagName, attribs };
      },
      'img': (tagName, attribs) => {
        // Ensure 'src' attribute does not use 'javascript:' scheme
        if (attribs.src && attribs.src.startsWith('javascript:')) {
          delete attribs.src;
        }
        return { tagName, attribs };
      }
    },
    disallowedTagsMode: 'discard', // discard any disallowed tags
    nonTextTags: [ 'style', 'script', 'textarea', 'noscript' ], // discard contents of these tags
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto', 'data' ], // allowed schemes for URLs
    allowProtocolRelative: false
  };

const htmlSanitizeUtils = {
  /**
   * 
   * @param {string} str a "dirty" html string that needs to be sanitized
   * @returns a string where all dangerous html tags and attributes were removed
   */
    sanitizeString(str) {
        return sanitizeHtml(str, options)
    }
}

export default htmlSanitizeUtils