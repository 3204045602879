/**
 * View component for /admin/files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as fileActions from '../../fileActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminFileForm from '../components/AdminFileForm.js.jsx'
import AdminFileLayout from '../components/AdminFileLayout.js.jsx'

class AdminCreateFile extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      file: _cloneDeep(this.props.defaultFile.obj),
      formHelpers: {},
    }
    this._bind('_handleFormChange', '_handleFormSubmit')
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fileActions.fetchDefaultFile())
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      file: _cloneDeep(nextProps.defaultFile.obj),
    })
  }
  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(this.state, e.target.name, () => {
      return e.target.value
    })
    this.setState({ newState })
  }

  _handleFormSubmit(e) {
    const { dispatch, history } = this.props
    e.preventDefault()
    dispatch(fileActions.sendCreateFile(this.state.file)).then(fileRes => {
      if (fileRes.success) {
        dispatch(fileActions.invalidateList())
        history.push(`/admin/files/${fileRes.item._id}`)
      } else {
        alert('ERROR: ' + fileRes.error)
        // alert("ERROR - Check logs");
      }
    })
  }

  render() {
    const { location, match } = this.props
    const { file, formHelpers } = this.state
    const isEmpty = !file || file.name === null || file.name === undefined
    return (
      <AdminFileLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        {isEmpty ? (
          <h2> Loading...</h2>
        ) : (
          <AdminFileForm
            file={file}
            cancelLink="/admin/files"
            formHelpers={formHelpers}
            formTitle="Create File"
            formType="create"
            handleFormChange={this._handleFormChange}
            handleFormSubmit={this._handleFormSubmit}
          />
        )}
      </AdminFileLayout>
    )
  }
}

AdminCreateFile.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    defaultFile: store.file.defaultItem,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminCreateFile))
