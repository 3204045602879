/**
 * View component for /firm/:firmId/settings/advanced
 *
 * Displays firm's settings and lets the owner use edit them.
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import _cloneDeep from 'lodash/cloneDeep'
import _update from 'lodash/update'
import _uniqueId from 'lodash/uniqueId'
import { Helmet } from 'react-helmet'

// import actions
import * as firmActions from '../../firm/firmActions'
import * as staffActions from '../../staff/staffActions'
import * as clientActions from '../clientActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

// import layout components
import WorkspaceLayout from '../practice/components/WorkspaceLayout.js.jsx'

// import api utility
import permissions from '../../../global/utils/permissions'

class ClientSettingsLinks extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      isInEditMode: false,
      savedSettings: {}, // object containing the firm settings stored in the db to check if the state on the screen has changed or not
      editedSettings: {},
      expireShareFile: null,
      expireRequestFile: null,
      expireSignatureRequest: null,
      submitting: false,
    }
    this._bind('onInputElementChange', 'onCancel', 'onUpdate', 'holdSettings')

    this.selectedFirm = {}
  }

  componentDidMount() {
    console.log('componentDidMount')
    const { dispatch, match, loggedInUser } = this.props
    dispatch(clientActions.fetchListIfNeeded('_user', loggedInUser._id)) // this should live on every top-level route of the portal
    dispatch(firmActions.fetchListIfNeeded('_user', loggedInUser._id))
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId)).then(
      firm => {
        dispatch(clientActions.fetchSingleIfNeeded(match.params.clientId)).then(
          json => {
            if (json.success && json.item) {
              this.holdSettings(json.item)
            }
          }
        )
      }
    )
  }

  holdSettings(clientSettings) {
    const selectedFirm = this.props.firmStore.selected.getItem()

    let expireShareFile = ''
    let expireRequestFile = ''
    let expireSignatureRequest = ''

    // if expireShareFile and expireRequestFile and expireSignatureRequest is still null get the value of firm level
    if (clientSettings.expireShareFile) {
      expireShareFile = clientSettings.expireShareFile
    } else {
      if (selectedFirm.expireShareFile) {
        expireShareFile = selectedFirm.expireShareFile
      } else {
        expireShareFile = selectedFirm.expireLinks
      }
    }

    if (clientSettings.expireRequestFile) {
      expireRequestFile = clientSettings.expireRequestFile
    } else {
      if (selectedFirm.expireRequestFile) {
        expireRequestFile = selectedFirm.expireRequestFile
      } else {
        expireRequestFile = selectedFirm.expireLinks
      }
    }

    if (clientSettings.expireSignatureRequest) {
      expireSignatureRequest = clientSettings.expireSignatureRequest
    } else {
      if (selectedFirm.expireSignatureRequest) {
        expireSignatureRequest = selectedFirm.expireSignatureRequest
      } else {
        expireSignatureRequest = selectedFirm.expireLinks
      }
    }

    this.setState({
      isInEditMode: false,
      expireShareFile,
      expireRequestFile,
      expireSignatureRequest,
      savedSettings: clientSettings,
    })
  }

  onInputElementChange(e) {
    console.log('test')
    let newState = _update(this.state, e.target.name, function () {
      return e.target.value
    })
    this.setState({ newState })
  }

  onCancel() {
    const client = this.props.clientStore.selected.getItem()
    this.holdSettings(client)
  }

  onUpdate() {
    let updatedSettings = _cloneDeep(this.state.savedSettings)
    const { expireShareFile, expireRequestFile, expireSignatureRequest } =
      this.state

    updatedSettings.expireShareFile =
      expireShareFile == 0
        ? 'None'
        : isNaN(expireShareFile)
        ? 'None'
        : expireShareFile
    updatedSettings.expireRequestFile =
      expireRequestFile == 0
        ? 'None'
        : isNaN(expireRequestFile)
        ? 'None'
        : expireRequestFile
    updatedSettings.expireSignatureRequest =
      expireSignatureRequest == 0
        ? 'None'
        : isNaN(expireSignatureRequest)
        ? 'None'
        : expireSignatureRequest

    this.setState({ submitting: true }, () => {
      this.props
        .dispatch(clientActions.sendUpdateClient(updatedSettings))
        .then(json => {
          if (json.success && json.item) {
            this.holdSettings(json.item)
          } else {
            alert(json.error)
          }
          this.setState({ submitting: false })
        })
    })
  }

  render() {
    const { clientStore, staffStore, match, loggedInUser } = this.props

    const {
      isInEditMode,
      savedSettings,
      expireShareFile,
      expireRequestFile,
      expireSignatureRequest,
      submitting,
    } = this.state

    const isEmpty = !savedSettings || !savedSettings._id

    const isFetching = clientStore.selected.isFetching || submitting

    const ownerPermissions = permissions.isStaffOwner(
      staffStore,
      loggedInUser,
      match.params.firmId
    )

    return (
      <WorkspaceLayout>
        <Helmet>
          <title>Workspace Links</title>
        </Helmet>
        {isEmpty ? (
          <div className="-loading-hero hero">
            <div className="u-centerText">
              <div className="loading"></div>
            </div>
          </div>
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="-mob-layout-ytcol100 yt-row">
              <div
                className="yt-col _70"
                style={{ padding: '5px 0' }}
              >
                <div className="-practice-content">
                  <div
                    className={`-advance-setting-form ${
                      isInEditMode ? '-update-form' : '-display-form'
                    }`}
                  >
                    <div className="space-between">
                      <div>
                        <div>
                          Expire Share File Links Automatically after (Days)
                        </div>
                        <div className="-to-display">
                          <i className="fal fa-angle-right"></i>
                          {expireShareFile}
                        </div>
                        <div className="-to-update">
                          <TextInput
                            change={this.onInputElementChange}
                            label=""
                            helpText='Enter number per day (0 or blank is equivalent to "None")'
                            name="expireShareFile"
                            required={false}
                            value={
                              isNaN(expireShareFile) ? '0' : expireShareFile
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-between">
                      <div>
                        <div>
                          Expire Request File Links Automatically after (Days)
                        </div>
                        <div className="-to-display">
                          <i className="fal fa-angle-right"></i>
                          {expireRequestFile}
                        </div>
                        <div className="-to-update">
                          <TextInput
                            change={this.onInputElementChange}
                            label=""
                            helpText='Enter number per day (0 or blank is equivalent to "None")'
                            name="expireRequestFile"
                            required={false}
                            value={
                              isNaN(expireRequestFile) ? '0' : expireRequestFile
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-between">
                      <div>
                        <div>
                          Expire Signature Request Links Automatically after
                          (Days)
                        </div>
                        <div className="-to-display">
                          <i className="fal fa-angle-right"></i>
                          {expireSignatureRequest}
                        </div>
                        <div className="-to-update">
                          <TextInput
                            change={this.onInputElementChange}
                            label=""
                            helpText='Enter number per day (0 or blank is equivalent to "None")'
                            name="expireSignatureRequest"
                            required={false}
                            value={
                              isNaN(expireSignatureRequest)
                                ? '0'
                                : expireSignatureRequest
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {(ownerPermissions && (
                      <div
                        className="actions"
                        style={{ display: 'block' }}
                      >
                        {isInEditMode ? null : (
                          <button
                            className="yt-btn x-small success"
                            onClick={() =>
                              this.setState({ isInEditMode: true })
                            }
                          >
                            Edit
                          </button>
                        )}
                        {isInEditMode ? (
                          <button
                            className="yt-btn x-small link"
                            onClick={this.onCancel}
                            style={{ marginRight: 20 }}
                            disabled={submitting}
                          >
                            Cancel
                          </button>
                        ) : null}
                        {isInEditMode ? (
                          <button
                            className="yt-btn x-small success"
                            disabled={
                              expireShareFile ===
                                (savedSettings
                                  ? savedSettings.expireShareFile
                                  : 'None') &&
                              expireRequestFile ===
                                (savedSettings
                                  ? savedSettings.expireRequestFile
                                  : 'None') &&
                              expireSignatureRequest ===
                                (savedSettings
                                  ? savedSettings.expireSignatureRequest
                                  : 'None') &&
                              submitting
                            }
                            onClick={this.onUpdate}
                          >
                            Update
                          </button>
                        ) : null}
                      </div>
                    )) ||
                      null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </WorkspaceLayout>
    )
  }
}

ClientSettingsLinks.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    clientStore: store.client,
    firmStore: store.firm,
    staffStore: store.staff,
    userStore: store.user,
    loggedInUser: store.user.loggedIn.user,
  }
}
export default withRouter(connect(mapStoreToProps)(ClientSettingsLinks))
