/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

import * as fileActions from '../fileActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'
import FileInput from '../../../global/components/forms/FileInput.js.jsx'
import SelectFromArray from '../../../global/components/forms/SelectFromArray.js.jsx'
import routeUtils from '../../../global/utils/routeUtils'

// import file components
import FileLocation from './FileLocation.js.jsx'
import * as firmActions from '../../firm/firmActions'
import {
  createFolders,
  createItemUploads,
  notifyUpload,
} from '../fileActions.js'

class UploadFilesModal extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      submitting: false,
      status: props.filePointers.status ? props.filePointers.status : 'visible',
      uploadItems: {},
      match: props.match,
      folders: [],
      folderList: [],
      uuid: props.uuid ? props.uuid : '',
      selectedFolder: {
        _client: props.match.params.clientId
          ? props.match.params.clientId
          : null,
        _personal: props.match.params.userId ? props.match.params.userId : '',
        _id: props.match.params.folderId ? props.match.params.folderId : '',
      },
      searchListArgs: {
        searchFirmId: props.match.params.firmId,
        searchClientId: props.match.params.clientId,
        searchFolderId: props.match.params.folderId,
        searchPersonalId: props.match.params.userId,
        searchPageNumber: '1',
        searchPerPage: '50',
        searchSortName: 'updated_at',
        searchSortAsc: 'desc',
        searchViewingAs: 'workspace-view',
      },
    }
    this._bind(
      '_handleClose',
      '_handleFormSubmit',
      '_handleFilesChange',
      '_handleStatusChange',
      '_handleLocationChange'
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.folderId &&
      prevProps.match.params.folderId != prevState.match.params.folderId
    ) {
      const selectedFolder = _cloneDeep(this.state.selectedFolder)

      selectedFolder._id = prevProps.match.params.folderId
      selectedFolder._personal = prevProps.match.params.userId
      selectedFolder._client = prevProps.match.params.clientId
      this.setState({ selectedFolder, match: prevProps.match })
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    // this.fetchRecentFiles(dispatch);
  }

  // fetchRecentFiles(dispatch) {
  //   const searchParams = new URLSearchParams(this.props.location.search);
  //   const page = searchParams.get('page');
  //   const per = searchParams.get('per');
  //   const searchListArgs = this.state.searchListArgs;
  //   searchListArgs.searchPageNumber = page || searchListArgs.searchPageNumber;
  //   searchListArgs.searchPerPage = per || searchListArgs.searchPerPage;
  //   dispatch(fileActions.invalidateList());
  //   dispatch(fileActions.fetchListIfNeededV2(searchListArgs, ...routeUtils.listArgsFromObject(searchListArgs)));
  // }

  _handleFilesChange(files, folders) {
    console.log('-------- files -----')
    console.log(files)
    console.log(folders)
    this.setState({ files, folders })
  }

  _handleStatusChange(e) {
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  async _handleFormSubmit(e) {
    const {
      match,
      selectedClient,
      location,
      folderListItems,
      viewingAs,
      filePointers,
      firm,
      uuid,
      dispatch,
      listArgs,
      handleUploaded,
    } = this.props

    console.log('this.props', this.props)
    if (e) {
      e.preventDefault()
    }

    // convert to a FormData objet to allow uploading file=
    let { files, status, folders, folderList } = this.state
    files = files.filter(
      file => !file.virusDetected && !file.fileNotFound && file.isValidFileName,
    )

    if (!files.length && !folders.length) {
      alert('No files present')
    } else {
      const selectedFolder = _cloneDeep(this.state.selectedFolder)
      // save new files list;
      this.setState({ files })

      const _folder = selectedFolder._id || ''
      const _personal = selectedFolder._personal
        ? isNaN(selectedFolder._personal)
          ? ''
          : selectedFolder._personal
        : ''

      let mangoSubFolder
      let rootFolder

      if (match.params.folderId) {
        const urls = location.state.breadcrumbs
        let rootFolderId = null
        if (viewingAs === 'portal') {
          const rootFolderPath = urls.length >= 2 ? urls[1].path.split('/') : []
          rootFolderId =
            rootFolderPath.length >= 1
              ? rootFolderPath[rootFolderPath.length - 1]
              : null
        } else {
          const rootFolderPath = urls.length >= 3 ? urls[2].path.split('/') : []
          rootFolderId =
            rootFolderPath.length >= 2
              ? rootFolderPath[rootFolderPath.length - 2]
              : null
        }

        mangoSubFolder = match.params.folderId

        rootFolder = rootFolderId
      }

      const params = {
        status:
          firm && firm.default_file_status !== ''
            ? firm.default_file_status
            : status,
        _folder,
        _personal,
        viewingAs: viewingAs === 'default' ? 'workspace' : viewingAs,
        ParentID: mangoSubFolder ? mangoSubFolder : null,
        YellowParentID: rootFolder ? rootFolder : null,
        client: selectedClient,
        ...filePointers,
        uuid: uuid ? uuid : '',
      }

      console.log('file params', params)

      this.setState({ submitting: true })

      try {
        const createdFolders = await dispatch(
          createFolders({ folders, params, listArgs })
        )
        const uploads = dispatch(
          createItemUploads({
            files,
            folders: createdFolders,
            params,
          })
        ).map(async upload => {
          for await (const { type, value } of upload) {
            if (type === `progress`) {
              const {
                root: { _id, name },
                percent,
              } = value
              this.setState(prevState => ({
                uploadItems: {
                  ...prevState.uploadItems,
                  [_id]: {
                    ...prevState.uploadItems[_id],
                    name,
                    percent,
                  },
                },
              }))
            }

            if (type === `failedMessages`) {
              const {
                root: { _id, name },
                failedMessages,
              } = value

              this.setState(prevState => ({
                uploadItems: {
                  ...prevState.uploadItems,
                  [_id]: {
                    ...prevState.uploadItems[_id],
                    name,
                    failedMessages,
                  },
                },
              }))
            }

            if (type === `done`) {
              return value
            }
          }
        })

        const results = await Promise.allSettled(uploads)
        const { roots, createdFiles, failedMessages } = results
          .map(({ value }) => value)
          .reduce(
            (acc, { root, createdFiles, failedMessages }) => {
              acc.roots = [...acc.roots, root]
              acc.createdFiles = [...acc.createdFiles, ...createdFiles]
              acc.failedMessages = [...acc.failedMessages, ...failedMessages]
              return acc
            },
            {
              roots: [],
              createdFiles: [],
              failedMessages: [],
            }
          )

        if (createdFiles.length) {
          await dispatch(notifyUpload({ files: createdFiles, params }))
          dispatch(firmActions.invalidateTotalFileSize(firm._id))

          if (handleUploaded) {
            handleUploaded(roots)
          }
        }

        if (failedMessages.length) {
          const alertString =
            'There was a problem uploading your files.\n' +
            failedMessages.join('\n')
          alert(alertString)
        }
      } catch (e) {
        alert(e.message)
      }

      if (this.props.handleSetInvalidList) {
        this.props.handleSetInvalidList()
      }

      this._handleClose()
    }
  }

  _handleClose() {
    const { close, match } = this.props
    this.setState(
      {
        files: [],
        submitting: false,
        uploadItems: {},
        selectedFolder: {
          _client: match.params.clientId ? match.params.clientId : null,
          _personal: match.params.userId ? match.params.userId : '',
          _id: match.params.folderId ? match.params.folderId : '',
        },
      },
      () => {
        if (close) {
          close()
        }
      }
    )
  }

  _handleLocationChange(folder) {
    this.setState({ selectedFolder: folder })
  }

  render() {
    const {
      isOpen,
      multiple,
      showStatusOptions,
      folderListItems,
      selectedClient,
      viewingAs,
      firm,
    } = this.props

    const { files, uploadItems, submitting, selectedFolder } = this.state
    // const btnFileValid = !files.some(f => !f.virusDetected && !f.fileNotFound);

    return (
      <Modal
        closeAction={this._handleClose}
        closeText="Cancel"
        confirmAction={files.length > 0 ? this._handleFormSubmit : null}
        confirmText={submitting ? 'Uploading...' : 'Upload & save'}
        disableConfirm={
          submitting ||
          !files ||
          files.length < 1 ||
          files.every(f => !f.isValidFileName)
        }
        isOpen={isOpen}
        modalHeader="Upload files"
        modalClasses="upload-files-modal"
      >
        {!submitting ? (
          <FileInput
            change={this._handleFilesChange}
            label="Select Files"
            multiple={multiple}
            required={true}
            dispatch={this.props.dispatch}
          />
        ) : (
          Object.entries(uploadItems).map(
            ([id, { name, percent, failedMessages }]) => (
              <div
                key={id}
                style={{ padding: '1em' }}
              >
                <p>
                  <small>
                    <strong>{name}</strong>
                  </small>

                  {failedMessages && failedMessages.length
                    ? ` - ${failedMessages[0]}`
                    : ` - ${percent}%`}
                </p>
                <div className={`progress-bar-${percent}`}>
                  <div className="-progress">
                    <div className="-complete"></div>
                  </div>
                </div>
              </div>
            )
          )
        )}
        <div className="-container-upload-location">
          {showStatusOptions && firm && firm.default_file_status === '' ? (
            <SelectFromArray
              items={['hidden', 'visible']}
              change={this._handleStatusChange}
              label="Visibility to client"
              name="status"
              value={this.state.status}
            />
          ) : (
            ''
          )}
          {
            // fromFiles ? null :
            viewingAs === 'default' || submitting ? null : (
              <FileLocation
                selectedClient={selectedClient}
                handleLocationChange={this._handleLocationChange}
                folderListItems={folderListItems}
                listArgs={this.props.listArgs}
                allowCreateFolder={
                  (firm.allowCreateFolder && viewingAs === 'portal') ||
                  viewingAs !== 'portal'
                }
                handleSetInvalidList={this.props.handleSetInvalidList}
                getDetail={this.props.getDetail}
                viewingModal="uploadFiles"
                viewingAs={viewingAs}
              />
            )
          }
        </div>
      </Modal>
    )
  }
}

UploadFilesModal.propTypes = {
  close: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  filePointers: PropTypes.object,
  handleUploaded: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  multiple: PropTypes.bool,
  showStatusOptions: PropTypes.bool,
  folderListItems: PropTypes.array,
  uuid: PropTypes.string,
}

UploadFilesModal.defaultProps = {
  filePointers: {},
  multiple: true,
  showStatusOptions: false,
  folderListItems: [],
  uuid: '',
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    fileStore: store.file,
    firm: store.firm.selected.getItem(),
  }
}

export default withRouter(connect(mapStoreToProps)(UploadFilesModal))
