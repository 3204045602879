/**
 * View for route /firm/:firmId/clients/import
 */

// import primary libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import classNames from 'classnames'

// import global components
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import FileInput from '../../../../global/components/forms/FileInput.js.jsx'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'
import Modal from '../../../../global/components/modals/Modal.js.jsx'
import PracticeLayout from '../../../../global/practice/components/PracticeLayout.js.jsx'
import ActiveStaffListItem from '../../../staff/components/ActiveStaffListItem.js.jsx'
import brandingName from '../../../../global/enum/brandingName.js.jsx'

// import actions
import * as clientActions from '../../clientActions'
import * as clientUserActions from '../../../clientUser/clientUserActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'
import * as staffClientActions from '../../../staffClient/staffClientActions'

// import utils
import inviteUtils from '../../../../global/utils/inviteUtils'
import routeUtils from '../../../../global/utils/routeUtils'

// import component
import PracticeClientImportResult from '../components/PracticeClientImportResult.js.jsx'
import ClientNotificationForm from '../../../notification/components/ClientNotificationForm.js.jsx'
import StaffNotificationForm from '../../../notification/components/StaffNotificationForm.js.jsx'
import withSocket from '../../../../global/components/WithSocket.jsx'
import { cloneDeep } from 'lodash/lang'
import { update } from 'lodash/object'

const PracticeClientBulkImport = props => {
  const {
    staffStore,
    userStore,
    staffMap,
    match,
    dispatch,
    history,
    socket,
    location,
  } = props
  const { firmId } = match.params

  const [bulkClientObj, setBulkClientObj] = useState(null)
  const [progressPercent, setProgressPercent] = useState(0)
  const [responseData, setResponseData] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [unhandledErrors, setUnhandledErrors] = useState(null)
  const [warningModal, setWarningModal] = useState(false)
  const [clientNotification, setClientNotification] = useState({
    sN_upload: true,
    sN_viewed: true,
    sN_downloaded: true,
    sN_sendMessage: true,
    sN_leaveComment: true,
    sN_autoSignatureReminder: true,
  })
  const [staffNotification, setStaffNotification] = useState({
    sN_upload: true,
    sN_viewed: true,
    sN_downloaded: true,
    sN_leaveComment: true,
    sN_sendMessage: true,
    sN_viewSignatureRequest: true,
    sN_signingCompleted: true,
    sN_autoSignatureReminder: true,
  })
  const staffClientArgs = {
    _firm: firmId,
    '~staff.status': 'active',
  }
  const [step, setStep] = useState('import-client')
  const [selectedStaffIds, setSelectedStaffIds] = useState([])

  useEffect(() => {
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(firmId))
    dispatch(staffActions.fetchListIfNeeded('_firm', firmId))
    dispatch(userActions.fetchListIfNeeded('_firm', firmId))
    dispatch(userActions.fetchListIfNeeded('_firmStaff', firmId))
    const clientArgs = routeUtils.listArgsFromObject(staffClientArgs)
    dispatch(staffClientActions.fetchListIfNeeded(...clientArgs))
    dispatch(staffClientActions.fetchDefaultStaffClient())

    socket.on('upload_status', progress => {
      setProgressPercent(progress)
      setStep('progress')
    })

    socket.on('finish_upload', stats => {
      setProgressPercent(100)
      setSubmitting(false)
      setResponseData(stats)
      setStep('import-result')
      dispatch(
        clientActions.invalidateList('_firm', firmId, 'status', 'visible')
      )
      dispatch(clientActions.invalidateList('engagement-types', firmId))
      dispatch(userActions.invalidateList('_firm', firmId))
    })

    socket.on('upload_error', error => {
      setSubmitting(false)
      alert(error)
      _close()
    })

    return () => {
      socket.off('upload_status')
      socket.off('finish_upload')
      socket.off('upload_error')
      dispatch(clientActions.fetchList('_firm', firmId))
      dispatch(userActions.fetchList('_firm', firmId))
      dispatch(clientUserActions.fetchList('_firm', firmId))
    }
  }, [])

  const _handleFilesChange = files => {
    setStep('preparing')
    let submitObj = { firmId: firmId }
    let [file] = files
    let reader = new FileReader()

    reader.onload = () => {
      const clientObj = inviteUtils.getCSVSubmitObj(reader.result, submitObj)
      if (clientObj.success) {
        setBulkClientObj(clientObj)
        setStep('import-review')
        setUnhandledErrors(clientObj.errors && clientObj.errors.length > 0)
      } else {
        alert(clientObj.message)
        setStep(clientObj.newClients ? 'import-review' : 'import-client')
      }
    }

    reader.readAsBinaryString(file)
  }

  const _handleInviteClients = uploadOnly => {
    setStep('submitting')

    if (bulkClientObj) {
      const newBulkClientObj = cloneDeep(bulkClientObj)
      newBulkClientObj.clientNotification = clientNotification
      newBulkClientObj.staffNotification = staffNotification
      newBulkClientObj.uploadOnly = uploadOnly

      setSubmitting(true)
      setWarningModal(false)
      setBulkClientObj(newBulkClientObj)

      if (selectedStaffIds.length) {
        newBulkClientObj.selectedStaffs = selectedStaffIds.map(
          id => id && staffMap && staffMap[id],
        )
      }

      dispatch(clientActions.sendBulkInviteClients(newBulkClientObj)).then(
        clientRes => {
          setStep('import-result')
          if (!clientRes.success) {
            alert('ERROR - Check logs')
          }
        },
      )
    } else {
      setSubmitting(false)
      alert('Please upload a valid CSV and try again.')
    }
  }

  const _close = () => {
    setBulkClientObj(null)
    setResponseData(null)
    setSubmitting(false)
    setIsOpen(false)
    setClientNotification({
      sN_upload: true,
      sN_viewed: true,
      sN_downloaded: true,
      sN_sendMessage: true,
      sN_leaveComment: true,
      sN_autoSignatureReminder: true,
    })
    setStaffNotification({
      sN_upload: true,
      sN_viewed: true,
      sN_downloaded: true,
      sN_leaveComment: true,
      sN_sendMessage: true,
      sN_viewSignatureRequest: true,
      sN_signingCompleted: true,
      sN_autoSignatureReminder: true,
    })
    setStep('import-client')
    history.push(`/firm/${firmId}/workspaces`)
  }

  const _exportErrorReport = () => {
    const csv = inviteUtils.generateErrorReport(bulkClientObj.errors)
    const filename = `CLIENT_IMPORT_ERRORS_${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}.csv`
    let csvFile = new Blob([csv], { type: 'text/csv' })
    let data = URL.createObjectURL(csvFile)
    let link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', filename)
    link.click()
    setUnhandledErrors(false)
  }

  const _exportResultsReport = () => {
    const csv = inviteUtils.generateResultsReport(responseData)
    const filename = `CLIENT_IMPORT_RESULTS_${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}.csv`
    let csvFile = new Blob([csv], { type: 'text/csv' })
    let data = URL.createObjectURL(csvFile)
    let link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', filename)
    link.click()
  }

  const nextStep = newStep => {
    setStep(newStep)
  }

  const showWarningModal = () => {
    setWarningModal(true)
  }

  const _handleNotificationChange = (name, value) => {
    const newState = update(bulkClientObj, name, () => value)
    const notificationType = name.split('.')[0]
    const notificationName = name.split('.')[1]

    if (notificationType === 'clientNotification') {
      setClientNotification({
        ...clientNotification,
        [notificationName]: value,
      })
    }
    if (notificationType === 'staffNotification') {
      setStaffNotification({
        ...staffNotification,
        [notificationName]: value,
      })
    }

    setBulkClientObj(newState)
  }

  const stepUi = {
    'import-client': {
      confirmAction: null,
      confirmText: null,
      closeText: null,
      modalHeader: 'Step 1 of 5',
      cardSize: 'standard',
    },
    preparing: {
      confirmAction: null,
      confirmText: null,
      closeText: null,
      modalHeader: 'Step 1 of 5',
      cardSize: 'standard',
    },
    'import-review': {
      confirmAction: () =>
        !bulkClientObj.hasStaffEmail
          ? nextStep('assign-staff')
          : nextStep('set-notification'),
      confirmText: 'Next',
      closeText: 'Cancel',
      modalHeader: 'Step 2 of 5',
      cardSize: 'jumbo',
    },
    'assign-staff': {
      confirmAction: () => nextStep('set-notification'),
      confirmText: 'Next',
      closeText: 'Cancel',
      modalHeader: 'Step 3 of 5',
      cardSize: 'jumbo',
    },
    'set-notification': {
      confirmAction:
        bulkClientObj && bulkClientObj.hasContactEmail
          ? () => showWarningModal()
          : _handleInviteClients,
      confirmText: 'Continue importing',
      closeText: 'Cancel',
      modalHeader: 'Step 4 of 5',
      cardSize: 'jumbo',
    },
    submitting: {
      confirmAction: null,
      confirmText: null,
      closeText: null,
      modalHeader: 'Step 4 of 5',
      cardSize: 'standard',
    },
    progress: {
      confirmAction: _close,
      confirmText: 'Continue in background',
      closeText: 'Cancel',
      modalHeader: 'Step 4 of 5',
      cardSize: 'standard',
    },
    'import-result': {
      confirmAction: _close,
      confirmText: 'Okay',
      closeText: 'Cancel',
      modalHeader: 'Step 5 of 5',
      cardSize: 'jumbo',
    },
  }

  const errorCount = bulkClientObj ? bulkClientObj.errors.length : null
  const lineCount = bulkClientObj
    ? bulkClientObj.newClients.length + bulkClientObj.errors.length
    : null
  const staffListItems = staffStore.util.getList('_firm', match.params.firmId)
  const availableStaff =
    step === 'assign-staff' && staffListItems && staffListItems.length
      ? staffListItems.flatMap(staff => {
          let item = staff
          let fullName = userStore.byId[staff._user]
            ? `${userStore.byId[staff._user].firstname} ${
                userStore.byId[staff._user].lastname
              }`
            : ''
          let userName = userStore.byId[staff._user]
            ? userStore.byId[staff._user].username
            : ''
          item.displayName = `${fullName} | ${userName}`
          item.fullName = fullName
          item.userName = userName
          return staff && staff.status === 'active' ? item : []
        })
      : []

  let progressClass = classNames(`progress-bar-${progressPercent || 0}`)

  console.log('bulkClientObj', bulkClientObj)

  return (
    <PracticeLayout>
      <div className="-practice-subnav">
        <div className="yt-container fluid">
          <div className="yt-row center-vert space-between">
            <Breadcrumbs links={location.state.breadcrumbs} />
          </div>
        </div>
      </div>
      <Modal
        btnColor="info"
        closeAction={_close}
        isOpen={isOpen}
        // If they've already submitted the import it's too late to cancel. Get rid of the cancel button
        // disableConfirm={this.state.unhandledErrors || (!submitting && (bulkClientObj ? bulkClientObj.newClients.length ? false : true : false))}
        modalClasses="info client-import-modal"
        cardSize={stepUi[step].cardSize}
        closeText={stepUi[step].closeText}
        confirmAction={stepUi[step].confirmAction}
        confirmText={stepUi[step].confirmText}
        modalHeader={stepUi[step].modalHeader}
      >
        {
          // submitting ?
          // SUBMITTING LOADING
          step === 'preparing' || step === 'submitting' ? (
            <div className="-loading-hero hero">
              <div className="u-centerText">
                <div className="loading"></div>
              </div>
            </div>
          ) : step === 'progress' ? (
            <div className="yt-container">
              <div className="upload-progress-container">
                <p>{`Import Progress ${progressPercent}%`}</p>
                <div className={progressClass}>
                  <div className="-progress">
                    <div className="-complete"></div>
                  </div>
                </div>
              </div>
              <br />
              <div className="yt-row">
                <p>
                  Taking too long? We can finish this in the background while
                  you do something else.
                </p>
              </div>
              <div className="yt-row">
                <p>
                  <strong>
                    You'll see your import progress at the top of the page.
                  </strong>
                </p>
              </div>
            </div>
          ) : step === 'import-client' ? (
            <div className="yt-container -modal-importing">
              <div className="yt-row">
                <h3 style={{ margin: '1em 0' }}>Upload your CSV file</h3>
                {/* <div className="alert-message warning -left"><strong>WARNING: </strong> This will automatically send invitations to all emails listed in the CSV.</div> */}
                <div className="yt-col full ">
                  <br />
                  <div className="yt-row">
                    <p>
                      Import data into {brandingName.title} from CSV files.{' '}
                      <a
                        href="/client_bulk_import_template_csv.csv"
                        target="_blank"
                        download
                      >
                        Click here to download a CSV template.
                      </a>
                    </p>
                  </div>
                  <div className="yt-row">
                    <div className="yt-col full">
                      {submitting ? (
                        <div className="-loading-hero hero">
                          <div className="u-centerText">
                            <div className="loading"></div>
                          </div>
                        </div>
                      ) : (
                        <FileInput
                          accepts={['.csv']}
                          multiple={false}
                          change={_handleFilesChange}
                          required={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 'import-review' ? (
            <div className="table-wrapper -invitation-results">
              <h3 style={{ margin: '1em 0' }}>Review Import</h3>
              {unhandledErrors ? (
                <a
                  onClick={_exportErrorReport}
                  className="yt-btn warn u-pullRight small"
                >
                  Download error report
                </a>
              ) : null}
              <p>
                <strong>Clients to import: </strong>{' '}
                {bulkClientObj.newClients.length}
              </p>
              {unhandledErrors ? (
                <p>
                  <strong>Errors found: </strong> {errorCount || '0'}
                </p>
              ) : null}
              {unhandledErrors ? (
                <div>
                  <strong>
                    <p className="u-danger">
                      {errorCount && lineCount
                        ? `We found errors with ${errorCount} of the ${lineCount} clients in your csv file!`
                        : null}
                    </p>
                  </strong>
                  <p>
                    {`Don't worry, you can still import the other ${bulkClientObj.newClients.length} clients, but first click above to download an error report in the same format as your upload.`}
                  </p>
                  <p>You can fix the errors and import those clients later.</p>
                </div>
              ) : (
                <p className="u-success">
                  <strong>Ready to import!</strong>
                </p>
              )}
              <table className="yt-table -multiple-data">
                <thead style={{ display: 'block!important' }}>
                  <tr>
                    <th></th>
                    <th>Client ID</th>
                    <th>Client Name</th>
                    <th>Engagement Type</th>
                    <th>Folder Template</th>
                    <th>Contact Address</th>
                    <th>Contact Number</th>
                    <th>Contact Name</th>
                    <th>Contact Email</th>
                    <th>Staff Name</th>
                    <th>Staff Email</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {unhandledErrors
                    ? bulkClientObj.errors.map((client, i) => (
                        <tr key={'errorClient_' + i}>
                          <td>
                            <i className="u-danger fas fa-times-octagon" />
                          </td>
                          <td>{client.clientIdentifier || '-'}</td>
                          <td>{client.clientName || '-'}</td>
                          <td>
                            {client.engagementTypes.length
                              ? client.engagementTypes.map(engagement => (
                                  <>
                                    {engagement}
                                    <br />
                                  </>
                                ))
                              : '-'}
                          </td>
                          <td>{client.folderTemplateName || '-'}</td>
                          <td>
                            <p>
                              {!client.street1 &&
                              !client.city &&
                              !client.state &&
                              !client.postal &&
                              !client.country
                                ? '-'
                                : `${client.street1} ${client.city} ${client.state} ${client.postal} ${client.country}`}
                            </p>
                          </td>
                          <td>
                            <p>{!client.number ? '-' : `${client.number}`}</p>
                          </td>
                          <td>
                            {client.primaryContact.map((contact, j) => (
                              <p key={j}>
                                {!contact.firstname && !contact.lastname
                                  ? '-'
                                  : `${contact.firstname} ${contact.lastname}`}
                              </p>
                            ))}
                          </td>
                          <td>
                            {client.primaryContact.map((contact, j) => (
                              <p key={j}>{contact.email || '-'}</p>
                            ))}
                          </td>
                          <td>
                            {client.error
                              ? client.error
                              : client.primaryContact.map((contact, j) => (
                                  <p key={j}>{contact.error || '-'}</p>
                                ))}
                          </td>
                          <td>
                            {client.staffs.map((staff, j) => (
                              <p key={j}>
                                {!staff.firstname && !staff.lastname
                                  ? '-'
                                  : `${staff.firstname} ${staff.lastname}`}{' '}
                              </p>
                            ))}
                          </td>
                          <td>
                            {client.error
                              ? client.error
                              : client.staffs.map((staff, i) => (
                                  <p key={i}>{staff.error || '-'}</p>
                                ))}
                          </td>
                        </tr>
                      ))
                    : null}
                  {bulkClientObj.newClients.map((client, i) => (
                    <tr key={'newClient_' + i}>
                      <td>
                        <i className="u-success fas fa-check" />
                      </td>
                      <td>{client.clientIdentifier || '-'}</td>
                      <td>{client.clientName || '-'}</td>
                      <td>
                        {client.engagementTypes.length
                          ? client.engagementTypes.map((engagement, j) => (
                              <React.Fragment key={'engagement_' + j}>
                                {engagement}
                                <br />
                              </React.Fragment>
                            ))
                          : '-'}
                      </td>
                      <td>{client.folderTemplateName || '-'}</td>
                      <td>
                        <p>
                          {!client.street1 &&
                          !client.city &&
                          !client.state &&
                          !client.postal &&
                          !client.country
                            ? '-'
                            : `${client.street1} ${client.city} ${client.state} ${client.postal} ${client.country}`}
                        </p>
                      </td>
                      <td>
                        <p>{!client.number ? '-' : `${client.number}`}</p>
                      </td>
                      <td>
                        {client.primaryContact.map((contact, j) => (
                          <p key={'primaryContact_' + j}>
                            {!contact.firstname && !contact.lastname
                              ? '-'
                              : `${contact.firstname} ${contact.lastname}`}{' '}
                          </p>
                        ))}
                      </td>
                      <td>
                        {client.primaryContact.map((contact, j) => (
                          <p key={'primaryContactEmail_' + j}>
                            {contact.email || '-'}{' '}
                          </p>
                        ))}
                      </td>
                      <td>
                        {client.staffs.map((staff, j) => (
                          <p key={'staff_' + j}>
                            {!staff.firstname && !staff.lastname
                              ? '-'
                              : `${staff.firstname} ${staff.lastname}`}{' '}
                          </p>
                        ))}
                      </td>
                      <td>
                        {client.staffs.map((staff, j) => (
                          <p key={'staffEmail_' + j}>{staff.email || '-'}</p>
                        ))}
                      </td>
                      <td>{client.error || ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : step === 'assign-staff' ? (
            <div className="table-wrapper -invitation-results">
              <h3 style={{ margin: '1em 0' }}>Assign Staff</h3>
              <ActiveStaffListItem
                staffListItems={availableStaff}
                staffMap={staffMap}
                viewingAs="single-client"
                handleNewStaffClient={() => console.log('hello world')}
                handleSelectStaff={staffs => setSelectedStaffIds(staffs)}
              />
            </div>
          ) : step === 'set-notification' ? (
            <div className="table-wrapper -invitation-results">
              <h3 style={{ margin: '1em 0' }}>Set Notification</h3>
              <ClientNotificationForm
                handleFormChange={_handleNotificationChange}
                clientNotification={clientNotification}
                allowedToUpdate={true}
              />
              <StaffNotificationForm
                handleFormChange={_handleNotificationChange}
                staffNotification={staffNotification}
                allowedToUpdate={true}
                noTopMargin={true}
                multiple={true}
              />
            </div>
          ) : step === 'import-result' ? (
            <div className="table-wrapper -invitation-results">
              <h3 style={{ margin: '1em 0' }}>Import Results</h3>
              <br />
              <p>
                <strong>Clients submitted: </strong>{' '}
                {responseData && responseData.results
                  ? responseData.results.length
                  : ''}
              </p>
              <p>
                <strong>Invitations sent: </strong>{' '}
                {responseData && responseData.successfulInvites
                  ? responseData.successfulInvites
                  : ''}
              </p>
              <p>
                <strong>Existing Clients: </strong>{' '}
                {responseData && responseData.existingClients
                  ? responseData.existingClients
                  : ''}
              </p>
              <p>
                <strong>Existing Users: </strong>{' '}
                {responseData && responseData.existingUsers
                  ? responseData.existingUsers
                  : ''}
              </p>
              <p>
                <strong>Errors: </strong>{' '}
                {responseData && responseData.errors ? responseData.errors : ''}
              </p>
              {responseData &&
              responseData.selectedStaffs &&
              responseData.selectedStaffs.length ? (
                <div className="yt-row">
                  <h4>Assigned Staff:</h4>
                  {responseData.selectedStaffs.map(staff => (
                    <div
                      key={staff._id}
                      className="yt-row"
                      style={{ paddingLeft: '1em' }}
                    >
                      <label className="u-muted">
                        {`${staff.fullName} | ${staff.userName}`}
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}
              <ClientNotificationForm
                handleFormChange={_handleNotificationChange}
                clientNotification={clientNotification}
                allowedToUpdate={false} // viewing only
              />
              <StaffNotificationForm
                handleFormChange={_handleNotificationChange}
                staffNotification={staffNotification}
                allowedToUpdate={false}
                noTopMargin={true}
                multiple={true}
              />
              <button
                className="yt-btn x-small u-pullRight"
                onClick={_exportResultsReport}
              >
                Download Report
              </button>
              <table className="yt-table -multiple-data">
                <caption>
                  Total:{' '}
                  {responseData && responseData.results
                    ? responseData.results.length
                    : ''}
                </caption>
                <thead>
                  <tr>
                    <th></th>
                    <th>Client ID</th>
                    <th>Client Name</th>
                    <th>Engagement Type</th>
                    <th>Folder Template</th>
                    <th>Contact Address</th>
                    <th>Contact Number</th>
                    <th>Contact Name</th>
                    <th>Contact Email</th>
                    <th>Staff Email</th>
                    <th>Result</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {responseData && responseData.results
                    ? responseData.results.map((result, i) => (
                        <PracticeClientImportResult
                          result={result}
                          index={i}
                          key={i}
                          bulkClientObj={bulkClientObj}
                        />
                      ))
                    : null}
                  {!responseData.results ||
                  responseData.results.length === 0 ? (
                    <tr>
                      <td
                        colSpan="3"
                        className="u-centerText"
                      >
                        <em>No results</em>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          ) : null
        }
      </Modal>
      <AlertModal
        alertMessage={
          <div>
            {' '}
            <h4>Are you sure?</h4> This will send an invitation email to all
            contacts uploaded.
          </div>
        }
        alertTitle="Invite clients"
        closeAction={() => setWarningModal(false)}
        confirmAction={_handleInviteClients}
        confirmText="Yes, upload and send invites"
        // declineAction={this._closeDeleteModal}
        // declineText="Never mind"
        isOpen={warningModal}
        type="danger"
        addConfirmText="Upload only"
        addConfirmAction={_handleInviteClients.bind(null, true)}
        addFooterClass="-bulk-upload"
      />
    </PracticeLayout>
  )
}

PracticeClientBulkImport.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

PracticeClientBulkImport.defaultProps = {}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    loggedInUser: store.user.loggedIn.user,
    userStore: store.user,
    staffClientStore: store.staffClient,
    staffStore: store.staff,
    defaultStaffClient: store.staffClient.defaultItem,
    staffMap: store.staff.byId,
  }
}

export default withRouter(
  connect(mapStoreToProps)(withSocket(PracticeClientBulkImport))
)
