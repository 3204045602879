/**
 * Resuable modal component for inviting new stafff members to a firm.
 *
 * Creates a new staff from a copy of the defaultItem in the staff reducer
 */

// import primary libraries
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

// import global components
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'
import EmailInput from '../../../global/components/forms/EmailInput.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

const StaffInviteForm = ({ index, invite, remove, change }) => {
  const handleFormChange = useCallback(
    e => {
      change(e, index)
    },
    [index]
  )
  return (
    <div className="invitation-form">
      <div className="yt-row space-between">
        <strong>Invitation #{index + 1}</strong>
        <button
          className="yt-btn x-small link"
          onClick={() => remove(index)}
        >
          <i className="far fa-times" />
        </button>
      </div>
      <EmailInput
        name="email"
        label="Email Address"
        value={invite.email}
        change={handleFormChange}
        required={true}
        error={invite.emailError}
        errorName="emailError"
      />
      <TextInput
        change={handleFormChange}
        label="Full Name"
        name="fullname"
        required={true}
        value={invite.fullname}
        errorName="fullnameError"
        error={invite.fullnameError}
        validationRegex={/^[a-zA-Z0-9 ]+$/}
      />
      {/* <TextInput
        change={handleFormChange}
        label="First Name"
        name="firstname"
        required={false}
        value={invite.firstname}
      />
      <TextInput
        change={handleFormChange}
        label="Last Name"
        name="lastname"
        required={false}
        value={invite.lastname}
      /> */}
      <CheckboxInput
        name="owner"
        label="This staff member has owner privileges"
        value={invite.owner}
        change={handleFormChange}
        checked={invite.owner}
      />
      <CheckboxInput
        name="showGeneralFiles"
        label="Show General Files"
        value={invite.showGeneralFiles}
        change={handleFormChange}
        checked={invite.showGeneralFiles}
      />
      <CheckboxInput
        name="showStaffFolder"
        label="Show Staff Folders"
        value={invite.showStaffFolder}
        change={handleFormChange}
        checked={invite.showStaffFolder}
      />
    </div>
  )
}

StaffInviteForm.defaultProps = {
  change: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  invite: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    owner: PropTypes.isRequired,
  }),
  remove: PropTypes.func.isRequired,
}

export default StaffInviteForm
