import React, { useState, useMemo, useCallback } from 'react'

import Select from 'react-select'
import { connect } from 'react-redux'

import Modal from '../../../global/components/modals/Modal.js.jsx'
import FileLocation from './FileLocation.js.jsx'

import * as fileActions from '../fileActions'

const FileCopyModal = ({
	isOpen,
	close,
	viewingAs,
	options,
	match,
	selectedFileIds,
	clientListItem,
	selectedClient,
	getDetail,
	dispatch,
	handleUpdateList,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [selectedFolder, setSelectedFolder] = useState({
		_client: match.params.clientId ? match.params.clientId : null,
		_personal: match.params.userId ? match.params.userId : '',
		_id: match.params.folderId ? match.params.folderId : '',
	})

	const onRootFolderChange = useCallback(
		item => {
			if (item.value == 'public') {
				setSelectedFolder({
					_client: '',
					_personal: '',
					_id: '',
				})
			} else if (item.value.toString().includes('personal')) {
				setSelectedFolder({
					_client: null,
					_personal: item.value.replace('personal', ''),
					_id: '',
				})
			} else {
				setSelectedFolder({
					_client: item.value,
					_personal: '',
					_id: '',
				})
			}
		},
		[setSelectedFolder],
	)

	const onFolderChange = useCallback(
		folder => {
			setSelectedFolder({
				...folder,
				_id: folder._id == 'rootfolder' ? null : folder._id,
			})
		},
		[setSelectedFolder],
	)

	const selectedRootFolder = useMemo(() => {
		const clientId =
			selectedFolder && selectedFolder._client ? selectedFolder._client : ''
		const selectedStaffId =
			selectedFolder && selectedFolder._personal
				? `personal${selectedFolder._personal}`
				: ''

		if (!options) {
			return null
		}

		return (
			options.find(
				folder => folder.value == (clientId || selectedStaffId || 'public'),
			) ?? null
		)
	}, [options, selectedFolder])

	const selectedClientProcessed = useMemo(() => {
		let clientId =
			selectedFolder && selectedFolder._client ? selectedFolder._client : ''
		const found = clientListItem.find(client => client._id == clientId)

		if (found) {
			return found
		}

		if (!match.params.firmId) {
			return selectedClient
		}

		return []
	}, [clientListItem, selectedFolder, selectedClient])

	const newGetDetail = useMemo(() => {
		const clientId =
			selectedFolder && selectedFolder._client ? selectedFolder._client : ''
		const selectedStaffId =
			selectedFolder && selectedFolder._personal
				? `personal${selectedFolder._personal}`
				: ''

		const newGetDetail = { ...getDetail }

		if (match.params.firmId) {
			newGetDetail.firmId = match.params.firmId
		}

		if (viewingAs != 'portal') {
			if (clientId) {
				newGetDetail.type = 'workspace'
				newGetDetail.id = clientId
			} else if (selectedStaffId) {
				newGetDetail.type = 'personal'
				newGetDetail.id = selectedStaffId
			} else {
				newGetDetail.type = 'general'
				newGetDetail.id = null
			}
		}

		return newGetDetail
	}, [getDetail, match.params.firmId, selectedFolder])

	const onSubmit = useCallback(() => {
		setIsSubmitting(true)

		dispatch(
			fileActions.sendUBulkupdateFiles({
				filesId: selectedFileIds,
				clientId: selectedFolder._client,
				_personal: selectedFolder._personal,
				_folder: selectedFolder._id,
				action: 'copy',
				firmId: match.params.firmId || (selectedClient && selectedClient._firm),
				viewingAs,
			}),
		).then(() => {
			setIsSubmitting(false)
			close()
			handleUpdateList?.()
		})
	}, [
		dispatch,
		selectedFileIds,
		setIsSubmitting,
		selectedFolder,
		match,
		selectedClient,
		viewingAs,
		handleUpdateList,
		close,
	])

	return (
		<Modal
			cardSize="standard"
			isOpen={isOpen}
			closeAction={close}
			modalHeader="Copy files"
			showButtons={true}
			confirmAction={onSubmit}
			confirmText={isSubmitting ? 'Submitting' : 'Copy'}
			disableConfirm={isSubmitting}
		>
			<div className="-container-upload-location">
				{viewingAs != 'portal' ? (
					<div style={{ margin: '16px 0' }}>
						<Select
							options={options || []}
							onChange={onRootFolderChange}
							value={selectedRootFolder}
						/>
					</div>
				) : null}
			</div>
			<FileLocation
				folderListItems={[]}
				handleLocationChange={onFolderChange}
				selectedClient={selectedClientProcessed}
				selectedFileIds={selectedFileIds}
				viewingAs={viewingAs}
				allowCreateFolder={true}
				action="copy"
				getDetail={newGetDetail}
				selectedFolder={selectedFolder}
			/>
		</Modal>
	)
}

export default connect()(FileCopyModal)
