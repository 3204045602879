import React, { useEffect } from 'react'

import CommonView from './CommonView.js.jsx'

const SpaCallback = () => {
	const query = new URLSearchParams(window.location.search)

	const isError = query.has('error')
	const message = query.get('error_description')

	useEffect(() => {
		window.opener.postMessage(window.location.search, '*')
	}, [])

	return (
		<CommonView
			isError={isError}
			message={
				isError
					? message || 'Something went wrong. Please try again later.'
					: 'Authorized. Linking account...'
			}
		/>
	)
}

export default SpaCallback
