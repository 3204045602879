import { baseApi } from '../base'

export const statisticsApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getFirmStatistics: build.query({
      query: firmId => {
        return `/statistics/${firmId}`
      },
      providesTags: (result, error, arg) => [{ type: 'Statistics', id: arg }],
    }),
    overrideExisting: false,
  }),
})

export const { useGetFirmStatisticsQuery } = statisticsApi
