/**
 * Boilerplate code for a new Redux-connected view component.
 * Nice for copy/pasting
 */

// import primary libraries
import React from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';

// import third-party libraries
import { DateTime } from 'luxon';

// import global components
import Binder from '../../components/Binder.js.jsx';
import CheckboxInput from '../../components/forms/CheckboxInput.js.jsx';
import DateRangePickerInput from '../../components/forms/dates/DateRangePickerInput.js.jsx';
import TextInput from '../../components/forms/TextInput.js.jsx';
import SearchInput from '../../components/forms/SearchInput.js.jsx';

// import resource components 
import _update from 'lodash/update'; 
import _cloneDeep from 'lodash/cloneDeep';

class PracticeMagicSearchFilter extends Binder {
  constructor(props) {
    super(props);
        this.state = {
            advanceSearch: true
            , filter: {
                dateCreated: {
                    startDate: DateTime.local().minus({ days: 30 }).toMillis()
                    , endDate: DateTime.local().toMillis()
                }
                , clientName: ""
                , creatorName: ""
                , status: {
                    visible: true
                    , hidden: true
                    , archived: true
                }
                , typeName: ""
            }
        }
        this._bind(
            '_handleFormChange'
            , '_handleClear'
            , '_handleKeyDown'
            , '_handleSearchClick'
        )
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        // fire actions
    }

    _handleClear() {
        this.setState({
            filter: {
                dateCreated: {
                    startDate: DateTime.local().minus({ days: 30 }).toMillis()
                    , endDate: DateTime.local().toMillis()
                }
                , clientName: ""
                , creatorName: ""
                , status: {
                    visible: true
                    , hidden: true
                    , archived: true
                }
                , typeName: ""
            }
        }, () => {
            const filter = _cloneDeep(this.state.filter);
            this.props.handleFilterChange(filter);
        });
    }

    componentWillUnmount() {
        this._handleClear();
    }

    _handleFormChange(e) {
        let newState = _update( this.state, e.target.name, function() {
            return e.target.value;
        });
        this.setState(newState, () => {
            if (this.props.handleFilterChange) {
                const filter = _cloneDeep(this.state.filter);
                this.props.handleFilterChange(filter);
            }
        });
    }

    _handleKeyDown(e) {
        console.log('e.key', e.key);
        if (e.key === 'Enter') {
            console.log('do validate');
            console.log('keydown press');
            this.props.handleSearch();
        }
    }
    
    _handleSearchClick() {
        this.props.handleSearch();
    }

  render() {

    const {
        handleSearch
        , handleInputChange
        , query
        , toggleAdvanceSearch
        , enableAdvanceSearch
    } = this.props;
    const {
        advanceSearch
        , filter
    } = this.state;
    const {
        dateCreated
        , clientName
        , creatorName
        , status
        , typeName
    } = filter;
    return (
        <div className="-share-link-configuration -advance-search">
            <div className="default-search">
                <SearchInput
                    change={handleInputChange}
                    // focus={() => this.setState({showResults: true})}
                    name="query"
                    placeholder="Search files, clients"
                    required={false}
                    value={query}
                    // value={this.state.query}
                    keydown={this._handleKeyDown}
                    click={this._handleSearchClick}
                    showButton={true}
                />
            </div>
            <hr/>
            <div className={`-filter-column ${enableAdvanceSearch && " " || "-inactive"}`}>
                <div>
                    <CheckboxInput
                        label="Advance File Search"
                        value={enableAdvanceSearch}
                        change={toggleAdvanceSearch}
                        checked={enableAdvanceSearch}
                        classes="-header-advance-search"
                    />
                </div>
                <div className="-advance-search-field" style={advanceSearch ? {} : { display: "none" }}>
                    <div className="-setting yt-row space-between">
                        <div className="-instructions yt-col">
                            <DateRangePickerInput
                                startDatePlaceholderText="from"
                                endDatePlaceholderText="to"
                                dateRange={dateCreated}
                                change={this._handleFormChange}
                                minDate={0}
                                name="filter.dateCreated"
                                placeholder="Date created:"
                                disabled={!enableAdvanceSearch}
                            />
                        </div>
                        <div className="-inputs yt-col _50" style={{ height: "auto"}}>
                            <TextInput
                                change={this._handleFormChange}
                                name="filter.typeName"
                                value={typeName}
                                placeholder="search file extension eg. pdf, doc, image,(or img type: png)"
                                disabled={!enableAdvanceSearch}
                            />
                            <div className="-search-file-status">
                                <CheckboxInput
                                    name="filter.status.visible"
                                    label="visible"
                                    value={status.visible}
                                    change={this._handleFormChange}
                                    checked={status.visible}
                                    disabled={!enableAdvanceSearch}
                                />
                                <CheckboxInput
                                    name="filter.status.hidden"
                                    label="hidden"
                                    value={status.hidden}
                                    change={this._handleFormChange}
                                    checked={status.hidden}
                                    disabled={!enableAdvanceSearch}
                                />
                                <CheckboxInput
                                    name="filter.status.archived"
                                    label="archived"
                                    value={status.archived}
                                    change={this._handleFormChange}
                                    checked={status.archived}
                                    disabled={!enableAdvanceSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="-setting yt-row space-between">
                        <div className="-instructions yt-col">
                            <TextInput
                                change={this._handleFormChange}
                                name="filter.clientName"
                                value={clientName}
                                placeholder="search client name"
                                disabled={!enableAdvanceSearch}
                            />
                        </div>
                        <div className="-inputs yt-col _50">
                            <TextInput
                                change={this._handleFormChange}
                                name="filter.creatorName"
                                value={creatorName}
                                placeholder="search uploader name"
                                disabled={!enableAdvanceSearch}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="-setting yt-row space-between">
                        <div className="yt-row actions" style={{ display: "flow-root" }}>
                            <div style={{ float: "right" }}>
                                <button className="yt-btn x-small link info" onClick={this._handleClear} disabled={!enableAdvanceSearch}>Clear Filter</button>
                                <button className="yt-btn x-small rounded info" style={{ marginLeft: "10px" }} onClick={handleSearch} disabled={!enableAdvanceSearch}>Apply Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

PracticeMagicSearchFilter.propTypes = {
}

PracticeMagicSearchFilter.defaultProps = {

}


const mapStoreToProps = (store) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
  }
}

export default withRouter(
    connect(
    mapStoreToProps
  )(PracticeMagicSearchFilter)
);
