/**
 * View component for /admin/phone-numbers/:phoneNumberId/update
 *
 * Updates a single phoneNumber from a copy of the selcted phoneNumber
 * as defined in the phoneNumber reducer
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as phoneNumberActions from '../../phoneNumberActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminPhoneNumberForm from '../components/AdminPhoneNumberForm.js.jsx'
import AdminPhoneNumberLayout from '../components/AdminPhoneNumberLayout.js.jsx'

class AdminUpdatePhoneNumber extends Binder {
  constructor(props) {
    super(props)
    const { match, phoneNumberStore } = this.props
    this.state = {
      phoneNumber: phoneNumberStore.byId[match.params.phoneNumberId]
        ? _cloneDeep(phoneNumberStore.byId[match.params.phoneNumberId])
        : {},
      formHelpers: {},
    }
    this._bind('_handleFormChange', '_handleFormSubmit')
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(phoneNumberActions.fetchSingleIfNeeded(match.params.phoneNumberId))
  }

  componentWillReceiveProps(nextProps) {
    const { match, phoneNumberStore } = nextProps
    this.setState({
      phoneNumber: phoneNumberStore.byId[match.params.phoneNumberId]
        ? _cloneDeep(phoneNumberStore.byId[match.params.phoneNumberId])
        : {},
      // NOTE: ^ we don't want to actually change the store's phoneNumber, just use a copy
    })
  }

  _handleFormChange(e) {
    let newState = _update(this.state, e.target.name, () => {
      return e.target.value
    })
    this.setState({ newState })
  }

  _handleFormSubmit(e) {
    const { dispatch, history } = this.props
    e.preventDefault()
    dispatch(
      phoneNumberActions.sendUpdatePhoneNumber(this.state.phoneNumber)
    ).then(phoneNumberRes => {
      if (phoneNumberRes.success) {
        history.push(`/admin/phone-numbers/${phoneNumberRes.item._id}`)
      } else {
        alert('ERROR - Check logs')
      }
    })
  }

  render() {
    const { location, match, phoneNumberStore } = this.props
    const { phoneNumber, formHelpers } = this.state

    const selectedPhoneNumber = phoneNumberStore.selected.getItem()

    const isEmpty = !phoneNumber || !phoneNumber._id

    const isFetching =
      !phoneNumberStore.selected.id || phoneNumberStore.selected.isFetching

    return (
      <AdminPhoneNumberLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        {isEmpty ? (
          isFetching ? (
            <h2>Loading...</h2>
          ) : (
            <h2>Empty.</h2>
          )
        ) : (
          <AdminPhoneNumberForm
            phoneNumber={phoneNumber}
            cancelLink={`/admin/phone-numbers/${phoneNumber._id}`}
            formHelpers={formHelpers}
            formTitle="Update Phone Number"
            formType="update"
            handleFormChange={this._handleFormChange}
            handleFormSubmit={this._handleFormSubmit}
          />
        )}
      </AdminPhoneNumberLayout>
    )
  }
}

AdminUpdatePhoneNumber.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    phoneNumberStore: store.phoneNumber,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminUpdatePhoneNumber))
