const sessionStorageUtils = {
    getJSONValue(key, defaultValue) {
      let sessionStorageValue = sessionStorage.getItem(key);
      let sessionStorageValueParsed = null;
      if(!!sessionStorageValue) {
        try {
          sessionStorageValueParsed = JSON.parse(sessionStorageValue);
        }
        catch(err) {
          console.error('error while reading values from session storage:', err);
        }
      }
      return sessionStorageValueParsed || defaultValue;
    }
  
    , setJSONValue(key, valueObj) {
        sessionStorage.setItem(key, JSON.stringify(valueObj));
    }
  }
  
  export default sessionStorageUtils;
  