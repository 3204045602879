import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'

const AIAuthentication = ({ firmId, open, onAuthenticated, onError }) => {
  const [authInProgress, setAuthInProgress] = useState(false)

  useEffect(() => {
    if (!open || authInProgress) return // Skip if already authenticating or modal is closed

    const authenticate = async () => {
      setAuthInProgress(true) // Start authentication
      try {
        const response = await fetch(`/api/users/ai-initiate/${firmId}`, {
          method: 'POST',
          credentials: 'same-origin',
        })
        const result = await response.json()
        if (result.success) {
          onAuthenticated(result.data)
        } else {
          throw new Error('Authentication failed')
        }
      } catch (err) {
        onError(err)
      } finally {
        setAuthInProgress(false) // End authentication
      }
    }

    authenticate()
  }, [firmId, open, onAuthenticated, onError]) // Trigger only on firmId, open, or callbacks change

  return authInProgress ? (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <CircularProgress size={24} />
      <Typography variant="body1">Initializing AI assistant...</Typography>
    </Box>
  ) : null
}

export default AIAuthentication
