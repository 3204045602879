/**
 * Reusable dropdown to pick new client options.
 */

// import primary libararies
import React from 'react'
import PropTypes from 'prop-types'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

const NewClientOptionsMenu = ({ firmId, isOpen }) => {
  return (
    <TransitionGroup>
      {isOpen ? (
        <CSSTransition
          classNames="dropdown-anim"
          timeout={250}
        >
          <ul className="-client-options">
            <li className="-header">
              <strong>Add workspaces</strong>
            </li>
            <li className="-option">
              <Link
                to={`/firm/${firmId}/workspaces/new`}
                className="-select"
              >
                Create New Workspace
              </Link>
            </li>
            <li className="-option">
              <Link
                to={`/firm/${firmId}/workspaces/import`}
                className="-select"
              >
                Bulk Workspace Upload
              </Link>
            </li>
          </ul>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

NewClientOptionsMenu.propTypes = {
  firmId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
}

NewClientOptionsMenu.defaultProps = {
  isOpen: false,
}

export default withRouter(NewClientOptionsMenu)
