import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from '../../../../global/components/modals/Modal.js.jsx';
import { SingleDatePickerInput } from '../../../../global/components/forms/index.js';
import { DateTime } from 'luxon';

const ShareLinkModal = (props) => {
  const { shareLink, close, isOpen, save } = props;

  const initialExpireDate = shareLink && shareLink.expireDate
    ? DateTime.fromISO(shareLink.expireDate).toMillis()
    : DateTime.local().plus({ days: 30 }).toMillis();

  const [expireDate, setExpireDate] = useState(initialExpireDate);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setExpireDate(value);
  }

  const handleSubmit = () => {
    const newShareLink = {
      _id: shareLink.id,
      expireDate: new Date(expireDate)
    };
    save(newShareLink);
  }

  return (
    <Modal
      closeAction={close}
      isOpen={isOpen}
      modalHeader="Edit Share Link"
      confirmText="Save"
      confirmAction={handleSubmit}
    >
      <div>
        <div className="-share-link-configuration">
          <div className="-header">
            <i className="fas fa-eye" /> Link settings
          </div>
          <div className="-body">
            <div className="-setting yt-row space-between">
              <div className="-instructions yt-col">
                <p>
                  <strong>Expiration</strong>
                </p>
                <p>Disable this link on a specific date</p>
              </div>
              <div className="-inputs yt-col">
                <SingleDatePickerInput
                  anchorDirection="right"
                  change={handleFormChange}
                  enableOutsideDays={false}
                  initialDate={expireDate}
                  inputClasses="-right"
                  minDate={DateTime.local().toMillis()}
                  name="expireDate"
                  numberOfMonths={1}
                  placeholder={''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ShareLinkModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  shareLink: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

const mapStoreToProps = (store) => {
  return {};
};

export default withRouter(connect(mapStoreToProps)(ShareLinkModal));
