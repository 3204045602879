import { init } from '@elastic/apm-rum'

const initApm = (appName, flushInterval = 3000, forceActive = false) => {
	if (!appName) {
		return
	}

	init({
		serviceName: 'mangoshare-' + appName,
		serviceVersion: process.env.MANGOSHARE_APP_VERSION || 'unknown',
		serverUrl: 'https://apm.elk.mangobilling.com',
		flushInterval: flushInterval,
		environment: process.env.NODE_ENV,
		distributedTracing: false,
		active: process.env.NODE_ENV == 'production' || forceActive,
	})
}

export default initApm
