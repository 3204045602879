import React, { useRef, useState, useLayoutEffect } from 'react'

import useResizeObserver from '@react-hook/resize-observer'

const ellipsisCondition = el => el.scrollWidth > el.offsetWidth

const EllipsisText = ({ children, text, ...rest }) => {
	const ref = useRef(null)
	const [isEllipsis, setIsEllipsis] = useState(false)

	useLayoutEffect(() => {
		if (!ref.current) {
			return
		}

		setIsEllipsis(ellipsisCondition(ref.current))
	}, [ref, children])

	useResizeObserver(ref, e => {
		setIsEllipsis(ellipsisCondition(e.target))
	})

	return (
		<p
			ref={ref}
			className="ellipsisText"
			title={isEllipsis ? (text ? text : children) : undefined}
			{...rest}
		>
			{children}
		</p>
	)
}

export default React.memo(EllipsisText)
