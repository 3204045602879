import { baseApi } from '../base'
import qs from 'qs'

const firmApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getFirms: build.query({
      query: params => {
        const queryParams = qs.stringify(params)

        return `/firms/v2/search?${queryParams}`
      },
      getFirm: build.query({
        query: firmId => `/firms/${firmId}`,
      }),
    }),
    overrideExisting: false,
  }),
})

export const { useGetFirmsQuery, useGetFirmQuery } = firmApi
