import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

import * as addressActions from '../../../address/addressActions'
import * as clientActions from '../../../client/clientActions'
import * as firmActions from '../../firmActions'
import * as fileActions from '../../../file/fileActions'
import * as staffActions from '../../../staff/staffActions'
import * as subscriptionActions from '../../../subscription/subscriptionActions'
import * as userActions from '../../../user/userActions'

import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import SelectFromArray from '../../../../global/components/forms/SelectFromArray.js.jsx'
import ToggleSwitchInput from '../../../../global/components/forms/ToggleSwitchInput.js.jsx'
import SelectFromObject from '../../../../global/components/forms/SelectFromObject.js.jsx'
import { getBrandingSettings } from '../../../../global/enum/brandingName.js.jsx'

import fileUtils from '../../../../global/utils/fileUtils'

import AddressCard from '../../../address/components/AddressCard.js.jsx'
import AddressEditor from '../../../address/components/AddressEditor.js.jsx'
import AdminFirmLayout from '../components/AdminFirmLayout.js.jsx'
import AdminStaffListItem from '../../../staff/admin/components/AdminStaffListItem.js.jsx'
import AdminUpdateAssureSignModal from '../components/AdminUpdateAssureSignModal.js.jsx'
import InviteStaffModal from '../../../staff/components/InviteStaffModal.js.jsx'

import NewImageModal from '../../../file/components/NewImageModal.js.jsx'
import ManageLicensesModal from '../../../subscription/components/ManageLicensesModal.js.jsx'

import { DateTime } from 'luxon'
import classNames from 'classnames'
import { useGetFirmStatisticsQuery } from '../../../../config/api/statistics/statistics'
import TextWithTooltip from '../../../../global/components/TextWithTooltip/TextWithTooltip'
const assureSignUrls = [
  {
    value: '',
    name: 'Default',
  },
  {
    value: 'https://sb.assuresign.net/api/documentnow/v3.5',
    name: 'Demo',
  },
  {
    value: 'https://www.assuresign.net/api/documentnow/v3.5',
    name: 'Prod',
  },
]

const AdminSingleFirm = ({
  match,
  dispatch,
  location,
  firmStore,
  clientStore,
  loggedInUser,
  staffStore,
  userStore,
  subscriptionStore,
  addressStore,
}) => {
  const [apiKey, setApiKey] = useState('')
  const [apiUsername, setApiUsername] = useState('')
  const [assureSignModalOpen, setAssureSignModalOpen] = useState(false)
  const [contextIdentifier, setContextIdentifier] = useState('')
  const [contextUsername, setContextUsername] = useState('')
  const [firmSubDomain, setFirmSubDomain] = useState(null)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [isAddingAddress, setIsAddingAddress] = useState(false)
  const [manageLicenses, setManageLicenses] = useState(false)
  const [newImageFileOpen, setNewImageFileOpen] = useState(false)
  const [selectedStaffId, setSelectedStaffId] = useState(null)
  const [showUpdateDomain, setShowUpdateDomain] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [status, setStatus] = useState('')
  const [changePublishStatus, setChangePublishStatus] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState('')
  const [selectedAssureSignUrl, setSelectedAssureSignUrl] = useState('')

  const { data, isLoading } = useGetFirmStatisticsQuery(match.params.firmId)

  useEffect(() => {
    dispatch(addressActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(clientActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId)).then(
      firmRes => {
        if (firmRes.item._file) {
          dispatch(fileActions.fetchSingleIfNeeded(firmRes.item._file))
        }
      },
    )

    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(
      subscriptionActions.fetchListIfNeeded('_firm', match.params.firmId),
    )
    dispatch(userActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId))
  }, [dispatch, match.params.firmId])

  const createSubscription = () => {
    dispatch(
      subscriptionActions.sendCreateSubscription({
        _firm: match.params.firmId,
      }),
    ).then(subRes => {
      if (subRes.success) {
        let firm = _.cloneDeep(firmStore.selected.getItem())
        firm._subscription = subRes.item._id
        dispatch(firmActions.sendUpdateFirm(firm))
      }
    })
  }

  const handleFormChange = e => {
    const { name, value } = e.target
    switch (name) {
      case 'apiKey':
        setApiKey(value)
        break
      case 'apiUsername':
        setApiUsername(value)
        break
      case 'contextIdentifier':
        setContextIdentifier(value)
        break
      case 'firmSubDomain':
        setFirmSubDomain(value)
        break
      case 'status':
        setStatus(value)
        break
      default:
        break
    }
  }

  const handleUpdateDomain = () => {
    const updatedFirm = _.cloneDeep(firmStore.byId[match.params.firmId])
    updatedFirm.domain =
      firmSubDomain + '.' + getBrandingSettings().baseHostname
    dispatch(firmActions.sendUpdateFirm(updatedFirm)).then(() => {
      setShowUpdateDomain(false)
    })
  }

  const handleRemoveDomain = () => {
    const updatedFirm = _.cloneDeep(firmStore.byId[match.params.firmId])
    updatedFirm.domain = null
    dispatch(firmActions.sendUpdateFirm(updatedFirm)).then(() => {
      setShowUpdateDomain(false)
    })
  }

  const handleRemoveLogo = () => {
    const updatedFirm = _.cloneDeep(firmStore.byId[match.params.firmId])
    updatedFirm._file = null
    updatedFirm.logoUrl = null
    dispatch(firmActions.sendUpdateFirm(updatedFirm))
  }

  const makePrimaryAddress = addressId => {
    const updatedFirm = _.cloneDeep(firmStore.byId[match.params.firmId])
    updatedFirm._primaryAddress = addressId
    dispatch(firmActions.sendUpdateFirm(updatedFirm))
  }

  const handleNewAddress = addressId => {
    if (addressId) {
      dispatch(
        addressActions.addAddressToList(
          addressId,
          '_firm',
          match.params.firmId,
        ),
      )
    }
    setIsAddingAddress(false)
  }

  const handleSelectedStaffChange = e => {
    const selectedStaff = staffStore.byId[e.target.value]
    const selectedUser = userStore.byId[selectedStaff._user]
    setSelectedStaffId(e.target.value)
    setContextUsername(selectedUser.username)
    setApiKey(selectedStaff.apiKey || '')
    setApiUsername(selectedStaff.apiUsername || '')
  }

  const handleResendInvite = (user, owner, firm = null) => {
    setSending(true)
    setSelectedStaff(user._id)
    if (!firm) firm = firmStore.selected.getItem()

    let sendData = {
      invitations: [
        {
          email: user.username,
          fullName: user.firstname + ' ' + user.lastname,
          owner: owner,
        },
      ],
      personalNote: '',
    }
    try {
      dispatch(staffActions.sendInviteStaff(firm._id, sendData)).then(
        staffRes => {
          setSending(false)
          if (staffRes.success) {
            setSuccess(true)
            setTimeout(() => {
              setSuccess(false)
              setSelectedStaff('')
            }, 2000)
          } else {
            alert('ERROR - Check logs')
          }
        },
      )
    } catch (err) {
      setSending(false)
      setSelectedStaff('')
    }
  }

  const saveStatusChange = () => {
    if (!status) {
      alert('You must select a status')
    } else {
      let sub = _.cloneDeep(
        subscriptionStore.byId[firmStore.selected.getItem()._subscription],
      )
      sub.status = status
      dispatch(subscriptionActions.sendUpdateSubscription(sub)).then(action => {
        if (action.success) {
          setChangePublishStatus(false)
          setStatus('')
        } else {
          alert(`ERROR: ${action.error}`)
        }
      })
    }
  }

  const handleNewImageFile = file => {
    const newFirm = _.cloneDeep(firmStore.selected.getItem())
    newFirm._file = file._id
    newFirm.logoUrl = file.filename

    dispatch(fileActions.fetchSingleIfNeeded(file._id))
    dispatch(firmActions.sendUpdateFirm(newFirm)).then(() => {
      setNewImageFileOpen(false)
    })
  }

  const handleToggleESigAccess = () => {
    const newFirm = _.cloneDeep(firmStore.selected.getItem())
    newFirm.eSigAccess = !newFirm.eSigAccess

    dispatch(firmActions.sendUpdateFirm(newFirm))
  }

  const handleToggleDeveloperAccess = () => {
    const newFirm = _.cloneDeep(firmStore.selected.getItem())
    newFirm.developer_account = !newFirm.developer_account

    dispatch(firmActions.sendUpdateFirm(newFirm))
  }

  const openAssureSignModal = () => {
    const firm = _.cloneDeep(firmStore.byId[match.params.firmId])
    setAssureSignModalOpen(true)
    setContextIdentifier(firm.contextIdentifier)
  }

  const handleUpdateESigCredentials = () => {
    const updatedFirm = _.cloneDeep(firmStore.byId[match.params.firmId])
    const updatedStaff = _.cloneDeep(staffStore.byId[selectedStaffId])

    updatedStaff.apiUsername = apiUsername
    updatedStaff.apiKey = apiKey
    updatedStaff.contextUsername = contextUsername
    updatedStaff.eSigAccess = true
    updatedStaff._eSigGrantedBy = loggedInUser._id
    updatedFirm.contextIdentifier = contextIdentifier

    dispatch(staffActions.sendUpdateStaff(updatedStaff)).then(staffRes => {
      if (staffRes.success) {
        dispatch(firmActions.sendUpdateFirm(updatedFirm)).then(() => {
          setAssureSignModalOpen(false)
          setApiKey('')
          setApiUsername('')
          setContextIdentifier('')
          setContextUsername('')
          setSelectedStaffId(null)
        })
      } else {
        alert(staffRes.error)
      }
    })
  }

  const cancelUpdateESigCredentials = () => {
    setAssureSignModalOpen(false)
    setApiKey('')
    setApiUsername('')
    setContextIdentifier('')
    setContextUsername('')
    setSelectedStaffId(null)
  }

  const handleBuildStaffUserList = staffListItems => {
    const staffUserListItems = []
    staffListItems.forEach(staff => {
      const user = userStore.byId[staff._user]
      if (user) {
        const staffUserItem = {
          _id: staff._id,
          displayName: `${user.firstname} ${user.lastname} - ${user.username}`,
        }
        staffUserListItems.push(staffUserItem)
      }
    })
    return staffUserListItems
  }

  const handleAssureSignUrl = e => {
    const { value } = e.target

    setSelectedAssureSignUrl(value)

    let updatedFirm = _.cloneDeep(firmStore.byId[match.params.firmId])
    updatedFirm.assureSign_url = value

    dispatch(firmActions.sendUpdateFirm(updatedFirm))
  }

  const selectedFirm = firmStore.selected.getItem()
  let firmLogo = ''
  if (selectedFirm && selectedFirm._id && selectedFirm.logoUrl) {
    firmLogo = `/api/firms/logo/${selectedFirm._id}/${selectedFirm.logoUrl}`
  }

  const clientList =
    clientStore.lists && clientStore.lists._firm
      ? clientStore.lists._firm[match.params.firmId]
      : null
  const clientListItems = clientStore.util.getList('_firm', match.params.firmId)

  const staffList =
    staffStore.lists && staffStore.lists._firm
      ? staffStore.lists._firm[match.params.firmId]
      : null
  const staffListItems = staffStore.util.getList('_firm', match.params.firmId)

  const staffOwnerListItems = staffListItems
    ? staffListItems.filter(staff => staff.owner)
    : []
  const staffUserListItems = staffOwnerListItems
    ? handleBuildStaffUserList(staffOwnerListItems)
    : []

  const contactList =
    userStore.lists && userStore.lists._firm
      ? userStore.lists._firm[match.params.firmId]
      : null
  const contactListItems = userStore.util.getList('_firm', match.params.firmId)

  const addressListItems = addressStore.util.getList(
    '_firm',
    match.params.firmId,
  )

  const filesSize = data?.statistics?.files_size

  const isEmpty =
    !selectedFirm || !selectedFirm._id || firmStore.selected.didInvalidate

  const isFetching = firmStore.selected.isFetching

  const clientsEmpty = !clientListItems || !clientList

  const clientsFetching =
    !clientListItems || !clientList || clientList.isFetching

  const staffFetching = !staffListItems || !staffList || staffList.isFetching

  const staffEmpty = !staffListItems || !staffList

  const contactsFetching =
    !contactListItems || !contactList || contactList.isFetching

  const contactsEmpty = !contactListItems || !contactList

  const activeStaff = staffListItems
    ? staffListItems.filter(s => s.status === 'active')
    : []

  const subscription =
    selectedFirm && selectedFirm._subscription
      ? subscriptionStore.byId[selectedFirm._subscription]
      : null

  const subStatus = classNames(
    'status-pill -subscription',
    subscription ? subscription.status : null,
  )

  return (
    <AdminFirmLayout>
      <Helmet>
        <title>Admin Single Firm</title>
      </Helmet>
      <Breadcrumbs
        links={location.state.breadcrumbs}
        classes="-admin"
      />
      {isEmpty ? (
        isFetching ? (
          <h2>Loading...</h2>
        ) : (
          <h2>Empty.</h2>
        )
      ) : (
        <div style={{ opacity: isFetching ? 0.5 : 1 }}>
          <div className="yt-row with-gutters">
            <div className="yt-col full s_60 m_70">
              <div className="content-container">
                <div className="yt-row space-between">
                  <p>
                    <strong>General firm info</strong>
                  </p>
                  <div>
                    <Link
                      to={`/firm/${selectedFirm._id}`}
                      target="_blank"
                      className="yt-btn x-small link info"
                    >
                      Go to firm view <i className="fad fa-external-link" />
                    </Link>
                  </div>
                </div>
                <div className="-settings-card-info">
                  <div
                    className="-info"
                    style={{ minWidth: '80%' }}
                  >
                    <small>
                      <strong>Firm name</strong>
                    </small>
                    <TextWithTooltip text={selectedFirm.name} />
                  </div>
                  <div>
                    <Link
                      to={`${match.url}/update`}
                      className="yt-btn link info x-small"
                    >
                      {' '}
                      Edit firm name
                    </Link>
                  </div>
                </div>
                <div className="-settings-card-info">
                  {showUpdateDomain ? (
                    <div className="-info">
                      <small>
                        <strong>Domain</strong>
                      </small>

                      <div className="input-group -cdn-input">
                        <div className="input-add-on ">
                          <span className="item -prefix">https://</span>
                          <input
                            className="field"
                            name="firmSubDomain"
                            onChange={handleFormChange}
                            type="text"
                            value={firmSubDomain}
                          />
                          <span className="item">
                            .{getBrandingSettings().baseHostname}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="-info">
                      <small>
                        <strong>Domain</strong>
                      </small>
                      <p>
                        {selectedFirm.domain ? (
                          <a
                            href={`https://${selectedFirm.domain}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {' '}
                            {selectedFirm.domain}{' '}
                            <i className="fad fa-external-link fa-sm" />
                          </a>
                        ) : (
                          <em>Add a custom domain</em>
                        )}
                      </p>
                    </div>
                  )}
                  <div>
                    {showUpdateDomain ? (
                      <div>
                        <button
                          className="yt-btn info link x-small"
                          disabled={
                            !firmSubDomain ||
                            !firmSubDomain.trim() ||
                            firmSubDomain.trim().length < 3
                          }
                          onClick={handleUpdateDomain}
                        >
                          Save
                        </button>
                        <button
                          className="yt-btn link muted  x-small"
                          onClick={() => {
                            setFirmSubDomain(null)
                            setShowUpdateDomain(false)
                          }}
                        >
                          Cancel
                        </button>
                        <br />
                        <button
                          className="yt-btn danger link x-small"
                          disabled={!selectedFirm.domain}
                          onClick={handleRemoveDomain}
                        >
                          Remove Subdomain
                        </button>
                      </div>
                    ) : (
                      <button
                        className="yt-btn link info x-small"
                        onClick={() => {
                          setFirmSubDomain(
                            selectedFirm.domain
                              ? selectedFirm.domain.split('.')[0]
                              : '',
                          )
                          setShowUpdateDomain(true)
                        }}
                      >
                        Edit domain
                      </button>
                    )}
                  </div>
                </div>
                <small>
                  <em>
                    NOTE: Any changes to the custom domain can take several
                    hours to go through.
                  </em>
                </small>
                <br />
                <br />
                <div className="-settings-card-info">
                  <div className="-info">
                    <small>
                      <strong>Logo</strong>
                    </small>
                    {firmLogo ? (
                      <div className="-settings-firm-logo">
                        <img src={firmLogo} />
                      </div>
                    ) : (
                      <div className="-settings-firm-logo">
                        <div className="empty-state-hero">
                          <div style={{ padding: '8px' }}>
                            <p className="u-centerText">
                              <em>Upload firm logo</em>
                              <br />
                              <small className="u-centerText">
                                <em>Recommended dimensions - 200x60</em>
                              </small>
                              <br />
                              <small className="u-centerText">
                                <em>Should be visible on white background</em>
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {firmLogo ? (
                      <div>
                        <button
                          className="yt-btn link info  x-small"
                          onClick={() => setNewImageFileOpen(true)}
                          type="button"
                        >
                          Update logo
                        </button>
                        <br />
                        <button
                          className="yt-btn link danger x-small"
                          onClick={handleRemoveLogo}
                          type="button"
                        >
                          Remove logo
                        </button>
                      </div>
                    ) : (
                      <button
                        className="yt-btn x-small"
                        onClick={() => setNewImageFileOpen(true)}
                        type="button"
                      >
                        Add firm logo
                      </button>
                    )}
                  </div>
                </div>
                <div className="-settings-card-info">
                  <div className="-info">
                    <p>
                      <strong>AssureSign URL</strong>
                    </p>
                    <SelectFromObject
                      change={handleAssureSignUrl}
                      name="selectedAssureSignUrl"
                      items={assureSignUrls}
                      placeholder="Select default AssureSign URL..."
                      required={false}
                      value="value"
                      selected={
                        selectedFirm.assureSign_url
                          ? selectedFirm.assureSign_url
                          : selectedAssureSignUrl
                      }
                      display="name"
                    />
                  </div>
                </div>
                <div>
                  <div className="-settings-card-info">
                    <div className="-info">
                      <ToggleSwitchInput
                        change={handleToggleESigAccess}
                        label={'E-Signature Access'}
                        name={'eSigAccess'}
                        rounded={true}
                        value={selectedFirm.eSigAccess}
                      />
                    </div>
                    {selectedFirm.eSigAccess ? (
                      <button
                        className="yt-btn link info x-small"
                        onClick={openAssureSignModal}
                        type="button"
                      >
                        Edit Credentials
                      </button>
                    ) : null}
                  </div>
                  <div className="-settings-card-info">
                    <div className="-info">
                      <ToggleSwitchInput
                        change={handleToggleDeveloperAccess}
                        label={'Developer Access'}
                        name={'developer_account'}
                        rounded={true}
                        value={selectedFirm.developer_account}
                      />
                    </div>
                  </div>
                </div>

                <div className="addresses">
                  <p>
                    <strong>Primary address</strong>
                  </p>
                  {selectedFirm._primaryAddress &&
                  addressStore.byId[selectedFirm._primaryAddress] ? (
                    <AddressCard
                      address={addressStore.byId[selectedFirm._primaryAddress]}
                      editable={true}
                      isPrimary={true}
                    />
                  ) : (
                    <p>
                      <em>No primary address on file</em>
                    </p>
                  )}
                  <hr />
                  <p>
                    <strong>Additional addresses</strong>
                  </p>
                  <div className="yt-container">
                    {addressListItems && addressListItems.length > 0
                      ? addressListItems.map((address, i) =>
                          address._id !== selectedFirm._primaryAddress ? (
                            <div
                              key={'address_' + address._id + i}
                              className="yt-col full s_50 m_33 l_25"
                            >
                              <AddressCard
                                address={address}
                                editable={true}
                                isPrimary={false}
                                makePrimary={makePrimaryAddress}
                              />
                            </div>
                          ) : null,
                        )
                      : null}
                  </div>
                  <div className="yt-container">
                    {isAddingAddress ? (
                      <AddressEditor
                        pointers={{ _firm: selectedFirm._id }}
                        onSubmit={handleNewAddress}
                        editorClasses="-quick-view"
                      />
                    ) : (
                      <div>
                        <button
                          onClick={() => setIsAddingAddress(true)}
                          className="yt-btn link info x-small"
                        >
                          <i className="fal fa-plus" /> Add address
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="content-container">
                <div className="yt-row space-between">
                  <p>
                    <strong>Staff</strong>
                  </p>
                  <button
                    className="yt-btn x-small"
                    onClick={() => setInviteModalOpen(true)}
                  >
                    Invite Staff Members{' '}
                  </button>
                  {/* <Link className="yt-btn  x-small " to={`/admin/staff/new?firm=${match.params.firmId}`}> Invite Staff Members</Link> */}
                </div>
                <hr />
                <div className="admin-table-wrapper">
                  <table className="yt-table striped">
                    {subscription &&
                    activeStaff.length > subscription.licenses ? (
                      <caption className="u-danger">
                        <strong>WARNING:</strong> The number of active licenses
                        ({activeStaff.length}) exceeds the purchased
                        subscription licenses ({subscription.licenses}){' '}
                      </caption>
                    ) : !subscription ? (
                      <caption className="u-danger">
                        <strong>WARNING:</strong> This firm has no active
                        subscription
                      </caption>
                    ) : (
                      <caption>
                        Using {activeStaff.length} of{' '}
                        {subscription ? subscription.licenses : 0} active
                        licenses
                      </caption>
                    )}
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Permissions</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffEmpty ? (
                        staffFetching ? (
                          <tr>
                            <td>Loading staff...</td>
                          </tr>
                        ) : (
                          <tr>
                            <td>No Staff.</td>
                          </tr>
                        )
                      ) : (
                        staffListItems.map((staff, i) => (
                          <AdminStaffListItem
                            key={'staff_' + staff._id + '_' + i}
                            staff={staff}
                            user={userStore.byId[staff._user]}
                            selectedFirm={staff._firm}
                            handleResendInvite={handleResendInvite}
                            sending={sending}
                            success={success}
                            selectedStaff={selectedStaff}
                          />
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="yt-col full s_40 m_30">
              {/* <div className="content-container">
                  <div className="yt-row space-between">
                    <p><strong>Firm Logo</strong></p>
                  </div>
                  <hr/>
                  
                  { logoUrl ?
                    <div className="-settings-firm-logo">
                      <img src={logoUrl}/>
                      <button
                        className="yt-btn link info block x-small"
                        onClick={() => this.setState({newImageFileOpen: true})}
                        type="button"
                      >
                        Update Firm Logo
                      </button>
                    </div>
                    :
                    <div className="-settings-firm-logo">
                    <div className="empty-state-hero">
                        <div>
                          <p className="u-centerText"><em>No logo?</em></p>
                          <button
                            className="yt-btn x-small"
                            onClick={() => this.setState({newImageFileOpen: true})}
                            type="button"
                          >
                            Add Firm Logo
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                </div> */}
              <div className="content-container">
                <div className="yt-row space-between">
                  <p>
                    <strong>Subscription info</strong>
                  </p>
                </div>
                <hr />
                {subscription ? (
                  <div>
                    <ManageLicensesModal
                      close={() => setManageLicenses(false)}
                      isOpen={manageLicenses}
                      numActiveStaff={activeStaff.length}
                      subscription={subscription}
                    />
                    <p>
                      Created on{' '}
                      {DateTime.fromISO(subscription.created_at).toLocaleString(
                        DateTime.DATETIME_MED,
                      )}
                      {subscription._createdBy &&
                      // doing this only because its the way it was, not sure where is the variable userMap coming from
                      // eslint-disable-next-line no-undef
                      userMap[subscription._createdBy] ? (
                        <span>
                          {' '}
                          by
                          <Link
                            to={`/admin/users/${subscription._createdBy}`}
                            target="_blank"
                          >
                            {' '}
                            {/* eslint-disable-next-line no-undef */}
                            {userMap[subscription._createdBy].username}
                          </Link>
                        </span>
                      ) : null}
                    </p>
                    {subscription.created_at != subscription.updated_at ? (
                      <p>
                        <small>
                          <em>
                            Last updated{' '}
                            {DateTime.fromISO(
                              subscription.updated_at,
                            ).toLocaleString(DateTime.DATETIME_MED)}
                          </em>
                        </small>
                      </p>
                    ) : null}
                    <br />
                    <div className="yt-row space-between center-vert">
                      <span>
                        <strong>Licenses:</strong> {subscription.licenses}
                      </span>

                      <button
                        className="yt-btn x-small link info"
                        onClick={() => {
                          setManageLicenses(true)
                        }}
                      >
                        <i className="fal fa-users-medical" /> Add licenses
                      </button>
                    </div>
                    <br />
                    <p>
                      <strong>Status:</strong>
                    </p>
                    {changePublishStatus ? (
                      <div>
                        <SelectFromArray
                          items={[
                            'trialing',
                            'active',
                            'incomplete',
                            'incomplete_expired',
                            'past_due',
                            'canceled',
                            'unpaid',
                          ]}
                          change={handleFormChange}
                          name="status"
                          value={subscription.status}
                        />
                        <div>
                          <button
                            className="yt-btn x-small danger link"
                            onClick={() => setChangePublishStatus(false)}
                          >
                            cancel
                          </button>
                          <button
                            className="yt-btn x-small info"
                            onClick={() => saveStatusChange()}
                          >
                            save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={subStatus}>
                          {_.startCase(subscription.status).toUpperCase()}
                        </div>
                        <button
                          className="yt-btn x-small link danger"
                          onClick={() => setChangePublishStatus(true)}
                        >
                          change
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="empty-state-hero">
                    <div>
                      <p className="u-centerText">
                        <em>No subscription</em>
                      </p>
                      <button
                        onClick={createSubscription}
                        className="yt-btn x-small"
                      >
                        Create Subscription
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="content-container">
                <div className="yt-row space-between">
                  <p>
                    <strong>Clients & Contacts</strong>
                  </p>
                </div>
                <hr />
                <div className="-quick-list">
                  {clientsEmpty ? (
                    clientsFetching ? (
                      <div>
                        <span>
                          <i className="fal fa-spinner fa-spin" />{' '}
                        </span>{' '}
                        Loading client stats...
                      </div>
                    ) : (
                      <div>No clients.</div>
                    )
                  ) : (
                    <Link
                      to={`/admin/firms/${match.params.firmId}/clients`}
                      className="-item"
                    >
                      <p>
                        <strong>Total Clients: </strong>{' '}
                        {clientList.items.length}
                      </p>
                      <div className="-icon">
                        <i className="fal fa-angle-right" />
                      </div>
                    </Link>
                  )}
                </div>
                <div className="-quick-list">
                  {contactsEmpty ? (
                    contactsFetching ? (
                      <div>
                        <span>
                          <i className="fal fa-spinner fa-spin" />{' '}
                        </span>{' '}
                        Loading contact stats...
                      </div>
                    ) : (
                      <div>No contacts.</div>
                    )
                  ) : (
                    <Link
                      to={`/admin/firms/${match.params.firmId}/contacts`}
                      className="-item"
                    >
                      <p>
                        <strong>Total Contacts: </strong>{' '}
                        {contactList.items.length}
                      </p>
                      <div className="-icon">
                        <i className="fal fa-angle-right" />
                      </div>
                    </Link>
                  )}
                </div>
                <div style={{ marginTop: '8px' }}>
                  <p>
                    <strong>Data Size: </strong>{' '}
                    {isLoading ? (
                      <i className="far fa-spinner fa-spin" />
                    ) : (
                      fileUtils.formatFileSize(filesSize || 0)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <InviteStaffModal
            close={() => setInviteModalOpen(false)}
            firm={selectedFirm}
            isOpen={inviteModalOpen}
            maxInvites={
              subscription ? subscription.licenses - activeStaff.length : 0
            }
          />
          <NewImageModal
            close={() => setNewImageFileOpen(false)}
            handleUploaded={handleNewImageFile}
            isOpen={newImageFileOpen}
            filePointers={{ _firm: match.params.firmId }}
          />
          <AdminUpdateAssureSignModal
            apiKey={apiKey}
            apiUsername={apiUsername}
            availableStaff={staffUserListItems}
            close={cancelUpdateESigCredentials}
            contextIdentifier={contextIdentifier}
            handleFormChange={handleFormChange}
            handleFormSubmit={handleUpdateESigCredentials}
            handleStaffChange={handleSelectedStaffChange}
            isOpen={assureSignModalOpen}
            selectedStaffId={selectedStaffId}
          />
        </div>
      )}
    </AdminFirmLayout>
  )
}
AdminSingleFirm.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    addressStore: store.address,
    clientStore: store.client,
    firmStore: store.firm,
    fileStore: store.file,
    loggedInUser: store.user.loggedIn.user,
    phoneNumberStore: store.phoneNumber,
    staffStore: store.staff,
    subscriptionStore: store.subscription,
    userStore: store.user,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminSingleFirm))
