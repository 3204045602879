/**
 * View component for /firm/:firmId/clients/:clientId/billing
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import { Helmet } from 'react-helmet'

// import actions
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'

// import resource components
import WorkspaceLayout from '../../../client/practice/components/WorkspaceLayout.js.jsx'

class PracticeWorkspaceNotesAboutClient extends Binder {
  constructor(props) {
    super(props)
    this.state = {}
    this._bind()
  }

  componentDidMount() {
    const { dispatch, loggedInUser, match } = this.props
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_client', match.params.clientId))
  }

  render() {
    const { addressStore, clientStore, clientUserStore, firmStore, match } =
      this.props

    return (
      <WorkspaceLayout>
        <Helmet>
          <title>Client Notes</title>
        </Helmet>
        <div className="hero -empty-hero">
          <div className="u-centerText">
            <h3>
              <em>Coming soon.</em>
            </h3>
            <h2>Workspace Notes About this Client</h2>
            <p>
              Leave general notes regarding this client. These are ONLY visible
              to the firm.
            </p>
          </div>
        </div>
      </WorkspaceLayout>
    )
  }
}

PracticeWorkspaceNotesAboutClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

PracticeWorkspaceNotesAboutClient.defaultProps = {}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    addressStore: store.activity,
    clientStore: store.client,
    clientUserStore: store.clientUser,
    firmStore: store.firm,
    loggedInUser: store.user.loggedIn.user,
    staffStore: store.staff,
    staffClientStore: store.staffClient,
    userStore: store.user,
  }
}

export default withRouter(
  connect(mapStoreToProps)(PracticeWorkspaceNotesAboutClient)
)
