import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../../global/components/Binder.js.jsx'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

// import third-party libraries
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'

class SingleClientOptions extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      showAlertModal: false,
    }
    this._bind('_toggleAlertModal')
  }

  _toggleAlertModal() {
    this.setState({ showAlertModal: !this.state.showAlertModal })
  }

  render() {
    const {
      isOpen,
      setStatus,
      singleClient,
      archived,
      match,
      client,
      handleFavoriteToggle,
    } = this.props

    return (
      <span
        className="single-file-options"
        style={
          singleClient
            ? { position: 'absolute' }
            : { position: 'absolute', right: '120px' }
        }
      >
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={250}
            >
              {singleClient ? (
                <ul className="dropMenu -options-menu">
                  {archived ? (
                    <li className="-option">
                      <a
                        className="-delete-link"
                        onClick={() => this.setState({ showAlertModal: true })}
                      >
                        Delete Client
                      </a>
                    </li>
                  ) : null}
                  <li className="-option">
                    <a
                      onClick={() =>
                        setStatus(archived ? 'visible' : 'archived')
                      }
                    >
                      {archived ? 'Reinstate Client' : 'Archive Client'}
                    </a>
                  </li>
                  {singleClient && !archived && handleFavoriteToggle && (
                    <li className="-option">
                      <a onClick={handleFavoriteToggle}>
                        {client?.isFavorite
                          ? 'Remove from favorites'
                          : 'Mark as favorite'}
                      </a>
                    </li>
                  )}
                </ul>
              ) : (
                <ul className="dropMenu -options-menu">
                  {archived ? (
                    <li className="-option">
                      <Link to={`/firm/${match.params.firmId}/workspaces`}>
                        View Clients
                      </Link>
                    </li>
                  ) : (
                    <li className="-option">
                      <Link
                        to={`/firm/${match.params.firmId}/workspaces/archived`}
                      >
                        View Archive
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </CSSTransition>
          ) : null}
        </TransitionGroup>
        <AlertModal
          alertMessage={'Are you sure? This cannot be undone.'}
          alertTitle={'Delete this client?'}
          closeAction={this._toggleAlertModal}
          confirmAction={() => setStatus('deleted')}
          confirmText={'Delete'}
          declineAction={this._toggleAlertModal}
          declineText={'Cancel'}
          isOpen={this.state.showAlertModal}
          type={'danger'}
        ></AlertModal>
      </span>
    )
  }
}

SingleClientOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openQuickTaskModal: PropTypes.func,
}

SingleClientOptions.defaultProps = {}

export default withRouter(SingleClientOptions)
