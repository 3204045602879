/**
 * View component for /firms/:firmId/settings/tags
 *
 * Displays a list of a firm's custom tags.
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

import _orderBy from 'lodash/orderBy'
import _slice from 'lodash/slice'

// import actions
import * as firmActions from '../../firm/firmActions'
import * as staffActions from '../../staff/staffActions'
import * as userActions from '../../user/userActions'
import * as engagementActions from '../engagementActions'

// import global components
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import Binder from '../../../global/components/Binder.js.jsx'
import PageTabber from '../../../global/components/pagination/PageTabber.js.jsx'
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

// import utilities
import filterUtils from '../../../global/utils/filterUtils'
import routeUtils from '../../../global/utils/routeUtils'

// import resource components
import PracticeFirmLayout from '../../firm/practice/components/PracticeFirmLayout.js.jsx'
import EngagementTypeListItems from '../components/EngagementTypeListItems.js.jsx'
import EngagementForm from '../components/EngagementForm.js.jsx'

class EngagementTypes extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      per: 50,
      query: '',
      engagementsListArgsObj: {
        _firm: props.match.params.firmId,
      },
      engagementOptionsOpen: false,
      showMobileActionOption: false,
      openCreateModal: false,
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        lastUpdated: { disableSearch: true, searchText: '' },
      },
      showDeleteModal: false,
      selectedEngagementType: null,
    }
    this._bind(
      '_handleSetPagination',
      '_handleQuery',
      '_setPerPage',
      '_handleCloseTemplateListOptions',
      '_handleCloseMobileOption',
      '_handleFilter',
      '_handleClose',
      '_handleCreate',
      '_toggleHeaderSearch',
      '_changeHeaderSearch',
      '_submitDelete',
      '_toggleDelete'
    )
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const engagementsListArgsObj = routeUtils.listArgsFromObject(
      this.state.engagementsListArgsObj
    )
    dispatch(
      engagementActions.fetchListByFirmIdIfNeeded(
        match.params.firmId,
        ...engagementsListArgsObj
      )
    )
    dispatch(engagementActions.setQuery('', ...engagementsListArgsObj))
    dispatch(
      engagementActions.setFilter(
        { query: '', sortBy: '-update' },
        ...engagementsListArgsObj
      )
    )
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId))
    this._handleSetPagination({ page: 1, per: 50 })
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
  }

  _handleSetPagination(newPagination) {
    const { dispatch } = this.props
    dispatch(
      engagementActions.setPagination(
        newPagination,
        ...routeUtils.listArgsFromObject(this.state.engagementsListArgsObj)
      )
    )
  }

  _handleFilter(sortBy) {
    const { engagementList, dispatch } = this.props
    const engagementsListArgsObj = routeUtils.listArgsFromObject(
      this.state.engagementsListArgsObj
    )
    let newFilter = engagementList.filter
    if (
      engagementList.filter.sortBy &&
      engagementList.filter.sortBy.indexOf('-') === -1
    ) {
      sortBy = '-' + sortBy
    } else {
      sortBy = sortBy.substring(0)
    }
    newFilter.sortBy = sortBy
    dispatch(engagementActions.setFilter(newFilter, ...engagementsListArgsObj))
  }

  _handleQuery(e) {
    const { dispatch } = this.props
    // always defaulting the page to page 1 so we can see our results
    let pagination = {}
    pagination.page = 1
    pagination.per = this.state.per
    this._handleSetPagination(pagination)
    // continue query logic
    dispatch(
      engagementActions.setQuery(
        e.target.value.toLowerCase(),
        ...routeUtils.listArgsFromObject(this.state.engagementsListArgsObj)
      )
    )
    this.setState({ query: e.target.value.toLowerCase() })
  }

  _setPerPage(per) {
    var newPagination = {}
    newPagination.per = parseInt(per)
    newPagination.page = 1
    this._handleSetPagination(newPagination)
    this.setState({ per: newPagination.per })
  }

  _handleCloseTemplateListOptions(e) {
    e.stopPropagation()
    this.setState({ engagementOptionsOpen: false })
  }

  _handleCloseMobileOption(e) {
    e.stopPropagation()
    this.setState({ showMobileActionOption: false })
  }

  _handleClose() {
    this.setState({ openCreateModal: false })
  }

  _handleCreate(newEngagement) {
    const { dispatch } = this.props
    const engagementsListArgsObj = routeUtils.listArgsFromObject(
      this.state.engagementsListArgsObj
    )
    dispatch(
      engagementActions.addEngagementToList(
        newEngagement,
        ...engagementsListArgsObj
      )
    )
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { engagementStore, dispatch } = this.props
    const { filterHeaders } = engagementStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(engagementActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { engagementStore, dispatch } = this.props
    const { filterHeaders } = engagementStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(engagementActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _submitDelete() {
    const { dispatch } = this.props
    const selectedEngagementType = _.cloneDeep(
      this.state.selectedEngagementType
    )
    if (!selectedEngagementType) return
    dispatch(engagementActions.sendDelete(selectedEngagementType._id)).then(
      json => {
        this.setState({ showDeleteModal: false, selectedEngagementType: null })
      }
    )
  }

  _toggleDelete(engagement) {
    let showDeleteModal = !!engagement
    let selectedEngagementType = engagement
    this.setState({ showDeleteModal, selectedEngagementType })
  }

  render() {
    const {
      match,
      engagementStore,
      staffStore,
      userStore,
      location,
      filteredEngagementListItems,
      engagementList,
      paginatedList,
      filter,
    } = this.props

    const {
      engagementOptionsOpen,
      showMobileActionOption,
      openCreateModal,
      searchHeaderColumns,
      showDeleteModal,
      selectedEngagementType,
    } = this.state

    const isEmpty =
      !engagementList || !engagementList.items || engagementList.isFetching

    const isFetching = !engagementList || engagementList.isFetching

    return (
      <PracticeFirmLayout>
        <EngagementForm
          isOpen={openCreateModal}
          handleClose={this._handleClose}
          handleCreate={this._handleCreate}
        />
        {isEmpty ? (
          isFetching ? (
            <div className="-loading-hero hero">
              <div className="u-centerText">
                <div className="loading"></div>
              </div>
            </div>
          ) : (
            <div className="hero -empty-hero">
              <div className="u-centerText">
                <p>Looks like you don't have any files yet. </p>
                <p>Let's add some.</p>
              </div>
            </div>
          )
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="file-list-wrapper">
              <div className="yt-toolbar">
                <div className="yt-tools space-between">
                  <div className="-left"></div>
                  <div className="yt-tools -right">
                    <button
                      className="yt-btn x-small info -filename"
                      onClick={() => this.setState({ openCreateModal: true })}
                    >
                      New Engagement
                    </button>
                  </div>
                </div>
              </div>
              <hr
                className="-mobile-yt-hide"
                style={{ margin: 0 }}
              />
              <div className="yt-table table firm-table -workspace-table truncate-cells -yt-edit-table">
                <div className="table-caption">
                  <PageTabber
                    totalItems={engagementList.items.length}
                    totalPages={Math.ceil(
                      engagementList.items.length /
                        engagementList.pagination.per,
                    )}
                    pagination={engagementList.pagination}
                    setPagination={this._handleSetPagination}
                    setPerPage={this._setPerPage}
                    viewingAs="top"
                    itemName="engagements"
                    searchText="Search..."
                    enableSearch={true}
                    handleQuery={this._handleQuery}
                  />
                </div>
                <div className="-table-horizontal-scrolling">
                  <div className="table-head">
                    <div className="table-cell sortable _50">
                      <div
                        className="-table-header -title"
                        onClick={() => this._handleFilter('name')}
                      >
                        Name
                        {filter && filter.sortBy === 'name' ? (
                          <i className="fad fa-sort-down"></i>
                        ) : filter && filter.sortBy === '-name' ? (
                          <i className="fad fa-sort-up"></i>
                        ) : (
                          <i className="fad fa-sort"></i>
                        )}
                      </div>
                      <div className="-table-header-search">
                        <CheckboxInput
                          name="name"
                          value={!searchHeaderColumns.name.disableSearch}
                          checked={!searchHeaderColumns.name.disableSearch}
                          change={this._toggleHeaderSearch}
                        />
                        <TextInput
                          blur={() => console.log('blur')}
                          change={this._changeHeaderSearch}
                          name="name"
                          value={searchHeaderColumns.name.searchText}
                          disabled={searchHeaderColumns.name.disableSearch}
                          placeholder="Search name"
                        />
                      </div>
                    </div>
                    <div
                      className="table-cell sortable _50"
                      onClick={() => this._handleFilter('update')}
                    >
                      <div
                        className="-table-header-title"
                        onClick={() => this._handleFilter('name')}
                      >
                        Last Updated
                        {filter && filter.sortBy === 'update' ? (
                          <i className="fad fa-sort-down"></i>
                        ) : filter && filter.sortBy === '-update' ? (
                          <i className="fad fa-sort-up"></i>
                        ) : (
                          <i className="fad fa-sort"></i>
                        )}
                      </div>
                      <div className="-table-header-search">
                        <CheckboxInput
                          name="lastUpdated"
                          value={!searchHeaderColumns.lastUpdated.disableSearch}
                          checked={
                            !searchHeaderColumns.lastUpdated.disableSearch
                          }
                          change={this._toggleHeaderSearch}
                        />
                        <TextInput
                          blur={() => console.log('blur')}
                          change={this._changeHeaderSearch}
                          name="lastUpdated"
                          value={searchHeaderColumns.lastUpdated.searchText}
                          disabled={
                            searchHeaderColumns.lastUpdated.disableSearch
                          }
                          placeholder="Search last updated"
                        />
                      </div>
                    </div>
                    <div className="table-cell _5">
                      <div className="-table-header-title">Update</div>
                      <div className="-table-header-search -hidden"></div>
                    </div>
                    <div className="table-cell _5">
                      <div className="-table-header-title">Delete</div>
                      <div className="-table-header-search -hidden"></div>
                    </div>
                  </div>
                  {paginatedList && paginatedList.length ? (
                    paginatedList.map((engagement, i) => (
                      <EngagementTypeListItems
                        key={i}
                        engagement={engagement}
                        dispatch={this.props.dispatch}
                        toggleDelete={this._toggleDelete}
                      />
                    ))
                  ) : (
                    <div className="table-head empty-state">
                      <div
                        className="table-cell"
                        colSpan="6"
                      >
                        <em>No files</em>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <AlertModal
              alertMessage={
                <div>
                  <p>
                    Delete Engagement type{' '}
                    <b>
                      {selectedEngagementType && selectedEngagementType.name}
                    </b>
                    ?
                  </p>
                </div>
              }
              alertTitle="Warning"
              closeAction={this._toggleDelete}
              confirmAction={this._submitDelete}
              confirmText="Delete"
              isOpen={showDeleteModal}
              type="warning"
            />
          </div>
        )}
      </PracticeFirmLayout>
    )
  }
}

EngagementTypes.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  const listArgs = {
    _firm: props.match.params.firmId,
  }
  const engagementStore = store.engagement
  const engagementList = store.engagement.util.getListInfo(
    ...routeUtils.listArgsFromObject(listArgs)
  )
  const filter = engagementList && engagementList.filter
  const engagementListItems = store.engagement.util.getList(
    ...routeUtils.listArgsFromObject(listArgs)
  )
  const filteredEngagementListItems = engagementListItems
    ? engagementListItems.filter(data => data !== undefined)
    : []
  let sortedAndFilteredList = []
  let paginatedList = []

  if (filteredEngagementListItems && filteredEngagementListItems.length) {
    const engagementMap = store.engagement.byId

    const query = engagementList.query
    const sortBy = filter ? filter.sortBy : 'name'

    // FILTER BY QUERY
    let queryTestString = ('' + query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers

    filteredEngagementListItems.forEach(item => {
      let showMe = true
      if (queryTestString && queryTestString.trim()) {
        showMe = item && filterUtils.filterTag(queryTestString, item)
      }

      if (
        showMe &&
        !!engagementStore.filterHeaders &&
        !!engagementStore.filterHeaders.name &&
        engagementStore.filterHeaders.name.trim()
      ) {
        const name = engagementStore.filterHeaders.name.toLowerCase()
        showMe = item.name && item.name.toLowerCase().indexOf(name) > -1
      }

      if (
        showMe &&
        !!engagementStore.filterHeaders &&
        !!engagementStore.filterHeaders.lastUpdated &&
        engagementStore.filterHeaders.lastUpdated.trim()
      ) {
        const lastUpdated =
          engagementStore.filterHeaders.lastUpdated.toLowerCase()
        const updatedAt =
          item.updated_at &&
          new Date(item.updated_at).toLocaleDateString('en-US')
        showMe = updatedAt && updatedAt.toLowerCase().indexOf(lastUpdated) > -1
      }

      if (showMe) sortedAndFilteredList.push(item)
    })

    if (sortedAndFilteredList) {
      if (sortBy === '-update') {
        sortedAndFilteredList = _orderBy(
          sortedAndFilteredList,
          [item => item.updated_at],
          ['desc']
        )
      } else if (sortBy === 'update') {
        sortedAndFilteredList = _orderBy(
          sortedAndFilteredList,
          [item => item.updated_at],
          ['asc']
        )
      } else if (sortBy === '-name') {
        sortedAndFilteredList = _orderBy(
          sortedAndFilteredList,
          [item => item.name],
          ['desc']
        )
      } else if (sortBy === 'name') {
        sortedAndFilteredList = _orderBy(
          sortedAndFilteredList,
          [item => item.name],
          ['asc']
        )
      }
    }

    const pagination = engagementList.pagination || { page: 1, per: 50 }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _slice(sortedAndFilteredList, start, end)
  }

  return {
    engagementStore: store.engagement,
    staffStore: store.staff,
    userStore: store.user,
    filteredEngagementListItems,
    engagementList,
    paginatedList,
    filter,
  }
}

export default withRouter(connect(mapStoreToProps)(EngagementTypes))
