// import primary libraries
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _cloneDeep from 'lodash/cloneDeep'

import withRouter from 'react-router-dom/withRouter'

import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import routeUtils from '../../../../global/utils/routeUtils'

// import compoents
import SingleClientOptions from './SingleClientOptions.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import { FeedbackMessage } from '../../../../global/components/helpers/FeedbackMessage.js.jsx'
import EllipsisText from '../../../../global/components/EllipsisText'

// import actions
import * as clientActions from '../../clientActions'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'

const WorkspaceListItem = props => {
  const feedbackMessage = useRef(null)
  const [singleClientDropDownOption, setSingleClientDropdownOptions] =
    useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)

  const _handleCloseSingleClientOptions = e => {
    e.stopPropagation()
    setSingleClientDropdownOptions(false)
  }

  const _setStatus = status => {
    const { dispatch, match, client, clientStore, listArgs, handleFetchList } =
      props
    const clientMap = clientStore && clientStore.byId
    const newClient = clientMap && clientMap[client._id]

    if (status === 'visible') {
      // can reinstate archived client list if have a same client name in visible status
      let clients = clientMap ? Object.keys(clientMap) : []
      clients = clients.filter(clientId => {
        if (clientMap[clientId]) {
          if (clientMap[clientId].status === 'visible') {
            let client = clientMap[clientId].name
              ? clientMap[clientId].name.trim().toLowerCase()
              : null
            let compareClient = newClient.name
              ? newClient.name.trim().toLowerCase()
              : null
            if (client && compareClient && client === compareClient) {
              return clientId
            }
          }
        }
      })

      if (clients && clients.length) {
        setShowAlertModal(true)
      } else {
        newClient.status = status
        dispatch(clientActions.sendUpdateClientStatus(newClient)).then(json => {
          if (json.success && json.id) {
            dispatch(clientActions.removeClientFromList(json.id, ...listArgs))
            dispatch(
              clientActions.returnClientListPromise(
                ...routeUtils.listArgsFromObject({
                  _firm: match.params.firmId,
                  status: status,
                }),
              ),
            ).then(result => {
              if (result.success && result.list) {
                dispatch(
                  clientActions.addClientToList(
                    json.item,
                    ...routeUtils.listArgsFromObject({
                      _firm: match.params.firmId,
                      status: status,
                    }),
                  ),
                )
              }
            })
          }
        })
      }
    } else if (status === 'forced') {
      setShowAlertModal(false)
    } else {
      newClient.status = status
      dispatch(clientActions.sendUpdateClientStatus(newClient)).then(json => {
        if (json.success && json.id) {
          dispatch(clientActions.removeClientFromList(json.id, ...listArgs))
          dispatch(
            clientActions.returnClientListPromise(
              ...routeUtils.listArgsFromObject({
                _firm: match.params.firmId,
                status: status,
              }),
            ),
          ).then(result => {
            if (result.success && result.list) {
              dispatch(
                clientActions.addClientToList(
                  json.item,
                  ...routeUtils.listArgsFromObject({
                    _firm: match.params.firmId,
                    status: status,
                  }),
                ),
              )
            }
          })
        }
      })
    }
  }

  const _toggleAlertModal = () => {
    setShowAlertModal(false)
  }

  const _handleFavoriteToggle = (isFavorite, clientId) => {
    const { dispatch, clientStore } = props
    const data = {
      ...clientStore.byId[clientId],
      _id: clientId,
      isFavorite,
    }

    dispatch(clientActions.updateFavoriteClient(data)).then(json => {
      if (!json.success) {
        console.error('Could not toggle workspace favorite!')
        feedbackMessage.current.showError(
          'Could not change workspace favorite status!',
        )
      } else {
        feedbackMessage.current.showSuccess(
          isFavorite
            ? 'Workspace marked as favorite!'
            : 'Workspace removed from favorites!',
        )
      }
    })
  }

  const {
    client,
    handleSelectedClient,
    checked,
    selectedDisplayColumns,
    ISARCHIVEDVIEW,
    ownerPermissions,
  } = props

  return (
    <div className="table-row -file-item -option-pointer">
        {(ownerPermissions && (
          <div className="table-cell">
            <CheckboxInput
              name="client"
              value={checked}
              checked={checked}
              change={() => handleSelectedClient(client._id)}
            />
          </div>
        )) ||
          null}
        {(ownerPermissions && (
          <div className="table-cell">
            <div
              className="-options"
              onClick={() => {
                setSingleClientDropdownOptions(true)
              }}
            >
              <div
                style={{ position: 'relative', height: '100%', width: '100%' }}
              >
                <CloseWrapper
                  isOpen={singleClientDropDownOption}
                  closeAction={_handleCloseSingleClientOptions}
                />
                <i className="far fa-ellipsis-v"></i>
                <SingleClientOptions
                  isOpen={singleClientDropDownOption}
                  setStatus={_setStatus}
                  singleClient={true}
                  archived={ISARCHIVEDVIEW}
                  handleFavoriteToggle={() =>
                    _handleFavoriteToggle(!client.isFavorite, client._id)
                  }
                  client={client}
                />
              </div>
            </div>
          </div>
        )) ||
          null}
        {selectedDisplayColumns && selectedDisplayColumns.length
          ? selectedDisplayColumns.map(column => (
              <div
                key={column.key + client._id}
                className="table-cell"
                style={column.style}
              >
                <div
                  className="overflow-hidden"
                >
                  {client[column.key] ? (
                    !!column.valueFunction && !ISARCHIVEDVIEW ? (
                      <EllipsisText text={client[column.key]}>
                        {column.valueFunction(client, column.params)}
                      </EllipsisText>
                    ) : (
                      <EllipsisText>
                        {client[column.key]}
                      </EllipsisText>
                    )
                  ) : column.key === 'staffClientsCount' ? (
                    <em>0</em>
                  ) : (
                    <em>n/a</em>
                  )}
                </div>
              </div>
            ))
          : null}
        <AlertModal
          alertMessage={
            'This client cannot be reinstated because an active client with the same name already exists.'
          }
          alertTitle={'Warning: Duplicate name'}
          closeAction={_toggleAlertModal}
          confirmAction={() => _setStatus('forced')}
          confirmText={'Okay'}
          isOpen={showAlertModal}
          type={'danger'}
        ></AlertModal>
        <FeedbackMessage ref={feedbackMessage} />
      </div>
  )

}

WorkspaceListItem.propTypes = {
  address: PropTypes.object,
  client: PropTypes.object.isRequired,
  phoneNumber: PropTypes.object,
  primaryContact: PropTypes.object,
}

WorkspaceListItem.defaultProps = {
  address: null,
  phoneNumber: null,
  primaryContact: null,
}

const mapStoreToProps = store => {
  return {
    clientStore: store.client,
    clientUserStore: store.clientUser,
    loggedInUser: store.user.loggedIn.user,
    userStore: store.user,
  }
}

export default withRouter(connect(mapStoreToProps)(WorkspaceListItem))
