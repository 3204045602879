/**
 * View component for /admin/staff/:staffId
 *
 * Displays a single staff from the 'byId' map in the staff reducer
 * as defined by the 'selected' property
 */

// import primary libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import _cloneDeep from 'lodash/cloneDeep'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

// import actions
import * as staffActions from '../../staffActions'
import * as firmActions from '../../../firm/firmActions'
import * as userActions from '../../../user/userActions'

// import global components
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminStaffLayout from '../components/AdminStaffLayout.js.jsx'

const AdminSingleStaff = props => {
  const { match, location, dispatch, staffStore, userStore, firmStore } = props
  const [staff, setStaff] = useState(
    staffStore.byId[match.params.staffId]
      ? _cloneDeep(staffStore.byId[match.params.staffId])
      : {},
  )
  const [firm, setFirm] = useState(
    firmStore.byId[staff._firm] ? firmStore.byId[staff._firm] : {},
  )
  const [user, setUser] = useState(
    userStore.byId[staff._user] ? userStore.byId[staff._user] : {},
  )

  useEffect(() => {
    dispatch(staffActions.fetchSingleIfNeeded(match.params.staffId))
    dispatch(firmActions.fetchList('all'))
    dispatch(userActions.fetchList('all'))
  }, [match.params.staffId])

  useEffect(() => {
    setStaff(
      staffStore.byId[match.params.staffId]
        ? _cloneDeep(staffStore.byId[match.params.staffId])
        : {},
    )
  }, [staffStore.byId[match.params.staffId]])

  useEffect(() => {
    setFirm(firmStore.byId[staff._firm] ? firmStore.byId[staff._firm] : {})
  }, [firmStore.byId[staff._firm]])

  useEffect(() => {
    setUser(userStore.byId[staff._user] ? userStore.byId[staff._user] : {})
  }, [userStore.byId[staff._user]])

  const isEmpty = !staff || !staff._id
  const isFetching = !staffStore.selected.id || staffStore.selected.isFetching

  return (
    <AdminStaffLayout>
      <Helmet>
        <title>Admin Single Staff</title>
      </Helmet>
      <Breadcrumbs links={location.state.breadcrumbs} />
      {isEmpty ? (
        isFetching ? (
          <h2>Loading...</h2>
        ) : (
          <h2>Empty.</h2>
        )
      ) : (
        <div className="yt-container">
          <div className="yt-row center-horiz">
            <div className="form-container -slim">
              <div className="yt-col w-full" style={{padding: '16px'}}>
                <div className="formHeader">
                  <h2>Staff Details</h2>
                  <hr />
                </div>
                <p>
                  <strong>Firm: </strong>
                </p>
                <p>{firm.name}</p>
                <p>
                  <strong>Staff Member: </strong>
                </p>
                <p>
                  {user.firstname} {user.lastname}
                </p>
                <p>{user.username}</p>
                <hr />
                <p>
                  <strong>Status: </strong>
                </p>
                {
                  staff.status==='active' ?
                  <b style={{color: 'green'}}>Active</b>
                  :
                  <b style={{color: 'red'}}>Inactive</b>
                }
                <hr />
                <p>
                  {staff.owner
                    ? 'Staff has owner privileges'
                    : "Staff doesn't have owner privileges"}
                </p>
                <br />
                <Link to={`${match.url}/update`}> Update Staff </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminStaffLayout>
  )
}

AdminSingleStaff.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    staffStore: store.staff,
    userStore: store.user,
    firmStore: store.firm,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminSingleStaff))
