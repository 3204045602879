/**
 * View component for /user/reset-password/:hex
 *
 * Reached from a reset password link within an email.  Checks mactch.params.hex
 * against the reset hex for that user in the database.  If valid, allows the
 * user to reach the reset-password form.  If not, displays "invalid" message
 */

// import form components
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import { Helmet } from 'react-helmet'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

// import actions
import * as userActions from '../userActions'

// import global components
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import Binder from '../../../global/components/Binder.js.jsx'
import UISwitchChecker from '../../../global/practice/components/PracticeUISwitcher.js.jsx'

// import form components
import NewPasswordInput from '../../../global/components/forms/NewPasswordInput.js.jsx'

// import user components
import UserLayout from '../components/UserLayout.js.jsx'

//import constant.js
import { RECAPTCHA_SITE_KEY } from '../../../config/constants.js'

class ResetPassword extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      isErrorModalOpen: false,
      password: '',
      submitting: false,
      captchaToken: '',
      showCaptcha: false,
    }
    this._bind(
      '_handleFormChange',
      '_handleFormSubmit',
      '_toggleErrorModal',
      '_handleCaptchaVerify',
      '_handleSubmitResetPassword'
    )
    this.captchaRef = React.createRef()
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(userActions.sendCheckResetHex(match.params.hex))

    // //initialize google recaptcha
    // const script = document.createElement('script');
    // script.src = 'https://www.google.com/recaptcha/api.js';
    // script.async = true;
    // script.defer = true;
    // script.onload = () => {
    //   console.log('recaptcha is loaded');
    // }
    // document.body.appendChild(script);
  }

  _handleFormChange(e) {
    let nextState = this.state
    nextState[e.target.name] = e.target.value
    this.setState(nextState)
  }

  _handleFormSubmit(e) {
    e.preventDefault()

    this.setState({ showCaptcha: true })
    // const token = this.captchaRef.current.getValue();
    // console.log('captcha token', token);
  }

  _toggleErrorModal() {
    this.setState({ isErrorModalOpen: !this.state.isErrorModalOpen })
  }

  _handleCaptchaVerify(token) {
    console.log('token', token)
    this.setState({ captchaToken: token })

    //verify captcha token
    if (token) {
      console.log('verify captcha token')
      setTimeout(() => {
        this._handleSubmitResetPassword()
      }, 1500)
    }
  }

  _handleSubmitResetPassword() {
    const { dispatch, history, match } = this.props
    const { captchaToken } = this.state

    this.setState({ submitting: true })
    dispatch(
      userActions.sendResetPassword(
        match.params.hex,
        this.state.password,
        captchaToken
      )
    ).then(action => {
      this.setState({ submitting: false })
      if (action.success) {
        history.push('/user/login')
      } else {
        this.setState({ errorMessage: action.error })
        this._toggleErrorModal()
      }
    })
  }

  render() {
    const { user } = this.props
    const { password, submitting, showCaptcha } = this.state

    let isDisabled = !password;
    return  (
      <UserLayout>
        <UISwitchChecker></UISwitchChecker>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <div className="yt-container">
          <div className="yt-row center-horiz">
            {user.isFetching ? (
              <h3>Loading...</h3>
            ) : (
              <div className="form-container -skinny">
                {user.resetTokenValid ? (
                  <form
                    name="userForm"
                    className="user-form"
                  >
                    <h2>Reset Password</h2>
                    <hr />
                    <NewPasswordInput
                      change={this._handleFormChange}
                      name="password"
                      value={password}
                    />
                    <div className="input-group">
                      <div className="yt-row right">
                        <Link
                          to={'/user/login'}
                          className="yt-btn link"
                        >
                          {' '}
                          Back To Login{' '}
                        </Link>
                        <button
                          id="resetPassword"
                          className="yt-btn "
                          onClick={this._handleFormSubmit}
                          disabled={isDisabled || submitting}
                        >
                          {submitting ? (
                            <span>Sending...</span>
                          ) : (
                            <span>Reset Password </span>
                          )}
                        </button>
                        {showCaptcha ? (
                          <div className="recaptcha-container">
                            <GoogleReCaptchaProvider
                              reCaptchaKey={RECAPTCHA_SITE_KEY}
                              useEnterprise={true}
                              scriptProps={{
                                async: true,
                                defer: true,
                                appendTo: 'head',
                                nonce: undefined
                              }}
                            >
                              <GoogleReCaptcha onVerify={this._handleCaptchaVerify} />
                            </GoogleReCaptchaProvider>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </form>
                ) : (
                  <form>
                    <h2>
                      The password reset token is invalid or has expired. Please
                      visit the forgot password page to request a new token.
                    </h2>
                    <br />
                    <br />
                    <div className="input-group">
                      <Link
                        to={'/user/forgot-password'}
                        className="yt-btn"
                      >
                        <i className="fa fa-angle-double-left" /> Forgot
                        Password{' '}
                      </Link>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
          <AlertModal
            alertMessage={
              <div>
                <strong>{this.state.errorMessage}</strong>
                <br />
                <div>Please try again.</div>
              </div>
            }
            alertTitle="Error"
            closeAction={this._toggleErrorModal}
            confirmAction={this._toggleErrorModal}
            confirmText="Try again"
            isOpen={this.state.isErrorModalOpen}
            type="danger"
          />
        </div>
      </UserLayout>
    )
  }
}

ResetPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return { user: store.user.loggedIn }
}

export default withRouter(connect(mapStoreToProps)(ResetPassword))
