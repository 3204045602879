// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import actions
import * as noteActions from '../../noteActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminNoteLayout from '../components/AdminNoteLayout.js.jsx'
import AdminNoteListItem from '../components/AdminNoteListItem.js.jsx'

class NoteList extends Binder {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // fetch a list of your choice
    this.props.dispatch(noteActions.fetchListIfNeeded('all')) // defaults to 'all'
  }

  render() {
    const { location, noteStore } = this.props

    const noteList = noteStore.lists ? noteStore.lists.all : null
    const noteListItems = noteStore.util.getList('all')

    const isEmpty = !noteListItems || !noteList

    const isFetching = !noteListItems || !noteList || noteList.isFetching

    return (
      <AdminNoteLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        <h1> Note List </h1>
        <hr />
        <br />
        {isEmpty ? (
          isFetching ? (
            <h2>Loading...</h2>
          ) : (
            <h2>Empty.</h2>
          )
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="admin-table-wrapper">
              <Link to={'/admin/notes/new'}> New Note</Link>
              <table className="yt-table striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Last modified</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {noteListItems.map((note, i) => (
                    <AdminNoteListItem
                      key={note._id + i}
                      note={note}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </AdminNoteLayout>
    )
  }
}

NoteList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    noteStore: store.note,
  }
}

export default withRouter(connect(mapStoreToProps)(NoteList))
