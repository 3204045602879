/**
 * View component for /admin/firms
 *
 * Generic firm list view. Defaults to 'all' with:
 * this.props.dispatch(firmActions.fetchListIfNeeded());
 *
 * NOTE: See /product/views/ProductList.js.jsx for more examples
 */

// import primary libraries
import React, { useState } from 'react'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

// import actions
import * as firmActions from '../../firmActions'

// import global components
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminFirmLayout from '../components/AdminFirmLayout.js.jsx'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import displayUtils from '../../../../global/utils/displayUtils'
import { Box, TextField } from '@mui/material'
import { useGetFirmsQuery } from '../../../../config/api/firm/firm'
import { fileUtils } from '../../../../global/utils'

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    flex: 1,
    renderCell: params => (
      <Link to={`/admin/firms/${params.row.id}`}>{params.value}</Link>
    ),
  },
  {
    field: 'lastActivity',
    headerName: 'Last Activity',
    sortable: true,
    flex: 1,
  },
  {
    field: 'files_size',
    headerName: 'Firm Size',
    sortable: true,
    flex: 1,
  },
  {
    field: 'updated_at',
    headerName: 'Last Modified',
    sortable: true,
    flex: 1,
  },
  {
    field: 'createdBy',
    sortable: false,
    headerName: 'Created By',
    flex: 1,
  },
  {
    field: 'update',
    sortable: false,
    headerName: '',
    headerClassName: 'no-separator',
    cellClassName: 'no-separator',
    flex: 1,
    renderCell: params => (
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <Link to={`/admin/firms/${params.row.id}/update`}>Update</Link>
      </Box>
    ),
  },
]

const FirmList = ({ location, history }) => {
  const searchParams = new URLSearchParams(location.search)

  const page = parseInt(searchParams.get('page')) || 0
  const pageSize = parseInt(searchParams.get('pageSize')) || 10
  const sortBy = searchParams.get('sortBy') || 'name'
  const sortDirection = searchParams.get('sortDirection') || 'asc'
  const search = searchParams.get('search') || ''
  const [searchText, setSearchText] = useState(search)
  const { data, isLoading, isFetching } = useGetFirmsQuery({
    page,
    pageSize,
    sortBy,
    sortDirection,
    search,
  })

  const firmListItems = data?.firms
  const totalCount = data?.pager?.totalCount ? Number(data.pager.totalCount) : 0

  const updateSearch = () => {
    history.push({ search: searchParams.toString() })
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      searchParams.set('page', '0')
      searchParams.set('search', searchText)
      updateSearch()
    }
  }
  const rows = firmListItems?.map(firm => ({
    id: firm._id,
    name: firm.name,
    lastActivity: firm.lastActivity
      ? DateTime.fromISO(firm.lastActivity).toLocaleString(
          DateTime.DATETIME_SHORT,
        )
      : '',
    files_size:
      firm.files_size !== 0 ? fileUtils.formatFileSize(firm.files_size) : '',
    updated_at: DateTime.fromISO(firm.updated_at).toLocaleString(
      DateTime.DATETIME_SHORT,
    ),
    createdBy: firm.created_by,
  }))

  return (
    <AdminFirmLayout>
      <Helmet>
        <title>Admin Firm List</title>
      </Helmet>
      <Breadcrumbs links={location.state.breadcrumbs} />
      <h1> Firm List </h1>
      <hr />
      <br />
      <Box sx={{ opacity: isFetching || isLoading ? 0.5 : 1 }}>
        <div className="admin-table-wrapper">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: '6px',
            }}
          >
            <Link to={'/admin/firms/new'}> New Firm</Link>
            <TextField
              onChange={event => setSearchText(event.target.value)}
              id="standard-basic"
              label="Search"
              variant="standard"
              onKeyDown={handleKeyDown}
              value={searchText}
            />
          </Box>
          <Box>
            <DataGrid
              sx={{
                '& .MuiDataGrid-scrollbar': {
                  display: 'none',
                },
              }}
              rows={rows}
              columns={columns}
              rowCount={totalCount}
              paginationMode="server"
              paginationModel={{ page, pageSize }}
              onPaginationModelChange={({ page, pageSize }) => {
                searchParams.set('page', page)
                searchParams.set('pageSize', pageSize)
                updateSearch()
              }}
              sortingMode="server"
              sortModel={[{ field: sortBy, sort: sortDirection }]}
              onSortModelChange={([{ field, sort }]) => {
                searchParams.set('sortBy', field)
                searchParams.set('sortDirection', sort)
                updateSearch()
              }}
              sortingOrder={['desc', 'asc']}
              pageSizeOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnResize
              disableColumnSelector
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </Box>
    </AdminFirmLayout>
  )
}

export default withRouter(FirmList)
