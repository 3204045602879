import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NavLink from 'react-router-dom/NavLink'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../components/Binder.js.jsx'
import AlertModal from '../../components/modals/AlertModal.js.jsx'
import brandingName from '../../enum/brandingName.js.jsx'

// import third-party libraries
import classNames from 'classnames'

// import utils
import permissions from '../../utils/permissions'

// Actions
import * as firmActions from '../../../resources/firm/firmActions'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import cx from 'classnames'

class PracticeSideNav extends Binder {
  constructor(props, context) {
    super(props)
    this.state = {
      showPayment: false,
      isKCMenuOpened: false,
    }
    this.KBCenterRef = React.createRef(null)
    this._bind('_handleClose', '_onKnowledgeCenterClick')
  }
  _handleClose() {
    this.setState({
      showPayment: false,
      isKCMenuOpened: false,
    })
  }

  componentDidMount() {
    const { match, dispatch } = this.props
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
  }

  _onKnowledgeCenterClick() {
    console.log('KCenter click')
    this.setState({
      isKCMenuOpened: !this.state.isKCMenuOpened,
    })
  }

  render() {
    const {
      firmStore,
      isSidebarOpen,
      loggedInUser,
      match,
      staffStore,
      merchantStore,
    } = this.props
    let sidebarClass = classNames(
      'sidebar practice-sidebar',
      // , {
      //   '-open': isSidebarOpen
      // }
    )
    let logoClass = classNames(
      '-it-logo',
      // , { '-open': isSidebarOpen }
    )
    const ownerPermissions = permissions.isStaffOwner(
      staffStore,
      loggedInUser,
      match.params.firmId,
    )

    const basicPermissions = permissions.isStaffBasic(
      staffStore,
      loggedInUser,
      match.params.firmId,
    )

    const selectedFirm = firmStore.selected.getItem()
    let firmLogo = brandingName.image.logoBlack
    let favicon = ''
    if (selectedFirm && selectedFirm._id && selectedFirm.logoUrl) {
      firmLogo = `/api/firms/logo/${selectedFirm._id}/${selectedFirm.logoUrl}`
    }
    if (selectedFirm && selectedFirm._id && selectedFirm.logoFaviconUrl) {
      favicon = `/api/files/download/${selectedFirm._id}/firm/${selectedFirm._fileFavicon}/${selectedFirm.logoFaviconUrl}`
    }
    const { showPayment, isKCMenuOpened } = this.state

    // selectedFirm = merchData

    return (
      <div className={sidebarClass}>
        <AlertModal
          // alertMessage="Coming soon"
          alertTitle="To our valued customer"
          closeAction={this._handleClose}
          confirmAction={this._handleClose}
          confirmText="Okay"
          declineAction={null}
          declineText={null}
          isOpen={showPayment}
        >
          <div style={{ color: 'black' }}>
            <h4>Coming soon</h4>
            <p>
              We are currently working on this feature and will launch soon!
            </p>
          </div>
        </AlertModal>
        <div className="-nav-content -fixed">
          <div className="nav-header">
            <div className={logoClass}>
              {firmStore.selected.isFetching ? (
                <div className="-text-logo loading -small"></div>
              ) : (
                <img
                  className="-text-logo"
                  src={firmLogo}
                />
              )}
              <img
                className="-icon-logo"
                src={favicon || brandingName.image.icon}
              />
            </div>
          </div>
          <ul className="side-nav -firm">
            <li>
              <NavLink
                to={`/firm/${match.params.firmId}/workspaces`}
                activeclassname="active"
              >
                <span className="-icon">
                  <i className="fal fa-briefcase" />
                </span>
                <span className="-text">
                  {/* {ownerPermissions ? "All " : "My "} Workspaces */}
                  Workspaces
                </span>
              </NavLink>
            </li>
            {!basicPermissions ? (
              <li>
                <NavLink
                  to={`/firm/${match.params.firmId}/contacts`}
                  activeclassname="active"
                >
                  <span className="-icon">
                    <i className="fal fa-address-book" />
                  </span>
                  <span className="-text">
                    {/* {ownerPermissions ? "All " : "My "} Contacts */}
                    Contacts
                  </span>
                </NavLink>
              </li>
            ) : null}
            {/* <li>
              <NavLink
                to={`/firm/${match.params.firmId}/signatures`}
                activeclassname="active"
              >
                <span className="-icon">
                  <i className="fal fa-pencil" />
                </span>
                <span className="-text">
                  {ownerPermissions ? "All " : "My "} Signatures
                </span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to={`/firm/${match.params.firmId}/files`}
                activeclassname="active"
              >
                <span className="-icon">
                  <i className="fal fa-file" />
                </span>
                <span className="-text">
                  {/* {ownerPermissions ? "All " : "General "} Files */}
                  Files
                </span>
              </NavLink>
            </li>
            {ownerPermissions ? (
              <li>
                <NavLink
                  to={`/firm/${match.params.firmId}/lists`}
                  activeclassname="active"
                >
                  <span className="-icon">
                    <i className="fal fa-list" />
                  </span>
                  <span className="-text">Lists</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
          {ownerPermissions ? (
            <ul className="side-nav">
              <li>
                <NavLink
                  to={`/firm/${match.params.firmId}/settings`}
                  activeclassname="active"
                >
                  <span className="-icon">
                    <i className="fal fa-sliders-h" />
                  </span>
                  <span className="-text">Firm Settings</span>
                </NavLink>
              </li>
            </ul>
          ) : null}
          <ul className="side-nav">
            <li
              className="help"
              onClick={this._onKnowledgeCenterClick}
            >
              <a
                href={null}
                activeclassname="active"
                rel="noreferrer"
              >
                <span className="-icon">
                  <i className="fal fa-question-circle" />
                </span>
                <span className="-text">Knowledge Center</span>
                <TransitionGroup>
                  {isKCMenuOpened ? (
                    <CSSTransition
                      classNames="dropdown-anim"
                      nodeRef={this.KBCenterRef}
                      timeout={250}
                    >
                      <div
                        className={cx(
                          'dropMenu',
                          'kbCenter',
                          `${isSidebarOpen ? 'open' : 'closed'}`,
                        )}
                      >
                        <div>
                          <a
                            href="https://help.mangopractice.com/mangoshare"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Help Articles
                          </a>
                          {/*to do replace with AXSHARE link once we have it. the link in learning center videos
                          <a
                            href="https://help.mangopractice.com/mangoshare"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learning Center Videos
                          </a>
                          */}
                          <a
                            href="mailto:mangoshare@mangopractice.com?subject=MangoShare%20Web%20Support%20Case%20Request"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Email Support
                          </a>
                        </div>
                      </div>
                    </CSSTransition>
                  ) : null}
                </TransitionGroup>
                {/*{!isSidebarOpen && isKCMenuOpened ? (
                  <ul>
                    <li>
                      <a
                        href="https://help.mangopractice.com/mangoshare"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="-icon">
                          <i className="fal fa-info" />
                        </span>
                        <span className="-text">Help Articles</span>
                      </a>
                    </li>
                  </ul>
                ) : null}*/}
              </a>
            </li>
          </ul>
        </div>
        <div className="side-nav-footer">
          <div className="yt-row right center-vert">
            <a
              className="footer-logo"
              href={brandingName.url}
              target="_blank"
              rel="noreferrer"
            >
              <img src={brandingName.image.poweredby} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

PracticeSideNav.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
}
const mapStoreToProps = store => {
  return {
    loggedInUser: store.user.loggedIn.user,
    staffStore: store.staff,
    firmStore: store.firm,
    merchantStore: store.merchant,
  }
}
export default withRouter(
  connect(mapStoreToProps, null, null, {
    pure: false,
  })(PracticeSideNav),
)
