// import primary libraries
import React from 'react'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

// import global components
import Binder from '../../global/components/Binder.js.jsx'
import YTRoute from '../../global/components/routing/YTRoute.js.jsx'

import SignatureList from './views/SignatureList.js.jsx'

class SignatureRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        <YTRoute
          breadcrumbs={[{ display: 'Signatures', path: null }]}
          component={SignatureList}
          path="/firm/:firmId/signatures"
          exact
          staff={true}
        />
      </Switch>
    )
  }
}

const mapStoreToProps = (store, props) => {
  return props
}

export default withRouter(connect(mapStoreToProps)(SignatureRouter))
