import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

// import utils
import { validationUtils } from '../../utils'

const EmailInput = props => {
  const {
    autoFocus,
    disabled,
    label,
    placeholder,
    name,
    required,
    helpText,
    value,
    change,
    allowComment,
    onBlur,
    error,
    errorName,
  } = props

  const handleInputChange = e => {
    const newEmail = e.target.value
    let newStateEmail = newEmail
    if (props.allowComment) {
      newStateEmail = validationUtils.stripEmailComment(newEmail)
    }

    // Checks for ____@____.__
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidEmail = re.test(newStateEmail)

    const event = {
      target: {
        name,
        value: newEmail,
      },
    }

    change(event)

    if (errorName) {
      setTimeout(() => {
        change({
          target: {
            name: errorName,
            value: isValidEmail ? null : 'Please enter a valid email',
          },
        })
      }, 0)
    }
  }

  const inputClass = classNames({ '-error': !!error })

  return (
    <div className="input-group">
      <label htmlFor={name}>
        {label}
        {required && label ? <sup className="-required">*</sup> : null}
      </label>
      <input
        autoFocus={autoFocus}
        className={inputClass}
        disabled={disabled}
        name="email"
        onChange={handleInputChange}
        placeholder={placeholder}
        required={required}
        type={allowComment ? 'text' : 'email'}
        value={value}
        onBlur={onBlur}
      />
      {!!error && <div className="-error-message">{error}</div>}
      <small className="help-text">
        <em>{helpText}</em>
      </small>
    </div>
  )
}

EmailInput.propTypes = {
  allowComment: PropTypes.bool,
  autoFocus: PropTypes.bool,
  change: PropTypes.func.isRequired,
  helpText: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
}

EmailInput.defaultProps = {
  allowComment: false,
  autoFocus: false,
  helpText: null,
  label: '',
  placeholder: '',
  required: false,
}

export default EmailInput
