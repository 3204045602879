import React from 'react';
import { connect } from 'react-redux';
import * as notificationActions from '../../../resources/notification/notificationActions';

const withNotifications = (WrappedComponent) => {
  class WithNotifications extends React.Component {
    componentDidMount() {
      const pollingInterval = 300 * 1000;
      this.fetchNotifications();
      this.startNotificationsPolling(pollingInterval);
    }

    componentWillUnmount() {
      this.stopNotificationsPolling();
    }

    fetchNotifications = () => {
      const { dispatch, loggedInUser } = this.props;
      dispatch(notificationActions.fetchListIfNeeded('_user', loggedInUser._id));
    }

    startNotificationsPolling = (pollingInterval) => {
      this._interval = setInterval(() => {
        const { dispatch, loggedInUser } = this.props;
        dispatch(notificationActions.invalidateList('_user', loggedInUser._id));
        this.fetchNotifications();
      }, pollingInterval);
    }

    stopNotificationsPolling = () => {
      clearInterval(this._interval);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    loggedInUser: state.user.loggedIn.user,
  });

  return connect(mapStateToProps)(WithNotifications);
}

export default withNotifications;