// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

const ClientWorkflowGridListItem = ({ clientWorkflow, match, tagStore }) => {
  return (
    <Link
      to={`${match.url}/${clientWorkflow._id}`}
      className="portal-workflow-list-item"
    >
      <div className="-info">
        <div className="-title">{clientWorkflow.title}</div>
        <div className="-description">{clientWorkflow.description}</div>
        <div className="-items">{clientWorkflow.items.length} items</div>
      </div>
      <div className="-arrow">
        <i className="-i fal fa-chevron-right" />
      </div>
    </Link>
  )
}

ClientWorkflowGridListItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  clientWorkflow: PropTypes.object.isRequired,
}

ClientWorkflowGridListItem.defaultProps = {
  isSelected: false,
}

const mapStoreToProps = store => {
  return {
    loggedInUser: store.user.loggedIn.user,
    tagStore: store.tag,
  }
}

export default withRouter(connect(mapStoreToProps)(ClientWorkflowGridListItem))
