/**
 * Reusable stateless form component for Client
 */

// import primary libraries
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'

// import form components
import StaffNotificationForm from '../../../notification/components/StaffNotificationForm.js.jsx'
import ListComparator from '../../../../global/components/forms/ListComparator.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'

const PracticeClientForm = ({
  cancelLink,
  client,
  formHelpers,
  formTitle,
  formType,
  handleFormChange,
  handleFormSubmit,
  message,
  handleExistingClient,
  staffListItems,
  selected,
  selectedStaffIds,
  handleSelectStaff,
  handleAddNewStaff,
  handleSetNotification,
  setNotification,
  staffNotification,
  handleNotificationChange,
}) => {
  // set the button text
  const buttonText = formType === 'create' ? 'Create Client' : 'Update Client'
  let isDisabled =
    !client ||
    !client.name ||
    !client.name.trim() ||
    (_.isObject(message) && client && message.name === client.name)

  let errorMessage = _.isObject(message)
    ? `This client name exist in ${
        message.status == 'visible'
          ? 'active client list'
          : 'archived client list'
      }`
    : errorMessage
      ? 'Could not save Client'
      : ''
  let clientExist = _.isObject(message) ? message.status === 'archived' : false
  let clientName = _.isObject(message) ? message.name : ''

  let title = 'Add a new client'
  let confirmButton = handleFormSubmit
  let confirmText = 'Save'

  if (selectedStaffIds && selectedStaffIds.length && selectedStaffIds[0]) {
    if (setNotification) {
      title = 'Set staff notification'
      confirmButton = handleFormSubmit
      confirmText = 'Save'
    } else {
      confirmButton = handleSetNotification
      confirmText = 'Next'
    }
  }
  const hasStaff = staffListItems.length > 0
  const sortedStaffList = staffListItems
    .slice(0)
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
  const onSubmit = useCallback(() => {
    if (client.name.trim().length < 3) {
      alert('Client name must be at least 3 characters long')
      return
    }

    confirmButton()
  }, [client, confirmButton])

  return (
    <div className="form-container -slim -yt-edit-form">
      <div
        name="clientForm"
        className="client-form"
      >
        <h3>{title}</h3>
        {setNotification ? (
          <div>
            <StaffNotificationForm
              handleFormChange={handleNotificationChange}
              staffNotification={staffNotification}
              allowedToUpdate={true}
              noTopMargin={true}
              multiple={true}
            />
            <div
              className="input-group"
              style={{ marginTop: '1em' }}
            >
              <div className="yt-row space-between">
                <button
                  className="yt-btn link"
                  onClick={handleSetNotification}
                >
                  Back
                </button>
                <button
                  className="yt-btn "
                  onClick={onSubmit}
                >
                  {confirmText}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <TextInput
              change={handleFormChange}
              label="Name"
              name="client.name"
              required={true}
              value={client && client.name}
              helpText={errorMessage}
              classes="-yt-edit-input"
            />
            {clientExist ? (
              <div style={{ position: 'relative', top: '-10px' }}>
                <button
                  className="yt-btn xx-small link info"
                  onClick={() => handleExistingClient('createNew', message)}
                >
                  {`Create new client with this name ${
                    selectedStaffIds &&
                    selectedStaffIds.length &&
                    selectedStaffIds[0]
                      ? 'and assign staff'
                      : ''
                  }`}
                </button>
                <br />
                <button
                  className="yt-btn xx-small link info"
                  onClick={() => handleExistingClient('reInstate', message)}
                  style={{ position: 'relative', top: '-4px' }}
                >
                  {`Reinstate existing client ${
                    selectedStaffIds &&
                    selectedStaffIds.length &&
                    selectedStaffIds[0]
                      ? 'and assign staff'
                      : ''
                  }`}
                </button>
              </div>
            ) : null}
            <TextInput
              change={handleFormChange}
              label="Client identifier"
              name="client.identifier"
              required={false}
              value={client.identifier || client.externalId}
            />
            <FormControl
              sx={{ width: '100%', mb: '16px' }}
              disabled={!hasStaff}
            >
              <InputLabel
                sx={{ top: -8 }}
                id="assign-staff-label"
              >
                {hasStaff ? 'Assign Staff' : 'No available staff'}
              </InputLabel>
              <Select
                labelId="assign-staff-label"
                id="assign-staff-select"
                multiple
                value={selectedStaffIds}
                onChange={handleSelectStaff}
                renderValue={selected => {
                  const selectedNames = staffListItems
                    .filter(item => selected.includes(item._id))
                    .map(item => item.displayName)
                  return selectedNames.join(', ')
                }}
                variant="filled"
                MenuProps={{
                  sx: {
                    zIndex: 100000,
                  },
                  PaperProps: {
                    sx: {
                      maxHeight: 350,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                {sortedStaffList.map(option => (
                  <MenuItem
                    key={option._id}
                    value={option._id}
                    sx={{ textTransform: 'none' }}
                  >
                    <Checkbox checked={selectedStaffIds.includes(option._id)} />
                    <ListItemText primary={option.displayName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ListComparator
              change={handleFormChange}
              filterable={false}
              allItems={formHelpers.engagementTypes}
              label="Engagement types"
              name="client.engagementTypes"
              reorderable={false}
              required={false}
              items={client.engagementTypes}
            />
            <div
              className="input-group"
              style={{ marginTop: '1em' }}
            >
              <div className="yt-row space-between">
                <Link
                  className="yt-btn link"
                  to={cancelLink}
                >
                  Cancel
                </Link>
                <button
                  className="yt-btn "
                  disabled={isDisabled}
                  onClick={onSubmit}
                >
                  {confirmText}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

PracticeClientForm.propTypes = {
  cancelLink: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  formHelpers: PropTypes.object,
  formTitle: PropTypes.string,
  formType: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  users: PropTypes.array,
}

PracticeClientForm.defaultProps = {
  formHelpers: {},
  formTitle: '',
}

export default PracticeClientForm
