export const getShareAuthTypes = (
  staffStore,
  loggedInUser,
  firm,
  hasIndividual = false,
) => {
  const staff = Object.values(staffStore.byId).find(
    s => s._user === loggedInUser._id,
  )

  const authTypes = [
    { display: 'Direct Link', val: 'none' },
    { display: 'Question/Answer', val: 'secret-question' },
  ]

  const individualAuthType = {
    display: 'Individual Authentication',
    val: 'individual-auth',
  }

  const questionAuthType = {
    display: 'Question/Answer',
    val: 'secret-question',
  }

  const isAuthTypeRestricted = !firm.allowChangeAuthScheme

  if (isAuthTypeRestricted && hasIndividual && firm.authDefault === 'QA') {
    return {
      authTypes: [questionAuthType, individualAuthType],
      isAuthTypeRestricted: false,
    }
  }

  if (loggedInUser.admin || staff?.allowDirectLink) {
    return hasIndividual
      ? { authTypes: [...authTypes, individualAuthType], isAuthTypeRestricted }
      : { authTypes, isAuthTypeRestricted }
  }

  return {
    authTypes: hasIndividual
      ? [questionAuthType, individualAuthType]
      : [questionAuthType],
    isAuthTypeRestricted,
  }
}
