/**
 * Reusable stateless form component for QuickTask file requests
 *
 * TODO: Build this form.
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'

// import form components
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'

const QuickTaskFileForm = ({
  cancelLink,
  formHelpers,
  formTitle,
  formType,
  handleFormChange,
  handleFormSubmit,
  quickTask,
}) => {
  // set the button text
  const buttonText =
    formType === 'create' ? 'Create Quick Task' : 'Update Quick Task'

  // set the form header
  const header = formTitle ? (
    <div className="formHeader">
      <h2> {formTitle} </h2>
      <hr />
    </div>
  ) : (
    <div />
  )

  return (
    <div className="yt-container">
      <div className="yt-row center-horiz">
        <div className="form-container -slim">
          <form
            name="quickTaskForm"
            className="quickTask-form"
            onSubmit={handleFormSubmit}
          >
            {header}
            {/* <TextInput
              change={handleFormChange}
              label="Name"
              name="quickTask.name"
              placeholder="Name (required)"
              required={true}
              value={quickTask.name}
            /> */}
            <div className="input-group">
              <div className="yt-row space-between">
                {/* <Link className="yt-btn link" to={cancelLink}>Cancel</Link> */}
                <button
                  className="yt-btn "
                  type="submit"
                >
                  {' '}
                  {buttonText}{' '}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

QuickTaskFileForm.propTypes = {
  // cancelLink: PropTypes.string.isRequired
  // , formHelpers: PropTypes.object
  // , formTitle: PropTypes.string
  // , formType: PropTypes.string.isRequired
  // , handleFormChange: PropTypes.func.isRequired
  // , handleFormSubmit: PropTypes.func.isRequired
}

QuickTaskFileForm.defaultProps = {
  formHelpers: {},
  formTitle: '',
}

export default QuickTaskFileForm
