import React, { useMemo, useState } from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

import permissions from '../../../global/utils/permissions.js'

const FileSettingsDropdown = React.memo(props => {
  const {
    history,
    match,
    sendDeleteFile,
    role,
    file,
    isSubfolderFromTemplate,
    selectedFirm,
    toggleUpdateFilename,
    parentFolder,
    handleOpenMoveModal,
    basicPermissions,
    eSigAccess,
    handleOpenQuickTaskModal,
    viewingAs,
    handleSetStatus,
    handleOpenShareModal,
    isFolderFromTemplate,
    handleOpenFolderPermissionModal,
    handleScan,
    handleDownload,
  } = props
  const [anchorElement, setAnchorElement] = useState(null)

  const handleSettingsMenuOpen = event => setAnchorElement(event.currentTarget)
  const handleSettingsMenuClose = () => setAnchorElement(null)

  const contentType = useMemo(() => {
    let contentType = file && file.contentType
    if (file && file.category != 'folder' && file.fileExtension) {
      if (file.fileExtension.toLowerCase().indexOf('.pdf') > -1) {
        contentType = 'application/pdf'
      } else if (file.fileExtension.toLowerCase().indexOf('.doc') > -1) {
        contentType = 'application/doc'
      } else {
        contentType = file.fileExtension
      }
    }
    return contentType
  }, [file])

  const fileSettingsItems = [
    {
      label: 'Delete File',
      name: 'delete_file',
      value: 'delete_file',
      visible: viewingAs === 'archived',
      disabled: !permissions.hasPermission(
        selectedFirm,
        parentFolder,
        file,
        `${role}Delete`,
      ),
      tooltipMessage:
        "You don't have permission to archive/delete files and folders",
      clickAction: () => {
        sendDeleteFile(file)
      },
    },
    {
      label: 'Reinstate File',
      name: 'reinstate_file',
      value: 'reinstate_file',
      visible: viewingAs === 'archived',
      disabled: false,
      tooltipMessage: null,
      clickAction: () => {
        handleSetStatus(file, 'visible')
      },
    },
    {
      label: `Delete ${file.category !== 'folder' ? 'File' : 'Folder'}`,
      name: 'delete_file',
      value: 'delete_file',
      visible: viewingAs === 'portal' && !isSubfolderFromTemplate,
      disabled: !selectedFirm || !selectedFirm.allowDeleteFiles,
      tooltipMessage:
        "You don't have permission to archive/delete files and folders",
      clickAction: () => {
        sendDeleteFile()
      },
    },
    {
      label: `Move ${file.category !== 'folder' ? 'File' : 'Folder'}`,
      name: 'move_file',
      value: 'move_file',
      visible:
        viewingAs === 'portal' &&
        !isSubfolderFromTemplate &&
        selectedFirm &&
        selectedFirm.allowMoveFiles,
      disabled: false,
      tooltipMessage: null,
      clickAction: () => {
        handleOpenMoveModal(file)
      },
    },
    {
      label: `Rename ${file.category !== 'folder' ? 'File' : 'Folder'}`,
      name: 'rename_file',
      value: 'rename_file',
      visible: viewingAs === 'portal',
      disabled: !selectedFirm || !selectedFirm.allowRenameFiles,
      tooltipMessage: "You don't have permission to update files and folders",
      clickAction: () => {
        toggleUpdateFilename()
      },
    },
    {
      label: `Archive`,
      name: 'archive_file',
      value: 'archive_file',
      visible: viewingAs !== 'archived' && viewingAs !== 'portal',
      disabled:
        isSubfolderFromTemplate ||
        !permissions.hasPermission(
          selectedFirm,
          parentFolder,
          file,
          `${role}Delete`,
        ),
      tooltipMessage:
        "You don't have permission to archive/delete files and folders",
      clickAction: () => {
        handleSetStatus(file, 'archived')
      },
    },
    {
      label: `Download`,
      name: 'download_file',
      value: 'download_file',
      visible: viewingAs !== 'archived' && viewingAs !== 'portal',
      disabled: !permissions.hasPermission(
        selectedFirm,
        parentFolder,
        file,
        `${role}Download`,
      ),
      tooltipMessage: "You don't have permission to download files and folders",
      clickAction: () => {
        handleDownload(file)
      },
    },
    {
      label: `File Activity`,
      name: 'file_activity',
      value: 'file_activity',
      visible:
        file.category !== 'folder' &&
        viewingAs !== 'archived' &&
        viewingAs !== 'portal',
      disabled: file.category === 'folder',
      tooltipMessage: "You don't have permission to view activity",
      clickAction: () => {
        const endIndexOf =
          match && match.url ? match.url.indexOf('file-activity') : -1
        let fileActivityUrl =
          endIndexOf > 0 ? match.url.substr(0, endIndexOf) : `${match.url}/`
        fileActivityUrl +=
          viewingAs === 'archived' ? '' : `file-activity/${file._id}`
        history.replace(fileActivityUrl)
      },
    },
    {
      label: `Move`,
      name: 'move_file',
      value: 'move_file',
      visible: viewingAs !== 'archived' && viewingAs !== 'portal',
      disabled: isSubfolderFromTemplate || !handleOpenMoveModal,
      tooltipMessage:
        "You don't have permission to move sub folders created from folder templates",
      clickAction: () => {
        handleOpenMoveModal(file)
      },
    },
    {
      label: `Rename`,
      name: 'rename_file',
      value: 'rename_file',
      visible: viewingAs !== 'archived' && viewingAs !== 'portal',
      disabled: !permissions.hasPermission(
        selectedFirm,
        parentFolder,
        file,
        `${role}Update`,
      ),
      tooltipMessage: "You don't have permission to rename files and folders",
      clickAction: () => {
        toggleUpdateFilename()
      },
    },
    {
      label: `Request Signature`,
      name: 'request_signature',
      value: 'request_signature',
      visible:
        viewingAs !== 'archived' &&
        viewingAs !== 'portal' &&
        file.category !== 'folder',
      disabled:
        basicPermissions ||
        file.category === 'folder' ||
        !eSigAccess ||
        (contentType &&
          contentType.indexOf('pdf') < 0 &&
          contentType.indexOf('doc') < 0),
      tooltipMessage: "You don't have permission to request signature",
      clickAction: () => {
        handleOpenQuickTaskModal(file)
      },
    },
    {
      label: `Share`,
      name: 'share_file',
      value: 'share_file',
      visible: viewingAs !== 'archived' && viewingAs !== 'portal',
      disabled: basicPermissions,
      tooltipMessage: "You don't have permission to share files and folders",
      clickAction: () => {
        handleOpenShareModal(file)
      },
    },
    {
      label: `Permissions`,
      name: 'permissions',
      value: 'permissions',
      visible: file.category === 'folder',
      disabled:
        isFolderFromTemplate ||
        isSubfolderFromTemplate ||
        (file.category === 'folder' &&
          (file.status === 'archived' || file.status === 'deleted')),
      tooltipMessage:
        "You don't have permission to modify the permissions of files and folders",
      clickAction: () => {
        handleOpenFolderPermissionModal(file)
      },
    },
    {
      label: `Virus Scan`,
      name: 'virus_scan',
      value: 'virus_scan',
      visible: handleScan && file.category !== 'folder',
      disabled: false,
      tooltipMessage: null,
      clickAction: () => {
        handleScan(file)
      },
    },
  ]

  return (
    <>
      <Tooltip title="Settings">
        <IconButton onClick={handleSettingsMenuOpen}>
          <SettingsIcon color="disabled" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleSettingsMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {selectedFirm && fileSettingsItems.map(
          item =>
            item.visible && (
              <Tooltip
                placement={'right'}
                title={item.disabled && item.tooltipMessage}
              >
                <span>
                  <MenuItem
                    key={item.value}
                    onClick={() => {
                      item.clickAction()
                      handleSettingsMenuClose()
                    }}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </MenuItem>
                </span>
              </Tooltip>
            ),
        )}
      </Menu>
    </>
  )
})

export default FileSettingsDropdown
