/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import moment from 'moment';
import { DateTime } from 'luxon'

// import actions

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import CloseWrapper from '../../../global/components/helpers/CloseWrapper.js.jsx'

// import components
import SingleRequestOptions from './SingleRequestOptions.js.jsx'

class RequestTableListItem extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      singleRequestOptionsOpen: false,
    }
    this._bind('_handleCloseRequestListOption')
  }

  _handleCloseRequestListOption(e) {
    e.stopPropagation()
    this.setState({
      singleRequestOptionsOpen: false,
    })
  }

  render() {
    const {
      checked,
      request,
      handleSelectRequest,
      clearSelectedRequestIds,
      userMap,
      handleUpdateRequest,
      match,
      isViewing,
    } = this.props

    const { singleRequestOptionsOpen } = this.state

    const createdBy =
      userMap && request && request._createdBy && userMap[request._createdBy]
        ? userMap[request._createdBy].firstname +
          ' ' +
          userMap[request._createdBy].lastname
        : 'n/a'

    return (
      <div className="table-row -file-item -request-list-table">
        {/* {
                    isViewing === "portal" ? null :
                    <div className="table-cell">
                        <CheckboxInput
                            // disabled={!checked}
                            name="request"
                            value={checked}
                            change={() => handleSelectRequest(request._id)}
                            checked={checked}
                        />
                    </div>
                } */}
        {isViewing === 'portal' ? null : (
          <div className="table-cell">
            <div
              className="-options"
              onClick={() => this.setState({ singleRequestOptionsOpen: true })}
            >
              <div
                style={{ position: 'relative', height: '100%', width: '100%' }}
              >
                <CloseWrapper
                  isOpen={singleRequestOptionsOpen}
                  closeAction={this._handleCloseRequestListOption}
                />
                <i className="far fa-ellipsis-v"></i>
                <SingleRequestOptions
                  isOpen={singleRequestOptionsOpen}
                  closeAction={this._handleCloseRequestListOption}
                  request={request}
                  handleUpdateRequest={handleUpdateRequest}
                />
              </div>
            </div>
          </div>
        )}
        <div className="table-cell -title">
          <div className="yt-row center-vert">
            <span className="-icon">
              <i className="fas fa-list-alt"></i>
            </span>
            <div className="-file-info">
              <Link
                className="-name"
                to={`${match.url}/${request._id}/${
                  isViewing === 'portal' ? 'published' : 'unpublished'
                }`}
                onClick={clearSelectedRequestIds}
              >
                {request.name}
              </Link>
              <br />
            </div>
          </div>
        </div>
        <div className="table-cell">{request.totalTasks || 0}</div>
        <div className="table-cell">{request.totalUploadedFiles || 0}</div>
        <div className="table-cell">{createdBy}</div>
        <div className="table-cell -date">
          {DateTime.fromISO(request.updated_at).toLocaleString(
            DateTime.DATE_SHORT
          )}
        </div>
      </div>
    )
  }
}

RequestTableListItem.propTypes = {}

RequestTableListItem.defaultProps = {}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    loggedInUser: store.user.loggedIn.user,
  }
}

export default withRouter(connect(mapStoreToProps)(RequestTableListItem))
