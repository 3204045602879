import React, { useEffect, useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import queryString from 'query-string'
import { DateTime } from 'luxon'

import _cloneDeep from 'lodash/cloneDeep'

import {
  Box,
  Tooltip,
  Typography,
  Chip,
  Checkbox,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  InputLabel,
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {
  DataGrid,
  getGridStringOperators,
  getGridDateOperators,
  useGridApiRef,
} from '@mui/x-data-grid'
import Autocomplete from '@mui/material/Autocomplete'

// import actions
import * as fileActions from '../fileActions'
import * as fileActivityActions from '../../fileActivity/fileActivityActions'

// import global components
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'

// import resource components
import FileActivityListItem from '../../activity/components/fileActivityListItem.js.jsx'
import RoleModalComponent from '../../../global/enum/RoleModalComponent.js.jsx'

// import utils
import displayUtils from '../../../global/utils/displayUtils'
import permissions from '../../../global/utils/permissions'

import filterUtils from '../../../global/utils/filterUtils'
import fileUtils from '../../../global/utils/fileUtils'
import sortUtils from '../../../global/utils/sortUtils'
import brandingName from '../../../global/enum/brandingName.js'
import FileListToolbar from './FileListToolbar.jsx'
import { debounce, min } from 'lodash'
import FileSettingsDropdown from './FileSettingsDropdown'
import EditFileNameForm from './EditFileNameForm'

const COLUMNS_KEY = 'AllFilesList_DisplayColumns'

const CustomInputComponent = ({ item, applyValue }) => {
  const [value, setValue] = useState(item.value || '')

  const handleApplyClick = () => {
    applyValue({ ...item, value }) // Apply the filter when the "Apply" button is clicked
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <TextField
        value={value}
        onChange={e => setValue(e.target.value)}
        size="small"
        placeholder="Search..."
        label="Name"
        fullWidth
        variant="standard"
      />
      <Button
        onClick={handleApplyClick}
        fullWidth
        variant="outlined"
        size="small"
      >
        Apply
      </Button>
    </div>
  )
}

const CustomFilter = ({ item, applyValue, options }) => {
  const handleChange = event => {
    applyValue({ ...item, value: event.target.value })
  }

  return (
    <FormControl
      fullWidth
      size="small"
      variant="standard"
      disableCloseOnSelect
    >
      <InputLabel id="tagsSelect">Tags</InputLabel>
      <Select
        labelId="tagsSelect"
        multiple
        placeholder="Select Tag"
        value={item.value || []}
        onChange={handleChange}
        renderValue={selected =>
          selected
            .map(id => {
              const option = options.find(opt => opt._id === id)
              return option ? option.name : ''
            })
            .join(', ')
        }
      >
        {options.map(option => (
          <MenuItem
            key={option._id}
            value={option._id}
          >
            <Checkbox checked={(item.value || []).indexOf(option._id) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const linkActionListItems = (eSigAccess, selectedFileIds, fileMap) => {
  let disabledSignatureRequest = true

  if (
    eSigAccess &&
    selectedFileIds &&
    selectedFileIds.length === 1 &&
    fileMap
  ) {
    const fileId = selectedFileIds && selectedFileIds[0]
    const file = fileMap && fileMap[fileId]
    let contentType = file && file.contentType
    if (file && file.category != 'folder' && file.fileExtension) {
      if (file.fileExtension.toLowerCase().indexOf('.pdf') > -1) {
        contentType = 'application/pdf'
      } else if (file.fileExtension.toLowerCase().indexOf('.doc') > -1) {
        contentType = 'application/doc'
      } else {
        contentType = file.fileExtension
      }
      if (
        contentType &&
        (contentType.indexOf('pdf') > -1 || contentType.indexOf('doc') > -1)
      ) {
        disabledSignatureRequest = false
      }
    }
  }

  return [
    { label: 'Share files', name: 'file_share_file', value: 'file_share_file' },
    {
      label: 'Request files',
      name: 'file_request_file',
      value: 'file_request_file',
    },
    {
      label: 'Request signature',
      name: 'file_signature',
      value: 'file_signature',
      disabled: disabledSignatureRequest,
    },
  ]
}

const FileList = props => {
  const {
    dispatch,
    match,
    location,
    history,
    searchListArgs,
    // listArgs,
    // listArgsObj,
    fileStore,
    firmStore,
    userStore,
    clientStore,
    utilFileStore,
    clearSelectedFileIds,
    // selectedFileIds,
    selectedFirm,
    loggedInUser,
    userMap,
    // allTags,
    isConfigScreenView,
    newState,
    // handleQuery,
    // handleFilter,
    handleFileList,
    handleUpdateList,
    handleSetPagination,
    // setPerPage,
    // handleSelectFiles,
    handleSelectFile,
    // handleToggleSelectAll,
    // handleOpenShareModal,
    // handleOpenCopyModal,
    // handleOpenMoveModal,
    // handleOpenFolderPermissionModal,
    // handleOpenQuickTaskModal,
    // handleOpenFolderModal,
    // handleOpenUploadModal,
    // handleOpenRequestModal,
    // handleOpenTemplateModal,
    // _handleMoveSelectedChange,
    // _handleLocationChange,
    paginatedList,
    // selectedTagIds,
    // showActions,
    sortedAndFilteredList, // Use this list to get total file count minus archived and deleted files.
    tagStore,
    // totalListInfo,
    viewingAs,
    // staffClientStore,
    staffStore,
    // handleSearch,
    folderListItems,
    // allFilesFromListArgs,
    staffListItem,
    // objFileActivityListItems,
    // sortBy,
    handleSort,
    loggedInStaff,
    isFirmOwner,
    // fIds,
    // handleOpenFileVersionModal,
    // handleOpenDocumentTemplateModal,
    // handleChangeRoleModal,
    fileMap,
    clientUserStore,
    fileActivityStore,
  } = props

  const gridApiRef = useGridApiRef()

  const isFirmStaff = useMemo(() => {
    return staffListItem && loggedInUser
      ? staffListItem.some(
          x => x._user == loggedInUser._id && x.status == 'active',
        )
      : false
  }, [staffListItem, loggedInUser])

  const [onProcess, setOnProcess] = useState(false)
  const [progressOpen, setProgressOpen] = useState(false)
  const [progressText, setProgressText] = useState('')
  const [archiveProcess, setArchiveProcess] = useState(false)
  const [downloadWarningModalOpen, setDownloadWarningModalOpen] =
    useState(false)
  const [selectedFile, setSelectedFile] = useState(
    match.params.folderId ? fileStore.byId[match.params.folderId] : {},
  )
  const [selectedFileData, setSelectedFileData] = useState(null)
  const [fileOptionsOpen, setFileOptionsOpen] = useState(false)
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [searchHeaderColumns, setSearchHeaderColumns] = useState({
    filename: { disableSearch: true, searchText: '' },
    tags: { disableSearch: true, searchText: '' },
    uploadName: { disableSearch: true, searchText: '' },
    updated_at: { disableSearch: true, searchText: '' },
  })
  const [quickFileSearch, setQuickFileSearch] = useState('')
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedModel = localStorage.getItem(COLUMNS_KEY)
    return savedModel ? JSON.parse(savedModel) : {}
  })
  const [dropFilesStatusText, setDropFilesStatusText] = useState()
  const [dropFilesFolderId, setDropFilesFolderId] = useState()

  const [selectedFileIds, setSelectedFileIds] = useState([])
  const [invalidateList, setInvalidateList] = useState(false)
  const [roleModal, setRoleModal] = useState()

  const searchParams = new URLSearchParams(location.search)
  const page = useMemo(() => {
    return parseInt(searchParams.get('page')) || 1
  }, [location.search])
  const per = useMemo(() => {
    return parseInt(searchParams.get('per')) || 50
  }, [location.search])

  const memoizedRows = useMemo(() => {
    return paginatedList
  }, [paginatedList])

  useEffect(() => {
    return () => {
      _clearAllState()
    }
  }, [])

  const updateList = () => {
    if (handleUpdateList) {
      handleUpdateList()
    }
    setSelectedFileIds([])
    setSelectedFile(null)
    dispatch(fileActions.invalidateList())
  }

  const _handleChangeRoleModal = (roleModal, selectedFile) => {
    if (invalidateList) {
      updateList()
    }
    if (selectedFile && selectedFile._id && selectedFile.filename) {
      setRoleModal(roleModal)
      setSelectedFile(selectedFile)
      setInvalidateList(false)
    } else {
      setRoleModal(roleModal)
      setInvalidateList(false)
    }
  }

  const _handleContextMenuSubmit = (action, showWarningModal) => {
    if (action === 'copy') {
      // handleOpenCopyModal()
      _handleChangeRoleModal('file_copy_file')
    } else if (action === 'move') {
      if (showWarningModal) {
        setProgressOpen(true)
        setProgressText('move')
      } else {
        // handleOpenMoveModal()
        _handleChangeRoleModal('file_move_file')
      }
    } else if (action === 'download') {
      _handleDownloadFiles()
    } else if (action === 'print') {
      //TODO
      // this._handleBulkPrintFiles() - not declared in the original function, action==='print' doesn't seem to come from anywhere
    } else if (action === 'archive') {
      if (showWarningModal) {
        setProgressOpen(true)
        setProgressText('archive')
      } else {
        _handleSetMultipleStatus()
      }
    } else if (action === 'share') {
      if (showWarningModal) {
        setProgressOpen(true)
        setProgressText('share')
      } else {
        // handleOpenShareModal()
        _handleChangeRoleModal('file_share_file')
      }
    } else if (action == 'permission') {
      // handleOpenFolderPermissionModal()
      _handleChangeRoleModal('folder_permission')
    } else {
      if (showWarningModal) {
        setProgressOpen(true)
        setProgressText('move')
      } else {
        setProgressOpen(false)
        setProgressText('')
      }
    }
  }

  const _handleSetMultipleStatus = () => {
    const sendData = {
      status: 'archived',
      filesId:
        selectedFile && selectedFile._id ? [selectedFile._id] : selectedFileIds,
      action: 'status',
      firmId: match.params.firmId,
    }

    setArchiveProcess(true)
    setProgressOpen(false)
    dispatch(fileActions.sendUBulkupdateFiles(sendData)).then(json => {
      updateList()
      _clearAllState()
    })
  }

  const _handleDownloadFiles = async () => {
    setOnProcess(true)
    if (
      selectedFileIds &&
      selectedFileIds.length > 1 &&
      selectedFirm &&
      selectedFirm.zipFilesDownload
    ) {
      dispatch(fileActions.zipFiles(selectedFileIds))
    } else if (selectedFileIds && selectedFileIds.length) {
      const files = selectedFileIds.map(
        id => id && fileStore && fileStore.byId && fileStore.byId[id],
      )

      dispatch(fileActions.startFilesDownload())
      let downloadedCount = 0
      // iterate through selected file ids to download each file content
      for (let file of files) {
        // download file or download folder as zip
        if (file && file._id && file.category === 'folder') {
          dispatch(fileActions.zipFiles([file._id]))
        } else {
          const fileLink = fileUtils.getDownloadLink(file)
          var a = document.createElement('a')
          a.style.display = 'none'
          a.href = `${fileLink}?userLevel=staffclient&type=downloaded`
          a.setAttribute('download', file.filename)
          a.setAttribute('target', '_blank')
          document.body.appendChild(a)
          a.click()
          await new Promise(resolve => setTimeout(resolve, 1000))
          a.remove()
        }
        downloadedCount++
      }
      dispatch(fileActions.finishFilesDownload())
    }

    _clearAllState()
  }

  const _clearAllState = () => {
    setFileOptionsOpen(false)
    setDownloadWarningModalOpen(false)
    setArchiveProcess(false)
    setProgressOpen(false)
    setProgressText('')
    setOnProcess(false)
    setSelectedFile(null)
    setSelectedFileIds([])
  }

  const _handleActivityModal = (status, file) => {
    if (status) {
      setIsActivityModalOpen(status)
      setSelectedFileData(file)
      dispatch(
        fileActivityActions.fetchListIfNeeded(
          '_firm',
          match.params.firmId,
          '_file',
          file._id,
        ),
      )
    } else {
      setIsActivityModalOpen(false)
      setSelectedFileData(null)
    }
  }

  const showWarningModal = useMemo(() => {
    let show = false
    if (selectedFileIds && selectedFileIds.length && paginatedList) {
      show =
        selectedFileIds && selectedFileIds.length > 10
          ? 'More than 10 files have been selected'
          : paginatedList.some(
                file =>
                  file &&
                  selectedFileIds.includes(file._id) &&
                  file.category === 'folder' &&
                  (file.totalChildFile || file.totalChildFolder),
              )
            ? 'A folder has been selected'
            : false
    }
    return show
  }, [selectedFileIds, paginatedList])

  const parentFolder = useMemo(() => {
    let folder = {}
    if (match.params.folderId) {
      folder = fileStore.byId[match.params.folderId]
        ? fileStore.byId[match.params.folderId]
        : {}
    }
    return folder
  }, [match.params.folderId])

  const role = useMemo(() => {
    return permissions.getUserRole(
      loggedInUser,
      match.params.firmId,
      match.params.clientId,
      staffStore,
      clientUserStore,
    )
  }, [
    loggedInUser,
    match.params.firmId,
    match.params.clientId,
    staffStore,
    clientUserStore,
  ])

  const basicPermissions = useMemo(() => {
    return permissions.isStaffBasic(
      staffStore,
      loggedInUser,
      match.params.firmId,
    )
  }, [staffStore, loggedInUser, match.params.firmId])

  const isFilterActive = useMemo(() => {
    return (
      !searchHeaderColumns.filename.disableSearch ||
      !searchHeaderColumns.uploadName.disableSearch ||
      !searchHeaderColumns.tags.disableSearch ||
      !searchHeaderColumns.updated_at.disableSearch
    )
  }, [searchHeaderColumns])

  const { sortedTagListItems, tagNameList } = useMemo(() => {
    let tagListArgs = ['~firm', match.params.firmId]
    const tagListItems = tagStore.util.getList('~firm', match.params.firmId)
    const tagList = tagListItems
      ? tagListArgs.reduce((obj, nextKey) => obj[nextKey], tagStore.lists)
      : null
    let filteredByQuery = []
    let sortedTagListItems = []
    let tagNameList = []
    let query = tagList && tagList.query
    let queryTestString = ('' + query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers
    if (queryTestString) {
      filteredByQuery =
        tagList && tagList.items && queryTestString && query
          ? tagList.items.filter(tagId => {
              return (
                tagStore.byId[tagId] &&
                filterUtils.filterTag(queryTestString, tagStore.byId[tagId])
              )
            })
          : []
    } else if (tagList && tagList.items && tagList.items.length) {
      filteredByQuery = tagList.items
    }
    sortedTagListItems = filteredByQuery.map(item => {
      let newItem = tagStore.byId[item]
      tagNameList.push(newItem.name)
      return newItem
    })
    return {
      sortedTagListItems: sortedTagListItems,
      tagNameList: tagNameList,
    }
  }, [match.params.firmId, tagStore.util.getList('~firm', match.params.firmId)])

  const customDateOperators = getGridDateOperators()
    .filter(element => {
      return element.value === 'is'
    })
    .map(operator => {
      return {
        ...operator,
        label: 'Is near',
      }
    })

  const customStringOperators = getGridStringOperators()
    .filter(operator => operator.value === 'contains')
    .map(operator => {
      return {
        ...operator,
        InputComponent: CustomInputComponent,
      }
    })

  const staffFilesRoute = useMemo(() => {
    return location.state.breadcrumbs.length - 1
  }, [location])

  const isPublicFiles = useMemo(() => {
    return (
      match.path === '/firm/:firmId/files/public' ||
      (match.path === '/firm/:firmId/files/public/:fileId' &&
        !match.params.clientId)
    )
  }, [match.path, match.params.clientId])

  const clientListItem = useMemo(() => {
    return clientStore.util.getList(
      '_firm',
      match.params.firmId,
      'status',
      'visible',
    )
  }, [
    clientStore.util.getList('_firm', match.params.firmId, 'status', 'visible'),
  ])

  const selectedClient = useMemo(() => {
    return clientStore.byId[match.params.clientId]
  }, [clientStore.byId[match.params.clientId]])

  const options = useMemo(() => {
    let opts = []
    if (
      !isEmpty &&
      !isFetching &&
      (roleModal === 'file_move_file' || roleModal === 'file_copy_file')
    ) {
      opts.push({ value: 'public', label: '(General Files)' }) // General Location)

      const staffListItem = staffStore.util.getList(
        '_firm',
        match.params.firmId,
      )
      if (isFirmOwner && staffListItem) {
        staffListItem.map(staff => {
          if (staff.status === 'active' && userMap[staff._user]) {
            const displayName =
              `${userMap[staff._user].firstname}${
                userMap[staff._user].firstname ? ' ' : ''
              }${userMap[staff._user].lastname}` ||
              `${userMap[staff._user].username}`
            opts.push({
              value: `personal${staff._user}`,
              label: `${displayName} | Personal files`,
            })
          }
        })
      } else {
        opts.push({
          value: `personal${loggedInUser._id}`,
          label: '(Personal Files)',
        })
      }

      opts = sortUtils._object(opts, 'label')
      if (clientListItem && opts && opts.length > 1) {
        let clientOptions = []
        for (const client of clientListItem) {
          if (client && client._id) {
            if (client.status === 'visible') {
              let newObj = {
                value: client._id,
                label: client.name,
              }
              clientOptions.push(newObj)
            }
          }
        }
        clientOptions = sortUtils._object(clientOptions, 'label')
        opts = opts.concat(clientOptions)
      }
    }
    return opts
  }, [
    isEmpty,
    isFetching,
    roleModal,
    staffStore.util.getList('_firm', match.params.firmId),
    isFirmOwner,
    userMap,
    loggedInUser,
    clientListItem,
  ])

  const getVirusScanIcon = file => {
    if (file.category == 'folder') {
      return 'N/A'
    }

    switch (file.virus_scan) {
      case 'not_scanned':
        return (
          <i
            className="fas fa-question"
            style={{ color: 'gray' }}
          ></i>
        )
      case 'pending':
        return (
          <i
            className="fas fa-hourglass-half"
            style={{ color: 'turquoise' }}
          ></i>
        )
      case 'clean':
        return (
          <i
            className="fas fa-shield"
            style={{ color: 'green' }}
          ></i>
        )
      case 'infected':
        return (
          <i
            className="fas fa-virus"
            style={{ color: 'crimson' }}
          ></i>
        )
    }
  }

  const getVirusScanTooltip = file => {
    if (file.category == 'folder') {
      return null
    }
    switch (file.virus_scan) {
      case 'not_scanned':
        return 'Not scanned yet'
      case 'pending':
        return 'Scanning now...'
      case 'clean':
        return 'Safe'
      case 'infected':
        return `Virus infected with ${file.virus_scan_description}`
    }
  }

  const getUploadOriginText = file => {
    switch (file.uploadOrigin) {
      case 'user_upload': {
        return 'User Upload'
      }
      case 'file_request': {
        return 'Requested File'
      }
      case 'signature_request': {
        return 'Requested Signature'
      }
      case 'api_upload': {
        return 'Firm Upload'
      }
      default: {
        return ''
      }
    }
  }

  const getUploadOriginTooltip = file => {
    let firm = firmStore?.selected?.getItem()

    if (!file.uploadInitiator) {
      return null
    }

    switch (file.uploadOrigin) {
      case 'user_upload': {
        return file.uploadInitiator && userStore.byId[file.uploadInitiator]
          ? `Uploaded by ${userStore.byId[file.uploadInitiator].firstname} ${
              userStore.byId[file.uploadInitiator].lastname
            }`
          : null
      }
      case 'file_request':
      case 'signature_request': {
        return file.uploadInitiator && userStore.byId[file.uploadInitiator]
          ? `Requested by ${userStore.byId[file.uploadInitiator].firstname} ${
              userStore.byId[file.uploadInitiator].lastname
            }`
          : null
      }
      case 'api_upload': {
        return file.uploadInitiator && firm && file.uploadInitiator === firm._id
          ? `Uploaded by ${firm.name}`
          : null
      }
      default: {
        return ''
      }
    }
  }

  const _handleScan = async file => {
    const { selectedFileIds, dispatch } = props

    if (selectedFileIds.length) {
      // scan multiple files
      selectedFileIds.forEach(fileId => {
        dispatch(fileActions.scanFile(fileId))
      })
    } else {
      dispatch(fileActions.scanFile(file._id))
    }
  }

  const handleVisibility = (file, status) => {
    const newFile = { ...file }
    newFile.status = status
    dispatch(fileActions.sendUpdateFile(newFile))
  }

  const handleColumnVisibilityChange = newModel => {
    setColumnVisibilityModel(newModel)
    localStorage.setItem(COLUMNS_KEY, JSON.stringify(newModel))
  }

  const _filterListByDate = activityList => {
    //const { selectedDate } = this.state - selectedDate was not declared anywhere in the original component
    let selectedDate
    let newActivityList
    if (selectedDate) {
      // Filter out activities newer than the selected date. Ignore the time and only compare dates.
      // We were not zeroing milliseconds which was excluding activites with a date equal to selectedDate. It works correctly now.
      newActivityList = activityList.filter(
        activity =>
          new Date(activity.created_at).setHours(0, 0, 0, 0) <=
          selectedDate.setHours(0, 0, 0, 0),
      )
    } else {
      newActivityList = _cloneDeep(activityList)
    }
    return newActivityList
  }

  const _groupActivitiesByDate = activityListItems => {
    const dates = activityListItems.map(activity =>
      DateTime.fromISO(activity.created_at).toISODate(),
    )
    let activitiesGroupedByDate = {}
    // Create an array for each date.
    dates.forEach(date => (activitiesGroupedByDate[date] = []))
    // push all activities to their respective date arrays.
    activityListItems.forEach(activity =>
      activitiesGroupedByDate[
        DateTime.fromISO(activity.created_at).toISODate()
      ].push(activity),
    )
    return activitiesGroupedByDate
  }

  // file activity
  const filesActivitiesGroupedByDate = useMemo(() => {
    const activityListItems = selectedFileData
      ? fileActivityStore.util.getList(
          '_firm',
          match.params.firmId,
          '_file',
          selectedFileData._id,
        )
      : null
    const filteredActivityListItems = activityListItems
      ? _filterListByDate(activityListItems)
      : []
    let filesActivitiesGroupedByDate = filteredActivityListItems
      ? _groupActivitiesByDate(filteredActivityListItems)
      : []
    if (selectedFileData && selectedFileData.created_at) {
      const selectedFileDate = DateTime.fromISO(
        selectedFileData.created_at,
      ).toISODate()
      const user = userMap[selectedFileData && selectedFileData._user] || {}
      filesActivitiesGroupedByDate[selectedFileDate] = [
        ...(filesActivitiesGroupedByDate[selectedFileDate] || []),
        { ...selectedFileData, text: 'Uploaded by %USER%', user },
      ]
    }
    return filesActivitiesGroupedByDate
  }, [match.params.firmId, selectedFileData])

  const progressPresentText = useMemo(() => {
    let progressPresentText = progressText

    switch (progressText) {
      case 'move':
        progressPresentText = 'moving'
        break
      case 'archive':
        progressPresentText = 'archiving'
        break
      case 'share':
        progressPresentText = 'sharing'
        break
      default:
        progressPresentText = progressText
        break
    }
    return progressPresentText
  }, [progressText])

  const eSigAccess = useMemo(() => {
    return (
      selectedFirm &&
      selectedFirm.eSigAccess &&
      loggedInStaff &&
      loggedInStaff.eSigAccess
    )
  }, [selectedFirm, loggedInStaff])

  const icon = useMemo(() => {
    return selectedFileData
      ? displayUtils.getFileIcon(
          selectedFileData.category,
          selectedFileData.contentType,
          selectedFileData,
        )
      : null
  }, [selectedFileData])

  const _handleOpenMoveModal = () => {
    setProgressOpen(false)
    setProgressText('')
    // handleOpenMoveModal(selectedFile)
    _handleChangeRoleModal('file_move_file', selectedFile)
  }

  const _handleOpenShareModal = () => {
    setProgressOpen(false)
    setProgressText('')
    // handleOpenShareModal(selectedFile)
    _handleChangeRoleModal('file_share_file', selectedFile)
  }

  const isEmpty = !utilFileStore || !paginatedList || utilFileStore.isFetching

  const isFetching = useMemo(() => {
    return (
      !utilFileStore ||
      utilFileStore.isFetching ||
      !paginatedList ||
      !selectedFirm
    )
  }, [utilFileStore, paginatedList, selectedFirm])

  const queryParameters = new URLSearchParams(window.location.search)
  const fileIds = queryParameters.get('fIds')

  const updateSearch = () => {
    history.push({ search: searchParams.toString() })
  }

  const onFilterChange = useCallback(
    filterModel => {
      // Here you save the data you need from the filter model
      const changedState = { ...newState }
      changedState.searchListArgs.group = []
      let filterObjects = filterModel.items

      for (let filterObject of filterObjects) {
        if (!filterObject.value) {
          continue
        }
        if (filterObject.field === 'filename') {
          changedState.searchListArgs.group.push({
            fieldName: filterObject.field,
            value: filterObject.value,
          })
          continue
        }
        if (filterObject.field === 'tags') {
          changedState.searchListArgs.group.push({
            fieldName: filterObject.field,
            value: filterObject.value,
          })
          continue
        }
        if (filterObject.field === 'updated_at') {
          changedState.searchListArgs.group.push({
            fieldName: filterObject.field,
            value: DateTime.fromJSDate(filterObject.value).toISO(),
          })
          continue
        }
        if (filterObject.field === 'createdBy') {
          const createdByIds = []
          Object.keys(userMap).forEach(keyName => {
            let item = userMap[keyName]
            if (item && item._id) {
              const fullName = item.firstname + ' ' + item.lastname
              if (
                fullName &&
                fullName
                  .toLowerCase()
                  .indexOf(filterObject.value.toLowerCase()) > -1
              ) {
                createdByIds.push(item._id)
              }
            }
          })
          changedState.searchListArgs.group.push({
            fieldName: 'uploadName',
            value: filterObject.value,
            ids: createdByIds,
          })
          continue
        }
      }
      if (
        !changedState.searchListArgs.group ||
        (changedState.searchListArgs.group &&
          changedState.searchListArgs.group.length === 0)
      ) {
        delete changedState.searchListArgs.group
      } else {
        changedState.searchListArgs.group = JSON.stringify(
          changedState.searchListArgs.group,
        )
      }
      handleFileList(changedState)
    },
    [userMap],
  )

  const isMobile = window.innerWidth <= 768

  const ModalComponent = useMemo(() => {
    return RoleModalComponent[roleModal]
  }, [roleModal])

  const headerTitle = useMemo(() => {
    return match.params.clientId
      ? 'Workspace'
      : match.params.userId
        ? 'Personal'
        : 'General'
  }, [match.params.clientId, match.params.userId])

  const handleQuickFileNameSearch = debounce(value => {
    // handleQuickFileSearch(value)
    setQuickFileSearch(value)
    history.push({
      search: `?page=1&per=${per}`,
    })
    const changedState = { ...newState }
    changedState.searchListArgs.searchText = value
    changedState.searchListArgs.searchPageNumber = 1
    changedState.searchListArgs.searchPerPage = per
    changedState.selectedFileIds = []
    changedState.selectedFile = null
    handleFileList(changedState)
  }, 500)

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'Settings',
        headerName: '',
        width: 30,
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        disableExport: true,
        checkboxSelection: false,
        renderCell: params => {
          const file = params.row
          const isFolderFromTemplate =
            file.contentType &&
            file.category === 'folder' &&
            file.contentType.indexOf('template_folder') > -1
          const isSubfolderFromTemplate =
            file.contentType &&
            file.category === 'folder' &&
            file.contentType.indexOf('template_subfolder') > -1
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FileSettingsDropdown
                file={file}
                match={match}
                history={history}
                viewingAs={viewingAs}
                sendDeleteFile={file => {
                  //TODO Implement when will introduce the new file table to archived files view
                  console.log('sendDeleteFile')
                }}
                handleSetStatus={(file, status) => {
                  const sendData = {
                    status: status,
                    filesId: [file._id],
                    action: 'status',
                    firmId: match.params.firmId,
                  }
                  setArchiveProcess(true)
                  setProgressOpen(true)
                  dispatch(fileActions.sendUBulkupdateFiles(sendData)).then(
                    json => {
                      updateList()
                      _clearAllState()
                    },
                  )
                }}
                role={role}
                isSubfolderFromTemplate={isSubfolderFromTemplate}
                selectedFirm={selectedFirm}
                handleOpenMoveModal={file => {
                  _handleChangeRoleModal('file_move_file', file)
                }}
                toggleUpdateFilename={() => {
                  gridApiRef.current.startCellEditMode({
                    id: file._id,
                    field: 'filename',
                  })
                }}
                parentFolder={parentFolder}
                handleOpenQuickTaskModal={file => {
                  _handleChangeRoleModal('file_signature', file)
                }}
                basicPermissions={basicPermissions}
                eSigAccess={eSigAccess}
                handleOpenShareModal={file => {
                  _handleChangeRoleModal('file_share_file', file)
                }}
                isFolderFromTemplate={isFolderFromTemplate}
                handleOpenFolderPermissionModal={file => {
                  _handleChangeRoleModal('folder_permission', file)
                }}
                handleScan={file => {
                  _handleScan(file)
                }}
                handleDownload={file => {
                  if (file.category === 'folder') {
                    dispatch(fileActions.zipFiles([file._id]))
                  } else {
                    const url = `${fileUtils.getDownloadLink(file)}?userLevel=staffclient`
                    var a = document.createElement('a')
                    a.setAttribute(
                      'href',
                      `${url}?userLevel=staffclient&type=download`,
                    )
                    a.setAttribute('download', '')
                    a.setAttribute('target', '_blank')
                    a.click()
                  }
                }}
              />
            </Box>
          )
        },
      },
      {
        field: 'filename',
        headerName: 'File Name',
        sortable: true,
        editable: true,
        flex: 2,
        minWidth: isMobile ? 200 : 370,
        renderCell: params => {
          const file = params.row
          const isSubfolderFromTemplate =
            file.contentType &&
            file.category === 'folder' &&
            file.contentType.indexOf('template_subfolder') > -1
          const targetPath =
            file.category === 'folder' &&
            location.state &&
            location.state.breadcrumbs &&
            location.state.breadcrumbs[staffFilesRoute]
              ? (location.state.breadcrumbs[staffFilesRoute].path ||
                  match.url) + `/${file._id}/folder`
              : page && per
                ? `${match.url}/${file._id}?page=${page}&per=${per}`
                : `${match.url}/${file._id}`
          return (
            <div className="-file-info">
              {isFirmStaff ||
              (file.category == 'folder' && !isConfigScreenView) ? (
                <Link
                  className="-filename"
                  to={targetPath}
                  onClick={() => {
                    clearSelectedFileIds()
                    if (props.resetSearch) {
                      props.resetSearch()
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                    draggable={!isSubfolderFromTemplate}
                    onDragStart={e => {
                      if (selectedFileIds && selectedFileIds.length) {
                        e.dataTransfer.setData('move', selectedFileIds)
                      } else if (file) {
                        e.dataTransfer.setData('move', [file._id])
                      }
                    }}
                    onDragOver={e => {
                      file.category === 'folder' ? e.preventDefault() : null
                    }}
                    onDrop={e => {
                      if (file.category !== 'folder') {
                        return
                      }
                      let fileIds = e.dataTransfer.getData('move')
                      fileIds = fileIds ? fileIds.split(',').map(Number) : []
                      if (
                        fileIds &&
                        fileIds.length &&
                        fileIds.indexOf(file._id) === -1
                      ) {
                        setDropFilesFolderId(file._id)
                        setDropFilesStatusText(
                          ` file${
                            fileIds.length > 1 ? 's' : ''
                          } moving here...`,
                        )
                        const sendData = {
                          filesId: fileIds,
                          clientId: file._client,
                          _personal: file._personal,
                          _folder: file._id,
                          action: 'move',
                          firmId: match.params.firmId,
                        }

                        dispatch(
                          fileActions.sendUBulkupdateFiles(sendData),
                        ).then(json => {
                          setDropFilesFolderId(null)
                          setDropFilesStatusText(null)
                          updateList()
                        })
                      }
                    }}
                  >
                    <img
                      style={{
                        width: '24px',
                        height: '24px',
                      }}
                      src={
                        brandingName.image[file.icon] ||
                        `/img/icons/${file.icon}.png`
                      }
                    />
                    <Typography
                      style={{ marginBottom: '0px' }}
                      variant="body2"
                      noWrap
                    >
                      {file.filename}
                      {file.isNew ? (
                        <span className="-new-file-status">
                          <b> (</b>New<b>)</b>
                        </span>
                      ) : null}
                      {dropFilesFolderId === file._id && dropFilesStatusText ? (
                        <span className="-black-color">
                          {dropFilesStatusText}
                        </span>
                      ) : null}
                    </Typography>
                  </Box>
                </Link>
              ) : (
                file.filename
              )}
            </div>
          )
        },
        filterOperators: customStringOperators,
        renderEditCell: params => {
          const file = params.row
          return (
            <EditFileNameForm
              {...params}
              initialFileName={file.filename}
              onSave={newFilename => {
                let newFile = { ...file }
                // Add the fileExtension back to the filename.
                newFile.filename = newFilename + (file.fileExtension || '')
                if (newFilename.length > 0) {
                  dispatch(fileActions.sendUpdateFile(newFile)).then(action => {
                    if (!action.success) {
                      alert(`ERROR: ${action.error}`)
                    }
                    gridApiRef.current.stopCellEditMode({
                      id: file._id,
                      field: 'filename',
                      ignoreModifications: true,
                    })
                  })
                }
              }}
              onCancel={() => {
                gridApiRef.current.stopCellEditMode({
                  id: file._id,
                  field: 'filename',
                })
              }}
            />
          )
        },
      },
      {
        field: 'consumedStorage',
        headerName: 'Size',
        sortable: false,
        filterable: false,
        minWidth: isMobile ? 100 : null,
        renderCell: params => {
          return <span>{params.value}</span>
        },
      },
      {
        field: 'Virus Scan',
        headerName: 'Virus Scan',
        minWidth: isMobile ? 150 : null,
        sortable: false,
        filterable: false,
        renderCell: params => {
          const file = params.row
          return (
            <Tooltip
              title={getVirusScanTooltip(file)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -16],
                      },
                    },
                  ],
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                {getVirusScanIcon(file)}
              </Box>
            </Tooltip>
          )
        },
      },
      {
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        minWidth: isMobile ? 200 : 300,
        renderCell: params => {
          const file = params.row
          return (
            <Autocomplete
              onChange={(event, tags) => {
                let newFile = {
                  ...file,
                  _tags: tags.map(tag => tag._id),
                }
                dispatch(fileActions.sendUpdateFile(newFile))
              }}
              multiple
              limitTags={1}
              id="multiple-limit-tags"
              options={sortedTagListItems}
              getOptionLabel={option => option.name}
              value={file.tags}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Tags"
                />
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    key={option.name}
                    label={option.name}
                    size={'small'}
                    style={{
                      backgroundColor: `#${option.color}`,
                      color: '#fff',
                    }}
                    {...getTagProps({ index })}
                  />
                ))
              }
              fullWidth={true}
              size={'small'}
              disableCloseOnSelect
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          )
        },
        filterOperators: [
          {
            label: 'Has',
            value: 'equals',
            getApplyFilterFn: (filterItem, column) => {
              if (!filterItem.value || filterItem.value.length === 0)
                return null
              return (values, row, column) => {
                if (!values) return false
                let rowValues = values.map(item => item._id) //an array of ids contained by a table row
                let filterValues = filterItem.value //an array of ids the rows should be filtered by
                return filterValues.every(value => rowValues.includes(value))
              }
            },
            InputComponent: props => (
              <CustomFilter
                {...props}
                options={sortedTagListItems}
              />
            ),
          },
        ],
      },
      {
        field: 'Activity',
        headerName: 'Activity',
        sortable: false,
        filterable: false,
        minWidth: isMobile ? 100 : null,
        renderCell: params => {
          const file = params.row
          return (
            <span
              style={{ color: '#4EBAC5', textDecoration: 'none', fontSize: 13 }}
              onClick={() => _handleActivityModal(true, file)}
            >
              View
            </span>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Visibility',
        sortable: false,
        filterable: false,
        minWidth: isMobile ? 150 : null,
        renderCell: params => {
          const file = params.row
          if (file.status === 'locked') {
            return null
          }
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              {file.status === 'visible' ? (
                <i
                  onClick={() => handleVisibility(file, 'hidden')}
                  className="fas fa-eye -pointer"
                />
              ) : (
                <i
                  onClick={() => handleVisibility(file, 'visible')}
                  className="u-danger fad fa-eye-slash -pointer"
                />
              )}
            </Box>
          )
        },
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        sortable: false,
        minWidth: isMobile ? 150 : null,
        renderCell: params => {
          const file = params.row
          if (isConfigScreenView) {
            return null
          }

          return params.value
        },
        filterOperators: customStringOperators,
      },
      {
        field: 'updated_at',
        headerName: 'Last Updated',
        sortable: true,
        minWidth: isMobile ? 150 : null,
        renderCell: params => {
          const file = params.row
          return DateTime.fromISO(file.updated_at).toLocaleString(
            DateTime.DATE_SHORT,
          )
        },
        filterOperators: customDateOperators,
        // filterOperators: getGridDateOperators(),
      },
      {
        field: 'uploadOrigin',
        headerName: 'Upload Origin',
        sortable: false,
        filterable: false,
        minWidth: isMobile ? 150 : null,
        renderCell: params => {
          const file = params.row
          if (!file.uploadOrigin) {
            return null
          }
          return (
            <Tooltip
              title={getUploadOriginTooltip(file)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -16],
                      },
                    },
                  ],
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                {getUploadOriginText(file)}
              </Box>
            </Tooltip>
          )
        },
      },
    ]
    if (selectedFirm && selectedFirm.fileVersionType === 'enable') {
      columns.splice(2, 0, {
        field: 'fileVersionCount',
        headerName: 'Versions',
        minWidth: isMobile ? 150 : null,
        sortable: false,
        filterable: false,
        renderCell: params => {
          const file = params.row
          return (
            <div className="table-cell _10">
              <i
                className={`fas fa-copy ${
                  file && file.fileVersionCount ? '-active' : ''
                }`}
                onClick={() => {
                  if (file && file.fileVersionCount) {
                    _handleChangeRoleModal('file_version', file)
                  }
                }}
                aria-hidden="true"
              />
              {file && file.fileVersionCount ? file.fileVersionCount : ''}
            </div>
          )
        },
      })
    }
    if (!isConfigScreenView) {
      columns.splice(
        4,
        0,
        ...[
          {
            field: 'totalChildFolder',
            headerName: 'Folders',
            sortable: false,
            filterable: false,
            minWidth: isMobile ? 100 : null,
            renderCell: params => {
              return <span>{params.value || 0}</span>
            },
          },
          {
            field: 'totalChildFile',
            headerName: 'Files',
            sortable: false,
            filterable: false,
            minWidth: isMobile ? 100 : null,
            renderCell: params => {
              return <span>{params.value || 0}</span>
            },
          },
        ],
      )
    }
    return columns
  }, [
    match.params.firmId,
    clientStore,
    tagStore,
    isFirmStaff,
    selectedFileIds,
    dropFilesFolderId,
  ])

  if (!selectedFirm) {
    return (
      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <div className="file-list-wrapper">
      <Box
        pr={'20px'}
        sx={{
          height: 'calc(100vh - 210px)',
          width: 'calc(100% - 20px)',
          overflow: 'hidden',
          opacity: isFetching ? 0.5 : 1,
          margin: '10px 20px',
        }}
      >
        <DataGrid
          slots={{ toolbar: FileListToolbar }}
          slotProps={{
            toolbar: {
              match: match,
              firmId: match.params.firmId,
              selectedFileIds: selectedFileIds,
              onProcess: onProcess,
              searchText: quickFileSearch,
              handleSearch: e => {
                handleQuickFileNameSearch(e.target.value)
              },
              selectCopyAction: () => {
                _handleContextMenuSubmit('copy')
              },
              selectMoveAction: () => {
                _handleContextMenuSubmit('move')
              },
              selectDownloadAction: () => {
                _handleDownloadFiles()
              },
              selectArchiveAction: () => {
                _handleContextMenuSubmit('archive')
              },
              linkActionListItems: linkActionListItems(
                eSigAccess,
                selectedFileIds,
                fileMap,
              ),
              selectLinkAction: value => {
                value === 'file_share_file'
                  ? _handleContextMenuSubmit('share', showWarningModal)
                  : value === 'file_signature'
                    ? eSigAccess && selectedFileIds.length === 1
                      ? _handleChangeRoleModal(
                          value,
                          fileMap[selectedFileIds[0]],
                        )
                      : null
                    : _handleChangeRoleModal(value)
              },
              selectFolderAction: value => {
                _handleChangeRoleModal(value)
              },
              selectNewFileAction: value => {
                _handleChangeRoleModal(value)
              },
            },
          }}
          apiRef={gridApiRef}
          rows={memoizedRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          keepNonExistentRowsSelected
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          disableVirtualization
          loading={isFetching}
          //pagination -->
          rowCount={utilFileStore.totalFiles}
          paginationMode="server"
          paginationModel={{
            page: page - 1, //the DataGrid pagination is 0 index-based, but the whole logic is built on index 1
            pageSize: per,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            let actualPage = page + 1 //the DataGrid pagination is 0 index-based, but the whole logic is built on index 1
            if (pageSize !== per) {
              actualPage = 1 // if the page size is changed, go to page 1 autommatically
            }
            searchParams.set('page', actualPage)
            searchParams.set('per', pageSize)
            updateSearch()
            handleSetPagination({
              page: actualPage,
              per: pageSize,
            })
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          // <-- pagination

          //filtering -->
          // filterMode="server"
          onFilterModelChange={onFilterChange}
          //<-- filtering

          //sorting -->
          sortingMode="server"
          onSortModelChange={(sortModel)=>{
            handleSort(sortModel)
          }}
          //<-- sorting

          checkboxSelection
          disableRowSelectionOnClick
          getRowId={row => row._id}
          // disableColumnResize
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            },
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
          onRowSelectionModelChange={selectedIds => {
            //handleSelectFiles(selectedIds)
            setSelectedFileIds(selectedIds)
            setSelectedFile(null)
          }}
          rowSelectionModel={selectedFileIds}
          onCellEditStop={(params, event) => {
            //prevents the form from closing on enter/esc/tab or clicking outside the cell
            //closing is done manually onCancel or onSave
            event.defaultMuiPrevented = true
          }}
        />
      </Box>
      {!isEmpty && !isFetching && roleModal ? (
        <ModalComponent
          close={() => {
            _handleChangeRoleModal(null)
          }}
          isOpen={!!roleModal}
          match={match}
          viewingAs="workspace"
          options={options}
          clientListItem={clientListItem}
          listArgs={searchListArgs}
          type={roleModal === 'file_signature' ? 'signature' : roleModal}
          firmId={match.params.firmId}
          firm={selectedFirm}
          fileListArgsObj={{}}
          sortedAndFilteredList={paginatedList}
          allFilesFromListArgs={paginatedList}
          selectedFileIds={
            selectedFile && selectedFile._id
              ? [selectedFile._id]
              : selectedFileIds
          }
          folderListItems={folderListItems}
          file={selectedFile}
          filePointers={{
            _client: match.params.clientId,
            _firm: match.params.firmId,
          }}
          showStatusOptions={roleModal === 'file_upload'}
          selectedClient={selectedClient}
          client={selectedClient}
          clientId={match.params.clientId}
          handleSelectFile={handleSelectFile}
          // handleUploaded={this._handleUploadedFiles}
          handleUpdateSelectedFile={selectedIds => {
            setSelectedFileIds(selectedIds)
            setSelectedFile(null)
          }}
          handleSetInvalidList={() => {
            setInvalidateList(true)
          }}
          handleUpdateList={() => {
            updateList()
          }}
          getDetail={{
            type: headerTitle && headerTitle.toLocaleLowerCase(),
            id: match.params.clientId || match.params.userId,
            firmId: match.params.firmId,
          }}
        />
      ) : null}
      <Modal
        isOpen={isActivityModalOpen}
        closeAction={() => _handleActivityModal(false, null)}
        cardSize="standard"
        showButtons={false}
        modalHeader="Activity List"
      >
        <div className="-content">
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="-body">
              <div
                className="-user-info"
                style={{ margin: '1em 0' }}
              >
                <span className="-icon">
                  <img
                    src={`/img/icons/${icon}.png`}
                    style={{ width: '60px' }}
                  />
                </span>
                <div
                  className="-text"
                  style={{ lineHeight: '1.2' }}
                >
                  <Link
                    className="-filename"
                    to={`/firm/${match.params.firmId}/files/${
                      selectedFileData && selectedFileData._id
                    }`}
                  >
                    {selectedFileData && selectedFileData.filename}
                  </Link>
                  <br />
                  <small>
                    {userMap[selectedFileData && selectedFileData._user] ? (
                      <span>
                        {`by ${userMap[selectedFileData && selectedFileData._user].firstname} ${userMap[selectedFileData && selectedFileData._user].lastname}`}
                      </span>
                    ) : selectedFileData && selectedFileData.uploadName ? (
                      <span>
                        by{' '}
                        <em>
                          {selectedFileData && selectedFileData.uploadName}(not
                          logged in)
                        </em>
                      </span>
                    ) : null}
                  </small>
                  {selectedFileData && selectedFileData.uploadEmailAddress ? (
                    <>
                      <br />
                      <small>
                        <span>
                          email:{' '}
                          <em>
                            {selectedFileData &&
                              selectedFileData.uploadEmailAddress}
                          </em>
                        </span>
                      </small>
                    </>
                  ) : null}
                  {selectedFileData && selectedFileData.uploadCompanyName ? (
                    <>
                      <br />
                      <small>
                        <span>
                          company:{' '}
                          <em>
                            {selectedFileData &&
                              selectedFileData.uploadCompanyName}
                          </em>
                        </span>
                      </small>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="file-preview-activity-list">
                {Object.keys(filesActivitiesGroupedByDate).map(key => (
                  <div
                    key={key}
                    className="activity-day-group"
                  >
                    <div className="-day">
                      {DateTime.fromISO(key).toFormat('D') ===
                      DateTime.local().toFormat('D')
                        ? 'Today'
                        : DateTime.fromISO(key).toFormat('D')}
                    </div>
                    {filesActivitiesGroupedByDate[key].map((activity, i) => (
                      <FileActivityListItem
                        key={`${activity._id}_${i}`}
                        activity={activity}
                        loggedInUser={loggedInUser}
                        user={userMap[activity._user] || {}}
                        client={clientStore.byId[activity._client] || {}}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <AlertModal
        alertTitle="More than ten files have been selected"
        alertMessage={`While ${brandingName.title} allows you to download unlimited files simultaneously, certain browsers may limit you to downloading 10 separate files at one time. If you experience this, please select a maximum of 10 files per download attempt.`}
        closeAction={() => {
          setDownloadWarningModalOpen(false)
        }}
        confirmAction={_handleDownloadFiles}
        confirmText="Try downloading anyway"
        isOpen={downloadWarningModalOpen}
        type="warning"
      />
      <AlertModal
        isOpen={progressOpen && progressText ? true : false} // app.js.jsx?93ea:56 Warning: Failed prop type: Invalid prop `isOpen` of type `string` supplied to `AlertModal`,
        type="warning"
        confirmText={`Try ${progressPresentText} anyway`}
        alertTitle={showWarningModal ? showWarningModal : 'Warning Modal'}
        alertMessage={
          showWarningModal === 'A folder has been selected '
            ? `All files associated with this folder will also be ${progressText}d.`
            : `While ${brandingName.title} allows you to ${progressText} unlimited files simultaneously, certain browsers may limit you to ${progressPresentText} 10 separate files at one time. If you experience this, please select a maximum of 10 files per ${progressText} attempt.`
        }
        closeAction={() => {
          setProgressOpen(false)
          setProgressText('')
        }}
        confirmAction={
          progressText === 'move'
            ? _handleOpenMoveModal
            : progressText === 'archive'
              ? _handleSetMultipleStatus
              : progressText === 'share'
                ? _handleOpenShareModal
                : null
        }
      />
    </div>
  )
}

FileList.propTypes = {
  allFilesSelected: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  utilFileStore: PropTypes.object.isRequired,
  allTags: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleOpenRequestModal: PropTypes.func,
  handleOpenShareModal: PropTypes.func,
  handleOpenUploadModal: PropTypes.func,
  handleQuery: PropTypes.func.isRequired,
  handleSelectFile: PropTypes.func.isRequired,
  handleSetPagination: PropTypes.func.isRequired,
  handleToggleSelectAll: PropTypes.func,
  handleSort: PropTypes.func.isRequired,
  selectedFileIds: PropTypes.array,
  selectedTagIds: PropTypes.array,
  showActions: PropTypes.bool,
  sortedAndFilteredList: PropTypes.array,
  viewingAs: PropTypes.oneOf([
    'workspace',
    'general',
    'admin',
    'client',
    'staff',
    'personal',
    'public',
  ]),
}

FileList.defaultProps = {
  allFilesSelected: false,
  handleOpenRequestModal: null,
  handleOpenShareModal: null,
  handleOpenUploadModal: null,
  handleToggleSelectAll: null,
  selectedFileIds: [],
  selectedTagIds: [],
  showActions: true,
  sortedAndFilteredList: [],
  viewingAs: 'workspace',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  /**
   * REGARDING PAGINATION: Pagination would normally be handled on the parent component WorkspaceFiles.
   * The listArgs in WorkspaceFiles.state are not accessible from that component's mapStoreToProps
   * function. We have to paginate the list here instead since it is passed to this component as a prop
   * with no need to be aware of the listArgs.
   */
  let { utilFileStore, sortedAndFilteredList, listArgsObj } = props
  let paginatedList = []
  // let orderedList = [];
  const loggedInUser = store.user.loggedIn.user
  const userMap = store.user.byId

  const sortBy =
    listArgsObj && listArgsObj.ordervalue === 'asc'
      ? listArgsObj.ordername
      : `-${listArgsObj.ordername}`
  const staffListItem = store.staff.util.getList(
    '_firm',
    props.match.params.firmId,
  )
  const isFirmStaff =
    staffListItem && loggedInUser
      ? staffListItem.some(
          x => x._user == loggedInUser._id && x.status == 'active',
        )
      : false

  let { fIds } = queryString.parse(props.location.search)
  fIds = fIds ? fIds.split(',') : []
  paginatedList = sortedAndFilteredList

  //paginated list needs the icon element, otherwise it's calculated in DataGrid on scroll
  const { selectedFirm, objFileActivityListItems } = props
  paginatedList = paginatedList.map(file => {
    const isNew = !(
      (selectedFirm && !selectedFirm.showNewLabel) ||
      (file.category === 'folder' && file.wasAccessed) ||
      ((file.category !== 'folder' || file.category === null) &&
        objFileActivityListItems &&
        objFileActivityListItems[file._id]) ||
      ((file.category === 'folder' ||
        file.category !== 'folder' ||
        file.category === null) &&
        file.fileIsSelected)
    )
    let consumedStorage = 0
    let fileSize = 0
    if (file) {
      if (file.category === 'folder' && fileSize) {
        const intFileSize = fileSize
        consumedStorage = displayUtils.convertBytesToReadable(intFileSize)
      } else if (file.fileSize) {
        const intFileSize = parseInt(file.fileSize)
        consumedStorage = displayUtils.convertBytesToReadable(intFileSize)
      }
    }
    const fileTags = file._tags
      ? file._tags
          .map(tagId => store.tag.byId[tagId] || null)
          .filter(tag => !!tag)
      : []

    const createdBy =
      file._user && store.user.byId[file._user]
        ? `${store.user.byId[file._user].firstname} ${
            store.user.byId[file._user].lastname
          }`
        : file.uploadName
          ? `${file.uploadName} (not logged in)`
          : null

    return {
      ...file,
      icon: displayUtils.getFileIcon(file.category, file.contentType, file),
      isNew: isNew,
      consumedStorage: consumedStorage,
      tags: fileTags,
      createdBy: createdBy,
    }
  })

  return {
    utilFileStore,
    loggedInUser,
    clientStore: store.client,
    paginatedList: paginatedList,
    fileStore: store.file,
    tagStore: store.tag,
    staffClientStore: store.staffClient,
    staffStore: store.staff,
    clientUserStore: store.clientUser,
    userMap: userMap,
    firmStore: store.firm,
    staffListItem,
    isFirmStaff,
    sortBy,
    fIds,
    newSortedAndFilteredList: sortedAndFilteredList,
    fileMap: store.file.byId,
    fileActivityStore: store.fileActivity,
    userStore: store.user,
  }
}

export default withRouter(connect(mapStoreToProps)(FileList))
