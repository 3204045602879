/**
 * Boilerplate code for a new Redux-connected view component.
 * Nice for copy/pasting
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import ProfilePic from '../../../../global/components/navigation/ProfilePic.js.jsx'

class AccountUsers extends Binder {
  constructor(props) {
    super(props)
    this.state = {}
    this._bind()
  }

  render() {
    const {
      match,
      clientId,
      clientStore,
      clientUserStore,
      firmStore,
      loggedInUser,
      staffClientStore,
      userStore,
    } = this.props

    const selectedFirm = firmStore.selected.getItem()
    const selectedClient = clientStore.selected.getItem()

    // clientUsers(contacts) list
    const clientUserList =
      clientUserStore.lists && clientUserStore.lists._client
        ? clientUserStore.lists._client[clientId]
        : null
    const clientUserListItems = clientUserStore.util.getList(
      '_client',
      clientId
    )

    // staffClient  list
    const staffClientList =
      staffClientStore.lists && staffClientStore.lists._client
        ? staffClientStore.lists._client[clientId]
        : null
    const staffClientListItems = staffClientStore.util.getList(
      '_client',
      clientId
    )

    return (
      <div className="-portal-content">
        <h2>Users</h2>
        <h3>Users in {selectedClient.name}</h3>
        <div className="-contact-list">
          {clientUserListItems ? (
            clientUserListItems.map((cu, i) =>
              userStore.byId[cu._user] ? (
                <div
                  className="-contact-card"
                  key={cu._id + '_cu_' + i}
                >
                  <div className="yt-row">
                    <ProfilePic user={userStore.byId[cu._user]} />
                    <div>
                      <div className="-name">
                        {userStore.byId[cu._user].firstname}{' '}
                        {userStore.byId[cu._user].lastname}{' '}
                        {cu._user == loggedInUser._id ? '(You)' : null}
                      </div>
                      <small>{userStore.byId[cu._user].username}</small>
                    </div>
                  </div>
                </div>
              ) : (
                <i
                  className="fal fa-spinner fa-spin"
                  key={cu._id + '_cu_' + i}
                />
              )
            )
          ) : (
            <p>
              <em>No contacts yet</em>
            </p>
          )}
          <div className="-portal-content">
            <h3>
              {selectedFirm.name} users in {selectedClient.name}
            </h3>
            <div className="-contact-list">
              {staffClientListItems ? (
                staffClientListItems.map((sc, i) =>
                  sc.status === 'hidden' ? null : userStore.byId[sc._user] ? (
                    <div
                      className="-contact-card"
                      key={sc._id + '_sc_' + i}
                    >
                      <div className="yt-row">
                        <ProfilePic user={userStore.byId[sc._user]} />
                        <div>
                          <div className="-name">
                            {userStore.byId[sc._user].firstname}{' '}
                            {userStore.byId[sc._user].lastname}{' '}
                            {sc._user == loggedInUser._id ? '(You)' : null}
                          </div>
                          <small>{userStore.byId[sc._user].username}</small>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <i
                      className="fal fa-spinner fa-spin"
                      key={sc._id + '_sc_' + i}
                    />
                  )
                )
              ) : (
                <p>
                  <em>No contacts yet</em>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AccountUsers.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

AccountUsers.defaultProps = {}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    clientUserStore: store.clientUser,
    clientStore: store.client,
    firmStore: store.firm,
    staffClientStore: store.staffClient,
    userStore: store.user,
    loggedInUser: store.user.loggedIn.user,
  }
}

export default withRouter(connect(mapStoreToProps)(AccountUsers))
