// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

// import third-party libraries
import moment from 'moment'
import { DateTime } from 'luxon'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import brandingName from '../../../../global/enum/brandingName.js.jsx'
import displayUtils from '../../../../global/utils/displayUtils'

import TagPill from '../../../tag/components/TagPill.js.jsx'

class PortalFileGridListItem extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      hasViewedLog: true,
    }
    this
      ._bind
      // '_goToFile'
      ()
  }

  componentDidMount() {
    const { file, loggedInUser, fileActivityListItems } = this.props
    if (file && loggedInUser && fileActivityListItems) {
      this.setState({
        hasViewedLog:
          fileActivityListItems && loggedInUser
            ? fileActivityListItems.some(
                item =>
                  item &&
                  item._file === file._id &&
                  loggedInUser._id === item._user &&
                  item.text &&
                  item.text.includes('Viewed')
              )
            : true,
      })
    }
  }

  render() {
    const { file, match, tagStore, checked, handleSelectFile, disabled } =
      this.props
    const { hasViewedLog } = this.state
    // let foundComment = _.find(commentMap, { '_file': file._id });

    const fileTags = file._tags.map(tagId => tagStore.byId[tagId] || '')

    let icon = displayUtils.getFileIcon(file.category, file.contentType, file)
    // let icon = 'icon' // for testing.

    return (
      <div className="yt-col full s_50 m_33 l_25 xl_25 file-list-item">
        <div className="card -hoverable -grid">
          <div className="card-header -file-card">
            <CheckboxInput
              disabled={disabled && !checked}
              name="file"
              value={checked}
              change={() => handleSelectFile(file._id)}
              checked={checked}
            />
          </div>
          {file.status === 'locked' ? (
            <Link to={`${match.url}/${file._id}`}>
              <div className="-icon">
                <img src={`/img/icons/locked_file.png`} />
              </div>
            </Link>
          ) : (
            <Link to={`${match.url}/${file._id}`}>
              <div className="-icon">
                <img
                  src={brandingName.image[icon] || `/img/icons/${icon}.png`}
                />
              </div>
            </Link>
          )}
          <div className="card-body">
            <div className="-info">
              <div className="-title">
                <Link to={`${match.url}/${file._id}`}>
                  {file.filename}
                  {file.category === 'folder' || hasViewedLog ? null : (
                    <span className="-new-file-status">
                      <b>(</b>New<b>)</b>
                    </span>
                  )}
                </Link>
                {/** NOTE: We should do this in {brandingName.title.toLowerCase()} too
                <Link to={`${match.url}/${file._id}`}>
                  <UserClickEvent
                    description="Open Preview File"
                    eventAction="click"
                    eventType="file"
                    refKey="_file"
                    refId={file._id}
                  >
                    {file.filename}
                  </UserClickEvent>
                </Link>
                */}
              </div>
              <div className="-date">
                {DateTime.fromISO(file.updated_at).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="-tags">
          {fileTags.map((tag, i) =>
            tag.name ? (
              <TagPill
                key={tag._id}
                tag={tag}
              />
            ) : null
          )}
        </div>
      </div>
    )
  }
}

PortalFileGridListItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  file: PropTypes.object.isRequired,
}

PortalFileGridListItem.defaultProps = {
  isSelected: false,
}

const mapStoreToProps = store => {
  return {
    loggedInUser: store.user.loggedIn.user,
    tagStore: store.tag,
  }
}

export default withRouter(connect(mapStoreToProps)(PortalFileGridListItem))
