import React, { useEffect, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import { Helmet } from 'react-helmet'
import { formatPhoneNumber } from 'react-phone-number-input'

import _cloneDeep from 'lodash/cloneDeep'

import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import RoleModalComponent from '../../../../global/enum/RoleModalComponent.js.jsx'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'
import WorkspaceListToolbar from '../components/WorkspaceListToolbar.js'

import PracticeLayout from '../../../../global/practice/components/PracticeLayout.js.jsx'

import routeUtils from '../../../../global/utils/routeUtils'
import permissions from '../../../../global/utils/permissions'

import NewClientOptionsMenu from '../../components/NewClientOptionsMenu.js.jsx'

import * as clientActions from '../../clientActions'
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'
import * as staffClientActions from '../../../staffClient/staffClientActions'
import GainsightCmp from './GainsightCmp.js.jsx'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import {
  DataGrid,
  getGridStringOperators,
  useGridApiRef,
} from '@mui/x-data-grid'
import SettingsDropdown from '../components/SettingsDropdown.js'
import { FeedbackMessage } from '../../../../global/components/helpers/FeedbackMessage.js'
import { debounce } from 'lodash'

const COLUMNS_KEY = 'AllWorkspaceList_DisplayColumns'

const WorkspaceList = props => {
  const {
    match,
    location,
    firmStore,
    clientStore,
    staffStore,
    userStore,
    utilClientStore,
    clientListItems,
    loggedInUser,
    staffMap,
    newClientListItems,
    dispatch,
  } = props
  const params = { firmId: match.params.firmId }
  const isArchivedView = useMemo(
    () => Boolean(match.url && match.url.indexOf('archived') > -1),
    [match.url],
  )
  const customStringOperators = getGridStringOperators().filter(
    operator => operator.value !== 'isAnyOf',
  )
  const gridApiRef = useGridApiRef()
  const firmId = params.firmId
  const [listArgsObj, setListArgsObj] = useState({
    _firm: match.params.firmId,
    status: isArchivedView ? 'archived' : 'visible',
  })
  const [clientOptionsOpen, setClientOptionsOpen] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState([])
  const paginationModel = { page: 1, pageSize: 50 }
  const feedbackMessage = useRef(null)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [archiveProcess, setArchiveProcess] = useState(false)
  const [roleModal, setRoleModal] = useState()
  const [unassignStaffModalOpen, setUnassignStaffModalOpen] = useState(false)
  const [selectedStaffId, setSelectedStaffId] = useState([])
  const [isFavorite, setIsFavorite] = useState(false)
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, client: '' })
  const [searchText, setSearchText] = useState('')
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedModel = localStorage.getItem(COLUMNS_KEY)
    return savedModel ? JSON.parse(savedModel) : {}
  })

  useEffect(() => {
    dispatch(firmActions.fetchListIfNeeded('_user', loggedInUser._id))
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firm', match.params.firmId)) // fetches contacts
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId)) // fetches staff
    dispatch(clientActions.fetchListIfNeeded('_user', loggedInUser._id)) // This should live on every top-level route of the portal

    _handleFetchList()
  }, [])

  useEffect(() => {
    setListArgsObj({
      _firm: match.params.firmId,
      status: isArchivedView ? 'archived' : 'visible',
    })
    setClientOptionsOpen(false)
    setSelectedClientId([])
    setArchiveProcess(false)
    setRoleModal()
    setUnassignStaffModalOpen(false)
    setSelectedStaffId([])
    setShowDeleteAlertModal(false)
  }, [match.url, match.params.firmId])

  useEffect(() => {
    _handleFetchList()
  }, [listArgsObj])

  const _setStatus = (status, client) => {
    const listArgs = routeUtils.listArgsFromObject(listArgsObj)
    const clientMap = clientStore && clientStore.byId
    const newClient = clientMap && clientMap[client._id]

    if (status === 'visible') {
      // can reinstate archived client list if have a same client name in visible status
      let clients = clientMap ? Object.keys(clientMap) : []
      clients = clients.filter(clientId => {
        if (clientMap[clientId]) {
          if (clientMap[clientId].status === 'visible') {
            let client = clientMap[clientId].name
              ? clientMap[clientId].name.trim().toLowerCase()
              : null
            let compareClient = newClient.name
              ? newClient.name.trim().toLowerCase()
              : null
            if (client && compareClient && client === compareClient) {
              return clientId
            }
          }
        }
      })

      if (clients && clients.length) {
        setShowAlertModal(true)
      } else {
        newClient.status = status
        dispatch(clientActions.sendUpdateClientStatus(newClient)).then(json => {
          if (json.success && json.id) {
            dispatch(clientActions.removeClientFromList(json.id, ...listArgs))
            dispatch(
              clientActions.returnClientListPromise(
                ...routeUtils.listArgsFromObject({
                  _firm: match.params.firmId,
                  status: status,
                }),
              ),
            ).then(result => {
              if (result.success && result.list) {
                dispatch(
                  clientActions.addClientToList(
                    json.item,
                    ...routeUtils.listArgsFromObject({
                      _firm: match.params.firmId,
                      status: status,
                    }),
                  ),
                )
              }
            })
          }
        })
      }
    } else {
      newClient.status = status
      dispatch(clientActions.sendUpdateClientStatus(newClient)).then(json => {
        if (json.success && json.id) {
          dispatch(clientActions.removeClientFromList(json.id, ...listArgs))
          dispatch(
            clientActions.returnClientListPromise(
              ...routeUtils.listArgsFromObject({
                _firm: match.params.firmId,
                status: status,
              }),
            ),
          ).then(result => {
            if (result.success && result.list) {
              dispatch(
                clientActions.addClientToList(
                  json.item,
                  ...routeUtils.listArgsFromObject({
                    _firm: match.params.firmId,
                    status: status,
                  }),
                ),
              )
            }
          })
        }
      })
    }
  }

  const _handleFavoriteToggle = (isFavorite, clientId) => {
    const data = {
      ...clientStore.byId[clientId],
      _id: clientId,
      isFavorite,
    }

    dispatch(clientActions.updateFavoriteClient(data)).then(json => {
      if (!json.success) {
        console.error('Could not toggle workspace favorite!')
        feedbackMessage.current.showError(
          'Could not change workspace favorite status!',
        )
      } else {
        feedbackMessage.current.showSuccess(
          isFavorite
            ? 'Workspace marked as favorite!'
            : 'Workspace removed from favorites!',
        )
      }
    })
  }

  const _handleFetchList = () => {
    let listArgs = routeUtils.listArgsFromObject(listArgsObj)

    dispatch(clientActions.fetchListIfNeeded(...listArgs)).then(json => {
      dispatch(
        clientActions.setFilter({ query: '', sortBy: 'name' }, ...listArgs),
      )
    })
  }

  const _handleNewStaffClient = action => {
    if (action) {
      const pagination = utilClientStore.pagination || { page: 1, per: 50 }
      const objArgs = routeUtils.listArgsFromObject({
        _argsByPages: `page${pagination.page}-per${pagination.per}`,
      })
      dispatch(
        staffClientActions.fetchListByClientIds(objArgs, selectedClientId),
      )
      setSelectedClientId([])
      setRoleModal(null)
    } else {
      setSelectedClientId([])
      setRoleModal(null)
    }
  }

  const handleSelect = clientIds => {
    setSelectedClientId(clientIds)

    const staffIds = clientIds.flatMap(clientId => {
      return (clientStore.byId[clientId]?.staffclients || []).map(
        staff => staff?._id,
      )
    })

    setSelectedStaffId(staffIds)
  }

  const _handleSetStatus = status => {
    const sendData = { type: status, clientIds: selectedClientId }

    dispatch(clientActions.sendBulkUpdateClient(sendData)).then(json => {
      setSelectedClientId([])
      if (json.success && json.list) {
        let listArgs = _cloneDeep(listArgsObj)

        listArgs.status = status
        setShowDeleteAlertModal(false)
        if (status === 'deleted') {
          json.list.forEach(client => {
            dispatch(
              clientActions.removeClientFromList(
                client._id,
                ...routeUtils.listArgsFromObject(listArgsObj),
              ),
            )
          })
        } else {
          dispatch(
            clientActions.returnClientListPromise(
              ...routeUtils.listArgsFromObject(listArgs),
            ),
          ).then(result => {
            json.list.forEach(client => {
              dispatch(
                clientActions.removeClientFromList(
                  client._id,
                  ...routeUtils.listArgsFromObject(listArgsObj),
                ),
              )
              if (result.success && result.list) {
                dispatch(
                  clientActions.addClientToList(
                    client,
                    ...routeUtils.listArgsFromObject(listArgs),
                  ),
                )
              }
            })
          })
        }
      }
    })
  }

  const _handleUnassignStaff = () => {
    setUnassignStaffModalOpen(false)
    dispatch(
      staffClientActions.sendBulkDelete(selectedStaffId, match.params.firmId),
    ).then(json => {
      if (json.success) {
        selectedClientId.map(item => {
          if (clientStore && clientStore.byId && clientStore.byId[item]) {
            clientStore.byId[item].staffclients = []
          }
        })
        setSelectedStaffId([])
        setSelectedClientId([])
        _handleFetchList()
      }
    })
  }

  const _toggleAlertModal = () => {
    setShowDeleteAlertModal(!showDeleteAlertModal)
  }

  const ModalComponent = roleModal ? RoleModalComponent[roleModal] : undefined

  const hasExportAccess = permissions.hasExportAccess(
    staffStore,
    match.params.firmId,
  )

  const ownerPermissions = permissions.isStaffOwner(
    staffStore,
    loggedInUser,
    match.params.firmId,
  )
  const advancedPermissions = permissions.isStaffAdvanced(
    staffStore,
    loggedInUser,
    match.params.firmId,
  )

  const selectedFirm = firmStore.selected.getItem()
  const listArgs = routeUtils.listArgsFromObject(listArgsObj)
  const staffListItems = staffStore.util.getList('_firm', match.params.firmId)
  const isEmpty =
    clientStore.selected.didInvalidate ||
    !clientListItems ||
    utilClientStore.didInvalidate ||
    utilClientStore.isFetching ||
    !selectedFirm ||
    !staffListItems

  const isFetching =
    clientStore.selected.isFetching ||
    !clientListItems ||
    utilClientStore.isFetching ||
    !selectedFirm ||
    !staffListItems

  const availableStaff =
    isEmpty || isFetching || !staffListItems
      ? []
      : staffListItems.flatMap(staff => {
          let item = staff
          let fullName = userStore.byId[staff._user]
            ? `${userStore.byId[staff._user].firstname} ${
                userStore.byId[staff._user].lastname
              }`
            : ''
          let userName = userStore.byId[staff._user]
            ? userStore.byId[staff._user].username
            : ''
          item.displayName = `${fullName} | ${userName}`
          item.fullName = fullName
          item.userName = userName

          return staff && staff.status === 'active' ? item : []
        })

  const handleColumnVisibilityChange = newModel => {
    setColumnVisibilityModel(newModel)
    localStorage.setItem(COLUMNS_KEY, JSON.stringify(newModel))
  }

  const handleSearch = debounce(value => {
    setSearchText(value)
  }, 500)

  const handleFilterModelChange = newModel => {
    if (newModel.items.length > 0) {
      setSearchText('')
    }
    setFilterModel(newModel)
  }

  const columns = useMemo(() => {
    return [
      {
        field: 'Settings',
        headerName: '',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        disableExport: true,
        checkboxSelection: false,
        renderCell: params => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SettingsDropdown
              setDeleteModal={setDeleteModal}
              setStatus={_setStatus}
              handleFavoriteToggle={_handleFavoriteToggle}
              archived={isArchivedView}
              client={clientStore.byId[params.row._id]}
            />
          </Box>
        ),
        filterOperators: customStringOperators,
      },
      {
        field: 'identifier',
        headerName: 'Identifier',
        flex: 2,
        renderCell: params => <span>{params.value}</span>,
        filterOperators: customStringOperators,
      },
      {
        field: 'name',
        headerName: 'Workspace Name',
        flex: 3,
        renderCell: params => (
          <Link
            to={`/firm/${firmId}/workspaces/${params.row._id}/files`}
            onClick={e => e.stopPropagation()}
          >
            {params.value}
          </Link>
        ),
        filterOperators: customStringOperators,
      },
      {
        field: 'engagementTypes',
        headerName: 'Engagement Types',
        flex: 2,
        sortable: false,
        renderCell: params => <span>{params.value}</span>,
        filterOperators: customStringOperators,
      },
      {
        field: 'staffClientsCount',
        headerName: 'Assigned Staff',
        flex: 2,
        renderCell: params =>
          params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          ),
        filterOperators: customStringOperators,
      },
      {
        field: 'contactFullName',
        headerName: 'Primary Contact',
        flex: 3,
        renderCell: params =>
          params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          ),
        filterOperators: customStringOperators,
      },
      {
        field: 'contactEmail',
        headerName: 'Email',
        flex: 3,
        sortable: false,
        renderCell: params =>
          params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          ),
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        flex: 2,
        sortable: false,
        filterOperators: customStringOperators,
        renderCell: params =>
          params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          ),
      },
      {
        field: 'address',
        headerName: 'Address',
        flex: 2,
        sortable: false,
        filterOperators: customStringOperators,
        renderCell: params => {
          return params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          )
        },
      },
    ]
  }, [firmId, clientStore])

  const Toolbar = () => {
    return (
      <WorkspaceListToolbar
        selectedClientId={selectedClientId}
        _handleSetStatus={_handleSetStatus}
        setShowDeleteAlertModal={setShowDeleteAlertModal}
        isArchivedView={isArchivedView}
        ownerPermissions={ownerPermissions}
        setRoleModal={setRoleModal}
        setUnassignStaffModalOpen={setUnassignStaffModalOpen}
        advancedPermissions={advancedPermissions}
        firmId={firmId}
        hideExport={!hasExportAccess}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
        searchText={searchText}
        handleSearch={event => handleSearch(event.target.value)}
      />
    )
  }

  const filteredRows = useMemo(() => {
    const listItems = isFavorite
      ? newClientListItems.filter(client => client.isFavorite === true)
      : newClientListItems

    return listItems.filter(row => {
      const text = searchText.toLowerCase()
      const { name, identifier, engagementTypes } = row

      return (
        name?.toLowerCase().includes(text) ||
        identifier?.toLowerCase().includes(text) ||
        engagementTypes?.toLowerCase().includes(text)
      )
    })
  }, [newClientListItems, isFavorite, searchText])

  return (
    <PracticeLayout isSidebarOpen={true}>
      <Helmet>
        <title>Clients Workspaces</title>
      </Helmet>
      <FeedbackMessage ref={feedbackMessage} />
      <AlertModal
        alertMessage={
          <div>
            <h4>Are you sure?</h4>
            {`Do you want to unassign ${
              selectedStaffId.length > 1 ? 'these staffs' : 'this staff'
            } from client?`}
          </div>
        }
        alertTitle="Unassign staff"
        closeAction={() => setUnassignStaffModalOpen(false)}
        confirmAction={() => _handleUnassignStaff()}
        confirmText="Yes"
        declineText="Never mind"
        isOpen={unassignStaffModalOpen}
        type="warning"
      />
      <div className="-practice-subnav">
        <div className="yt-container fluid">
          <div className="yt-row center-vert space-between">
            <Breadcrumbs links={location.state.breadcrumbs} />
            {!ownerPermissions || isArchivedView ? null : (
              <button
                className="yt-btn x-small -x-small_26"
                onClick={() => setClientOptionsOpen(true)}
              >
                New Workspace
                <i
                  style={{ marginLeft: '.5em' }}
                  className="fas fa-caret-down"
                />
              </button>
            )}
          </div>
          <CloseWrapper
            isOpen={clientOptionsOpen || archiveProcess}
            closeAction={() =>
              archiveProcess ? null : setClientOptionsOpen(false)
            }
          />
          <div className="dropdown -client-list-add-options">
            <NewClientOptionsMenu
              firmId={parseInt(match.params.firmId)}
              isOpen={clientOptionsOpen}
            />
          </div>
        </div>
      </div>
      <div className="yt-container fluid">
        <h1 className="-tab-name">
          {' '}
          {isArchivedView ? 'Archived Workspaces' : 'Workspaces'}
        </h1>
      </div>
      <div className="-practice-content">
        {isEmpty ? (
          isFetching ? (
            <div className="-loading-hero">
              <div className="u-centerText">
                <div className="loading"></div>
              </div>
            </div>
          ) : (
            <h2>No client found.</h2>
          )
        ) : (
          <div
            className="yt-container fluid"
            style={{ opacity: isFetching ? 0.5 : 1 }}
          >
            <Box
              pr="20px"
              sx={{
                height: 'calc(100vh - 280px)',
                width: 'calc(100% - 20px)',
                overflow: 'hidden',
                opacity: isFetching ? 0.5 : 1,
                margin: '10px 20px',
              }}
            >
              <DataGrid
                apiRef={gridApiRef}
                rows={filteredRows}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                pageSize={paginationModel.pageSize}
                checkboxSelection
                disableSelectionOnClick
                getRowId={row => row._id}
                disableRowSelectionOnClick
                disableColumnResize
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'white',
                  },
                  overflowX: 'auto',
                  overflowY: 'hidden',
                }}
                slots={{ toolbar: Toolbar }}
                onRowSelectionModelChange={newRowSelectionModel => {
                  handleSelect(newRowSelectionModel)
                }}
                rowSelectionModel={selectedClientId}
              />
            </Box>
            {ModalComponent ? (
              <ModalComponent
                close={() => {
                  setRoleModal(null)
                  setSelectedClientId([])
                }}
                handleClose={() => {
                  setRoleModal(null)
                  setSelectedClientId([])
                }}
                isOpen={Boolean(roleModal)}
                selectedClientId={selectedClientId}
                handleNewStaffClient={_handleNewStaffClient}
                multipleAdd={true}
                firmId={match.params.firmId}
                staffListItems={availableStaff}
                staffMap={staffMap}
                match={match}
                viewingAs="client-setting"
                clientListArgs={listArgs}
              />
            ) : null}
            <AlertModal
              alertMessage={'Are you sure? This cannot be undone.'}
              alertTitle={`Delete this client${
                selectedClientId.length > 1 ? 's' : ''
              }`}
              closeAction={_toggleAlertModal}
              confirmAction={() => {
                _handleSetStatus('deleted')
              }}
              confirmText={'Delete'}
              declineAction={_toggleAlertModal}
              declineText={'Cancel'}
              isOpen={showDeleteAlertModal}
              type={'danger'}
            />
            <AlertModal
              alertMessage={'Are you sure? This cannot be undone.'}
              alertTitle={'Delete this client?'}
              closeAction={() => {
                setDeleteModal({ isOpen: false, client: '' })
              }}
              confirmAction={() => {
                _setStatus('deleted', deleteModal.client)
                setDeleteModal({ isOpen: false, client: '' })
              }}
              confirmText={'Delete'}
              declineAction={() => {
                setDeleteModal({ isOpen: false, client: '' })
              }}
              declineText={'Cancel'}
              isOpen={deleteModal.isOpen}
              type={'danger'}
            />
            <AlertModal
              alertMessage={
                'This client cannot be reinstated because an active client with the same name already exists.'
              }
              alertTitle={'Warning: Duplicate name'}
              closeAction={() => {
                setShowAlertModal(false)
              }}
              confirmAction={() => setShowAlertModal(false)}
              confirmText={'Okay'}
              isOpen={showAlertModal}
              type={'danger'}
            />
          </div>
        )}
        <GainsightCmp />
      </div>
    </PracticeLayout>
  )
}

WorkspaceList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = (store, props) => {
  const clientStore = store.client

  let listArgsObj

  listArgsObj = routeUtils.listArgsFromObject({
    _firm: props.match.params.firmId,
    status:
      props.match.url && props.match.url.indexOf('archived') > -1
        ? 'archived'
        : 'visible',
  })

  const utilClientStore = clientStore.util.getSelectedStore(...listArgsObj)
  let clientListItems = clientStore.util.getList(...listArgsObj)

  if (clientListItems) {
    clientListItems.sort((a, b) => a.name.localeCompare(b.name))
  }

  const newClientListItems = []

  if (clientListItems) {
    clientListItems.map(item => {
      const data = _cloneDeep(item)
      data.identifier = data.identifier || ''
      data.engagementTypes =
        (data.engagementTypes && data.engagementTypes.join(', ')) || null
      data.contactFullName = ''
      data.phoneNumber =
        data.phonenumber &&
        data.phonenumber.number &&
        formatPhoneNumber(data.phonenumber.number, 'National')
          ? formatPhoneNumber(data.phonenumber.number, 'National')
          : null
      if (data.phonenumber && data.phonenumber.extNumber) {
        data.phoneNumber += ' ' + data.phonenumber.extNumber
      }
      data.address = ''
      data.staff = ''
      data.contactEmail =
        (store.user.byId[data._primaryContact] &&
          store.user.byId[data._primaryContact].username) ||
        ''
      if (store.user.byId[data._primaryContact]) {
        const contact = store.user.byId[data._primaryContact]
        data.contactFullName = contact.firstname || ''
        data.contactFullName +=
          (data.contactFullName ? ' ' : '') + contact.lastname || ''
      }
      if (data.objaddress) {
        const address = data.objaddress
        data.address = address.street1 || ''
        data.address += (data.address ? ' ' : '') + address.city || ''
        data.address +=
          (data.address ? ' ' : '') + ` ${address.state ? address.state : ''}`
        data.address += (data.address ? ' ' : '') + address.country || ''
      }
      data.staffClientsCount =
        data.staffclients && data.staffclients[0] === null
          ? 0
          : data.staffclients && data.staffclients.length

      newClientListItems.push(data)
    })
  }

  return {
    clientStore: store.client,
    firmStore: store.firm,
    loggedInUser: store.user.loggedIn.user,
    staffClientStore: store.staffClient,
    staffStore: store.staff,
    userStore: store.user,
    utilClientStore,
    clientListItems,
    newClientListItems,
    staffMap: store.staff.byId,
  }
}

export default withRouter(connect(mapStoreToProps)(WorkspaceList))
