// import primary libraries
import React from 'react'
import Route from 'react-router-dom/Route'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import YTRoute from '../../../global/components/routing/YTRoute.js.jsx'

// import QuickTask views
import AdminQuickTaskList from './views/AdminQuickTaskList.js.jsx'

class QuickTaskAdminRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    let singleQuickTaskPath = this.props.location.pathname.replace(
      '/update',
      ''
    )
    return (
      <Switch>
        <YTRoute
          breadcrumbs={[
            { display: 'Dashboard', path: '/admin' },
            { display: 'All QuickTasks', path: null },
          ]}
          component={AdminQuickTaskList}
          exact
          path="/admin/quick-tasks"
          role="admin"
        />
      </Switch>
    )
  }
}

export default QuickTaskAdminRouter
