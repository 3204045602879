/* global process */

/**
 * configureStore builds the Redux store for the application
 */

// import primary libraries
import { configureStore as configure, combineReducers } from '@reduxjs/toolkit'

// import main application reducers
import * as resourceReducers from './resourceReducers.js'
import { baseApi } from './api/base'
import { setupListeners } from '@reduxjs/toolkit/query'

// combine application reducers with redux routing
const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer, // Add the RTK Query reducer
  ...resourceReducers,
})

export default function configureStore() {
  const middlewares = [baseApi.middleware]

  // if (process.env.NODE_ENV !== 'production') {
  //   // Don't log redux changes in production
  //   const loggerMiddleware = createLogger()
  //   middlewares.push(loggerMiddleware)
  // }

  const store = configure({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
  })

  // Call setupListeners if you need automated query refetching or polling
  // setupListeners(store.dispatch)

  return store
}
