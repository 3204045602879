/**
 * view component for /firm/:firmId/workspaces
 */

// import primary libraries
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import { Helmet } from 'react-helmet'
import { formatPhoneNumber } from 'react-phone-number-input'

import _uniq from 'lodash/uniq'
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import _slice from 'lodash/slice'

// import global components
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import FilterList from '../../../../global/components/helpers/FilterList.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'
import RoleModalComponent from '../../../../global/enum/RoleModalComponent.js.jsx'
import DropdownButton from '../../../../global/components/helpers/DropdownButton.js.jsx'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'
import SelectOrderedSubList from '../../../../global/components/helpers/SelectOrderedSubList.js.jsx'
import links from '../../../../global/components/navigation/links.js.jsx'

// import firm components
import PracticeLayout from '../../../../global/practice/components/PracticeLayout.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'

// import utilities
import filterUtils from '../../../../global/utils/filterUtils'
import routeUtils from '../../../../global/utils/routeUtils'
import permissions from '../../../../global/utils/permissions'
import localStorageUtils from '../../../../global/utils/localStorageUtils'
import sanitizeUtils from '../../../../global/utils/sanitizeUtils'

// import components
import NewClientOptionsMenu from '../../components/NewClientOptionsMenu.js.jsx'
import SingleClientOptions from '../components/SingleClientOptions.js.jsx'

// import actions
import * as clientActions from '../../clientActions'
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'
import * as staffClientActions from '../../../staffClient/staffClientActions'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import NumberInput from '../../../../global/components/forms/NumberInput.js.jsx'
import ExportList from '../../../../global/components/helpers/ExportList.js.jsx'
import StarCheckbox from '../../../../global/components/forms/star-checkbox/StarCheckbox.js.jsx'
import GainsightCmp from './GainsightCmp.js.jsx'
import WorkspaceListItem from '../components/WorkspaceListItem.jsx'

const FILTER_ALL = '__All__'
const FILTER_NONE = '__None__'
const FILTER_SOME = '__Some__'
const LSKEY_DISPLAYCOLUMNS = 'AllWorkspaceList_DisplayColumns'

let templateActionListItems = [
  {
    label: 'Apply Folder Template',
    name: 'file_folder_template_apply',
    value: 'file_folder_template_apply',
  },
  {
    label: 'Apply Request List',
    name: 'request_list_apply',
    value: 'request_list_apply',
  },
]

const staffActionListItems = [
  {
    label: 'Assign Staff',
    name: 'client_new_staff_client',
    value: 'client_new_staff_client',
  },
  {
    label: 'Unassigned Staff',
    name: 'unassigned_staff',
    value: 'unassigned_staff',
  },
]

const notificationActionListItems = [
  {
    label: 'Client Notification',
    name: 'client_notification',
    value: 'client_notification',
  },
  {
    label: 'Staff Notification',
    name: 'client_staff_notification',
    value: 'client_staff_notification',
  },
]

const WorkspaceList = props => {

  const params = { firmId: props.match.params.firmId }
  const ISARCHIVEDVIEW = useMemo(()=>Boolean(
    props.match.url && props.match.url.indexOf('archived') > -1,
  ), [props.match.url])
  const allDisplayColumns = [
    {
      label: 'Identifier',
      key: 'identifier',
      isSortable: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 150 },
      style: { whiteSpace: 'initial', minWidth: 150, maxWidth: 150 },
    },
    {
      label: 'Workspace Name',
      key: 'name',
      isSortable: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 450 },
      style: { whiteSpace: 'initial', minWidth: 150, maxWidth: 450 },
      valueFunction: (row, params) => {
        return links.getClientOverviewLink(row._id, row.name, params.firmId)
      },
      params: params,
    },
    {
      label: 'Engagement Types',
      key: 'engagementTypes',
      isSortable: true,
      dataType: 'Array',
      format: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 150 },
      style: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 150 },
      toolTip: true,
    },
    {
      label: 'Assigned Staff',
      key: 'staffClientsCount',
      isSortable: true,
      dataType: 'Array',
      format: true,
      headerStyle: { minWidth: 150, maxWidth: 150 },
      style: { minWidth: 150, maxWidth: 150 },
      toolTip: true,
    },
    {
      label: 'Primary Contact',
      key: 'contactFullName',
      isSortable: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 150 },
      style: { whiteSpace: 'initial', minWidth: 150, maxWidth: 150 },
    },
    {
      label: 'Email',
      key: 'contactEmail',
      isSortable: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 150 },
      style: { whiteSpace: 'initial', minWidth: 150, maxWidth: 150 },
    },
    {
      label: 'Phone Number',
      key: 'phoneNumber',
      isSortable: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 150, maxWidth: 150 },
      style: { whiteSpace: 'initial', minWidth: 150, maxWidth: 150 },
    },
    {
      label: 'Address',
      key: 'address',
      isSortable: true,
      headerStyle: { whiteSpace: 'nowrap', minWidth: 200, maxWidth: 200 },
      style: { whiteSpace: 'nowrap', minWidth: 200, maxWidth: 200 },
    },
  ]
  const defaultDisplayColumns = allDisplayColumns

  const [selectedDisplayColumns, setSelectedDisplayColumns] = useState(
    defaultDisplayColumns,
  )
  const [searchHeaderColumns, setSearchHeaderColumns] = useState({})
  const [listArgsObj, setListArgsObj] = useState({
    _firm: props.match.params.firmId,
    status: ISARCHIVEDVIEW ? 'archived' : 'visible',
  })
  const [clientOptionsOpen, setClientOptionsOpen] = useState(false)
  const [queryText, setQueryText] = useState('')
  const [selectedClientId, setSelectedClientId] = useState([])
  const [newStaffClient, setNewStaffClient] = useState()
  const [viewToggleDropDown, setViewToggleDropDown] = useState(false)
  const [checked, setChecked] = useState(false)
  const [archiveProcess, setArchiveProcess] = useState(false)
  const [clientUpdate, setClientUpdate] = useState(false)
  const [roleModal, setRoleModal] = useState()
  const [unassignStaffModalOpen, setUnassignStaffModalOpen] = useState(false)
  const [deleteProcess, setDeleteProcess] = useState(false)
  const [selectedStaffId, setSelectedStaffId] = useState([])
  const [unselectedStaffId, setUnselectedStaffId] = useState([])
  const [engagementTypeFilterNames, setEngagementTypeFilterNames] = useState([
    { label: '-- All --', name: 'All', value: FILTER_ALL },
    { label: '-- None --', name: 'None', value: FILTER_NONE },
    { label: '-- Some --', name: 'Some', value: FILTER_SOME },
  ])
  const [sortOrderAscending, setSortOrderAscending] = useState(true)
  const [isSelectDisplayColumnModalOpen, setIsSelectDisplayColumnModalOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)

  useEffect(() => {
    const { match, loggedInUser, clientStore } = props
    const { filterNames } = clientStore
    let columnsToDisplay = selectedDisplayColumns
    if (localStorage.getItem(LSKEY_DISPLAYCOLUMNS)) {
      columnsToDisplay = localStorageUtils.getJSONValue(
        LSKEY_DISPLAYCOLUMNS,
        defaultDisplayColumns,
      )
    }
    columnsToDisplay = sanitizeUtils.sanitizeDisplayColumns(
      columnsToDisplay,
      allDisplayColumns,
    )
    setSelectedDisplayColumns(columnsToDisplay)

    let searchHeaders = {}
    columnsToDisplay.forEach(item => {
      if (item.key === 'isFavorite') {
        searchHeaders[item.key] = {
          disableSearch: true,
          checked: false,
        }
      } else {
        searchHeaders[item.key] = {
          disableSearch: true,
          searchText: '',
        }
      }
    })
    setSearchHeaderColumns(searchHeaders)

    const {
      dispatch,
    } = props
    const engagementTypeFilters = _cloneDeep(engagementTypeFilterNames)
    dispatch(
      clientActions.fetchListIfNeeded('engagement-types', match.params.firmId),
    ).then(json => {
      if (json && json.engagementTypes && json.engagementTypes.length) {
        json.engagementTypes.forEach(item => {
          engagementTypeFilters.push({
            label: item,
            name: item,
            value: item,
          })
        })
        setEngagementTypeFilterNames(engagementTypeFilters)
      }
    })
    dispatch(firmActions.fetchListIfNeeded('_user', loggedInUser._id))
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
    dispatch(staffActions.fetchListIfNeeded('_firm', match.params.firmId))
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_firm', match.params.firmId)) // fetches contacts
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId)) // fetches staff
    dispatch(clientActions.fetchListIfNeeded('_user', loggedInUser._id)) // This should live on every top-level route of the portal

    _handleFetchList()
    let newFilterNames = _cloneDeep(filterNames)
    if (filterNames.engagementTypeFilter === null) {
      newFilterNames.engagementTypeFilter = FILTER_ALL
    }
    if (filterNames.staffFilter === null) {
      newFilterNames.staffFilter = FILTER_ALL
    }
    if (filterNames.contactFilter === null) {
      newFilterNames.contactFilter = FILTER_ALL
    }
    dispatch(clientActions.setSettingsScreenFilter(newFilterNames))

    return () => {
      setNewStaffClient(null)
      setQueryText('')
      _handleResetFilter()
    }
  }, [])

  useEffect(() => {
    setListArgsObj({
      _firm: props.match.params.firmId,
      status: ISARCHIVEDVIEW ? 'archived' : 'visible',
    })
    setClientOptionsOpen(false)
    setQueryText('')
    setSelectedClientId([])
    setNewStaffClient()
    setViewToggleDropDown(false)
    setChecked(false)
    setArchiveProcess(false)
    setClientUpdate(false)
    setRoleModal()
    setUnassignStaffModalOpen(false)
    setDeleteProcess(false)
    setSelectedStaffId([])
    setUnselectedStaffId([])
    setEngagementTypeFilterNames([
      { label: '-- All --', name: 'All', value: FILTER_ALL },
      { label: '-- None --', name: 'None', value: FILTER_NONE },
      { label: '-- Some --', name: 'Some', value: FILTER_SOME },
    ])
    setSortOrderAscending(true)
    setIsSelectDisplayColumnModalOpen(false)
    setIsProcessing(false)
    setShowDeleteAlertModal(false)
  }, [props.match.url, props.match.params.firmId])

  useEffect(()=>{
    _handleFetchList()
  }, [listArgsObj])

  const _handleFetchList = () => {
    const { dispatch, location } = props
    let listArgs = routeUtils.listArgsFromObject(listArgsObj)
    const query = new URLSearchParams(location.search)
    const page = query.get('page')
    const perPage = query.get('per')
    dispatch(clientActions.fetchListIfNeeded(...listArgs)).then(json => {
      dispatch(
        clientActions.setFilter({ query: '', sortBy: 'name' }, ...listArgs),
      )
      if (page) {
        setTimeout(() => {
          _handleSetPagination({ page: page, per: perPage })
        }, 500)
      } else {
        _handleSetPagination({ page: 1, per: 50 })
      }
    })
  }

  const _setPerPage = per => {
    let newPagination = {
      per: parseInt(per),
      page: 1,
    }
    _handleSetPagination(newPagination)
    props.history.push({ search: `?page=1&per=${per}` })
  }

  const _handleSetPagination = newPagination => {
    const { dispatch } = props
    const listArgs = routeUtils.listArgsFromObject(listArgsObj)
    dispatch(clientActions.setPagination(newPagination, ...listArgs))
  }

  const _handleFilter = sortBy => {
    const { utilClientStore, dispatch, userStore, paginatedList } = props
    const listArgs = routeUtils.listArgsFromObject(listArgsObj)
    let newFilter = utilClientStore.filter
    if (
      utilClientStore.filter.sortBy &&
      utilClientStore.filter.sortBy.indexOf('-') < 0
    ) {
      sortBy = '-' + sortBy
    } else {
      sortBy = sortBy.substring(0)
    }

    newFilter.sortBy = sortBy
    dispatch(clientActions.setFilter(newFilter, ...listArgs))
  }

  const _handleSelectedClient = clientId => {
    const { clientStore } = props
    let selectedStaffIds = _cloneDeep(selectedStaffId)
    const paginatedList = _cloneDeep(props.paginatedList)
    let newclientIds = _cloneDeep(selectedClientId)
    let checked = false

    const filterStaffList =
      clientStore.byId &&
      clientStore.byId[clientId] &&
      clientStore.byId[clientId].staffclients
    const staffListIds =
      (filterStaffList &&
        filterStaffList.map(data => {
          return data && data._id
        })) ||
      []

    if (newclientIds.indexOf(clientId) === -1) {
      newclientIds.push(clientId)
      staffListIds.map(item => {
        selectedStaffIds.push(item)
      })
      checked = paginatedList.length === newclientIds.length
    } else {
      newclientIds.splice(newclientIds.indexOf(clientId), 1)
      staffListIds.map(item => {
        selectedStaffIds.splice(selectedStaffIds.indexOf(item), 1)
      })
    }

    setSelectedClientId(newclientIds)
    setChecked(checked)
    setSelectedStaffId(selectedStaffIds)
  }

  const _handleNewStaffClient = action => {
    const { dispatch, paginatedList, utilClientStore } = props
    if (action) {
      const clientIds = paginatedList.map(client => client._id)
      const pagination = utilClientStore.pagination || { page: 1, per: 50 }
      const objArgs = routeUtils.listArgsFromObject({
        _argsByPages: `page${pagination.page}-per${pagination.per}`,
      })
      dispatch(staffClientActions.fetchListByClientIds(objArgs, clientIds))
      setSelectedClientId([])
      setRoleModal(null)
      setChecked(false)
    } else {
      setSelectedClientId([])
      setRoleModal(null)
      setChecked(false)
    }
  }

  const _handleCloseViewArchive = e => {
    e.stopPropagation()
    setViewToggleDropDown(false)
  }

  const _handleSelectedAllClient = () => {
    const paginatedList = _cloneDeep(props.paginatedList)
    if (!checked) {
      const selectedStaffIds = []
      const clientIds = paginatedList.map(client => {
        if (client && client.staffclients && client.staffclients.length) {
          client.staffclients.forEach(item => {
            if (item && item._id) {
              selectedStaffIds.push(item._id)
            }
          })
        }
        return client._id
      })
      setSelectedClientId(clientIds)
      setChecked(true)
      setSelectedStaffId(selectedStaffIds)
    } else {
      setSelectedClientId([])
      setChecked(false)
      setSelectedStaffId([])
    }
  }

  const _handleSetStatus = status => {
    const { dispatch } = props
    const sendData = { type: status, clientIds: selectedClientId }

    setIsProcessing(true)
    dispatch(clientActions.sendBulkUpdateClient(sendData)).then(json => {
      setIsProcessing(false)
      setSelectedClientId([])
      setChecked(false)
      if (json.success && json.list) {
        let listArgs = _cloneDeep(listArgsObj)

        listArgs.status = status
        setShowDeleteAlertModal(false)
        if (status === 'deleted') {
          json.list.forEach(client => {
            dispatch(
              clientActions.removeClientFromList(
                client._id,
                ...routeUtils.listArgsFromObject(listArgsObj),
              ),
            )
          })
        } else {
          dispatch(
            clientActions.returnClientListPromise(
              ...routeUtils.listArgsFromObject(listArgs),
            ),
          ).then(result => {
            json.list.forEach(client => {
              dispatch(
                clientActions.removeClientFromList(
                  client._id,
                  ...routeUtils.listArgsFromObject(listArgsObj),
                ),
              )
              if (result.success && result.list) {
                dispatch(
                  clientActions.addClientToList(
                    client,
                    ...routeUtils.listArgsFromObject(listArgs),
                  ),
                )
              }
            })
          })
        }
      }
    })
  }

  const _handleClientAction = value => {
    if (value === 'unassigned_staff') {
      setUnassignStaffModalOpen(true)
    } else {
      setRoleModal(value)
    }
  }

  const _handleUnassignStaff = () => {
    const { dispatch, match, clientStore } = props
    setUnassignStaffModalOpen(false)
    setDeleteProcess(true)
    dispatch(
      staffClientActions.sendBulkDelete(selectedStaffId, match.params.firmId),
    ).then(json => {
      if (json.success) {
        selectedClientId.map(item => {
          if (clientStore && clientStore.byId && clientStore.byId[item]) {
            clientStore.byId[item].staffclients = []
          }
        })
        setDeleteProcess(false)
        setSelectedStaffId([])
        setChecked(false)
        setSelectedClientId([])
        _handleFetchList()
      }
    })
  }

  const onChangeFilter = (value, type) => {
    const { clientStore, dispatch } = props
    const { filterNames } = clientStore
    if (value === filterNames[type]) {
      return
    }
    let newFilterNames = _cloneDeep(filterNames)

    newFilterNames[type] = value
    dispatch(clientActions.setSettingsScreenFilter(newFilterNames))
  }

  const _handleQuery = e => {
    const { dispatch } = props
    const listArgs = routeUtils.listArgsFromObject(listArgsObj)
    dispatch(clientActions.setQuery(e.target.value.toLowerCase(), ...listArgs))
    setQueryText(e.target.value.toLowerCase())
  }

  const onDisplayColumnChange = displayColumns => {
    const { dispatch } = props
    const filterHeaders = {}
    const searchHeaders = _cloneDeep(searchHeaderColumns)
    const newSearchHeaderColumns = {}

    displayColumns.forEach(item => {
      newSearchHeaderColumns[item.key] = {}
      if (searchHeaders[item.key]) {
        if (
          !searchHeaders[item.key].disableSearch &&
          searchHeaders[item.key].searchText
        ) {
          filterHeaders[item.key] = searchHeaders[item.key].searchText
        }
        newSearchHeaderColumns[item.key].disableSearch =
          searchHeaders[item.key].disableSearch
        newSearchHeaderColumns[item.key].searchText =
          searchHeaders[item.key].searchText
      } else {
        delete filterHeaders[item.key]
        newSearchHeaderColumns[item.key].disableSearch = true
        newSearchHeaderColumns[item.key].searchText = ''
      }
    })

    dispatch(clientActions.setHeaderFilter(filterHeaders))
    setSelectedDisplayColumns(displayColumns)
    setIsSelectDisplayColumnModalOpen(false)
    setSearchHeaderColumns(newSearchHeaderColumns)
    localStorageUtils.setJSONValue(LSKEY_DISPLAYCOLUMNS, displayColumns)
  }

  const showSelectDisplayColumnModal = () => {
    setIsSelectDisplayColumnModalOpen(true)
  }

  const _toggleAlertModal = () => {
    setShowDeleteAlertModal(!showDeleteAlertModal)
  }

  const _handleResetFilter = () => {
    const { dispatch } = props
    const listArgs = routeUtils.listArgsFromObject(listArgsObj)
    const columnsToDisplay = _cloneDeep(selectedDisplayColumns)

    const filterNames = {
      contactFilter: FILTER_ALL,
      engagementTypeFilter: FILTER_ALL,
      staffFilter: FILTER_ALL,
    }

    const searchHeaders = {}
    columnsToDisplay.forEach(item => {
      searchHeaders[item.key] = {
        disableSearch: true,
        searchText: '',
      }
    })
    setQueryText('')
    setSearchHeaderColumns(searchHeaders)
    dispatch(clientActions.setQuery('', ...listArgs))
    dispatch(
      clientActions.setFilter({ query: '', sortBy: 'name' }, ...listArgs),
    )
    dispatch(clientActions.setSettingsScreenFilter(filterNames))
    dispatch(clientActions.setHeaderFilter({}))
  }

  const _toggleHeaderSearch = e => {
    const searchHeaders = _cloneDeep(searchHeaderColumns)
    const { clientStore, dispatch } = props
    const { filterHeaders } = clientStore
    let newFilterHeaders = _cloneDeep(filterHeaders)

    if (searchHeaders[e.target.name]) {
      searchHeaders[e.target.name].disableSearch = !e.target.value
    } else if (e.target.name === 'isFavorite') {
      searchHeaders[e.target.name] = {
        disableSearch: false,
        checked: false,
      }
    } else {
      searchHeaders[e.target.name] = {
        disableSearch: false,
        searchText: '',
      }
    }

    if (searchHeaders[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else if (e.target.name === 'isFavorite') {
      newFilterHeaders[e.target.name] = searchHeaders[e.target.name].checked
    } else {
      newFilterHeaders[e.target.name] = searchHeaders[e.target.name].searchText
    }

    dispatch(clientActions.setHeaderFilter(newFilterHeaders))
    setSearchHeaderColumns(searchHeaders)
  }

  const _changeHeaderSearch = e => {
    const searchHeaders = _cloneDeep(searchHeaderColumns)
    const { clientStore, dispatch } = props
    const { filterHeaders } = clientStore
    let newFilterHeaders = _cloneDeep(filterHeaders)

    if (searchHeaders[e.target.name]) {
      if (e.target.name === 'isFavorite') {
        searchHeaders[e.target.name].checked = e.target.checked
      } else {
        searchHeaders[e.target.name].searchText = e.target.value
      }
    } else if (e.target.name === 'isFavorite') {
      searchHeaders[e.target.name] = {
        disableSearch: false,
        checked: e.target.checked,
      }
    } else {
      searchHeaders[e.target.name] = {
        disableSearch: false,
        searchText: e.target.value,
      }
    }

    if (e.target.name === 'isFavorite') {
      newFilterHeaders[e.target.name] = e.target.checked
    } else {
      newFilterHeaders[e.target.name] = e.target.value
    }

    dispatch(clientActions.setHeaderFilter(newFilterHeaders))
    setSearchHeaderColumns(searchHeaders)
  }

  const ModalComponent = roleModal ? RoleModalComponent[roleModal] : undefined

  const {
    match,
    location,
    firmStore,
    clientStore,
    staffStore,
    userStore,
    staffClientStore,
    utilClientStore,
    clientListItems,
    paginatedList,
    sortBy,
    assignedStaffListItems,
    assignedClientUserListItems,
    loggedInUser,
    orderedList,
    staffMap
  } = props

  const { filterNames } = clientStore

  const hasExportAccess = permissions.hasExportAccess(
    staffStore,
    match.params.firmId,
  )

  const ownerPermissions = permissions.isStaffOwner(
    staffStore,
    loggedInUser,
    match.params.firmId,
  )
  const advancedPermissions = permissions.isStaffAdvanced(
    staffStore,
    loggedInUser,
    match.params.firmId,
  )

  const selectedFirm = firmStore.selected.getItem()
  const listArgs = routeUtils.listArgsFromObject(listArgsObj)
  const staffListItems = staffStore.util.getList('_firm', match.params.firmId)
  const pagination = utilClientStore.pagination || { page: 1, per: 50 }
  const objArgs = routeUtils.listArgsFromObject({
    _argsByPages: `page${pagination.page}-per${pagination.per}`,
  })
  const staffClientList = staffClientStore.util.getList(...objArgs)
  const isEmpty =
    clientStore.selected.didInvalidate ||
    !clientListItems ||
    utilClientStore.didInvalidate ||
    utilClientStore.isFetching ||
    !selectedFirm ||
    !staffListItems ||
    !paginatedList

  const isFetching =
    clientStore.selected.isFetching ||
    !clientListItems ||
    utilClientStore.isFetching ||
    !selectedFirm ||
    !staffListItems ||
    !paginatedList

  const availableStaff =
    isEmpty || isFetching || !staffListItems
      ? []
      : staffListItems.flatMap(staff => {
          let item = staff
          let fullName = userStore.byId[staff._user]
            ? `${userStore.byId[staff._user].firstname} ${
                userStore.byId[staff._user].lastname
              }`
            : ''
          let userName = userStore.byId[staff._user]
            ? userStore.byId[staff._user].username
            : ''
          item.displayName = `${fullName} | ${userName}`
          item.fullName = fullName
          item.userName = userName

          return staff && staff.status === 'active' ? item : []
        })

  const staffItems = [
    { label: '-- All --', name: 'All', value: FILTER_ALL },
    { label: '-- None --', name: 'None', value: FILTER_NONE },
    { label: '-- Some --', name: 'Some', value: FILTER_SOME },
    ...assignedStaffListItems
      .map(s => ({ label: s, name: s, value: s }))
      .sort((a, b) => {
        const labelA = a.label || ''
        const labelB = b.label || ''

        return labelA.localeCompare(labelB)
      }),
  ]

  const contactItems = [
    { label: '-- All --', name: 'All', value: FILTER_ALL },
    { label: '-- None --', name: 'None', value: FILTER_NONE },
    { label: '-- Some --', name: 'Some', value: FILTER_SOME },
    ...assignedClientUserListItems
      .map(s => ({ label: s, name: s, value: s }))
      .sort((a, b) => {
        const labelA = a.label || ''
        const labelB = b.label || ''

        return labelA.localeCompare(labelB)
      }),
  ]

  if (advancedPermissions) {
    templateActionListItems = [
      {
        label: 'Apply Request List',
        name: 'request_list_apply',
        value: 'request_list_apply',
      },
    ]
  }

  if (advancedPermissions) {
    templateActionListItems = [
      {
        label: 'Apply Request List',
        name: 'request_list_apply',
        value: 'request_list_apply',
      },
    ]
  }

  return (
    <PracticeLayout isSidebarOpen={true}>
      <Helmet>
        <title>Clients Workspaces</title>
      </Helmet>
      <AlertModal
        alertMessage={
          <div>
            <h4>Are you sure?</h4>
            {`Do you want to unassign ${
              selectedStaffId.length > 1 ? 'these staffs' : 'this staff'
            } from client?`}
          </div>
        }
        alertTitle="Unassign staff"
        closeAction={() => setUnassignStaffModalOpen(false)}
        confirmAction={() => _handleUnassignStaff()}
        confirmText="Yes"
        declineText="Never mind"
        isOpen={unassignStaffModalOpen}
        type="warning"
      />
      <div className="-practice-subnav">
        <div className="yt-container fluid">
          <div className="yt-row center-vert space-between">
            <Breadcrumbs links={location.state.breadcrumbs} />
            {!ownerPermissions || ISARCHIVEDVIEW ? null : (
              <button
                className="yt-btn x-small -x-small_26"
                onClick={() => setClientOptionsOpen(true)}
              >
                New Workspace
                <i
                  style={{ marginLeft: '.5em' }}
                  className="fas fa-caret-down"
                />
              </button>
            )}
          </div>
          <CloseWrapper
            isOpen={clientOptionsOpen || archiveProcess}
            closeAction={() =>
              archiveProcess ? null : setClientOptionsOpen(false)
            }
          />
          <div className="dropdown -client-list-add-options">
            <NewClientOptionsMenu
              firmId={parseInt(match.params.firmId)}
              isOpen={clientOptionsOpen}
            />
          </div>
        </div>
      </div>
      <div className="yt-container fluid">
        <h1 className="-tab-name">
          {' '}
          {ISARCHIVEDVIEW ? 'Archived Workspaces' : 'Workspaces'}
        </h1>
      </div>
      <div className="-practice-content">
        {isEmpty ? (
          isFetching ? (
            <div className="-loading-hero">
              <div className="u-centerText">
                <div className="loading"></div>
              </div>
            </div>
          ) : (
            <h2>No client found.</h2>
          )
        ) : (
          <div
            className="yt-container fluid"
            style={{ opacity: isFetching ? 0.5 : 1 }}
          >
            <div className="yt-toolbar">
              <div className="yt-tools space-between">
                <div className="-filters -left">
                  <span className="-mobile-yt-hide">Filters </span>
                  <button
                    className="yt-btn x-small"
                    onClick={_handleResetFilter}
                  >
                    Reset Filter
                  </button>
                  <StarCheckbox
                    checked={filterNames.isFavorite}
                    onChange={e => {
                      onChangeFilter(e.target.checked, 'isFavorite')
                    }}
                  />
                  <FilterList
                    label="Engagement Type"
                    select={value =>
                      onChangeFilter(value, 'engagementTypeFilter')
                    }
                    displayKey="label"
                    items={engagementTypeFilterNames}
                    selected={filterNames.engagementTypeFilter}
                    valueKey="value"
                    name="_filterEngagementType"
                    isEnabled={true}
                  />
                  <FilterList
                    label="Assigned Staff"
                    select={value => onChangeFilter(value, 'staffFilter')}
                    displayKey="label"
                    items={staffItems}
                    selected={filterNames.staffFilter || FILTER_ALL}
                    valueKey="value"
                    name="_staffFilter"
                    isEnabled={true}
                  />
                  <FilterList
                    label="Primary Contact"
                    select={value => onChangeFilter(value, 'contactFilter')}
                    displayKey="label"
                    items={contactItems}
                    selected={filterNames.contactFilter || FILTER_ALL}
                    valueKey="value"
                    name="_contactFilter"
                    isEnabled={true}
                  />
                  <ExportList
                    data={[...orderedList]}
                    gridName={'workspace_list'}
                    columns={[...selectedDisplayColumns]}
                    hasExportAccess={hasExportAccess}
                  />
                </div>
                {ownerPermissions && ISARCHIVEDVIEW ? (
                  <div className="-options -right">
                    <button
                      className="yt-btn x-small link info"
                      disabled={!selectedClientId.length}
                      onClick={() => {
                        _handleSetStatus('visible')
                      }}
                      style={{ display: 'inline-flex' }}
                    >
                      Reinstate Clients
                      {selectedClientId.length ? (
                        <span className="-btn-cds">
                          {' '}
                          — {selectedClientId.length}
                        </span>
                      ) : null}
                    </button>
                    <button
                      className="yt-btn x-small link info"
                      disabled={!selectedClientId.length}
                      onClick={() => setShowDeleteAlertModal(true)}
                      style={{ display: 'inline-flex' }}
                    >
                      Delete Clients
                      {selectedClientId.length ? (
                        <span className="-btn-cds">
                          {' '}
                          — {selectedClientId.length}
                        </span>
                      ) : null}
                    </button>
                    <div
                      className="-options -yt-edit-option"
                      onClick={() => setViewToggleDropDown(true)}
                    >
                      <div
                        style={{
                          position: 'relative',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <CloseWrapper
                          isOpen={viewToggleDropDown}
                          closeAction={_handleCloseViewArchive}
                        />
                        <i className="far fa-ellipsis-v"></i>
                        <SingleClientOptions
                          isOpen={viewToggleDropDown}
                          archived={true}
                          singleClient={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : ownerPermissions ? (
                  <div className="-options -right">
                    <button
                      className="yt-btn x-small link info"
                      disabled={!selectedClientId.length}
                      onClick={() => {
                        _handleSetStatus('archived')
                      }}
                      style={{ display: 'inline-flex' }}
                    >
                      Archived Clients
                      {selectedClientId.length ? (
                        <span className="-btn-cds">
                          {' '}
                          — {selectedClientId.length}
                        </span>
                      ) : null}
                    </button>
                    <DropdownButton
                      label="Notification Setting"
                      selectedCount={selectedClientId.length}
                      select={_handleClientAction}
                      displayKey="label"
                      items={notificationActionListItems}
                      selected={null}
                      valueKey="value"
                      disabled={!selectedClientId.length}
                    />
                    <DropdownButton
                      label="Staff Setting"
                      selectedCount={selectedClientId.length}
                      select={_handleClientAction}
                      displayKey="label"
                      items={staffActionListItems}
                      selected={null}
                      valueKey="value"
                      disabled={!selectedClientId.length}
                    />
                    <DropdownButton
                      label="Apply Template"
                      selectedCount={selectedClientId.length}
                      select={_handleClientAction}
                      displayKey="label"
                      items={templateActionListItems}
                      selected={null}
                      valueKey="value"
                      disabled={!selectedClientId.length}
                    />
                    <button
                      disabled={false}
                      title="Edit Columns"
                      className="yt-btn info"
                      onClick={showSelectDisplayColumnModal}
                    >
                      <i className="fal fa-columns" />
                    </button>
                    <div
                      className="-options -yt-edit-option"
                      onClick={() => setViewToggleDropDown(true)}
                    >
                      <div
                        style={{
                          position: 'relative',
                          height: '100%',
                          width: '100%',
                          marginLeft: '10px',
                        }}
                      >
                        <CloseWrapper
                          isOpen={viewToggleDropDown}
                          closeAction={_handleCloseViewArchive}
                        />
                        <i className="far fa-ellipsis-v"></i>
                        <SingleClientOptions
                          isOpen={viewToggleDropDown}
                          archived={ISARCHIVEDVIEW}
                          singleClient={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="-options -right">
                    <button
                      disabled={false}
                      title="Edit Columns"
                      className="yt-btn info"
                      onClick={showSelectDisplayColumnModal}
                    >
                      <i className="fal fa-columns" />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <hr className="-mobile-yt-hide" />
            <div
              className="yt-table table -workspace-table truncate-cells"
              style={{ marginTop: 0 }}
            >
              <div
                className="table-caption -border-bottom"
                style={{ borderBottom: '1px solid #aaa' }}
              >
                <PageTabber
                  totalItems={orderedList.length}
                  totalPages={Math.ceil(
                    orderedList.length / utilClientStore.pagination.per,
                  )}
                  pagination={utilClientStore.pagination}
                  setPagination={_handleSetPagination}
                  setPerPage={_setPerPage}
                  viewingAs="top"
                  itemName="workspaces"
                  searchText="Search client name..."
                  firmId={match.params.firmId}
                  isChanged={true}
                  displayAll={true}
                  enableSearch={true}
                  query={queryText}
                  handleQuery={_handleQuery}
                />
              </div>
              <div className="-table-horizontal-scrolling">
                <div className="table-head">
                  {(ownerPermissions && (
                    <div
                      className="table-cell"
                      style={{ maxWidth: '16px' }}
                    >
                      <CheckboxInput
                        name="clients"
                        value={checked}
                        checked={checked}
                        change={_handleSelectedAllClient}
                      />
                    </div>
                  )) ||
                    null}
                  {(ownerPermissions && <div className="table-cell"></div>) ||
                    null}
                  {selectedDisplayColumns && selectedDisplayColumns.length ? (
                    selectedDisplayColumns.map(column => (
                      <div
                        key={column.key}
                        className="table-cell sortable"
                        style={column.headerStyle}
                      >
                        <div
                          className="-table-header-title"
                          onClick={() =>
                            column.isSortable ? _handleFilter(column.key) : null
                          }
                        >
                          {column.label}
                          {column.isSortable &&
                          sortBy &&
                          sortBy === column.key ? (
                            <i className="fad fa-sort-down"></i>
                          ) : column.isSortable &&
                            sortBy &&
                            sortBy === `-${column.key}` ? (
                            <i className="fad fa-sort-up"></i>
                          ) : (
                            <i className="fad fa-sort"></i>
                          )}
                        </div>
                        <div className="-table-header-search">
                          <CheckboxInput
                            name={column.key}
                            value={
                              searchHeaderColumns[column.key] &&
                              !searchHeaderColumns[column.key].disableSearch
                            }
                            checked={
                              searchHeaderColumns[column.key] &&
                              !searchHeaderColumns[column.key].disableSearch
                            }
                            change={_toggleHeaderSearch}
                          />
                          {column.key === 'isFavorite' ? (
                            <StarCheckbox
                              name={column.key}
                              onChange={_changeHeaderSearch}
                              checked={
                                searchHeaderColumns[column.key] &&
                                searchHeaderColumns[column.key].checked
                              }
                              disabled={
                                searchHeaderColumns[column.key] &&
                                searchHeaderColumns[column.key].disableSearch
                              }
                            />
                          ) : column.key === 'staffClientsCount' ? (
                            <NumberInput
                              change={_changeHeaderSearch}
                              firefoxDisplayFix={true}
                              min="0"
                              name={column.key}
                              required={false}
                              step="1"
                              value={
                                searchHeaderColumns[column.key] &&
                                searchHeaderColumns[column.key].searchText
                              }
                              disabled={
                                searchHeaderColumns[column.key] &&
                                searchHeaderColumns[column.key].disableSearch
                              }
                              placeholder={
                                'Search ' + column.label.substring(0, 3) + '...'
                              }
                            />
                          ) : (
                            <TextInput
                              blur={() => {}}
                              change={_changeHeaderSearch}
                              name={column.key}
                              value={
                                searchHeaderColumns[column.key] &&
                                searchHeaderColumns[column.key].searchText
                              }
                              disabled={
                                searchHeaderColumns[column.key] &&
                                searchHeaderColumns[column.key].disableSearch
                              }
                              placeholder={
                                'Search ' + column.label.substring(0, 3) + '...'
                              }
                            />
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>SELECT COLUMN DISPLAY</div>
                  )}
                </div>
                {paginatedList.map((client, i) => {
                  return (
                    <WorkspaceListItem
                      address={client.objaddress}
                      key={'client_' + client._id + '_' + i}
                      client={client}
                      phoneNumber={client.phonenumber}
                      primaryContact={
                        client._primaryContact
                          ? userStore.byId[client._primaryContact]
                          : null
                      }
                      staffClientList={staffClientList || []}
                      handleSelectedClient={_handleSelectedClient}
                      checked={selectedClientId.includes(client._id)}
                      archived={false}
                      listArgs={listArgs}
                      handleFetchList={_handleFetchList}
                      selectedDisplayColumns={selectedDisplayColumns}
                      ISARCHIVEDVIEW={ISARCHIVEDVIEW}
                      ownerPermissions={ownerPermissions}
                    />
                  )
                })}
              </div>
            </div>
            {ModalComponent ? (
              <ModalComponent
                close={() => {
                  setRoleModal(null)
                  setSelectedClientId([])
                  setChecked(false)
                }}
                handleClose={() => {
                  setRoleModal(null)
                  setSelectedClientId([])
                  setChecked(false)
                }}
                isOpen={Boolean(roleModal)}
                selectedClientId={selectedClientId}
                handleNewStaffClient={_handleNewStaffClient}
                multipleAdd={true}
                firmId={match.params.firmId}
                staffListItems={availableStaff}
                staffMap={staffMap}
                match={match}
                viewingAs="client-setting"
                clientListArgs={listArgs}
              />
            ) : null}
            <SelectOrderedSubList
              isOpen={isSelectDisplayColumnModalOpen}
              allItems={allDisplayColumns}
              selectedItems={selectedDisplayColumns}
              displayKey="label"
              valueKey="key"
              onDone={selectedDisplayColumns => {
                onDisplayColumnChange(selectedDisplayColumns)
              }}
              onCancelled={() => {
                setIsSelectDisplayColumnModalOpen(false)
              }}
            />
            <AlertModal
              alertMessage={'Are you sure? This cannot be undone.'}
              alertTitle={`Delete this client${
                selectedClientId.length > 1 ? 's' : ''
              }`}
              closeAction={_toggleAlertModal}
              confirmAction={() => {
                _handleSetStatus('deleted')
              }}
              confirmText={'Delete'}
              declineAction={_toggleAlertModal}
              declineText={'Cancel'}
              isOpen={showDeleteAlertModal}
              type={'danger'}
            ></AlertModal>
          </div>
        )}
        <GainsightCmp />
      </div>
    </PracticeLayout>
  )
}

WorkspaceList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = (store, props) => {
  const clientStore = store.client
  const staffStore = store.staff
  const loggedInUser = store.user.loggedIn.user
  const { firmId } = props.match.params

  const ownerPermissions = permissions.isStaffOwner(
    staffStore,
    loggedInUser,
    firmId,
  )
  const advancedPermissions = permissions.isStaffAdvanced(
    staffStore,
    loggedInUser,
    firmId,
  )

  let listArgsObj

  listArgsObj = routeUtils.listArgsFromObject({
    _firm: props.match.params.firmId,
    status:
      props.match.url && props.match.url.indexOf('archived') > -1
        ? 'archived'
        : 'visible',
  })

  const utilClientStore = clientStore.util.getSelectedStore(...listArgsObj)
  let clientListItems = clientStore.util.getList(...listArgsObj)
  let paginatedList = []
  let orderedList = []
  let sortBy = ''
  let assignedStaffListItems = []
  let assignedClientUserListItems = []

  if (clientListItems) {
    const pagination = utilClientStore.pagination || { page: 1, per: 50 }
    const query = utilClientStore.query || ''
    sortBy = utilClientStore.filter ? utilClientStore.filter.sortBy : 'name'

    // FILTER BY QUERY
    let queryTestString = String(query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers

    let newClientListItems = []

    // filter and prepared data
    clientListItems.map(item => {
      // preparing data -----------------------------------------------------------------------------------------------------------------------------
      const data = _cloneDeep(item)
      data.identifier = data.identifier || ''
      data.engagementTypes =
        (data.engagementTypes && data.engagementTypes.join(', ')) || null
      data.contactFullName = ''
      data.phoneNumber =
        data.phonenumber &&
        data.phonenumber.number &&
        formatPhoneNumber(data.phonenumber.number, 'National')
          ? formatPhoneNumber(data.phonenumber.number, 'National')
          : null
      if (data.phonenumber && data.phonenumber.extNumber) {
        data.phoneNumber += ' ' + data.phonenumber.extNumber
      }
      data.address = ''
      data.staff = ''
      data.contactEmail =
        (store.user.byId[data._primaryContact] &&
          store.user.byId[data._primaryContact].username) ||
        ''
      if (store.user.byId[data._primaryContact]) {
        const contact = store.user.byId[data._primaryContact]
        data.contactFullName = contact.firstname || ''
        data.contactFullName +=
          (data.contactFullName ? ' ' : '') + contact.lastname || ''
      }
      if (data.objaddress) {
        const address = data.objaddress
        data.address = address.street1 || ''
        data.address += (data.address ? ' ' : '') + address.city || ''
        data.address +=
          (data.address ? ' ' : '') + ` ${address.state ? address.state : ''}`
        data.address += (data.address ? ' ' : '') + address.country || ''
      }
      data.staffClientsCount =
        data.staffclients && data.staffclients[0] === null
          ? 0
          : data.staffclients && data.staffclients.length

      // filtering data --------------------------------------------------------------------------------------------------------------------------------------

      let showMe = true

      if (!ownerPermissions || advancedPermissions) {
        if (data.staffClientsCount <= 0) {
          showMe = false
        } else {
          let isStaffClient =
            data.staffclients &&
            data.staffclients.filter(staff => {
              return staff._user == loggedInUser._id
            })[0]

          if (isStaffClient) {
            if (data.staffclients && data.staffclients[0] !== null) {
              data.staff = data.staffclients.map(e => {
                const user = store.user.byId[e._user]
                return (
                  user &&
                  `${user.firstname || ''}${
                    user.lastname ? ` ${user.lastname}` : ''
                  }`
                )
              })

              assignedStaffListItems = _uniq([
                ...assignedStaffListItems,
                ...data.staff,
              ])
              data.staff = data.staff.join(', ')
            }
            if (data.contactFullName) {
              assignedClientUserListItems = _uniq([
                ...assignedClientUserListItems,
                data.contactFullName,
              ])
            }
          } else {
            showMe = false
          }
        }
      } else {
        if (data.staffclients && data.staffclients[0] !== null) {
          data.staff = data.staffclients.map(e => {
            const user = store.user.byId[e._user]
            return (
              user &&
              `${user.firstname || ''}${
                user.lastname ? ` ${user.lastname}` : ''
              }`
            )
          })

          assignedStaffListItems = _uniq([
            ...assignedStaffListItems,
            ...data.staff,
          ])
          data.staff = data.staff.join(', ')
        }
        if (data.contactFullName) {
          assignedClientUserListItems = _uniq([
            ...assignedClientUserListItems,
            data.contactFullName,
          ])
        }
      }

      // filter search Text
      if (queryTestString && !filterUtils.filterClient(queryTestString, item)) {
        showMe = false
      }

      // filter by favorite
      if (
        showMe &&
        Boolean(clientStore.filterNames) &&
        Boolean(clientStore.filterNames.isFavorite)
      ) {
        showMe = item.isFavorite
      }

      // filter by engagement types
      if (
        showMe &&
        Boolean(clientStore.filterNames) &&
        Boolean(clientStore.filterNames.engagementTypeFilter) &&
        clientStore.filterNames.engagementTypeFilter != FILTER_ALL
      ) {
        const selectedValue = clientStore.filterNames.engagementTypeFilter
        if (selectedValue === FILTER_NONE) {
          showMe = !data.engagementTypes
        } else if (selectedValue === FILTER_SOME) {
          showMe = Boolean(data.engagementTypes)
        } else {
          showMe =
            item.engagementTypes &&
            item.engagementTypes.some(engType => engType === selectedValue)
        }
      }

      // filter by assigned staff
      if (
        showMe &&
        Boolean(clientStore.filterNames) &&
        Boolean(clientStore.filterNames.staffFilter) &&
        clientStore.filterNames.staffFilter != FILTER_ALL
      ) {
        const selectedValue = clientStore.filterNames.staffFilter
        if (selectedValue === FILTER_NONE) {
          showMe = !data.staffClientsCount
        } else if (selectedValue === FILTER_SOME) {
          showMe = Boolean(data.staffClientsCount)
        } else {
          showMe = data.staff && data.staff.indexOf(selectedValue) > -1
        }
      }

      // filter by contact
      if (
        showMe &&
        Boolean(clientStore.filterNames) &&
        Boolean(clientStore.filterNames.contactFilter) &&
        clientStore.filterNames.contactFilter != FILTER_ALL
      ) {
        const selectedValue = clientStore.filterNames.contactFilter
        if (selectedValue === FILTER_NONE) {
          showMe = !data._primaryContact
        } else if (selectedValue === FILTER_SOME) {
          showMe = Boolean(data._primaryContact)
        } else {
          showMe =
            data.contactFullName &&
            data.contactFullName.indexOf(selectedValue) > -1
        }
      }

      // column header searchable - identifier
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.identifier) &&
        clientStore.filterHeaders.identifier.trim()
      ) {
        const identifier = clientStore.filterHeaders.identifier.toLowerCase()
        if (!data.identifier) {
          showMe = false
        } else if (data.identifier.toLowerCase().indexOf(identifier) === -1) {
          showMe = false
        }
      }

      // column header searchable - is favorite
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        clientStore.filterHeaders.isFavorite !== undefined &&
        clientStore.filterHeaders.isFavorite !== null
      ) {
        if (clientStore.filterHeaders.isFavorite === data.isFavorite) {
          showMe = true
        } else {
          showMe = false
        }
      }

      // column header searchable - client name
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.name) &&
        clientStore.filterHeaders.name.trim()
      ) {
        const name = clientStore.filterHeaders.name.toLowerCase()
        if (!data.name) {
          showMe = false
        } else if (data.name.toLowerCase().indexOf(name) === -1) {
          showMe = false
        }
      }

      // column header searchable - engagement type
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.engagementTypes) &&
        clientStore.filterHeaders.engagementTypes.trim()
      ) {
        const engagementType =
          clientStore.filterHeaders.engagementTypes.toLowerCase()
        if (!data.engagementTypes) {
          showMe = false
        } else if (
          data.engagementTypes.toLowerCase().indexOf(engagementType) === -1
        ) {
          showMe = false
        }
      }

      // column header searchable - assigned staff count
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        (Boolean(clientStore.filterHeaders.staffClientsCount) ||
          clientStore.filterHeaders.staffClientsCount === 0)
      ) {
        let { staffClientsCount } = clientStore.filterHeaders
        staffClientsCount = Number(staffClientsCount)
        if (staffClientsCount !== data.staffClientsCount) {
          showMe = false
        }
      }

      // column header searchable - contact full name
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.contactFullName) &&
        clientStore.filterHeaders.contactFullName.trim()
      ) {
        const contactFullName =
          clientStore.filterHeaders.contactFullName.toLowerCase()
        if (!data.contactFullName) {
          showMe = false
        } else if (
          data.contactFullName.toLowerCase().indexOf(contactFullName) === -1
        ) {
          showMe = false
        }
      }

      // column header searchable - contact email
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.contactEmail) &&
        clientStore.filterHeaders.contactEmail.trim()
      ) {
        const contactEmail =
          clientStore.filterHeaders.contactEmail.toLowerCase()
        if (!data.contactEmail) {
          showMe = false
        } else if (
          data.contactEmail.toLowerCase().indexOf(contactEmail) === -1
        ) {
          showMe = false
        }
      }

      // column header searchable - phone number
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.phoneNumber) &&
        clientStore.filterHeaders.phoneNumber.trim()
      ) {
        const phoneNumber = clientStore.filterHeaders.phoneNumber.toLowerCase()
        if (!data.phoneNumber) {
          showMe = false
        } else if (data.phoneNumber.toLowerCase().indexOf(phoneNumber) === -1) {
          showMe = false
        }
      }

      // column header searchable - address
      if (
        showMe &&
        Boolean(clientStore.filterHeaders) &&
        Boolean(clientStore.filterHeaders.address) &&
        clientStore.filterHeaders.address.trim()
      ) {
        const address = clientStore.filterHeaders.address.toLowerCase()
        if (!data.address) {
          showMe = false
        } else if (data.address.toLowerCase().indexOf(address) === -1) {
          showMe = false
        }
      }

      if (showMe) {
        newClientListItems.push(data)
      }
      return item
    })

    // SORT THE LIST
    switch (sortBy) {
      case 'identifier':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.identifier.toLowerCase()],
          ['asc'],
        )
        break
      case '-identifier':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.identifier.toLowerCase()],
          ['desc'],
        )
        break
      case 'name':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.name.toLowerCase()],
          ['asc'],
        )
        break
      case '-name':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.name.toLowerCase()],
          ['desc'],
        )
        break
      case 'contactFullName':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.contactFullName.toLowerCase()],
          ['asc'],
        )
        break
      case '-contactFullName':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.contactFullName.toLowerCase()],
          ['desc'],
        )
        break
      case 'contactEmail':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.contactEmail.toLowerCase()],
          ['asc'],
        )
        break
      case '-contactEmail':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.contactEmail.toLowerCase()],
          ['desc'],
        )
        break
      case 'phoneNumber':
        orderedList = _orderBy(
          newClientListItems,
          [
            item =>
              item.phoneNumber ? `${item.phoneNumber}`.toLowerCase() : '',
          ],
          ['asc'],
        )
        break
      case '-phoneNumber':
        orderedList = _orderBy(
          newClientListItems,
          [
            item =>
              item.phoneNumber ? `${item.phoneNumber}`.toLowerCase() : '',
          ],
          ['desc'],
        )
        break
      case 'address':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.address.toLowerCase()],
          ['asc'],
        )
        break
      case '-address':
        orderedList = _orderBy(
          newClientListItems,
          [item => item.address.toLowerCase()],
          ['desc'],
        )
        break
      case 'engagementTypes':
        orderedList = _orderBy(
          newClientListItems,
          [item => `${item.engagementTypes}`.toLowerCase()],
          ['asc'],
        )
        break
      case '-engagementTypes':
        orderedList = _orderBy(
          newClientListItems,
          [item => `${item.engagementTypes}`.toLowerCase()],
          ['desc'],
        )
        break
      case 'staffClientsCount':
        orderedList = _orderBy(
          newClientListItems,
          [item => parseInt(`${item.staffClientsCount}`)],
          ['asc'],
        )
        break
      case '-staffClientsCount':
        orderedList = _orderBy(
          newClientListItems,
          [item => parseInt(`${item.staffClientsCount}`)],
          ['desc'],
        )
        break
      default:
        orderedList = _orderBy(
          newClientListItems,
          [item => item.name.toLowerCase()],
          ['asc'],
        )
    }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = Number(start) + Number(pagination.per)
    paginatedList = _slice(orderedList, start, end)
  }
  return {
    clientStore: store.client,
    firmStore: store.firm,
    loggedInUser: store.user.loggedIn.user,
    staffClientStore: store.staffClient,
    staffStore: store.staff,
    userStore: store.user,
    utilClientStore,
    clientListItems,
    paginatedList,
    sortBy,
    assignedStaffListItems,
    assignedClientUserListItems,
    orderedList: orderedList,
    staffMap: store.staff.byId,
  }
}

export default withRouter(connect(mapStoreToProps)(WorkspaceList))
