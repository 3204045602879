/**
 * Modal component for adding a single image to a place or section (or user
 * profile)
 *
 * Creates a new file object in the database and passes back the reference _id
 * to the parent component
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries

// import actions
import * as fileActions from '../fileActions'
import { createItemUploads, notifyUpload } from '../fileActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'

import ImageInput from '../../../global/components/forms/ImageInput.js.jsx'

import ImageCropper from './ImageCropper.js.jsx'

class NewImageModal extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      submitting: false,
      cropped: null,
    }
    this._bind('_handleFormSubmit', '_handleFileChange')
  }

  _handleFileChange(files) {
    // console.log(files);
    this.setState({ files })
  }

  async _handleFormSubmit(e) {
    const {
      close,
      dispatch,
      handleUploaded,
      history,
      enableCrop,
      filePointers,
    } = this.props
    this.setState({ submitting: true })
    if (e) {
      e.preventDefault()
    }
    // convert to a FormData objet to allow uploading file=
    let { files } = this.state
    if (files.length < 1) {
      alert('No files present')
    } else {
      if (enableCrop) {
        const base64Data =
          this.state.cropped && this.state.cropped.split(',')[1]
        if (base64Data) {
          const decodedString = atob(base64Data)
          const byteArray = new Uint8Array(decodedString.length)
          for (let i = 0; i < decodedString.length; i++) {
            byteArray[i] = decodedString.charCodeAt(i)
          }
          const blob = new Blob([byteArray], { type: 'image/png' })
          const file = new File([blob], 'favicon.png', { type: 'image/png' })
          files = [file]
        }
      }

      const params = filePointers
      this.setState({ submitting: true })

      try {
        const createdFolders = []
        const uploads = dispatch(
          createItemUploads({
            files,
            folders: createdFolders,
            params,
          })
        ).map(async upload => {
          for await (const { type, value } of upload) {
            if (type === `done`) {
              return value
            }
          }
        })

        const results = await Promise.allSettled(uploads)
        const { roots, createdFiles, failedMessages } = results
          .map(({ value }) => value)
          .reduce(
            (acc, { root, createdFiles, failedMessages }) => {
              acc.roots = [...acc.roots, root]
              acc.createdFiles = [...acc.createdFiles, ...createdFiles]
              acc.failedMessages = [...acc.failedMessages, ...failedMessages]
              return acc
            },
            {
              roots: [],
              createdFiles: [],
              failedMessages: [],
            }
          )

        if (createdFiles.length) {
          await dispatch(notifyUpload({ files: createdFiles, params }))

          if (handleUploaded) {
            handleUploaded(createdFiles[0])
          }
        }

        if (failedMessages.length) {
          const alertString =
            'There was a problem uploading your files.\n' +
            failedMessages.join('\n')
          alert(alertString)
        }
      } catch (e) {
        alert(e.message)
      }

      this.setState({ submitting: false })
    }
  }

  render() {
    const {
      close,
      isOpen,
      title = 'Upload new image',
      enableCrop = false,
      enableRemove = false,
    } = this.props

    return (
      <Modal
        closeAction={close}
        isOpen={isOpen}
        btnColor="info"
        closeText="Cancel"
        confirmText={this.state.submitting ? 'Uploading...' : 'Upload & save'}
        disableConfirm={this.state.files.length < 1 || this.state.submitting}
        confirmAction={this._handleFormSubmit}
        enableRemove={enableRemove}
        onRemove={() => this.setState({ files: [] })}
        modalHeader={title}
        headerStyle={{ padding: '8px', paddingLeft: '16px' }}
        otherActions={
          enableRemove && this.state.files.length > 0 ? (
            <React.Fragment>
              <button
                className="yt-btn small link danger"
                style={{ marginRight: '5px', color: '#f67c65' }}
                onClick={() => this.setState({ files: [] })}
              >
                Clear
              </button>
            </React.Fragment>
          ) : null
        }
      >
        {enableCrop && this.state.files.length > 0 ? (
          <ImageCropper
            src={this.state.files[0]}
            onChange={cropped => this.setState({ cropped })}
          />
        ) : (
          <ImageInput
            change={this._handleFileChange}
            clickable={true}
            multiple={false}
          />
        )}
      </Modal>
    )
  }
}

NewImageModal.propTypes = {
  close: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleUploaded: PropTypes.func,
  isOpen: PropTypes.bool,
}

NewImageModal.defaultProps = {
  isOpen: false,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {}
}

export default withRouter(connect(mapStoreToProps)(NewImageModal))
