import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

export default function SettingsDropdown({
  archived,
  client,
  setDeleteModal,
  setStatus,
  handleFavoriteToggle,
  ownerPermissions,
}) {
  const [anchorElement, setAnchorElement] = useState(null)

  const handleSettingsMenuOpen = event => setAnchorElement(event.currentTarget)
  const handleSettingsMenuClose = () => setAnchorElement(null)

  const getMenuItems = () => {
    const getFavorite = () => (
      <MenuItem
        onClick={() => {
          handleFavoriteToggle(!client?.isFavorite, client?._id)
          handleSettingsMenuClose()
        }}
      >
        {client?.isFavorite ? 'Remove from Favorites' : 'Mark as Favorite'}
      </MenuItem>
    )

    if (!ownerPermissions) {
      return getFavorite()
    }

    return (
      <>
        {archived ? (
          <>
            <MenuItem
              onClick={() => {
                setDeleteModal({ isOpen: true, client })
                handleSettingsMenuClose()
              }}
            >
              Delete Client
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatus('visible', client)
                handleSettingsMenuClose()
              }}
            >
              Reinstate Client
            </MenuItem>
          </>
        ) : (
          <MenuItem
            onClick={() => {
              setStatus('archived', client)
              handleSettingsMenuClose()
            }}
          >
            Archive Client
          </MenuItem>
        )}
        {getFavorite()}
      </>
    )
  }

  return (
    <>
      <Tooltip title="Settings">
        <IconButton onClick={handleSettingsMenuOpen}>
          <SettingsIcon color="disabled" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleSettingsMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {getMenuItems()}
      </Menu>
    </>
  )
}
