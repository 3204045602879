/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { history, withRouter } from 'react-router-dom'

// import third-party libraries
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as fileActions from '../../file/fileActions'
import * as clientActions from '../../client/clientActions'
// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'
import validationUtils from '../../../global/utils/validationUtils'
import routeUtils from '../../../global/utils/routeUtils'
import brandingName from '../../../global/enum/brandingName.js.jsx'

// import file components

class SelectFolderList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      filteredFolders: [],
      allFolders: [],
      workspaces: [],
      rootFolders: [],
      selectedClient: {},
      selectedFolder: null,
      newFolders: {},
      submitting: false,
      listArgs: {
        '~firm': props.match.params.firmId,
        category: 'folder',
        status: 'not-archived',
      },
      isOpenFolder: false,
    }

    this._bind(
      '_handleSelectChange',
      '_handleSubmit',
      '_handleCreate',
      '_handleCancel',
      '_handleFormSubmit'
    )
  }

  componentDidMount() {
    const { dispatch, match, selectedUserId, selectedClientId } = this.props

    const firmId = match.params.firmId

    const clientId = match.params.clientId
      ? match.params.clientId
      : selectedClientId
      ? selectedClientId
      : null

    const userId = match.params.userId
      ? match.params.userId
      : selectedUserId
      ? selectedUserId
      : null

    if (clientId) {
      dispatch(
        clientActions.fetchListIfNeeded('_firm', firmId, '_id', clientId)
      ).then(clients => {
        if (clients && clients.list && clients.list.length > 0) {
          this.setState({ selectedClient: clients.list[0] })
        }
      })
    }

    const listArgs = _cloneDeep(this.state.listArgs)
    if (clientId) {
      listArgs._client = clientId
    }

    this.setState({ listArgs })

    dispatch(
      fileActions.fetchListIfNeeded(...routeUtils.listArgsFromObject(listArgs))
    ).then(folderRes => {
      if (folderRes && folderRes.list && folderRes.list.length > 0) {
        const allFolders = folderRes.list
        const filteredFolders = allFolders.filter(f => {
          if (clientId) {
            return f.status == 'visible' && f._client == clientId
          } else if (userId && userId == 'general') {
            return f.status == 'visible' && !f._client && !f._personal
          } else if (userId && userId != 'general') {
            return f.status == 'visible' && !f._client && f._personal == userId
          } else if (!userId && !clientId) {
            return f.status == 'visible' && !f._client && !f._personal
          } else {
            return false
          }
        })

        const rootFolders = filteredFolders.filter(f => {
          return !f._folder
        })

        this.setState({
          allFolders,
          filteredFolders,
          rootFolders,
        })
      }
    })
  }

  _handleSelectChange(folder) {
    this.setState({ selectedFolder: folder })
  }

  _handleSubmit(e) {
    e.preventDefault()

    const { handleSelectFolder } = this.props

    const { allFolders } = this.state

    const selectedFolder = _cloneDeep(this.state.selectedFolder)

    const selectedFolderObj = allFolders.filter(
      f => selectedFolder && f._id === selectedFolder._id
    )[0]
    if (handleSelectFolder) {
      handleSelectFolder(selectedFolderObj)
    }
  }

  _handleCreate(folder) {
    if (!folder) {
      const { match, selectedUserId, selectedClientId } = this.props
      const { selectedClient } = this.state
      const firmId = match.params.firmId
      const clientId = match.params.clientId
        ? match.params.clientId
        : selectedClientId
        ? selectedClientId
        : null
      const userId = match.params.userId
        ? match.params.userId
        : selectedUserId
        ? selectedUserId
        : null

      folder = {
        _id: null,
        _client: clientId,
        _folder: null,
        _firm: firmId,
        _personal: userId,
        category: 'folder',
        status: 'visible',
        wasAccessed: false,
        mangoClientID:
          selectedClient && selectedClient.mangoClientID
            ? selectedClient.mangoClientID
            : null,
        mangoCompanyID:
          selectedClient && selectedClient.mangoCompanyID
            ? selectedClient.mangoCompanyID
            : null,
      }
    }

    const newFolder = _cloneDeep(folder)
    newFolder._id = 'temporaryId-' + new Date().getTime()
    newFolder._folder = folder._id
    newFolder.filename = ''
    newFolder.isFilenameValid = true

    if (folder._id) {
      const filteredFolders = _cloneDeep(this.state.filteredFolders)
      filteredFolders.unshift(newFolder)
      this.setState({ filteredFolders })
    } else {
      const rootFolders = _cloneDeep(this.state.rootFolders)
      rootFolders.unshift(newFolder)
      this.setState({ rootFolders })
    }
  }

  _handleCancel(folder) {
    console.log(folder)
    const filteredFolders = _cloneDeep(this.state.filteredFolders)
    const rootFolders = _cloneDeep(this.state.rootFolders)
    const newFilteredFolders = filteredFolders.filter(
      item => item._id != folder._id
    )
    if (folder._folder) {
      this.setState({ filteredFolders: newFilteredFolders })
    } else {
      const newFolderListItems = rootFolders.filter(
        item => item._id != folder._id
      )
      this.setState({
        rootFolders: newFolderListItems,
        filteredFolders: newFilteredFolders,
      })
    }
  }

  _handleFormSubmit(folder) {
    const listArgs = _cloneDeep(this.state.listArgs)

    const { dispatch, loggedInUser, match } = this.props

    const firmId = match.params.firmId
    this.setState({ submitting: true })

    const sendData = {
      _user: loggedInUser._id,
      _client: folder._client,
      _folder: folder._folder,
      _firm: folder._firm || firmId,
      _personal: folder._personal,
      category: 'folder',
      filename: folder.filename,
      status: 'visible',
      wasAccessed: true,
      mangoClientID: folder.mangoClientID,
      mangoCompanyID: folder.mangoCompanyID,
      ParentID: folder.ParentID,
      YellowParentID: folder.YellowParentID,
    }

    if (sendData && sendData._personal === 'general') {
      delete sendData._personal
    }

    dispatch(fileActions.sendCreateFolder(sendData)).then(folderRes => {
      if (folderRes.success && folderRes.files && folderRes.files.length) {
        const file = folderRes.files[0]
        const allFolders = _cloneDeep(this.state.allFolders)
        allFolders.push(file)
        dispatch(fileActions.addSingleFileToMap(file))
        dispatch(
          fileActions.addFilesToList(
            [file._id],
            ...routeUtils.listArgsFromObject(listArgs)
          )
        )

        if (file._folder) {
          const filteredFolders = _cloneDeep(this.state.filteredFolders)
          let newFolderListItems = filteredFolders
          newFolderListItems = newFolderListItems.map(item => {
            return item._id === folder._id ? file : item
          })
          this.setState(
            {
              submitting: false,
              allFolders,
              filteredFolders: newFolderListItems,
            },
            () => {
              this._handleSelectChange(file)
            }
          )
        } else {
          const rootFolders = _cloneDeep(this.state.rootFolders)
          let newFolderListItems = rootFolders
          newFolderListItems = newFolderListItems.map(item => {
            return item._id === folder._id ? file : item
          })
          this.setState(
            { submitting: false, allFolders, rootFolders: newFolderListItems },
            () => {
              this._handleSelectChange(file)
            }
          )
        }
      } else {
        alert('ERROR - Check logs')
        this.setState({ submitting: false })
      }
    })
  }

  _renderFolders() {
    const {
      selectedFolder,
      selectedClient,
      newFolders,
      submitting,
      rootFolders,
      filteredFolders,
      isOpenFolder,
    } = _cloneDeep(this.state)

    return (
      <div
        className="folder-tree"
        style={{
          border: '1px solid rgba(0,0,0,.15)',
          marginBottom: '16px',
          padding: '16px',
        }}
      >
        {selectedClient._id ? (
          <div>{`${selectedClient.name} Folders`}</div>
        ) : null}
        <button
          className="yt-btn x-small info"
          onClick={this._handleCreate.bind(this, null)}
        >
          New Folder
        </button>
        <button
          className="yt-btn x-small info"
          style={{ margin: '0 10px' }}
          onClick={() => this.setState({ isOpenFolder: true })}
        >
          Show All Subfolders
        </button>
        <button
          className="yt-btn x-small info"
          onClick={() => this.setState({ isOpenFolder: false })}
        >
          Hide All Subfolders
        </button>
        <hr />
        <span style={{ fontSize: '0.8em' }}>
          <em>
            Subfolders can be hidden/shown by clicking on the folder icon.
          </em>
        </span>
        {rootFolders && rootFolders.length > 0 ? (
          <ul>
            {rootFolders.map((folder, index) => (
              <FolderItem
                index={index}
                folder={folder}
                key={folder._id}
                isOpenFolder={isOpenFolder}
                isOpenAllFolder={isOpenFolder}
                selectedFolder={selectedFolder}
                newFolders={newFolders}
                filteredFolders={filteredFolders}
                handleCancel={this._handleCancel}
                handleFormSubmit={this._handleFormSubmit}
                handleSelectChange={this._handleSelectChange}
                handleCreate={this._handleCreate}
              />
            ))}
          </ul>
        ) : (
          'No Folders Present'
        )}
      </div>
    )
  }

  // _handleClickFolder(id) {
  //   const updatedOpenFolders = _cloneDeep(this.state.isOpenFolder);
  //   const itemIndex = updatedOpenFolders.indexOf(id);

  //   if (itemIndex > -1) {
  //     updatedOpenFolders.splice(itemIndex, 1);
  //   } else {
  //     updatedOpenFolders.push(id);
  //   }
  //   this.setState({ isOpenFolder: updatedOpenFolders  })
  // }

  render() {
    const { fileStore, match, hideHeader } = this.props

    const selectedFolder = _cloneDeep(this.state.selectedFolder)
    const listArgs = _cloneDeep(this.state.listArgs)
    const fileStoreInfo = fileStore.util.getSelectedStore(
      ...routeUtils.listArgsFromObject(listArgs)
    )

    const isEmpty = !fileStoreInfo || fileStoreInfo.isFetching

    const isFetching = !fileStoreInfo || fileStoreInfo.isFetching

    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <div className="-select-folder">
          <div className="card">
            {hideHeader ? null : (
              <div className="card-header">
                <div className="yt-row center-vert space-between">
                  Select Folder
                </div>
              </div>
            )}
            <div className="card-body">
              {isEmpty ? (
                isFetching ? (
                  <div className="-loading-hero hero">
                    <div className="u-centerText">
                      <div className="loading"></div>
                    </div>
                  </div>
                ) : (
                  <h2>Empty.</h2>
                )
              ) : (
                this._renderFolders()
              )}
            </div>
            <div className="card-footer">
              <div className="yt-row space-between">
                <button style={{ visibility: 'hidden' }}></button>
                <button
                  type="button"
                  className="yt-btn small info"
                  disabled={!selectedFolder}
                  onClick={this._handleSubmit}
                >
                  Select Folder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SelectFolderList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSelectFolder: PropTypes.func,
}

SelectFolderList.defaultProps = {
  hideHeader: false,
}

const mapStoreToProps = (store, props) => {
  const loggedInUser = store.user.loggedIn.user

  return {
    loggedInUser,
    fileStore: store.file,
  }
}

export default withRouter(connect(mapStoreToProps)(SelectFolderList))

class FolderItem extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      folder: props.folder,
      isOpenFolder: props.isOpenFolder,
      submitting: false,
    }

    this._bind('_handleFormChange')
  }

  componentWillReceiveProps(nextProps) {
    let { folder, isOpenFolder } = _cloneDeep(this.state)
    if (nextProps && nextProps.folder && nextProps.folder._id !== folder._id) {
      folder = nextProps.folder
    }
    if (this.props.isOpenFolder !== nextProps.isOpenFolder) {
      isOpenFolder = nextProps.isOpenFolder
    }
    if (this.props.isOpenAllFolder !== nextProps.isOpenAllFolder) {
      isOpenFolder = nextProps.isOpenAllFolder
    }
    this.setState({ folder, isOpenFolder, submitting: false })
  }

  _handleFormChange(e) {
    const value = e.target.value
    const folder = _cloneDeep(this.state.folder)
    const newValue = value ? value.replaceAll(/(\r\n|\n|\r)/gm, '') : value
    folder.filename = newValue
    if (!validationUtils.checkFilenameIsValid(newValue)) {
      folder.isFilenameValid = false
    } else {
      folder.isFilenameValid = true
    }
    this.setState({ folder })
  }

  render() {
    const { folder, isOpenFolder, submitting } = _cloneDeep(this.state)

    const {
      handleSelectChange,
      handleCreate,
      handleCancel,
      handleFormSubmit,
      index,
      filteredFolders,
      selectedFolder,
      isOpenAllFolder,
    } = _cloneDeep(this.props)

    return (
      <li
        key={folder._id}
        className={isOpenFolder ? 'open' : ''}
      >
        {folder._id && folder._id.toString().indexOf('temporaryId') === -1 ? (
          <div
            style={{
              marginBottom: '6px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type="radio"
              name={`${folder.filename}${index}`}
              value={folder._id}
              onChange={() => handleSelectChange(folder)}
              checked={selectedFolder && folder._id === selectedFolder._id}
            />
            <div style={{ display: 'flex', cursor: 'pointer' }}>
              <span
                className="-icon"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 10px',
                }}
              >
                <img
                  src={
                    isOpenFolder
                      ? brandingName.image['opened-folder']
                      : brandingName.image['closed-folder']
                  }
                  style={{
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.setState({ isOpenFolder: !isOpenFolder })}
                />
              </span>
              <span
                htmlFor={`${folder.filename}${index}`}
                className="display"
                style={{ marginTop: '6px' }}
              >
                {folder.filename}
              </span>
              <span style={{ marginTop: '5px' }}>
                <i
                  className="fas fa-plus"
                  onClick={() => handleCreate(folder)}
                ></i>
              </span>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginBottom: '6px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="-addnew-folder">
              <div className="yt-row center-vert">
                <div className="-pB_10">
                  <TextInput
                    change={this._handleFormChange}
                    name={folder._id}
                    value={folder && folder.filename}
                    autoFocus={true}
                    onSubmit={() =>
                      this.setState({ submitting: true }, () =>
                        handleFormSubmit(folder)
                      )
                    }
                  />
                </div>
                <div className="center-vert">
                  <button
                    className="yt-btn x-small link"
                    onClick={() => handleCancel(folder)}
                  >
                    cancel
                  </button>
                  <button
                    className="yt-btn x-small success"
                    onClick={() =>
                      this.setState({ submitting: true }, () =>
                        handleFormSubmit(folder)
                      )
                    }
                    disabled={
                      submitting ||
                      !folder.filename ||
                      !folder.isFilenameValid ||
                      !(folder.filename && folder.filename.trim())
                    }
                  >
                    save
                  </button>
                </div>
              </div>
              {folder.isFilenameValid ? (
                ''
              ) : (
                <small className="help-text">
                  <em>
                    A filename can't contain any of the following characters: /
                    : * ? " &lt; &gt; |
                  </em>
                </small>
              )}
            </div>
          </div>
        )}
        <FolderList
          parentFolder={folder}
          selectedFolder={selectedFolder}
          filteredFolders={filteredFolders}
          handleCancel={handleCancel}
          handleFormSubmit={handleFormSubmit}
          handleSelectChange={handleSelectChange}
          handleCreate={handleCreate}
          isOpenFolder={isOpenFolder}
          isOpenAllFolder={isOpenAllFolder}
        />
      </li>
    )
  }
}

class FolderList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      parentFolder: props.parentFolder,
    }
  }

  render() {
    const { parentFolder } = _cloneDeep(this.state)
    const filteredFolders = _cloneDeep(this.props.filteredFolders)
    const childFolders = filteredFolders.filter(
      item => item._folder == parentFolder._id
    )

    return childFolders && childFolders.length ? (
      <div className="folder-child-tree">
        <ul>
          {childFolders.map((folder, index) => (
            <FolderItem
              index={index}
              folder={folder}
              key={folder._id}
              filteredFolders={filteredFolders}
              selectedFolder={this.props.selectedFolder}
              handleCancel={this.props.handleCancel}
              handleFormSubmit={this.props.handleFormSubmit}
              handleSelectChange={this.props.handleSelectChange}
              handleCreate={this.props.handleCreate}
              isOpenFolder={this.props.isOpenFolder}
              isOpenAllFolder={this.props.isOpenAllFolder}
            />
          ))}
        </ul>
      </div>
    ) : null
  }
}
