import {
  createTheme,
  alpha,
  darken,
  lighten,
  outlinedInputClasses,
  inputClasses,
  buttonClasses,
  listItemClasses,
  listItemIconClasses,
  paperClasses,
  listClasses,
  switchClasses,
  typographyClasses,
  iconButtonClasses,
} from '@mui/material'

const menuCommonStyle = {
  borderRadius: '10px',
  boxShadow:
    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
}

const themeConfig = {
  spacing: 10,
  typography: {
    fontFamily:
      '"-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Fira Sans","Helvetica Neue","Arial",sans-serif',
    fontSize: 14,
    subtitle1: {
      fontSize: '12px',
      lineHeight: 1.75,
      fontWeight: 400,
      letterSpacing: '0.00938em',
    },
    h4: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: '0.00735em',
    },
    h6: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '1.46429em',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '1.46429em',
    },
    button: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: 0,
      textTransform: 'capitalize',
    },
    link: {
      fontFamily:
        '"-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Fira Sans","Helvetica Neue","Arial",sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '1.46429em',
      color: '#0DA79D',
      cursor: 'pointer',
    },
    tag: {
      height: 24,
      maxHeight: 24,
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0 10px',
      fontSize: '12px',
      fontWeight: 600,
      borderRadius: '12px',
    },
  },
  color: {
    primary: {
      main: '#0DA79D',
    },
    secondary: {
      main: '#F5684D',
    },
    action: {
      active: '#4EBAC5',
      disabled: '#AAAAAA',
      disabledBackground: lighten('#AAAAAA', 0.85),
      focus: '#22D0B8',
      focusOpacity: 0.4,
    },
    basic: {
      main: '#383838',
    },
  },
  shadows: [],
}

const getThemeZIndex = ({ baseZIndex = 1000 }) => {
  return {
    mobileStepper: baseZIndex,
    speedDial: baseZIndex + 50,
    appBar: baseZIndex + 100,
    drawer: baseZIndex + 200,
    modal: baseZIndex + 300,
    snackbar: baseZIndex + 400,
    tooltip: baseZIndex + 500,
  }
}

const getTheme = ({ baseZIndex = 1000 }) => {
  const theme = createTheme({
    spacing: themeConfig.spacing,
    palette: {
      mode: 'light',
      ...themeConfig.color,
      error: {
        main: '#ff4949',
        contrastText: '#fff',
      },
      grey: {
        50: '#ffffff', // used
        100: '#f7f7f7', // used
        200: '#f2f2f2', // used
        300: '#ddd', // used
        400: '#E4E4E4', // used
        500: '#aaaaaa', // used
        600: '#777777', // used
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
    },
    typography: {
      ...themeConfig.typography,
    },
    zIndex: {
      ...getThemeZIndex({ baseZIndex }),
    },
  })

  return createTheme({
    ...theme,
    components: {
      MuiTooltip: {
        defaultProps: {
          arrow: true,
          disableFocusListener: true,
        },
        styleOverrides: {
          tooltip: {
            fontWeight: 350,
            backgroundColor: '#000',
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
            padding: theme.spacing(0.5, 1),
          },
          arrow: {
            fontSize: 9,
            '&:before': {
              backgroundColor: '#000',
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            padding: theme.spacing(),
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            padding: theme.spacing(1),
            lineHeight: 'normal',
            borderRadius: '3px',
          },
          label: {
            fontSize: theme.typography.button.fontSize,
            lineHeight: theme.typography.button.lineHeight,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'block',
            whiteSpace: 'nowrap',
          },
          contained: {
            boxShadow: 'none',
            color: '#fff',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
            '&:focusVisible': {
              boxShadow: 'none',
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.grey[300],
              color: '#fff',
            },
          },
          startIcon: {
            marginLeft: 0,
            marginRight: theme.spacing(1),
          },
        },
        variants: [
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              color: '#ffffff',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.1),
              },
              '&:active': {
                backgroundColor: darken(theme.palette.primary.main, 0.1),
              },
            },
          },
        ],
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            [`& .${paperClasses.root}`]: {
              ...menuCommonStyle,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: theme.spacing(1),
          },
          list: {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginBottom: '1px',
            [`&:hover, &:hover.${listItemClasses.selected}`]: {
              backgroundColor: 'rgba(248, 248, 248, 1)',
            },
            [`& .${listItemIconClasses.root}`]: {
              minWidth: '30px',
            },
            [`&.${listItemClasses.selected}`]: {
              backgroundColor: 'rgba(34, 208, 184, 0.15)',
            },

            [`&.${listItemClasses.focusVisible}`]: {
              background: theme.palette.background.default,
              outline: `1px solid ${alpha(
                theme.palette.action.focus,
                theme.palette.action.focusOpacity,
              )}`,
            },

            [`&.${listItemClasses.selected}.${listItemClasses.focusVisible}`]: {
              backgroundColor: 'rgba(34, 208, 184, 0.05)',
            },
            ...theme.typography.button,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: theme.spacing(1),
            boxShadow: '0px 1px 1px #0000000D',
            lineHeight: 'normal',
            borderRadius: '3px',
          },
          icon: {
            top: 0,
            bottom: 0,
            right: theme.spacing(1),
            marginTop: 'auto',
            marginBottom: 'auto',
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#999',
            borderColor: theme.palette.grey[300],
            lineHeight: '18px',
            [`&.${inputClasses.focused}`]: {
              color: 'inherit',
              [`&.${outlinedInputClasses.focused}`]: {
                color: theme.palette.action.focus,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            borderRadius: '3px',
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.action.focus,
            },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: theme.palette.action.focus,
                borderWidth: '1px',
              },
          },
          notchedOutline: {
            borderColor: theme.palette.grey[300],
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          cell: {
            borderBottom: `1px solid #F1F1F1`,
            cursor: 'pointer',
            fontSize: '14px',
            color: '#383838',
            '&:focus-within': {
              outlineColor: alpha(
                theme.palette.action.focus,
                theme.palette.action.focusOpacity,
              ),
            },
          },
          columnHeader: {
            '&:focus-within': {
              outline: 'none',
            },
          },
          columnHeaderTitle: {
            fontWeight: 'bold',
            fontSize: '14px',
          },
          columnsPanel: {
            padding: '15px',
          },
          columnsPanelRow: {
            padding: '6px 10px',
            [` .${typographyClasses.root}`]: {
              color: '#383838',
              cursor: 'default',
            },
          },
          iconButtonContainer: {
            marginLeft: '1px',
            [`& .${iconButtonClasses.root}`]: {
              padding: theme.spacing(0.5),
            },
          },
          panelHeader: {
            padding: '15px',
          },
          panelFooter: {
            backgroundColor: '#F8F8F8',
            borderTop: '1px solid #E4E4E4',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            padding: '10px',
            [`& .${buttonClasses.root}`]: {
              color: '#383838',
              padding: '5px 10px',
              border: `1px solid ${theme.palette.grey[300]}`,
              backgroundColor: theme.palette.grey[50],
              '&:hover': {
                backgroundColor: lighten(theme.palette.grey[50], 0.1),
                borderColor: theme.palette.grey[500],
              },
              '&:active': {
                borderColor: theme.palette.grey[300],
                backgroundColor: theme.palette.grey[100],
              },
            },
          },
          panel: {
            [`& .${paperClasses.root}`]: {
              ...menuCommonStyle,
            },
          },
          menu: {
            ...menuCommonStyle,
            [`& .${paperClasses.root}`]: {
              borderRadius: '10px',
              boxShadow: 'none',
              [`& .${listClasses.root}`]: {
                padding: '15px 10px',
              },
            },
          },
          row: {
            backgroundColor: '#fff',
            '&:hover': {
              background: '#FAFAFA',
            },
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '10px',
            marginRight: '10px !important',
          },
          track: {
            backgroundColor: '#c8ccd4', //theme.palette.primary.dark,
            opacity: 1,
          },
          thumb: {
            boxShadow: 'none',
          },
          switchBase: {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            [`&.${switchClasses.checked}`]: {
              color: '#fff', // theme.palette.secondary.main,
            },
            [`&.${switchClasses.checked} + .${switchClasses.track}`]: {
              opacity: 1,
              backgroundColor: theme.palette.secondary.main,
            },
          },
          sizeSmall: {
            width: '28px',
            height: '16px',
            padding: 0,
            [`& .${switchClasses.thumb}`]: {
              cursor: 'pointer',
              position: 'relative',
              top: '1px',
              boxShadow: '0 2px 6px rgb(154 153 153 / 75%)',
              width: '10px',
              height: '10px',
            },
            [`& .${switchClasses.switchBase}`]: {
              padding: '2px',
              transform: 'translateX(2px)',
              [`&.${switchClasses.checked}`]: {
                transform: 'translateX(12px)',
              },
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            zIndex: 1,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            [`&-MuiMenu-paper-MuiPaper-root-MuiPopover-paper`]: {
              boxShadow: '12px 12px 2px 1px rgba(0, 0, 255, .2)',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: theme.spacing(1),
            height: 'auto',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '&[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
    },
  })
}

export default getTheme
