import React, { useState, useCallback, useEffect } from 'react'

import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import { Helmet } from 'react-helmet'

import * as userActions from '../userActions'
import brandingName from '../../../global/enum/brandingName.js.jsx'
import UISwitchChecker from '../../../global/practice/components/PracticeUISwitcher.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

const UserForce2fa = ({ dispatch, history, loggedInUser }) => {
	const [code, setCode] = useState('')

	const logout = useCallback(() => {
		dispatch(userActions.sendLogout()).then(() => {
			localStorage.clear()
			history.push('/')
		})
	}, [dispatch, history])

	const onCodeChange = useCallback(
		e => {
			setCode(e.target.value)
		},
		[setCode],
	)

	const handleSubmit = useCallback(() => {
		dispatch(userActions.sendUpdateProfile({
			...loggedInUser,
			enable_2fa: true,
			token: code
		})).then(() => {
			history.push('/user/forward');
		})
	}, [code])

	useEffect(() => {
		if (!loggedInUser.enable_2fa) {
			dispatch(
				userActions.sendUpdateProfile({
					...loggedInUser,
					action: 'generate_qrcode',
				}),
			)
		}
	}, [])

	return (
		<React.Fragment>
			<UISwitchChecker />
			<Helmet>
				<title>Enable Two Factor Authentication</title>
			</Helmet>
			<div className="flex">
				<section className="section">
					<div className="yt-container slim">
						<div className="yt-row center-horiz">
							<div className="yt-col _80">
								<div className="card bordered profile-card">
									<div className="card-header">
										<h3>Two Factor Authentication</h3>
									</div>
									<div className="card-body">
										<p>
											One or more firms require you to enable two factor
											authentication for your account
										</p>
										<div className="-profile-card-info">
											<div>
												<p>
													<strong>
														Scan the QR code in Google Authenticator
													</strong>
												</p>
												<div
													className="-info"
													style={{
														fontStyle: 'normal',
														display: 'flex',
														justifyContent: 'space-between',
													}}
												>
													<div
														style={{
															width: '50%',
														}}
													>
														<img
															src={loggedInUser._2fa_qrcode_data}
															alt=""
															style={{
																width: '80%',
															}}
														/>
													</div>
													<div
														style={{
															width: '50%',
															paddingTop: '30px',
														}}
													>
														<p>
															<strong>Secret Key</strong>
														</p>
														<p
															style={{
																fontWeight: 'bold',
																color: '#f5684d',
																wordBreak: 'break-word',
															}}
														>
															{loggedInUser._2fa_secret}
														</p>
														<br />
														<TextInput
															name="code"
															label="Security Code"
															value={code}
															change={onCodeChange}
															maxLength="6"
														/>
														<button
															onClick={handleSubmit}
															className="yt-btn info x-small"
															disabled={code.length < 6}
														>
															<i className="fal" />
															Enable TFA
														</button>
													</div>
												</div>
												<br />
												<br />
												<div
													className="-info"
													style={{
														fontStyle: 'normal',
													}}
												>
													<p>
														<strong>Setting Up Authenticator</strong>
													</p>
													<br />
													<p>
														Download and install any authenticator application
														(Google Authenticator) on your mobile device. You
														can find it in the App Store (for iOS) or Google
														Play (for Android).
													</p>
													<br />
													<p>
														<strong>1. </strong>Open the mobile application then
														click the【+】symbol (
														<strong>Google Authenticator</strong>).{' '}
													</p>
													<p>
														<strong>2. </strong>Scan the QR Code on the left
														side of the screen using your authenticator
														application.{' '}
													</p>
													<p>
														<strong>3. </strong>Enter the 6 digit numbers from
														your authenticator application into the Security
														code text box ({brandingName.title}).{' '}
													</p>
													<p>
														<strong>4. </strong>Click the 'Enable TFA'.
													</p>
													<p>
														<strong>Note: </strong>Save the Secret Key somewhere
														safe which can be used if you have lost your access
														to your authenticator.
													</p>
													<br />
													<p>
														Everytime you login, {brandingName.title}{' '}
														application will ask for the code that will be
														generated on your authenticator.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<button
									className="yt-btn small link "
									onClick={logout}
								>
									Log out
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	)
}

export default withRouter(
	connect(store => ({
		loggedInUser: store.user.loggedIn.user,
	}))(UserForce2fa),
)
