import React from 'react';

const StarCheckbox = ({ name, checked, disabled, onChange }) => {
  return (
    <label>
      <input
        type="checkbox"
        className='starCheckbox'
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      /> 
      <span className={disabled ? 'checkboxDisabled' : ''}></span>
    </label>
  );
};

export default StarCheckbox;