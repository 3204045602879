/**
 * Reusable stateless form component for User by admins
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'

// import form components
import EmailInput from '../../../../global/components/forms/EmailInput.js.jsx'
import PasswordInput from '../../../../global/components/forms/PasswordInput.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import brandingName from '../../../../global/enum/brandingName.js.jsx'
import ReactTooltip from 'react-tooltip'

const AdminUserForm = ({
  user,
  formType,
  handleDeactivateUser,
  handleFormSubmit,
  handleFormChange,
  cancelLink,
  formTitle,
}) => {
  // set the button text
  const buttonText = formType === 'create' ? 'Create User' : 'Update User'

  // set the form header
  const header = formTitle ? (
    <div className="formHeader">
      <h2> {formTitle} </h2>
      <hr />
    </div>
  ) : (
    <div />
  )

  const submitDisabled =
    !user ||
    !user.username ||
    !user.username.trim() ||
    !user.password ||
    !user.password.trim() ||
    !user.firstname ||
    !user.firstname.trim() ||
    !user.lastname ||
    !user.lastname.trim() ||
    user.emailError ||
    user.firstnameError ||
    user.lastnameError

  const submitUpdateDisabled =
    !user ||
    !user.username ||
    !user.username.trim() ||
    !user.firstname ||
    !user.firstname.trim() ||
    !user.lastname ||
    !user.lastname.trim() ||
    user.emailError ||
    user.firstnameError ||
    user.lastnameError

  return (
    <div className="yt-container">
      <div className="yt-row center-horiz">
        <div className="form-container -slim">
          <form
            name="userForm"
            className="user-form"
            onSubmit={handleFormSubmit}
          >
            {header}
            <EmailInput
              name="user.username"
              label="Email Address"
              value={user.username}
              change={handleFormChange}
              placeholder="Email (required)"
              required={true}
              error={user.emailError}
              errorName="user.emailError"
            />
            {formType === 'create' ? (
              <PasswordInput
                name="user.password"
                label="Password"
                value={user.password}
                change={handleFormChange}
                required={true}
                password={true}
              />
            ) : null}
            <TextInput
              name="user.firstname"
              label="First Name"
              value={user.firstname}
              change={handleFormChange}
              required={true}
              errorName="user.firstnameError"
              error={user.firstnameError}
              validationRegex={/^[a-zA-Z0-9 ]+$/}
            />
            <TextInput
              name="user.lastname"
              label="Last Name"
              value={user.lastname}
              change={handleFormChange}
              required={true}
              errorName="user.lastnameError"
              error={user.lastnameError}
              validationRegex={/^[a-zA-Z0-9 ]+$/}
            />
            {/* For testing, until we can update users we'll have to on-board them at creation. */}
            <CheckboxInput
              name="user.onBoarded"
              label="On-boarded"
              value={user.onBoarded}
              change={handleFormChange}
              checked={user.onBoarded}
            />
            <CheckboxInput
              name="user.admin"
              label={`${brandingName.title} Admin`}
              value={user.admin}
              change={handleFormChange}
              checked={user.admin}
            />
            <CheckboxInput
              name="user.enable_2fa"
              label={'Two-Factor Authentication'}
              value={user.enable_2fa}
              change={handleFormChange}
              checked={user.enable_2fa}
            />
            <div className="yt-row">
              <div
                data-tip
                data-for="UserStatus"
              >
                <b>User is {user.isActive ? 'Active' : 'Inactive'}</b>
              </div>
              <ReactTooltip
                id="UserStatus"
                place="right"
                type={user.isActive ? 'success' : 'warning'}
                effect="solid"
              >
                <span className="tooltipMessage">
                  {user.isActive
                    ? 'User is Active(is Admin or has an assigned Staff or Contact account)'
                    : 'User is Inactive(is not an Admin and has no assigned Staff or Contact account)'}
                </span>
              </ReactTooltip>
            </div>
            <div className="input-group">
              <div className="yt-row space-between">
                {formType === 'update' ? (
                  <button
                    className="yt-btn link danger"
                    type="button"
                    onClick={handleDeactivateUser}
                    disabled={!user.isActive}
                  >
                    {' '}
                    Deactivate User{' '}
                  </button>
                ) : null}
                <button
                  className="yt-btn"
                  disabled={
                    formType === 'create'
                      ? submitDisabled
                      : submitUpdateDisabled
                  }
                  type="submit"
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

AdminUserForm.propTypes = {
  cancelLink: PropTypes.string.isRequired,
  formTitle: PropTypes.string,
  formType: PropTypes.string.isRequired,
  handleDeactivateUser: PropTypes.func,
  handleFormSubmit: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

AdminUserForm.defaultProps = {
  formTitle: '',
  handleDeactivateUser: null,
}

export default AdminUserForm
