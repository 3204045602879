import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { io } from 'socket.io-client'

const useSocket = ({ loggedInUser, autoConnect }) => {
  const socket = useMemo(() => {
    const socket = io({
      transports: ['websocket', 'polling'],
      autoConnect: false,
    })

    const onConnect = () => {
      if (!(loggedInUser && loggedInUser._id)) return
      socket.emit('subscribe', loggedInUser._id)
    }

    const onConnectError = () => {
      socket.io.opts.transports = ['polling', 'websocket']
    }

    socket.on('connect', onConnect)
    socket.on('connect_error', onConnectError)

    socket.connectAsPromise = () =>
      new Promise((resolve, reject) => {
        if (socket.connected) {
          return resolve(true)
        }

        const onConnect = () => {
          socketOff()
          resolve(true)
        }
        const onConnectErrror = () => {
          socketOff()
          reject()
        }
        const socketOff = () => {
          socket.off('connect', onConnect)
          socket.off('connect_error', onConnectErrror)
        }
        const socketOn = () => {
          socket.once('connect', onConnect)
          socket.once('connect_error', onConnectErrror)
        }

        socketOn()
        socket.connect()
      })

    return socket
  }, [loggedInUser])

  useEffect(() => {
    autoConnect && socket.connect()

    return () => {
      socket.off()
      socket.disconnect()
    }
  }, [])

  return socket
}

const withSocket = (WrappedComponent, autoConnect = true) => {
  const WithSocket = props => {
    const { loggedInUser } = props
    const socket = useSocket({ loggedInUser, autoConnect })

    return (
      <WrappedComponent
        {...props}
        socket={socket}
      />
    )
  }

  const mapStateToProps = state => ({
    loggedInUser: state.user.loggedIn.user,
  })

  return connect(mapStateToProps)(WithSocket)
}

export default withSocket

