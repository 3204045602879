// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'

// import utilities
import filterUtils from '../../../../global/utils/filterUtils'

// import resource components
import PracticeFolderTemplateTableListItem from './PracticeFolderTemplateTableListItem.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import NumberInput from '../../../../global/components/forms/NumberInput.js.jsx'

// actions
import * as folderTemplateActions from '../../folderTemplateActions.js'

class PracticefolderTemplateList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      viewingAs: this.props.viewingAs,
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        description: { disableSearch: true, searchText: '' },
        activeFolder: { disableSearch: true, searchText: '' },
        createdBy: { disableSearch: true, searchText: '' },
        lastUpdated: { disableSearch: true, searchText: '' },
      },
    }
    this._bind('_toggleHeaderSearch', '_changeHeaderSearch')
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { folderTemplateStore, dispatch } = this.props
    const { filterHeaders } = folderTemplateStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(folderTemplateActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { folderTemplateStore, dispatch } = this.props
    const { filterHeaders } = folderTemplateStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(folderTemplateActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  render() {
    const {
      handleSetPagination,
      paginatedList,
      sortedAndFilteredList,
      folderTemplateListItems,
      folderTemplateList,
      userMap,
      match,
      setPagination,
      dispatch,
      handleQuery,
    } = this.props

    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const isFiltered =
      folderTemplateList &&
      folderTemplateList.query &&
      folderTemplateList.query.length > 0

    return (
      <div className="yt-table table firm-table -workspace-table truncate-cells -yt-edit-table">
        <div className="table-caption">
          <PageTabber
            totalItems={folderTemplateList.items.length}
            totalPages={Math.ceil(
              folderTemplateList.items.length /
                folderTemplateList.pagination.per,
            )}
            pagination={folderTemplateList.pagination}
            setPagination={setPagination}
            setPerPage={this.props.setPerPage}
            viewingAs="top"
            itemName="templates"
            searchText="Search..."
            enableSearch={true}
            handleQuery={handleQuery}
          />
        </div>
        <div className="-table-horizontal-scrolling">
          <div className="table-head">
            <div className="table-cell"></div>
            <div className="table-cell -folder-title _30">
              <div className="-table-header-title">Name</div>
              <div className="-table-header-search">
                <CheckboxInput
                  name="name"
                  value={!searchHeaderColumns.name.disableSearch}
                  checked={!searchHeaderColumns.name.disableSearch}
                  change={this._toggleHeaderSearch}
                />
                <TextInput
                  blur={() => console.log('blur')}
                  change={this._changeHeaderSearch}
                  name="name"
                  value={searchHeaderColumns.name.searchText}
                  disabled={searchHeaderColumns.name.disableSearch}
                  placeholder="Search name"
                />
              </div>
            </div>
            <div className="table-cell _30">
              <div className="-table-header-title">Description</div>
              <div className="-table-header-search">
                <CheckboxInput
                  name="description"
                  value={!searchHeaderColumns.description.disableSearch}
                  checked={!searchHeaderColumns.description.disableSearch}
                  change={this._toggleHeaderSearch}
                />
                <TextInput
                  blur={() => console.log('blur')}
                  change={this._changeHeaderSearch}
                  name="description"
                  value={searchHeaderColumns.description.searchText}
                  disabled={searchHeaderColumns.description.disableSearch}
                  placeholder="Search description"
                />
              </div>
            </div>
            <div className="table-cell">
              <div className="-table-header-title">Active Folders</div>
              <div className="-table-header-search">
                <CheckboxInput
                  name="activeFolder"
                  value={!searchHeaderColumns.activeFolder.disableSearch}
                  checked={!searchHeaderColumns.activeFolder.disableSearch}
                  change={this._toggleHeaderSearch}
                />
                <NumberInput
                  change={this._changeHeaderSearch}
                  firefoxDisplayFix={true}
                  min="0"
                  name="activeFolder"
                  required={false}
                  step="1"
                  value={searchHeaderColumns.activeFolder.searchText}
                  disabled={searchHeaderColumns.activeFolder.disableSearch}
                  placeholder="Search active folders"
                />
              </div>
            </div>
            <div className="table-cell _20">
              <div className="-table-header-title">Created By</div>
              <div className="-table-header-search">
                <CheckboxInput
                  name="createdBy"
                  value={!searchHeaderColumns.createdBy.disableSearch}
                  checked={!searchHeaderColumns.createdBy.disableSearch}
                  change={this._toggleHeaderSearch}
                />
                <TextInput
                  blur={() => console.log('blur')}
                  change={this._changeHeaderSearch}
                  name="createdBy"
                  value={searchHeaderColumns.createdBy.searchText}
                  disabled={searchHeaderColumns.createdBy.disableSearch}
                  placeholder="Search created by"
                />
              </div>
            </div>
            <div className="table-cell -date _20">
              <div className="-table-header-title">Last Updated</div>
              <div className="-table-header-search">
                <CheckboxInput
                  name="lastUpdated"
                  value={!searchHeaderColumns.lastUpdated.disableSearch}
                  checked={!searchHeaderColumns.lastUpdated.disableSearch}
                  change={this._toggleHeaderSearch}
                />
                <TextInput
                  blur={() => console.log('blur')}
                  change={this._changeHeaderSearch}
                  name="lastUpdated"
                  value={searchHeaderColumns.lastUpdated.searchText}
                  disabled={searchHeaderColumns.lastUpdated.disableSearch}
                  placeholder="Search last updated"
                />
              </div>
            </div>
          </div>
          {paginatedList && paginatedList.length ? (
            paginatedList.map((folderTemplate, i) => (
              <PracticeFolderTemplateTableListItem
                key={i}
                folderTemplate={folderTemplate}
                userMap={userMap}
                match={match}
                dispatch={dispatch}
              />
            ))
          ) : (
            <div className="table-head empty-state">
              <div
                className="table-cell"
                colSpan="6"
              >
                <em>No files</em>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

PracticefolderTemplateList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleFilter: PropTypes.func,
  handleQuery: PropTypes.func,
  paginatedList: PropTypes.array.isRequired,
  sortedAndFilteredList: PropTypes.array,
  folderTemplateList: PropTypes.object,
  viewingAs: PropTypes.string,
}

PracticefolderTemplateList.defaultProps = {
  allFolderTemplates: null,
  handleFilter: null,
  handleQuery: null,
  handleSort: null,
  sortedAndFilteredList: [],
  viewingAs: 'table',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  const { folderTemplateList, folderTemplateListItems } = props
  const folderTemplateStore = store.folderTemplate
  let paginatedList = []
  let sortedAndFilteredList = []

  if (folderTemplateListItems) {
    const folderTemplateMap = store.folderTemplate.byId

    const query = folderTemplateList.query

    // FILTER BY QUERY
    let queryTestString = ('' + query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers

    folderTemplateListItems.forEach(item => {
      // praparing data --------------------------------------------------------------------------------------------------------------------------------------
      const subfolderCount =
        item && item.subfolder && item.subfolder.length
          ? item.subfolder.filter(folder => folder.status === 'visible')
          : []
      let createdByFullName = ''

      if (store.user.byId[item._createdBy]) {
        const user = store.user.byId[item._createdBy]
        createdByFullName = user.firstname || ''
        createdByFullName +=
          (createdByFullName ? ' ' : '') + user.lastname || ''
      }

      let showMe = true
      if (queryTestString && queryTestString.trim()) {
        showMe = item && filterUtils.filterTag(queryTestString, item)
      }

      if (
        showMe &&
        !!folderTemplateStore.filterHeaders &&
        !!folderTemplateStore.filterHeaders.name &&
        folderTemplateStore.filterHeaders.name.trim()
      ) {
        const name = folderTemplateStore.filterHeaders.name.toLowerCase()
        showMe = item.name && item.name.toLowerCase().indexOf(name) > -1
      }

      if (
        showMe &&
        !!folderTemplateStore.filterHeaders &&
        !!folderTemplateStore.filterHeaders.description &&
        folderTemplateStore.filterHeaders.description.trim()
      ) {
        const description =
          folderTemplateStore.filterHeaders.description.toLowerCase()
        showMe =
          item.description &&
          item.description.toLowerCase().indexOf(description) > -1
      }

      if (
        showMe &&
        !!folderTemplateStore.filterHeaders &&
        (!!folderTemplateStore.filterHeaders.activeFolder ||
          folderTemplateStore.filterHeaders.activeFolder === 0)
      ) {
        let activeFolder = folderTemplateStore.filterHeaders.activeFolder
        activeFolder = Number(activeFolder)
        if (activeFolder !== subfolderCount.length) showMe = false
      }

      if (
        showMe &&
        !!folderTemplateStore.filterHeaders &&
        !!folderTemplateStore.filterHeaders.createdBy &&
        folderTemplateStore.filterHeaders.createdBy.trim()
      ) {
        const createdBy =
          folderTemplateStore.filterHeaders.createdBy.toLowerCase()
        showMe =
          createdByFullName &&
          createdByFullName.toLowerCase().indexOf(createdBy) > -1
      }

      if (
        showMe &&
        !!folderTemplateStore.filterHeaders &&
        !!folderTemplateStore.filterHeaders.lastUpdated &&
        folderTemplateStore.filterHeaders.lastUpdated.trim()
      ) {
        const lastUpdated =
          folderTemplateStore.filterHeaders.lastUpdated.toLowerCase()
        const updatedAt =
          item.updated_at &&
          new Date(item.updated_at).toLocaleDateString('en-US')
        showMe = updatedAt && updatedAt.toLowerCase().indexOf(lastUpdated) > -1
      }

      if (showMe) sortedAndFilteredList.push(item)
    })

    if (sortedAndFilteredList) {
      sortedAndFilteredList = _.orderBy(
        sortedAndFilteredList,
        [item => item.updated_at],
        ['desc']
      )
    }

    const pagination = folderTemplateList.pagination || { page: 1, per: 50 }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(sortedAndFilteredList, start, end)
  }

  return {
    paginatedList: paginatedList,
    sortedAndFilteredList: sortedAndFilteredList,
    userMap: store.user.byId,
    folderTemplateStore,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticefolderTemplateList))
