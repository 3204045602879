const async = require('async');
import saveAs from 'save-as';
import fetch from 'isomorphic-fetch';
import fileUtils from './fileUtils';
import watermarkUtils from './watermarkUtils';
import apiUtils from './api';
import axios from 'axios'

const downloadsUtil =  {
    bulkZipped: async function(params) {
        const {
            selectedFileIds
            , files
            , filesMap
            , userLevel
            , shareLinkId
            , shareLink
            , uploadName
            , socket
            , loggedInUser
            , firm
            , uploadEmailAddress
            , uploadCompanyName
        } = params;

        let fileIds = [];
        let folders = []

        if (!(selectedFileIds && selectedFileIds.length && files && filesMap && userLevel)) {
            return
        }

        let socketConnected = false;
        if (socket)
            try {
                socketConnected = await socket.connectAsPromise();
            }
            catch (err) { }

        if (socketConnected) {
            socket.emit('start_progress', loggedInUser._id, 'In Progress');
        }

        selectedFileIds.map(id => {
            if (id && filesMap[id] && filesMap[id].category === "folder") {
                folders.push(filesMap[id]);
            } else if (id && filesMap[id] || shareLinkId) {
                fileIds.push(id);
            }
        });

        let zip = new JSZip();
        const zipName = 'Files Zipped.zip';
        const folderNames = [];
        let allFileIds = fileIds;
        console.log('start allFileIds', allFileIds)
        console.log('start folders', folders)
        console.log('start fileIds', fileIds)

        return new Promise((resolve, reject) => {
            async.mapSeries(folders, (folder, cb) => {
                let folderName = folder.filename;
                if (folderNames.includes(folderName)) {
                    let newFilename = `${folderName} (${folderNames.filter(fn => fn === folderName).length})`;
                    folderNames.push(folderName);
                    folderName = newFilename;
                } else {
                    folderNames.push(folderName);
                }
                folder.filename = folderName;
                downloadsUtil.zippedFolder(params, folder, zip, false, (resZip, fileIdsRes) => {
                    cb(null, resZip);
                });
            }, (err, resZipped) => {
                if (err && !resZipped) {
                    reject(err);
                } else {

                    console.log('end allFileIds', allFileIds)
                    if (resZipped && resZipped.length) {
                        resZipped = resZipped[0];
                    } else {
                        resZipped = zip;
                    }

                    // request too many fileId in one, getting max string limit
                    const fileNames = [];
                    let count = 0;
                    async.mapSeries(allFileIds, (fileId, cb) => {
                        const file = filesMap[fileId]
                        let progressPercent = (count / allFileIds.length) * 100;
                        if (socketConnected) {
                            progressPercent = parseInt(progressPercent);
                            count++;
                            socket.emit('progress_status', loggedInUser._id, progressPercent / 2);
                        }
                        if (shareLink && shareLink.watermark) {
                            downloadsUtil.singleWatermarkDownload(file, params).then((blob)=>{
                                if (socketConnected) {
                                    socket.emit('progress_status', loggedInUser._id, progressPercent);
                                }
                                if (blob) {
                                    let filename = file.filename.substr(0, file.filename.lastIndexOf("."));
                                    if (fileNames.includes(filename)) {
                                        let newFilename = `${filename} (${fileNames.filter(fn => fn === filename).length})`;
                                        fileNames.push(filename);
                                        filename = newFilename;
                                    } else {
                                        fileNames.push(filename);
                                    }
                                    resZipped.file(`${filename}${file.fileExtension}`, blob);
                                    cb();
                                } else if (filesMap[fileId]) {
                                    let link = fileUtils.getDownloadLink(filesMap[fileId]);
                                    if(link) {
                                        var a  = document.createElement("a");
                                        a.setAttribute('href', `${link}?userLevel=staffclient&type=downloaded&name=${uploadName}&uploadEmailAddress=${uploadEmailAddress}&uploadCompanyName=${uploadCompanyName}`); 
                                        a.setAttribute('download', '');
                                        a.setAttribute('target', '_blank');
                                        a.click();
                                    }
                                    cb();
                                } else {
                                    cb();
                                }
                            })
                        } else {
                            downloadsUtil.singleBase64String(fileId, firm).then(response => {
                                console.log(response);
                                if (socketConnected) {
                                    socket.emit('progress_status', loggedInUser._id, progressPercent);
                                }
                                if (response && response.success && response.file) {
                                    const file = response.file;
                                    let base64String = file.data;
                                    let filename = file.filename.substr(0, file.filename.lastIndexOf("."));
                                    if (fileNames.includes(filename)) {
                                        let newFilename = `${filename} (${fileNames.filter(fn => fn === filename).length})`;
                                        fileNames.push(filename);
                                        filename = newFilename;
                                    } else {
                                        fileNames.push(filename);
                                    }
                                    resZipped.file(`${filename}${file.fileExtension}`, base64String, { base64: true });
                                    cb();
                                } else if (filesMap[fileId]) {
                                    let link = fileUtils.getDownloadLink(filesMap[fileId]);
                                    if(link) {
                                        var a  = document.createElement("a");
                                        a.setAttribute('href', `${link}?userLevel=staffclient&type=downloaded&name=${uploadName}&uploadEmailAddress=${uploadEmailAddress}&uploadCompanyName=${uploadCompanyName}`); 
                                        a.setAttribute('download', '');
                                        a.setAttribute('target', '_blank');
                                        a.click();
                                    }
                                    cb();
                                } else {
                                    cb();
                                }
                            })
                        }
                    }, (err) => {
                        if (err) {
                            reject(err);
                        }
                        else {
                            resZipped.generateAsync({ type: 'blob' }).then(function (content) {
                                if (socketConnected) {
                                    socket.emit('progress_status', loggedInUser._id, 100);
                                }
                                saveAs(content, zipName);
                                downloadsUtil.downloadNotification({
                                    fileIds: allFileIds
                                    , shareLinkId
                                    , userLevel
                                    , uploadName
                                    , uploadEmailAddress
                                    , uploadCompanyName
                                })
                                    .then(response => {
                                        if (socketConnected) {
                                            socket.emit('finish_progress', loggedInUser._id, 'Download completed');
                                        }
                                        resolve(response);
                                    });
                            });
                        }
                    });
                }
            });
        })
            .finally(() => {
                if (socketConnected)
                    socket.disconnect();
            })
    }
    , blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]);
            }

            reader.onerror = reject;

            reader.readAsDataURL(blob);
        });
    }
    , singleBase64String(fileId, selectedFirm) { // get base64 string by file id 
        return apiUtils
          .callAPI(
            `/api/files/download-url/${fileId}?sessionToken=${selectedFirm && selectedFirm.apiKey}`,
          )
          .then(json => {
            if (!json.success) {
              throw new Error(json.message)
            }

            return fetch(json.download_url)
              .then(resp => resp.blob())
              .then(blob => downloadsUtil.blobToBase64(blob))
              .then(base64 => ({
                ...json,
                file: {
                  ...json.file,
                  data: base64,
                },
              }))
          })
          .catch(e => console.log('fetch base64 error: ', e))
    }
    , singleWatermarkDownload(file, params) {
        return new Promise(async resolve => {
          const {
            shareLink,
            uploadName,
            uploadEmailAddress,
            uploadCompanyName
          } = params
          const downloadLink = `/api/share-links/download/${shareLink.hex}/${file._id}/${encodeURIComponent(file.filename)}`
          let href = `${downloadLink}?userLevel=clientuser&type=downloaded&shareLinkId=${shareLink._id}&name=${uploadName}&sessionToken=${shareLink.firm && shareLink.firm.apiKey}&uploadEmailAddress=${uploadEmailAddress}&uploadCompanyName=${uploadCompanyName}`

          let existsOnBucket
          let uploadUrl //used to upload the generated watermarked file to bucket
          if (watermarkUtils.canWatermarkBeApplied(file, shareLink)) {
            const { success, url } = await apiUtils.callAPI(
              `/api/files/watermark-file/${shareLink._watermark}/${file._id}`,
              'GET',
            ) //returns the existing bucket link for download or the link to upload the generated watermarked file
            existsOnBucket = success
            if (success) {
              href = url
            } else {
              uploadUrl = url
            }
          }

          const xhr = new XMLHttpRequest()
          xhr.open('GET', href, true)
          xhr.responseType = 'blob'
          xhr.send()

          xhr.onload = async () => {
            if (xhr.status === 200) {
              let blob = xhr.response
              if (
                watermarkUtils.canWatermarkBeApplied(file, shareLink) &&
                !existsOnBucket
              ) {
                blob = await watermarkUtils.addWatermarkAndGenerateBlob(
                  blob,
                  file,
                  shareLink.watermark,
                )
                const fileToUpload = new File([blob], file.filename, {
                  type: file.contentType,
                })
                axios.put(uploadUrl, fileToUpload, {
                  headers: { 'Content-Type': file.contentType },
                })
              }
              resolve(blob)
            }
          }
          xhr.onerror = () => {
            console.error('Error downloading the file')
            xhr.abort()
            resolve()
          }
        })
    }
    , downloadNotification(data) {
        return fetch(`/api/view-download/download-notification`, {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
            , method:  'POST'
            , credentials: 'same-origin'
            , body: JSON.stringify(data)
        })
        .then(response => response.json());
    }
    , singleZipped(params, callback, progressCallback) {
        const {
            folder
            , files
            , shareLinkId
            , userLevel
            , uploadName
            , shareLink
        } = params;
        if (folder && files && files.length) {
            const zip = new JSZip();
            const zipName = `${folder.filename}.zip`;
            downloadsUtil.zippedFolder(params, folder, zip, true, (resZip, fileIds) => {
                if (resZip) {
                    resZip.generateAsync({ type: 'blob' }).then(function(content) {
                        saveAs(content, zipName);
                        // downloadsUtil.downloadNotification(data)
                        // .then(response => {
                        //     console.log("response download notification", response);
                        // });
                        downloadsUtil.downloadNotification({
                            fileIds
                            , shareLinkId
                            , userLevel
                            , uploadName
                        })
                        .then(response => {
                            console.log("response download notification", response);
                        });
                        callback();
                    });
                }
            },
            (percent)=>{
                progressCallback(percent)
            }
        );
        }
    }
    , zippedFolder(params, folder, zip, isRoot, callback, progressCallback) {
        const {
            files
            , userLevel
            , shareLinkId
            , uploadName
            , firm
            , shareLink
        } = params;
        let sendData = {
            folders: [folder]
            , downloadFiles: []
            , files
            , fileIds: []
            , root: isRoot
        }

        downloadsUtil.loopBack(sendData, result => {
            const fileNames = [];
            let index = 1;
            async.mapSeries(result.downloadFiles, (file, cb) => {
                let filename = file.downloadPath;
                if (fileNames.includes(filename)) {
                    let newFilename = `${filename} (${fileNames.filter(fn => fn === filename).length})`;
                    fileNames.push(filename);
                    filename = newFilename; 
                } else {
                    fileNames.push(filename);
                }
                if (file && file.category !== "folder") {
                    if (shareLink && shareLink.watermark) {
                        downloadsUtil.singleWatermarkDownload(file, params).then((blob)=>{
                            if (blob) {
                                zip.file(`${filename}${file.fileExtension}`, blob)
                            } else {
                              let link = fileUtils.getDownloadLink(file)
                              if (link) {
                                var a = document.createElement('a')
                                a.setAttribute(
                                  'href',
                                  `${link}?userLevel=staffclient&type=downloaded&name=${uploadName}`,
                                )
                                a.setAttribute('download', '')
                                a.setAttribute('target', '_blank')
                                setTimeout(() => {
                                  a.click()
                                }, 700)
                              }
                            }
                            index++
                            if (progressCallback) {
                                progressCallback(Math.ceil((100/result.downloadFiles.length)*index))
                            }
                            cb();
                        })
                    } else {
                        downloadsUtil.singleBase64String(file._id, firm).then(response => {
                            if (response && response.success && response.file) {
                                let base64String = response.file.data;
                                zip.file(`${filename}${file.fileExtension}`, base64String, { base64: true });
                            } else {
                                let link = fileUtils.getDownloadLink(file);
                                if(link) {
                                    var a  = document.createElement("a");
                                    a.setAttribute('href', `${link}?userLevel=staffclient&type=downloaded&name=${uploadName}`); 
                                    a.setAttribute('download', '');
                                    a.setAttribute('target', '_blank');
                                    setTimeout(() => {
                                        a.click();
                                    }, 700)
                                }
                            }
                            index++
                            progressCallback(Math.ceil((100/result.downloadFiles.length)*index))
                            cb();
                        });
                    }
                } else {
                    zip.folder(file.downloadPath);
                    cb();
                }
            }, (err) => {
                if (!err) {
                    callback(zip, result.fileIds);
                }
            });
        });
    }

    , loopBack(data, response) {
        let arrNames = [];
        async.map(data.folders, (folder, callback) => {
            
            let filename = folder.filename;
            if (folder.category !== "folder") {
                filename = folder.filename.substr(0, folder.filename.lastIndexOf("."));
                data.fileIds.push(folder._id);
            }

            // rename file (optional)
            if (arrNames.includes(filename)) {
                let newFilename = filename + ` (${arrNames.filter(item => item === filename).length})`;
                arrNames.push(filename);
                filename = newFilename;
            } else {
                arrNames.push(filename);
            }

            // set download path
            if (!data.root) {
                if (folder && !folder.downloadPath) {
                    folder.downloadPath = filename;
                } else {
                    folder.downloadPath += '/' + filename;
                }
            } else {
                data.root = false;
            }

            data.folders = data.files.flatMap(item => {
                if (item._folder == folder._id) {
                    item.downloadPath = folder.downloadPath;
                    return [item];
                } else {
                    return [];
                }
            });

            if (data.folders && data.folders.length) {
                downloadsUtil.loopBack(data, result => {
                    console.log('result', result)
                });
            }
            data.downloadFiles.push(folder);
            callback(null);
        }, err => {
            response(data)
        });
    },


    getFileName(response) {
        const disposition = response.headers.get("Content-Disposition"); // get content disposition from request headers

        let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1]; // get filename from content disposition

        if (filename.toLowerCase().startsWith("utf-8''")) {
            filename = decodeURIComponent(filename.replace(/utf-8''/i, ""));
        } else {
            filename = filename.replace(/['"]/g, "");
        }

        return filename
    },
    
    downloadBlob(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = filename;
        document.body.appendChild(a); 
        a.click();
        a.remove();
    },

    downloadUrl(url, filename) {
        const a = document.createElement('a');

        a.href = url;
        a.download = filename;

        document.body.appendChild(a);

        a.click();
        a.remove();
    }
}

export default downloadsUtil;