// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

// import global components
import CloseWrapper from '../../../global/components/helpers/CloseWrapper.js.jsx'
import Binder from '../../../global/components/Binder.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

// import resource components
import SingleEngagementOptions from './SingleEngagementOptions.js.jsx'

// import actions
import * as engagementActions from '../engagementActions'

class EngagementTypeListItems extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      engagementOptionsOpen: false,
      updateEngagement: false,
      baseName: '',
      newName: '',
      errorMessage: '',
      submitting: false,
      // , deleteShowWarning: false
    }
    this._bind(
      '_handleUpdateEngagement',
      '_toggleUpdateEngagement',
      '_handleFormChange',
      '_handleCloseListOptions'
    )
  }

  _handleCloseListOptions(e) {
    e.stopPropagation()
    this.setState({ engagementOptionsOpen: false })
  }

  _toggleUpdateEngagement(e) {
    e.stopPropagation()
    const updateEngagement = _.cloneDeep(this.state.updateEngagement)
    const { engagement } = this.props
    // preserve the fileExtension by removing it from the filename here. We'll add it back when they save.
    const baseName = engagement.name
    this.setState({
      updateEngagement: !updateEngagement,
      newName: baseName,
      engagementOptionsOpen: false,
      baseName,
    })
  }

  _handleUpdateEngagement() {
    const { dispatch, engagement } = this.props
    const newEngagement = _.cloneDeep(engagement)
    const { baseName, newName, submitting } = _.cloneDeep(this.state)

    if ((newName && baseName.trim() === newName.trim()) || submitting) return

    this.setState({ submitting: true })
    newEngagement.name = newName
    dispatch(engagementActions.sendUpdateEngagement(newEngagement)).then(
      json => {
        if (json.success) {
          this.setState({
            updateEngagement: false,
            newName: '',
            baseName: '',
            submitting: false,
          })
        } else {
          this.setState({ errorMessage: json.error, submitting: false })
        }
      }
    )
  }

  _handleFormChange(e) {
    let newState = _.update(_.cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    newState.errorMessage = ''
    this.setState(newState)
  }

  render() {
    const { engagement, toggleDelete } = this.props

    const {
      engagementOptionsOpen,
      deleteShowWarning,
      updateEngagement,
      newName,
      baseName,
      errorMessage,
      submitting,
    } = this.state

    const isEmpty = !engagement || !engagement._id

    return (
      <div className="table-row -file-item">
        <div className="table-cell">
          {updateEngagement ? (
            <div className="yt-row center-vert">
              <div className="-pB_10">
                <TextInput
                  change={this._handleFormChange}
                  name="newName"
                  value={newName}
                  onSubmit={this._handleUpdateEngagement}
                  showLabel={false}
                />
              </div>
              <div className="center-vert">
                <button
                  className="yt-btn x-small link"
                  onClick={this._toggleUpdateEngagement}
                >
                  cancel
                </button>
                <button
                  className="yt-btn x-small success"
                  onClick={this._handleUpdateEngagement}
                  disabled={
                    (newName.trim() === baseName.trim() && newName.trim()) ||
                    submitting ||
                    !!errorMessage
                  }
                >
                  save
                </button>
              </div>
              {errorMessage && (
                <p
                  className="-error-color"
                  style={{ marginLeft: '10px' }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
          ) : (
            <div className="yt-row center-vert">{engagement.name}</div>
          )}
        </div>
        <div className="table-cell -date">
          {DateTime.fromISO(engagement.updated_at).toLocaleString(
            DateTime.DATE_SHORT
          )}
        </div>
        <div
          className="table-cell"
          style={{ textAlign: 'center' }}
        >
          {engagement.allowToUpdate ? (
            <i
              className="fal fa-cog"
              style={{ color: '#0DA79D', cursor: 'pointer' }}
              onClick={this._toggleUpdateEngagement}
            />
          ) : (
            <i className="fas fa-lock" />
          )}
        </div>
        <div
          className="table-cell"
          style={{ textAlign: 'center' }}
        >
          {engagement.allowToDelete ? (
            <i
              className="far fa-trash-alt"
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => toggleDelete(engagement)}
            />
          ) : (
            <i className="fas fa-lock" />
          )}
        </div>
      </div>
    )
  }
}

EngagementTypeListItems.propTypes = {
  engagement: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

EngagementTypeListItems.defaultProps = {
  engagement: {},
  user: {},
}

export default EngagementTypeListItems
