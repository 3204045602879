// import primary libraries
import React from 'react'
import Route from 'react-router-dom/Route'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import YTRoute from '../../../global/components/routing/YTRoute.js.jsx'

// import clientWorkflow views
import AdminCreateClientWorkflow from './views/AdminCreateClientWorkflow.js.jsx'
import AdminClientWorkflowList from './views/AdminClientWorkflowList.js.jsx'
import AdminSingleClientWorkflow from './views/AdminSingleClientWorkflow.js.jsx'
import AdminUpdateClientWorkflow from './views/AdminUpdateClientWorkflow.js.jsx'

class ClientWorkflowAdminRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    let singleClientWorkflowPath = this.props.location.pathname.replace(
      '/update',
      ''
    )
    return (
      <Switch>
        <YTRoute
          breadcrumbs={[
            { display: 'Dashboard', path: '/admin' },
            { display: 'All clientWorkflows', path: null },
          ]}
          component={AdminClientWorkflowList}
          exact
          path="/admin/client-workflows"
          role="admin"
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Dashboard', path: '/admin' },
            { display: 'All clientWorkflows', path: '/admin/client-workflows' },
            { display: 'New ', path: null },
          ]}
          component={AdminCreateClientWorkflow}
          exact
          path="/admin/client-workflows/new"
          role="admin"
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Dashboard', path: '/admin' },
            { display: 'All clientWorkflows', path: '/admin/client-workflows' },
            { display: 'ClientWorkflow details', path: null },
          ]}
          component={AdminSingleClientWorkflow}
          exact
          path="/admin/client-workflows/:clientWorkflowId"
          role="admin"
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Dashboard', path: '/admin' },
            { display: 'All clientWorkflows', path: '/admin/client-workflows' },
            {
              display: 'ClientWorkflow Details',
              path: singleClientWorkflowPath,
            },
            { display: 'Update', path: null },
          ]}
          component={AdminUpdateClientWorkflow}
          exact
          path="/admin/client-workflows/:clientWorkflowId/update"
          role="admin"
        />
      </Switch>
    )
  }
}

export default ClientWorkflowAdminRouter
