import { Tooltip, Typography } from '@mui/material'
import React from 'react'

export default function TextWithTooltip({ text, tooltipProps, textProps }) {
  const [showTooltip, setShowTooltip] = React.useState(false)
  const textRef = React.useRef(null)
  const { sx, ...otherTextProps } = textProps || {}

  React.useLayoutEffect(() => {
    if (!textRef.current) {
      return
    }

    const hasOverflow =
      textRef.current.scrollWidth > textRef.current.clientWidth

    setShowTooltip(hasOverflow)
  }, [text])

  return (
    <Tooltip
      title={text}
      placement="top"
      disableHoverListener={!showTooltip}
      {...tooltipProps}
    >
      <Typography
        variant="h3"
        ref={textRef}
        sx={{
          display: 'block',
          fontWeight: 'normal',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          ...sx,
        }}
        {...otherTextProps}
      >
        {text}
      </Typography>
    </Tooltip>
  )
}
