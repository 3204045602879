/**
 * All taskActivity CRUD actions
 *
 * Actions are payloads of information that send data from the application
 * (i.e. Yote server) to the store. They are the _only_ source of information
 * for the store.
 *
 * TASK_ACTIVITY: In Yote, we try to keep actions and reducers dealing with CRUD payloads
 * in terms of 'item' or 'items'. This keeps the action payloads consistent and
 * aides various scoping issues with list management in the reducers.
 */

// import api utility
import { map } from 'lodash'
import apiUtils from '../../global/utils/api'

const shouldFetchSingle = (state, id) => {
  /**
   * This is helper method to determine whether we should fetch a new single
   * user object from the server, or if a valid one already exists in the store
   *
   * TASK_ACTIVITY: Uncomment console logs to help debugging
   */
  // console.log("shouldFetch single");
  const { byId, selected } = state.taskActivity
  if (!id) {
    // passed in null or undefined for the id parameter.  so we should NOT fetch
    return false
  } else if (selected.id != id) {
    // the "selected" id changed, so we _should_ fetch
    // console.log("Y shouldFetch - true: id changed");
    return true
  } else if (selected.isFetching) {
    // "selected" is already fetching, don't do anything
    // console.log("Y shouldFetch - false: isFetching");
    return false
  } else if (!byId[id] && !selected.error) {
    // the id is not in the map, fetch from server
    // however, if the api returned an error, then it SHOULDN'T be in the map
    // so re-fetching it will result in an infinite loop
    // console.log("Y shouldFetch - true: not in map");
    return true
  } else if (new Date().getTime() - selected.lastUpdated > 1000 * 60 * 5) {
    // it's been longer than 5 minutes since the last fetch, get a new one
    // console.log("Y shouldFetch - true: older than 5 minutes");
    // also, don't automatically invalidate on server error. if server throws an error,
    // that won't change on subsequent requests and we will have an infinite loop
    return true
  } else {
    // if "selected" is invalidated, fetch a new one, otherwise don't
    // console.log("Y shouldFetch - " + selected.didInvalidate + ": didInvalidate");
    return selected.didInvalidate
  }
}

export const INVALIDATE_SELECTED_TASK_ACTIVITY =
  'INVALIDATE_SELECTED_TASK_ACTIVITY'
export function invalidateSelected() {
  return {
    type: INVALIDATE_SELECTED_TASK_ACTIVITY,
  }
}

export const fetchSingleIfNeeded = id => (dispatch, getState) => {
  if (shouldFetchSingle(getState(), id)) {
    return dispatch(fetchSingleTaskActivityById(id))
  } else {
    return dispatch(returnSingleTaskActivityPromise(id)) // return promise that contains taskActivity
  }
}

export const returnSingleTaskActivityPromise = id => (dispatch, getState) => {
  /**
   * This returns the object from the map so that we can do things with it in
   * the component.
   *
   * For the "fetchIfNeeded()" functionality, we need to return a promised object
   * EVEN IF we don't need to fetch it. this is because if we have any .then()'s
   * in the components, they will fail when we don't need to fetch.
   */
  return new Promise(resolve => {
    resolve({
      id: id,
      item: getState().taskActivity.byId[id],
      success: true,
      type: 'RETURN_SINGLE_TASK_ACTIVITY_WITHOUT_FETCHING',
    })
  })
}

export const REQUEST_SINGLE_TASK_ACTIVITY = 'REQUEST_SINGLE_TASK_ACTIVITY'
function requestSingleTaskActivity(id) {
  return {
    id,
    type: REQUEST_SINGLE_TASK_ACTIVITY,
  }
}

export const RECEIVE_SINGLE_TASK_ACTIVITY = 'RECEIVE_SINGLE_TASK_ACTIVITY'
function receiveSingleTaskActivity(json) {
  return {
    error: json.message,
    id: json.taskActivity ? json.taskActivity._id : null,
    item: json.taskActivity,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_SINGLE_TASK_ACTIVITY,
  }
}

export function fetchSingleTaskActivityById(id) {
  return dispatch => {
    dispatch(requestSingleTaskActivity(id))
    return apiUtils
      .callAPI(`/api/task-activity/${id}`)
      .then(json => dispatch(receiveSingleTaskActivity(json)))
  }
}

export const ADD_SINGLE_TASK_ACTIVITY_TO_MAP = 'ADD_SINGLE_TASK_ACTIVITY_TO_MAP'
export function addSingleTaskActivityToMap(item) {
  return {
    item,
    type: ADD_SINGLE_TASK_ACTIVITY_TO_MAP,
  }
}

export const SET_SELECTED_TASK_ACTIVITY = 'SET_SELECTED_TASK_ACTIVITY'
export function setSelectedTaskActivity(item) {
  return {
    type: SET_SELECTED_TASK_ACTIVITY,
    item,
  }
}

export const REQUEST_DEFAULT_TASK_ACTIVITY = 'REQUEST_DEFAULT_TASK_ACTIVITY'
function requestDefaultTaskActivity(id) {
  return {
    type: REQUEST_DEFAULT_TASK_ACTIVITY,
  }
}

export const RECEIVE_DEFAULT_TASK_ACTIVITY = 'RECEIVE_DEFAULT_TASK_ACTIVITY'
function receiveDefaultTaskActivity(json) {
  return {
    error: json.message,
    defaultObj: json.defaultObj,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_DEFAULT_TASK_ACTIVITY,
  }
}

export function fetchDefaultTaskActivity() {
  return dispatch => {
    dispatch(requestDefaultTaskActivity())
    return apiUtils
      .callAPI(`/api/task-activity/default`)
      .then(json => dispatch(receiveDefaultTaskActivity(json)))
  }
}

export const REQUEST_TASK_ACTIVITY_SCHEMA = 'REQUEST_TASK_ACTIVITY_SCHEMA'
function requestTaskActivitySchema(id) {
  return {
    type: REQUEST_TASK_ACTIVITY_SCHEMA,
  }
}
export const RECEIVE_TASK_ACTIVITY_SCHEMA = 'RECEIVE_TASK_ACTIVITY_SCHEMA'
function receiveTaskActivitySchema(json) {
  return {
    error: json.message,
    schema: json.schema,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_TASK_ACTIVITY_SCHEMA,
  }
}
export function fetchTaskActivitySchema() {
  return dispatch => {
    dispatch(requestTaskActivitySchema())
    return apiUtils
      .callAPI(`/api/task-activity/schema`)
      .then(json => dispatch(receiveTaskActivitySchema(json)))
  }
}

export const REQUEST_CREATE_TASK_ACTIVITY = 'REQUEST_CREATE_TASK_ACTIVITY'
function requestCreateTaskActivity(taskActivity) {
  return {
    taskActivity,
    type: REQUEST_CREATE_TASK_ACTIVITY,
  }
}

export const RECEIVE_CREATE_TASK_ACTIVITY = 'RECEIVE_CREATE_TASK_ACTIVITY'
function receiveCreateTaskActivity(json) {
  return {
    error: json.message,
    id: json.taskActivity ? json.taskActivity._id : null,
    item: json.taskActivity,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_CREATE_TASK_ACTIVITY,
    activity: json.activity,
  }
}

export function sendCreateTaskActivity(data) {
  return dispatch => {
    dispatch(requestCreateTaskActivity(data))
    return apiUtils
      .callAPI('/api/task-activity', 'POST', data)
      .then(json => dispatch(receiveCreateTaskActivity(json)))
  }
}

export function sendCreateRequestChanges(data) {
  return dispatch => {
    dispatch(requestCreateTaskActivity(data))
    return apiUtils
      .callAPI('/api/task-activity/request-changes', 'POST', data)
      .then(json => dispatch(receiveCreateTaskActivity(json)))
  }
}

export const REQUEST_UPDATE_TASK_ACTIVITY = 'REQUEST_UPDATE_TASK_ACTIVITY'
function requestUpdateTaskActivity(taskActivity) {
  return {
    id: taskActivity ? taskActivity._id : null,
    taskActivity,
    type: REQUEST_UPDATE_TASK_ACTIVITY,
  }
}

export const RECEIVE_UPDATE_TASK_ACTIVITY = 'RECEIVE_UPDATE_TASK_ACTIVITY'
function receiveUpdateTaskActivity(json) {
  return {
    error: json.message,
    id: json.taskActivity ? json.taskActivity._id : null,
    item: json.taskActivity,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_UPDATE_TASK_ACTIVITY,
  }
}

export function sendUpdateTaskActivity(data) {
  return dispatch => {
    dispatch(requestUpdateTaskActivity(data))
    return apiUtils
      .callAPI(`/api/task-activity/${data._id}`, 'PUT', data)
      .then(json => dispatch(receiveUpdateTaskActivity(json)))
  }
}

export function sendBulkUpdateTaskActivity(data) {
  return dispatch => {
    // dispatch(requestBulkUpdateTaskActivity(data))
    return apiUtils
      .callAPI(`/api/task-activity/bulk-update/${data._client}`, 'PUT', data)
      .then(json => {
        if (json.success) {
          json.taskActivitys.map(taskActivity => {
            const newJson = { success: true, taskActivity }
            dispatch(receiveUpdateTaskActivity(newJson))
          })
        } else {
          const newJson = { success: false, message: json.message }
          dispatch(receiveUpdateTaskActivity(newJson))
        }
        return json
      })
  }
}

export const REQUEST_DELETE_TASK_ACTIVITY = 'REQUEST_DELETE_TASK_ACTIVITY'
function requestDeleteTaskActivity(id) {
  return {
    id,
    type: REQUEST_DELETE_TASK_ACTIVITY,
  }
}

export const RECEIVE_DELETE_TASK_ACTIVITY = 'RECEIVE_DELETE_TASK_ACTIVITY'
function receiveDeleteTaskActivity(id, json) {
  return {
    error: json.message,
    id,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_DELETE_TASK_ACTIVITY,
  }
}

export function sendDelete(id) {
  return dispatch => {
    dispatch(requestDeleteTaskActivity(id))
    return apiUtils
      .callAPI(`/api/task-activity/${id}`, 'DELETE')
      .then(json => dispatch(receiveDeleteTaskActivity(id, json)))
  }
}

/**
 * LIST ACTIONS
 */

const findListFromArgs = (state, listArgs) => {
  /**
   * Helper method to find appropriate list from listArgs.
   *
   * Because we nest taskActivityLists to arbitrary locations/depths,
   * finding the correct list becomes a little bit harder
   */
  // let list = Object.assign({}, state.taskActivity.lists, {});
  let list = { ...state.taskActivity.lists }
  for (let i = 0; i < listArgs.length; i++) {
    list = list[listArgs[i]]
    if (!list) {
      return false
    }
  }
  return list
}

const shouldFetchList = (state, listArgs) => {
  /**
   * Helper method to determine whether to fetch the list or not from arbitrary
   * listArgs
   *
   * TASK_ACTIVITY: Uncomment console logs to help debugging
   */
  // console.log("shouldFetchList with these args ", listArgs, "?");
  const list = findListFromArgs(state, listArgs)
  // console.log("LIST in question: ", list);
  if (!list || !list.items) {
    // yes, the list we're looking for wasn't found
    // console.log("X shouldFetch - true: list not found");
    return true
  } else if (list.isFetching) {
    // no, this list is already fetching
    // console.log("X shouldFetch - false: fetching");
    return false
  } else if (new Date().getTime() - list.lastUpdated > 1000 * 60 * 5) {
    // yes, it's been longer than 5 minutes since the last fetch
    // console.log("X shouldFetch - true: older than 5 minutes");
    return true
  } else {
    // maybe, depends on if the list was invalidated
    // console.log("X shouldFetch - " + list.didInvalidate + ": didInvalidate");
    return list.didInvalidate
  }
}

export const fetchListIfNeeded =
  (...listArgs) =>
  (dispatch, getState) => {
    if (listArgs.length === 0) {
      // If no arguments passed, make the list we want "all"
      listArgs = ['all']
    }
    if (shouldFetchList(getState(), listArgs)) {
      return dispatch(fetchList(...listArgs))
    } else {
      return dispatch(returnTaskActivityListPromise(...listArgs))
    }
  }

export const returnTaskActivityListPromise =
  (...listArgs) =>
  (dispatch, getState) => {
    /**
     * This returns the list object from the reducer so that we can do things with it in
     * the component.
     *
     * For the "fetchIfNeeded()" functionality, we need to return a promised object
     * EVEN IF we don't need to fetch it. This is because if we have any .then()'s
     * in the components, they will fail when we don't need to fetch.
     */

    // return the array of objects just like the regular fetch
    const state = getState()
    const listItemIds = findListFromArgs(state, listArgs).items
    const listItems = listItemIds.map(id => state.taskActivity.byId[id])

    return new Promise(resolve => {
      resolve({
        list: listItems,
        listArgs: listArgs,
        success: true,
        type: 'RETURN_TASK_ACTIVITY_LIST_WITHOUT_FETCHING',
      })
    })
  }

export const REQUEST_TASK_ACTIVITY_LIST = 'REQUEST_TASK_ACTIVITY_LIST'
function requestTaskActivityList(listArgs) {
  return {
    listArgs,
    type: REQUEST_TASK_ACTIVITY_LIST,
  }
}

export const RECEIVE_TASK_ACTIVITY_LIST = 'RECEIVE_TASK_ACTIVITY_LIST'
function receiveTaskActivityList(json, listArgs) {
  return {
    error: json.message,
    list: json.taskActivitys,
    listArgs,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_TASK_ACTIVITY_LIST,
  }
}

export const ADD_TASK_ACTIVITY_TO_LIST = 'ADD_TASK_ACTIVITY_TO_LIST'
export function addTaskActivityToList(item, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  // allow user to either send the entire object or just the _id
  if (typeof item === 'string' || typeof item === 'number') {
    return {
      type: ADD_TASK_ACTIVITY_TO_LIST,
      id: item,
      listArgs,
    }
  } else {
    return {
      type: ADD_TASK_ACTIVITY_TO_LIST,
      id: item._id,
      listArgs,
    }
  }
}

export const REMOVE_TASK_ACTIVITY_FROM_LIST = 'REMOVE_TASK_ACTIVITY_FROM_LIST'
export function removeTaskActivityFromList(id, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    type: REMOVE_TASK_ACTIVITY_FROM_LIST,
    id,
    listArgs,
  }
}

export function fetchList(...listArgs) {
  return dispatch => {
    if (listArgs.length === 0) {
      // default to "all" list if we don't pass any listArgs
      listArgs = ['all']
    }
    dispatch(requestTaskActivityList(listArgs))
    /**
     * determine what api route we want to hit
     *
     * TASK_ACTIVITY: use listArgs to determine what api call to make.
     * if listArgs[0] == null or "all", return list
     *
     * if listArgs has 1 arg, return "/api/task-activity/by-[ARG]"
     *
     * if 2 args, additional checks required.
     *  if 2nd arg is a string, return "/api/task-activity/by-[ARG1]/[ARG2]".
     *    ex: /api/task-activity/by-category/:category
     *  if 2nd arg is an array, though, return "/api/task-activity/by-[ARG1]-list" with additional query string
     *
     * TODO:  make this accept arbitrary number of args. Right now if more
     * than 2, it requires custom checks on server
     */
    let apiTarget = '/api/task-activity'
    if (listArgs.length == 1 && listArgs[0] !== 'all') {
      apiTarget += `/by-${listArgs[0]}`
    } else if (listArgs.length == 2 && Array.isArray(listArgs[1])) {
      // length == 2 has it's own check, specifically if the second param is an array
      // if so, then we need to call the "listByValues" api method instead of the regular "listByRef" call
      // this can be used for querying for a list of taskActivitys given an array of taskActivity id's, among other things
      apiTarget += `/by-${listArgs[0]}-list?`
      // build query string
      for (let i = 0; i < listArgs[1].length; i++) {
        apiTarget += `${listArgs[0]}=${listArgs[1][i]}&`
      }
    } else if (listArgs.length == 2) {
      // ex: ("author","12345")
      apiTarget += `/by-${listArgs[0]}/${listArgs[1]}`
    } else if (listArgs.length > 2) {
      apiTarget += `/by-${listArgs[0]}/${listArgs[1]}`
      for (let i = 2; i < listArgs.length; i++) {
        apiTarget += `/${listArgs[i]}`
      }
    }
    console.log('apiTarget', apiTarget)
    return apiUtils
      .callAPI(apiTarget)
      .then(json => dispatch(receiveTaskActivityList(json, listArgs)))
  }
}

/**
 * LIST UTIL METHODS
 */
export const SET_TASK_ACTIVITY_FILTER = 'SET_TASK_ACTIVITY_FILTER'
export function setFilter(filter, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    filter,
    listArgs,
    type: SET_TASK_ACTIVITY_FILTER,
  }
}

export const SET_TASK_ACTIVITY_PAGINATION = 'SET_TASK_ACTIVITY_PAGINATION'
export function setPagination(pagination, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    listArgs,
    pagination,
    type: SET_TASK_ACTIVITY_PAGINATION,
  }
}

export const INVALIDATE_TASK_ACTIVITY_LIST = 'INVALIDATE_TASK_ACTIVITY_LIST'
export function invalidateList(...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    listArgs,
    type: INVALIDATE_TASK_ACTIVITY_LIST,
  }
}
