/**
 * Reusable stateless form component for Activity
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'

// import form components
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'

const AdminActivityForm = ({
  cancelLink,
  formTitle,
  formType,
  handleFormChange,
  handleFormSubmit,
  activity,
}) => {
  // set the button text
  const buttonText =
    formType === 'create' ? 'Create Activity' : 'Update Activity'

  // set the form header
  const header = formTitle ? (
    <div className="formHeader">
      <h2> {formTitle} </h2>
      <hr />
    </div>
  ) : (
    <div />
  )

  return (
    <div className="yt-container">
      <div className="yt-row center-horiz">
        <div className="form-container -slim">
          <form
            name="activityForm"
            className="activity-form"
            onSubmit={handleFormSubmit}
          >
            {header}
            <TextInput
              change={handleFormChange}
              label="Name"
              name="activity.name"
              placeholder="Name (required)"
              required={true}
              value={activity.name}
            />
            <div className="input-group">
              <div className="yt-row space-between">
                <Link
                  className="yt-btn link"
                  to={cancelLink}
                >
                  Cancel
                </Link>
                <button
                  className="yt-btn "
                  type="submit"
                >
                  {' '}
                  {buttonText}{' '}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

AdminActivityForm.propTypes = {
  cancelLink: PropTypes.string.isRequired,
  formHelpers: PropTypes.object,
  formTitle: PropTypes.string,
  formType: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
}

AdminActivityForm.defaultProps = {
  formHelpers: {},
  formTitle: '',
}

export default AdminActivityForm
