import React from 'react'
import cx from 'classnames'

import { Helmet } from 'react-helmet'
import DefaultLayout from '../../../global/components/layouts/DefaultLayout.js.jsx'
import UISwitchChecker from '../../../global/practice/components/PracticeUISwitcher.js.jsx'

const CommonView = ({ isError, message, actionText, onClick }) => {
	return (
		<DefaultLayout>
			<UISwitchChecker />
			<Helmet>
				<title>Linking SSO provider</title>
			</Helmet>
			<div className="sso-page">
				<div className="sso-content">
					{isError ? (
						<i
							className={cx(
								'fas',
								'fa-circle-exclamation',
								'sso-status-icon',
								'error',
							)}
						/>
					) : (
						<i
							className={cx(
								'fas',
								'fa-circle-check',
								'sso-status-icon',
								'success',
							)}
						/>
					)}
					<p className="sso-text">{message}</p>
					{actionText && onClick ? (
						<button
							className="yt-btn"
							onClick={onClick}
						>
							{actionText}
						</button>
					) : null}
				</div>
			</div>
		</DefaultLayout>
	)
}

export default CommonView
