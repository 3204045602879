import React from 'react'
import ReactTooltip from 'react-tooltip';

const ExportList = ({
  gridName, data, columns = []
  , hasExportAccess = false
}) => {
  
  const convertJsonToCsv = (data, columns) => {

    let csvContent = columns.map(c => c.label).join(',') + '\r\n';
    
    for(const row of data) {
      let line = '';
      for(const header of columns) {
        if(!row[header.key]) {
          line += ","
        } else {
          line += `"${row[header.key]}"` + ',';
        }
      }
      csvContent += line + '\r\n';
    }
    
    return csvContent;
    
  }

  const downloadCSV = () => {    
    const filename = `${gridName}_${new Date().toLocaleDateString('en-CA')}`;
    const csvData = convertJsonToCsv(data, columns);

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();    
  }

  console.log('hasExportAccess', hasExportAccess);
  
  return (
    hasExportAccess ? 
    <button className="yt-btn"
      onClick={downloadCSV}
    >
      <i className="fal fa-download" />
    </button>
    :
    <div data-tip data-for="PFL_DisableDownload">
      <button
        className="yt-btn"
        disabled={true}
        onClick={null}
      >
        <i className="fal fa-download" />
      </button>
      <ReactTooltip id="PFL_DisableDownload" place="top" type="warning" effect="solid">
        <span className="tooltipMessage">You don't have permission to <br/> export data</span>
      </ReactTooltip>
    </div>

  )
}

ExportList.propTypes = {}

export default ExportList;