import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../../global/components/Binder.js.jsx'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// global
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'

import TagPill from '../../../tag/components/TagPill.js.jsx'

// actions
import * as tagActions from '../../../tag/tagActions'
import * as fileActions from '../../fileActions'

class SingleFileTagsDropdown extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      newTagName: '',
      type: 'other',
    }
    this._bind(
      '_handleFormChange',
      '_handleCreateNewTag',
      '_removeTag',
      '_addTag'
    )
  }

  _handleFormChange(e) {
    const { dispatch, match } = this.props
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })

    dispatch(
      tagActions.setQuery(
        e.target.value.toLowerCase(),
        '~firm',
        match.params.firmId
      )
    )
    this.setState({ newTagName: e.target.value.toLowerCase() })
  }

  pickColor(str) {
    const colors = [
      'F44336',
      'E91E63',
      '9C27B0',
      '673AB7',
      '3F51B5',
      '2196F3',
      '03A9F4',
      '00BCD4',
      '009688',
      '4CAF50',
      '8BC34A',
      'CDDC39',
      'FFEB3B',
      'FFC107',
      'FF9800',
      'FF5722',
      '795548',
      '9E9E9E',
      '607D8B',
    ]
    let index =
      (str.charCodeAt(0) + str.charCodeAt(str.length - 1)) % colors.length
    return colors[index]
  }

  _handleCreateNewTag() {
    const { dispatch, tagStore, file, match } = this.props
    let newTag = _cloneDeep(tagStore.defaultItem.obj)
    newTag.name = this.state.newTagName
    newTag.type = this.state.type
    newTag.color = this.pickColor(this.state.newTagName)
    newTag._firm = match.params.firmId
    dispatch(tagActions.sendCreateTag(newTag)).then(json => {
      if (json.success) {
        let newFile = _cloneDeep(file)
        newFile._tags.push(json.item._id)
        dispatch(fileActions.sendUpdateFile(newFile))
        json.item.allowDeleteTag = false
        dispatch(
          tagActions.addTagToList(json.item, '~firm', match.params.firmId)
        )
      }
    })
    this.setState({ newTagName: '', type: 'other' })
    dispatch(tagActions.setQuery('', '~firm', match.params.firmId))
  }

  _removeTag(tag) {
    const { dispatch, file } = this.props
    let newFile = _cloneDeep(file)
    let newTags = newFile._tags.filter(item => item != tag._id)
    newFile._tags = newTags
    dispatch(fileActions.sendUpdateFile(newFile))
  }

  _addTag(tag) {
    const { dispatch, file, match, tagStore } = this.props
    let newFile = _cloneDeep(file)
    if (newFile._tags.indexOf(tag._id) > -1) {
      null
    } else {
      newFile._tags.push(tag._id)
      dispatch(fileActions.sendUpdateFile(newFile))
      this.setState({ newTagName: '', type: 'other' })
      dispatch(tagActions.setQuery('', '~firm', match.params.firmId))
      if (tagStore && tagStore.byId && tagStore.byId[tag._id])
        tagStore.byId[tag._id].allowDeleteTag = false
    }
  }

  render() {
    const {
      isOpen,
      tagStore,
      match,
      fileTags,
      sortedTagListItems,
      tagNameList,
      isFirmOwner,
      cssInline,
    } = this.props
    const { newTagName } = this.state
    return (
      <span
        className="single-file-options"
        style={cssInline ? cssInline : {}}
      >
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={0}
            >
              <div className="dropMenu -tags-menu">
                <div
                  className="yt-row"
                  style={{ padding: 5 }}
                >
                  <div>
                    {fileTags.map((tag, i) =>
                      tag.name ? (
                        <TagPill
                          key={tag._id}
                          tag={tag}
                        >
                          {tag.name}{' '}
                          <i
                            onClick={() => this._removeTag(tag)}
                            style={{ paddingLeft: 5 }}
                            className="fal fa-times"
                          ></i>
                        </TagPill>
                      ) : null
                    )}
                  </div>
                  <TextInput
                    change={this._handleFormChange}
                    name={'newTagName'}
                    value={newTagName}
                    onSubmit={isFirmOwner ? this._handleCreateNewTag : null}
                    classes={'hidden-input'}
                    autoFocus={true}
                    placeholder={'Search for a tag...'}
                  />
                </div>
                <p className="tag-instructions">
                  Select a tag {isFirmOwner ? 'or create one' : null}
                </p>
                {newTagName.length > 0 && !tagNameList.includes(newTagName) ? (
                  <div>
                    {isFirmOwner ? (
                      <div
                        onClick={this._handleCreateNewTag}
                        className="tag-list-item center-vert"
                      >
                        <p>
                          <strong>Create: </strong> '{newTagName}'
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {sortedTagListItems &&
                  sortedTagListItems.map(tag => (
                    <div
                      onClick={() => this._addTag(tag)}
                      className="tag-list-item center-vert"
                      key={tag._id}
                    >
                      <p>
                        <TagPill tag={tag} />
                      </p>
                    </div>
                  ))}
              </div>
            </CSSTransition>
          ) : (
            <div>
              {fileTags.length > 0 ? (
                <div>
                  {fileTags.slice(0, 2).map(tag => (
                    <TagPill
                      key={tag._id}
                      tag={tag}
                    />
                  ))}
                  {fileTags.length > 2 ? (
                    <span className="tag-pill">
                      + {fileTags.length - 2} more
                    </span>
                  ) : null}
                </div>
              ) : (
                <div>
                  <span className="tag-pill add">Add tag</span>
                </div>
              )}
            </div>
          )}
        </TransitionGroup>
      </span>
    )
  }
}

SingleFileTagsDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

SingleFileTagsDropdown.defaultProps = {}

const mapStoreToProps = store => {
  return {
    tagStore: store.tag,
  }
}

export default withRouter(connect(mapStoreToProps)(SingleFileTagsDropdown))
