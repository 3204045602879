// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'

import TagPill from '../../components/TagPill.js.jsx'

const PracticeTagTableListItem = ({ tag, deleteModal }) => {
  const isEmpty = !tag || !tag._id
  return isEmpty ? (
    <tr>
      <td colSpan="4">
        <i className="far fa-spinner fa-spin" /> Loading...
      </td>
    </tr>
  ) : (
    <tr className="-tag-item">
      <td>{tag.name}</td>
      <td>{tag.type}</td>
      <td>{tag.createdBy}</td>
      <td style={{ display: 'flex' }}>
        <TagPill tag={tag}>#{tag.color}</TagPill>
      </td>
      {tag._firm ? (
        <td className="right">
          <Link to={`/firm/${tag._firm}/settings/tags/${tag._id}/update`}>
            <i className="fal fa-cog" />
          </Link>
        </td>
      ) : (
        <td className="right">
          <i className="fas fa-lock" />
        </td>
      )}
      {tag._firm ? (
        <td className="right">
          <i
            className="far fa-trash-alt"
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => deleteModal(tag)}
          />
        </td>
      ) : (
        <td className="right">
          <i className="fas fa-lock" />
        </td>
      )}
    </tr>
  )
}

PracticeTagTableListItem.propTypes = {
  tag: PropTypes.object.isRequired,
}

PracticeTagTableListItem.defaultProps = {
  tag: {},
}

export default PracticeTagTableListItem
