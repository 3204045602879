/**
 * View component for /admin/files
 *
 * Generic file list view. Defaults to 'all' with:
 * this.props.dispatch(fileActions.fetchListIfNeeded());
 *
 * NOTE: See /product/views/ProductList.js.jsx for more examples
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import PortalLayout from '../../../../global/portal/components/PortalLayout.js.jsx'

class UpdateAccountSettings extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <PortalLayout>
        <h1> Update Account Settings</h1>
        <hr />
      </PortalLayout>
    )
  }
}

UpdateAccountSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    clientStore: store.file,
    loggedInUser: store.user.loggedIn.user,
  }
}

export default withRouter(connect(mapStoreToProps)(UpdateAccountSettings))
