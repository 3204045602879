import React, { useEffect } from 'react'
import withRouter from 'react-router-dom/withRouter'

const GainsightCmp = props => {
  const firmId = props.match.params?.firmId

  useEffect(() => {
    const initGainsightFlow = async () => {
      const gainsightData = await retrieveGainsightData(firmId)
      if (!gainsightData) {
        return
      }
      if (gainsightData.staff && gainsightData.success) {
        sendDataToGainsight(gainsightData.staff)
      }
    }

    initGainsightFlow()
  }, [firmId])

  return null
}

const retrieveGainsightData = async firmId => {
  try {
    const response = await fetch(`/api/salesforce/${parseInt(firmId)}`, {
      method: 'POST',
    })
    return await response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    return null
  }
}

const sendDataToGainsight = staff => {
  if (!window.aptrinsic) {
    return console.warn('Aptrinsic not loaded')
  }
  window.aptrinsic(
    'identify',
    {
      id: `mangoshare-staff-${staff._id}`,
      email: `${staff.user.username}`,
      firstName: `${staff.user.firstname}`,
      lastName: `${staff.user.lastname}`,
    },
    {
      id: `mangoshare-firm-${staff.firm._id}`,
      name: `${staff?.firm.name}`,
    },
  )
}
export default withRouter(GainsightCmp)
