import React, { useState } from 'react'
import TextInput from '../forms/TextInput.js'
import Slider from '@mui/material/Slider'
import Modal from '../modals/Modal.js.jsx'
import ColorPicker from '../../../resources/tag/components/ColorPicker.js.jsx'
import Pdftron from './Pdftron.jsx'
import SelectFromObject from '../forms/SelectFromObject.js.jsx'

const API_EXAMPLE_FILE = `/api/files/download-example-file`

const defaultWatermarkObject = {
  _id: null,
  _firm: null,
  position: 'diagonal',
  color: 'FF0000',
  opacity: 0.2,
  fontSize: 40,
  text: 'Watermark',
  status: 'visible',
}

const WatermarkModal = ({
  type, //create|edit|preview
  watermarkToUpdate,
  handleSubmit,
  handleClose,
  watermarkIndex
}) => {
  const [watermark, setWatermark] = useState(
    watermarkToUpdate ? watermarkToUpdate : { ...defaultWatermarkObject },
  )
  const [formDisabled, setFormDisabled] = useState(true)

  const getModalTitle = () => {
    switch (type) {
      case 'create': {
        return 'Create Watermark'
      }
      case 'edit': {
        return 'Edit Watermark'
      }
      case 'preview': {
        return 'Preview Watermark'
      }
      default: {
        return 'Create Watermark'
      }
    }
  }

  return (
    <Modal
      closeAction={() => {
        handleClose()
      }}
      isOpen={true}
      modalHeader={getModalTitle()}
      showButtons={false}
      cardSize="large"
    >
      <div className="grid-12">
        {type !== 'preview' && (
          <div className="tablet:col-12 sm:col-4">
            <SelectFromObject
              change={e => {
                let value = e.target.value
                setWatermark(prevWatermark => ({
                  ...prevWatermark,
                  position: value,
                }))
              }}
              label="Position"
              required={true}
              selected={watermark.position}
              items={[
                { value: 'topLeft', display: 'Top Left' },
                { value: 'topCenter', display: 'Top Center' },
                { value: 'topRight', display: 'Top Right' },
                { value: 'diagonal', display: 'Diagonal' },
                { value: 'bottomLeft', display: 'Bottom Left' },
                { value: 'bottomCenter', display: 'Bottom Center' },
                { value: 'bottomRight', display: 'Bottom Right' },
              ]}
              value="value"
              display="display"
              disabled={formDisabled}
            />
            <TextInput
              change={e => {
                let value = e.target.value
                setWatermark(prevWatermark => ({
                  ...prevWatermark,
                  text: value,
                }))
              }}
              label="Text"
              name="watermarkText"
              required={true}
              value={watermark.text}
              disabled={formDisabled}
            />
            <div className="input-group">
              <label>
                Font Size <sup className="-required">*</sup>
              </label>
              <Slider
                size="small"
                defaultValue={defaultWatermarkObject.fontSize}
                aria-label="Small"
                valueLabelDisplay="auto"
                min={5}
                max={100}
                value={watermark.fontSize}
                onChange={(e, newFontSize) => {
                  setWatermark(prevWatermark => ({
                    ...prevWatermark,
                    fontSize: newFontSize,
                  }))
                }}
                disabled={formDisabled}
              />
            </div>
            <div className="input-group">
              <label>
                Opacity <sup className="-required">*</sup>
              </label>
              <Slider
                size="small"
                defaultValue={defaultWatermarkObject.fontSize}
                aria-label="Small"
                valueLabelDisplay="auto"
                min={0.1}
                max={1}
                step={0.1}
                value={watermark.opacity}
                onChange={(e, newOpacity) => {
                  setWatermark(prevWatermark => ({
                    ...prevWatermark,
                    opacity: newOpacity,
                  }))
                }}
                disabled={formDisabled}
              />
            </div>
            <ColorPicker
              value={watermark.color}
              onChange={newColor => {
                if (formDisabled) return
                setWatermark(prevWatermark => ({
                  ...prevWatermark,
                  color: newColor,
                }))
              }}
            />
          </div>
        )}
        <div className={`tablet:col-12 sm:col-${type === 'preview' ? 12 : 8}`}>
          <Pdftron
            isEditMode={false}
            isFetching={false}
            filePath={API_EXAMPLE_FILE}
            style={{ height: '100%', width: '100%', minHeight: '600px' }}
            fitWidth={true}
            watermark={watermark}
            handleDocumentLoaded={()=>{
                setFormDisabled(false)
            }}
          />
        </div>
        <div className="col-12 input-group">
          <div className="yt-row space-between">
            <button
              className="yt-btn link"
              onClick={() => {
                handleClose()
              }}
              type="button"
            >
              Cancel
            </button>
            {type !== 'preview' ? (
              <button
                className="yt-btn"
                onClick={() => {
                  handleSubmit(watermark, watermarkIndex)
                }}
                disabled={formDisabled}
              >
                Save
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WatermarkModal
