import React from 'react'
import { Typography } from '@mui/material'

const AIError = ({ message }) => {
  return (
    <Typography
      variant="h5"
      sx={{ mb: 4 }}
    >
      {message} Please try again later.
    </Typography>
  )
}

export default AIError
