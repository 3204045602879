// import primary libraries
import React from 'react'
import Redirect from 'react-router-dom/Redirect'
import Route from 'react-router-dom/Route'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import YTRoute from '../../../global/components/routing/YTRoute.js.jsx'

import PracticeContactsList from './views/PracticeContactsList.js.jsx'
import PracticeContactDetails from './views/PracticeContactDetails.js.jsx'

class UserFirmRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    const firmId = this.props.location.pathname.split('/')[2]
    return (
      <Switch>
        <YTRoute
          breadcrumbs={[
            { display: 'Contacts', path: `/firm/${firmId}/contacts` },
            { display: 'Contact details', path: null },
          ]}
          component={PracticeContactDetails}
          path="/firm/:firmId/contacts/details/:userId"
          exact
          login={true}
        />
        <YTRoute
          breadcrumbs={[{ display: 'Contacts', path: null }]}
          component={PracticeContactsList}
          path="/firm/:firmId/contacts"
          // exact
          staff={true}
        />
      </Switch>
    )
  }
}

export default UserFirmRouter
