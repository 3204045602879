import React, { useEffect, useRef } from 'react'
import WebViewer from '@pdftron/webviewer'
import { PDF_WebViewer_KEY } from '../../../config/licenseKeys'

import { createItemUploads } from '../../../resources/file/fileActions';
import watermarkUtils from '../../utils/watermarkUtils';

const Pdftron = props => {
  const {
    filePath,
    firmId,
    clientId,
    selectedFile,
    dispatch,
    handleUploaded,
    isEditMode,
    isFetching,
    style,
    watermark,
    fitWidth,
    handleDocumentLoaded
  } = props
  const viewer = useRef(null)
  const viewInstance = useRef(null)

  useEffect(() => {
    const callView = async () => {
      const disabledWebviewerElements = [
        ...(isEditMode
          ? ['menuButton']
          : [
              'menuButton',
              'panToolButton',
              'selectToolButton',
              'ribbonsDropdown',
              'ribbons',
              'contextMenuPopup',
              'annotationPopup',
              'toolsOverlay'
            ]),
      ]
      try {
        viewInstance.current = await WebViewer(
          {
            licenseKey: PDF_WebViewer_KEY,
            path: '/webviewer',
            initialDoc: filePath,
            enableAnnotations: true, //enables/disables all anotations
            isReadOnly: !isEditMode,
            disableVirtualDisplayMode: true, //page by page view for large files
            disabledElements: disabledWebviewerElements,
            enableOfficeEditing: isEditMode,
            disableLogs: true
          },
          viewer.current,
        )
        const { Core, UI } = viewInstance.current
        const { documentViewer, annotationManager } = Core

        if (handleDocumentLoaded) {
          documentViewer.addEventListener("documentLoaded", ()=>{
            handleDocumentLoaded()
          })
        }

        if (fitWidth) {
          UI.setFitMode(UI.FitMode.FitWidth)
        }

        if (watermark) {
          documentViewer.setWatermark(watermarkUtils.getPdftronWatermarkObject(watermark))
        }

        if (isEditMode) {
          UI.setHeaderItems(header => {
            header.push({
              type: 'actionButton',
              img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
              onClick: async () => {
                const doc = documentViewer.getDocument()
                const xfdfString = await annotationManager.exportAnnotations()
                const data = await doc.getFileData({ xfdfString })
                const arr = new Uint8Array(data)
                const blob = new Blob([arr], { type: doc.type })
                const file = new File([blob], doc.filename, { type: doc.type })
                const files = [file]

                const params = {
                  status: 'visible',
                  _folder: selectedFile._folder,
                  _personal: selectedFile._personal,
                  viewingAs: 'workspace',
                  _client: clientId,
                  _firm: firmId,
                }

                try {
                  const uploads = dispatch(
                    createItemUploads({
                      files,
                      folders: [],
                      params,
                    }),
                  ).map(async upload => {
                    for await (const { type, value } of upload) {
                      if (type === `done`) {
                        return value
                      }
                    }
                  })
    
                  const results = await Promise.allSettled(uploads)
                  const { roots, createdFiles, failedMessages } = results
                    .map(({ value }) => value)
                    .reduce(
                      (acc, { root, createdFiles, failedMessages }) => {
                        acc.roots = [...acc.roots, root]
                        acc.createdFiles = [...acc.createdFiles, ...createdFiles]
                        acc.failedMessages = [
                          ...acc.failedMessages,
                          ...failedMessages,
                        ]
                        return acc
                      },
                      {
                        roots: [],
                        createdFiles: [],
                        failedMessages: [],
                      },
                    )
    
                  if (!createdFiles.length) {
                    throw new Error(`Could not upload file`)
                  }
    
                  const editedFile = createdFiles[0]
                  const { location } = window
                  let updatedUrl = location.href
                  updatedUrl = updatedUrl.replace(/\/[^\/]*$/, `/${editedFile._id}`)
                  const nextUrl = `${updatedUrl}`
                  window.history.pushState({}, '', nextUrl)
    
                  handleUploaded(editedFile)
                } catch (err) {
                  handleUploaded()
                }
              },
            })
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    callView()
    return () => {
      if (viewInstance.current && viewInstance.current.UI) {
        viewInstance.current.UI.dispose()
      }
    }
  }, [filePath, isFetching])

  // Effect to update watermark when watermark changes
  useEffect(() => {
    if (viewInstance.current) {
      const { documentViewer } = viewInstance.current.Core
      if (!documentViewer.getDocument()) {
        //means the document has not loaded yet, so don't update the watermark
        return
      }

      if (watermark) {
        documentViewer.setWatermark(watermarkUtils.getPdftronWatermarkObject(watermark))
      }
      documentViewer.refreshAll()
      documentViewer.updateView()
    }
  }, [watermark])

  if (isFetching) {
    return <div>loading</div>
  }

  return (
    <div
      className="webviewer"
      ref={viewer}
      style={style}
    />
  )
}

Pdftron.defaultProps = {
  filePath: null,
}

export default Pdftron
