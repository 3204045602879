/**
 * Resuable component for an actionable file list used by both /admin and /firm users
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import queryString from 'query-string'
import ReactTooltip from 'react-tooltip'
import { DateTime } from 'luxon'

import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as fileActions from '../fileActions'
import * as tagActions from '../../tag/tagActions'
import * as fileActivityActions from '../../fileActivity/fileActivityActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import FilterBy from '../../../global/components/helpers/FilterBy.js.jsx'
import PageTabber from '../../../global/components/pagination/PageTabber.js.jsx'
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'
import CloseWrapper from '../../../global/components/helpers/CloseWrapper.js.jsx'
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'
import DropdownButton from '../../../global/components/helpers/DropdownButton.js.jsx'

// import resource components
import FileTableListItem from './FileTableListItem.js.jsx'
import FileListOptions from '../practice/components/FileListOptions.js.jsx'
import FileActivityListItem from '../../activity/components/fileActivityListItem.js.jsx'

// import utils
import displayUtils from '../../../global/utils/displayUtils'
import downloadsUtil from '../../../global/utils/downloadsUtil'
import permissions from '../../../global/utils/permissions'
import filterUtils from '../../../global/utils/filterUtils'
import routeUtils from '../../../global/utils/routeUtils'
import fileUtils from '../../../global/utils/fileUtils'
import { TextInput } from '../../../global/components/forms'
import brandingName from '../../../global/enum/brandingName.js'

const fileActionListItems = [
  { label: 'Upload new files', name: 'file_upload', value: 'file_upload' },
  // {label: 'Apply document template', name: "document_template_apply", value: "document_template_apply" }
]

const folderActionListItems = [
  {
    label: 'Create new folder',
    name: 'file_create_folder',
    value: 'file_create_folder',
  },
  {
    label: 'Apply folder template',
    name: 'file_folder_template_apply',
    value: 'file_folder_template_apply',
  },
]

const linkActionListItems = (eSigAccess, selectedFileIds, fileMap) => {
  let disabledSignatureRequest = true

  if (
    eSigAccess &&
    selectedFileIds &&
    selectedFileIds.length === 1 &&
    fileMap
  ) {
    const fileId = selectedFileIds && selectedFileIds[0]
    const file = fileMap && fileMap[fileId]
    let contentType = file && file.contentType
    if (file && file.category != 'folder' && file.fileExtension) {
      if (file.fileExtension.toLowerCase().indexOf('.pdf') > -1) {
        contentType = 'application/pdf'
      } else if (file.fileExtension.toLowerCase().indexOf('.doc') > -1) {
        contentType = 'application/doc'
      } else {
        contentType = file.fileExtension
      }
      if (
        contentType &&
        (contentType.indexOf('pdf') > -1 || contentType.indexOf('doc') > -1)
      ) {
        disabledSignatureRequest = false
      }
    }
  }

  return [
    { label: 'Share files', name: 'file_share_file', value: 'file_share_file' },
    {
      label: 'Request files',
      name: 'file_request_file',
      value: 'file_request_file',
    },
    {
      label: 'Request signature',
      name: 'file_signature',
      value: 'file_signature',
      disabled: disabledSignatureRequest,
    },
  ]
}

class FileList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      tagQuery: '',
      fileOptionsOpen: false,
      downloadWarningModalOpen: false,
      showClientList: false,
      bulkMoveSubmit: false,
      archiveProcess: false,
      checked: false,
      progressStart: false,
      progressOpen: false,
      progressText: '',
      showMobileActionOption: false,
      listArgsObj: props.listArgsObj,
      onProcess: false,
      isActivityModalOpen: false,
      selectedFileData: null,
      selectedFile: null,
    }
    this._bind(
      '_handleSelectedTagsChange',
      '_handleCloseFileListOptions',
      '_handleDownloadFiles',
      '_handleSetMultipleStatus',
      '_clearAllState',
      '_handleOpenShareModal',
      '_handleContextMenuSubmit',
      '_handleShowAllFiles',
      '_handleActivityModal',
      '_handleOpenMoveModal',
      '_toggleHeaderSearch',
      '_changeHeaderSearch',
      '_handleSearch',
      '_resetSearch',
    )

    this.searchHeaderColumns = {
      filename: { disableSearch: true, searchText: '' },
      tags: { disableSearch: true, searchText: '' },
      uploadName: { disableSearch: true, searchText: '' },
      updated_at: { disableSearch: true, searchText: '' },
    }
  }

  componentWillUnmount() {
    this._clearAllState()
  }

  _clearAllState() {
    const { clearSelectedFileIds, match } = this.props
    this.setState(
      {
        fileOptionsOpen: false,
        downloadWarningModalOpen: false,
        showClientList: false,
        bulkMoveSubmit: false,
        archiveProcess: false,
        checked: false,
        progressStart: false,
        progressOpen: false,
        progressText: '',
        onProcess: false,
        selectedFile: null,
      },
      () => {
        clearSelectedFileIds()
      }
    )
  }

  _handleFormChange(e) {
    const { dispatch, match } = this.props
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })

    dispatch(
      tagActions.setQuery(
        e.target.value.toLowerCase(),
        '~firm',
        match.params.firmId
      )
    )
    this.setState({ tagQuery: e.target.value.toLowerCase() })
  }

  _handleSelectedTagsChange(e) {
    // additional logic here if we want to break out tags into multiple filters, ie years
    // for now e.target.value contains all of the filters, but may only contain a subset
    // the output to the parent should be the entire list of tags
    this.props.handleFilter(e)
  }

  _handleCloseFileListOptions(e) {
    e.stopPropagation()
    this.setState({
      fileOptionsOpen: false,
      showMobileActionOption: false,
    })
  }

  _downloadSelectedFiles(downloadlinks, index) {
    if (index < downloadlinks.length) {
      var a = document.createElement('a')
      a.setAttribute(
        'href',
        `${downloadlinks[index]}?userLevel=staffclient&type=downloaded`
      )
      a.setAttribute('download', '')
      a.setAttribute('target', '_blank')
      a.click()
      index++
      setTimeout(() => {
        this._downloadSelectedFiles(downloadlinks, index)
      }, 700)
    }
  }

  async _handleDownloadFiles() {
    this.setState({ onProcess: true })
    const { selectedFileIds, selectedFirm, loggedInUser, fileStore, dispatch } =
      this.props

    if (
      selectedFileIds &&
      selectedFileIds.length > 1 &&
      selectedFirm &&
      selectedFirm.zipFilesDownload
    ) {
      dispatch(fileActions.zipFiles(selectedFileIds))
    } else if (selectedFileIds && selectedFileIds.length) {
      const files = selectedFileIds.map(
        id => id && fileStore && fileStore.byId && fileStore.byId[id]
      )

      dispatch(fileActions.startFilesDownload())
      let downloadedCount = 0
      // iterate through selected file ids to download each file content
      for (let file of files) {
        // download file or download folder as zip
        if (file && file._id && file.category === 'folder') {
          dispatch(fileActions.zipFiles([file._id]))
        } else {
          const fileLink = fileUtils.getDownloadLink(file)
          var a = document.createElement('a')
          a.style.display = 'none';
          a.href = `${fileLink}?userLevel=staffclient&type=downloaded`
          a.setAttribute('download', file.filename)
          a.setAttribute('target', '_blank')
          document.body.appendChild(a);
          a.click();
          await new Promise(resolve => setTimeout(resolve, 1000));
          a.remove();
        }
        downloadedCount++
        const downloadPercentage = Math.ceil(
          (downloadedCount / selectedFileIds.length) * 100
        )
      }
      dispatch(fileActions.finishFilesDownload())
    }

    this._clearAllState()
  }

  _handleSetMultipleStatus() {
    const { dispatch, selectedFileIds, handleUpdateList, match } = this.props
    const selectedFile = _cloneDeep(this.state.selectedFile)

    const sendData = {
      status: 'archived',
      filesId:
        selectedFile && selectedFile._id ? [selectedFile._id] : selectedFileIds,
      action: 'status',
      firmId: match.params.firmId,
    }

    this.setState({
      archiveProcess: true,
      progressOpen: false,
      progressStart: true,
    })
    dispatch(fileActions.sendUBulkupdateFiles(sendData)).then(json => {
      if (handleUpdateList) {
        handleUpdateList()
      }
      this._clearAllState()
    })
  }

  _handleOpenShareModal() {
    const selectedFile = _cloneDeep(this.state.selectedFile)
    this.setState({ progressOpen: false, progressText: '' }, () => {
      this.props.handleOpenShareModal(selectedFile)
    })
  }

  _handleContextMenuSubmit(action, showWarningModal) {
    if (action === 'copy') {
      this.props.handleOpenCopyModal();
    } else if (action === 'move') {
      if (showWarningModal) {
        this.setState({ progressOpen: true, progressText: 'move' })
      } else {
        this.props.handleOpenMoveModal()
      }
    } else if (action === 'download') {
      this._handleDownloadFiles()
    } else if (action === 'print') {
      this._handleBulkPrintFiles()
    } else if (action === 'archive') {
      if (showWarningModal) {
        this.setState({ progressOpen: true, progressText: 'archive' })
      } else {
        this._handleSetMultipleStatus()
      }
    } else if (action === 'share') {
      if (showWarningModal) {
        this.setState({ progressOpen: true, progressText: 'share' })
      } else {
        this.props.handleOpenShareModal()
      }
    } else if (action == 'permission') {
      console.log('click permission folder')
      this.props.handleOpenFolderPermissionModal()
    } else {
      if (showWarningModal) {
        this.setState({
          progressOpen: true,
          progressText: 'move',
          showClientList: false,
        })
      } else {
        this.setState({
          showClientList: true,
          progressOpen: false,
          progressText: '',
        })
      }
    }
  }

  _handleShowAllFiles() {
    const { history, listArgs, dispatch } = this.props
    history.push(window.location.pathname)
    delete listArgs.searchFIds
    dispatch(
      fileActions.fetchListV2(
        listArgs,
        ...routeUtils.listArgsFromObject(listArgs)
      )
    )
  }

  // File Activity
  _handleActivityModal(status, file) {
    const { dispatch, match } = this.props
    if (status) {
      this.setState({ isActivityModalOpen: status, selectedFileData: file })
      dispatch(
        fileActivityActions.fetchListIfNeeded(
          '_firm',
          match.params.firmId,
          '_file',
          file._id
        )
      )
    } else {
      this.setState({ isActivityModalOpen: false, selectedFileData: null })
    }
  }

  _resetSearch() {
    this.props.handleQuery({
      target: { value: '' },
    })
  }

  _filterListByDate(activityList) {
    const { selectedDate } = this.state
    let newActivityList
    if (selectedDate) {
      // Filter out activities newer than the selected date. Ignore the time and only compare dates.
      // We were not zeroing milliseconds which was excluding activites with a date equal to selectedDate. It works correctly now.
      newActivityList = activityList.filter(
        activity =>
          new Date(activity.created_at).setHours(0, 0, 0, 0) <=
          selectedDate.setHours(0, 0, 0, 0)
      )
    } else {
      newActivityList = _cloneDeep(activityList)
    }
    return newActivityList
  }

  _groupActivitiesByDate(activityListItems) {
    const dates = activityListItems.map(activity =>
      DateTime.fromISO(activity.created_at).toISODate()
    )
    let activitiesGroupedByDate = {}
    // Create an array for each date.
    dates.forEach(date => (activitiesGroupedByDate[date] = []))
    // push all activities to their respective date arrays.
    activityListItems.forEach(activity =>
      activitiesGroupedByDate[
        DateTime.fromISO(activity.created_at).toISODate()
      ].push(activity)
    )
    return activitiesGroupedByDate
  }

  _handleOpenMoveModal() {
    const selectedFile = _cloneDeep(this.state.selectedFile)

    this.setState({ progressOpen: false, progressText: '' }, () => {
      this.props.handleOpenMoveModal(selectedFile)
    })
  }

  _toggleHeaderSearch(objectName, objectValue) {
    this.searchHeaderColumns[objectName] = objectValue
    this.forceUpdate()
  }

  _changeHeaderSearch(objectName, objectValue) {
    this.searchHeaderColumns[objectName] = objectValue
    if (objectName === 'tags') this._handleSearch()
  }

  _handleSearch() {
    const { userMap, newState, match } = this.props
    const searchHeaderColumns = _.cloneDeep(this.searchHeaderColumns)

    newState.searchListArgs.group = []
    Object.keys(searchHeaderColumns).forEach(k => {
      let item = searchHeaderColumns[k]
      if (
        item &&
        !item.disableSearch &&
        item.searchText &&
        ((k === 'tags' && item.searchText.length) ||
          (k !== 'tags' && item.searchText.trim()))
      ) {
        let uploadName = searchHeaderColumns.uploadName.searchText
        uploadName = uploadName && uploadName.trim()
        uploadName = uploadName && uploadName.toLowerCase()

        if (
          k === 'uploadName' &&
          !searchHeaderColumns.uploadName.disableSearch &&
          !!uploadName
        ) {
          const createdByIds = []
          Object.keys(userMap).forEach(keyName => {
            let item = userMap[keyName]
            if (item && item._id) {
              const fullName = item.firstname + ' ' + item.lastname
              if (fullName && fullName.toLowerCase().indexOf(uploadName) > -1) {
                createdByIds.push(item._id)
              }
            }
          })
          newState.searchListArgs.group.push({
            fieldName: k,
            value: item.searchText,
            ids: createdByIds,
          })
        } else {
          newState.searchListArgs.group.push({
            fieldName: k,
            value: item.searchText,
          })
        }
      }
    })

    if (
      !newState.searchListArgs.group ||
      (newState.searchListArgs.group &&
        newState.searchListArgs.group.length === 0)
    ) {
      delete newState.searchListArgs.group
    } else {
      newState.searchListArgs.group = JSON.stringify(
        newState.searchListArgs.group
      )
    }
    this.props.handleFileList(newState)
  }

  render() {
    const {
      allTags,
      firmStore,
      clientStore,
      utilFileStore,
      listArgs,
      handleSetPagination,
      handleToggleSelectAll,
      handleOpenQuickTaskModal,
      handleOpenFolderPermissionModal,
      handleOpenShareModal,
      handleOpenFolderModal,
      handleOpenUploadModal,
      handleOpenRequestModal,
      handleOpenTemplateModal,
      handleOpenMoveModal,
      match,
      // , orderedList // Use this list to get matching file count after filters.
      paginatedList,
      selectedTagIds,
      selectedFileIds,
      showActions,
      sortedAndFilteredList, // Use this list to get total file count minus archived and deleted files.
      tagStore,
      totalListInfo,
      viewingAs,
      staffClientStore,
      loggedInUser,
      staffStore,
      clearSelectedFileIds,
      userMap,
      fileStore,
      handleQuery,
      handleSearch,
      fileQuery,
      folderListItems,
      allFilesFromListArgs,
      staffListItem,
      isFirmStaff,
      sortBy,
      selectedFirm,
      loggedInStaff,
      isFirmOwner,
      fIds,
      handleOpenFileVersionModal,
      handleOpenDocumentTemplateModal,
      handleChangeRoleModal,
      fileMap,
      clientUserStore,
      listArgsObj,
      handleSort,
      fileActivityStore,
    } = this.props

    const {
      archiveProcess,
      progressOpen,
      progressStart,
      bulkMoveSubmit,
      showClientList,
      progressText,
      showMobileActionOption,
      fileOptionsOpen,
      onProcess,
      isActivityModalOpen,
      selectedFileData,
    } = this.state

    // file activity
    const activityListItems = selectedFileData
      ? fileActivityStore.util.getList(
          '_firm',
          match.params.firmId,
          '_file',
          selectedFileData._id
        )
      : null
    const filteredActivityListItems = activityListItems
      ? this._filterListByDate(activityListItems)
      : []
    let filesActivitiesGroupedByDate = filteredActivityListItems
      ? this._groupActivitiesByDate(filteredActivityListItems)
      : []
    if (selectedFileData && selectedFileData.created_at) {
      const selectedFileDate = DateTime.fromISO(
        selectedFileData.created_at
      ).toISODate()
      const user = userMap[selectedFileData && selectedFileData._user] || {}
      filesActivitiesGroupedByDate[selectedFileDate] = [
        ...(filesActivitiesGroupedByDate[selectedFileDate] || []),
        { ...selectedFileData, text: 'Uploaded by %USER%', user },
      ]
    }
    const icon = selectedFileData
      ? displayUtils.getFileIcon(
          selectedFileData.category,
          selectedFileData.contentType,
          selectedFileData
        )
      : null

    let selectedFile = {}
    let parentFolder = {}

    if (match.params.folderId) {
      selectedFile = fileStore.byId[match.params.folderId]
        ? fileStore.byId[match.params.folderId]
        : {}
      parentFolder = selectedFile
    }

    const role = permissions.getUserRole(
      loggedInUser,
      match.params.firmId,
      match.params.clientId,
      staffStore,
      clientUserStore
    )

    const isEmpty =
      !utilFileStore || !sortedAndFilteredList || utilFileStore.isFetching

    const isFetching =
      !utilFileStore || utilFileStore.isFetching || !sortedAndFilteredList

    const allFilesSelected =
      selectedFileIds && selectedFileIds.length
        ? paginatedList.every(p => selectedFileIds.includes(p._id))
        : false
    const eSigAccess =
      selectedFirm &&
      selectedFirm.eSigAccess &&
      loggedInStaff &&
      loggedInStaff.eSigAccess

    // filter tag list stuff
    let tagListArgs = ['~firm', match.params.firmId] // so reduce func below will work
    const tagListItems = tagStore.util.getList('~firm', match.params.firmId)
    const tagList = tagListItems
      ? tagListArgs.reduce((obj, nextKey) => obj[nextKey], tagStore.lists)
      : null
    let filteredByQuery = []
    let sortedTagListItems = []
    let tagNameList = []
    let query = tagList && tagList.query
    let queryTestString = ('' + query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers

    if (queryTestString) {
      filteredByQuery =
        tagList && tagList.items && queryTestString && query
          ? tagList.items.filter(tagId => {
              return (
                tagStore.byId[tagId] &&
                filterUtils.filterTag(queryTestString, tagStore.byId[tagId])
              )
            })
          : []
    } else if (tagList && tagList.items && tagList.items.length) {
      filteredByQuery = tagList.items
    }
    sortedTagListItems = filteredByQuery.map(item => {
      var newItem = tagStore.byId[item]
      tagNameList.push(newItem.name)
      return newItem
    })

    // progress loader only show when the warning modal appears
    let showWarningModal = false

    if (selectedFileIds && selectedFileIds.length && sortedAndFilteredList) {
      showWarningModal =
        selectedFileIds && selectedFileIds.length > 10
          ? 'More than 10 files have been selected'
          : sortedAndFilteredList.some(
              file =>
                file &&
                selectedFileIds.includes(file._id) &&
                file.category === 'folder' &&
                (file.totalChildFile || file.totalChildFolder)
            )
          ? 'A folder has been selected'
          : false
    }
    let progressPresentText = progressText

    switch (progressText) {
      case 'move':
        progressPresentText = 'moving'
        break
      case 'archive':
        progressPresentText = 'archiving'
        break
      case 'share':
        progressPresentText = 'sharing'
        break
      default:
        progressPresentText = progressText
        break
    }

    const queryParameters = new URLSearchParams(window.location.search)
    const fileIds = queryParameters.get('fIds')

    const isFilterActive =
      !this.searchHeaderColumns.filename.disableSearch ||
      !this.searchHeaderColumns.uploadName.disableSearch ||
      !this.searchHeaderColumns.tags.disableSearch ||
      !this.searchHeaderColumns.updated_at.disableSearch

    const basicPermissions = permissions.isStaffBasic(
      staffStore,
      loggedInUser,
      match.params.firmId
    )

    return (
      <div className="file-list-wrapper">
        <div className="yt-toolbar">
          <div className="yt-tools space-between">
            <div className="-filters -left _19">
              {fileIds ? (
                <p style={{ color: 'red', fontSize: 10 }}>
                  This table shows the uploaded requested file/s.
                </p>
              ) : (
                ''
              )}
              {fileIds ? (
                <p
                  style={{
                    color: '#0DA79D',
                    fontSize: 10,
                    textDecoration: 'underline',
                    marginLeft: -10,
                    cursor: 'pointer',
                  }}
                  onClick={this._handleShowAllFiles}
                >
                  Click to view all files.
                </p>
              ) : (
                ''
              )}
            </div>
            {showActions ? (
              <div className="-options -right">
                <button
                  disabled={selectedFileIds.length < 1 || onProcess}
                  className="yt-btn x-small link info -move-option"
                  onClick={this._handleContextMenuSubmit.bind(
                    this,
                    'copy',
                    showWarningModal,
                  )}
                >
                  Copy{' '}
                  {selectedFileIds && selectedFileIds.length > 0 ? (
                    <span> &mdash; {selectedFileIds.length}</span>
                  ) : null}
                </button>
                <button
                  disabled={selectedFileIds.length < 1 || onProcess}
                  className="yt-btn x-small link info -move-option"
                  onClick={this._handleContextMenuSubmit.bind(
                    this,
                    'move',
                    showWarningModal,
                  )}
                >
                  Move{' '}
                  {selectedFileIds && selectedFileIds.length > 0 ? (
                    <span> &mdash; {selectedFileIds.length}</span>
                  ) : null}
                </button>
                {
                  // (selectedFile && selectedFile.permission && !!selectedFile.permission[`${role}Download`]) ||
                  // (selectedFirm && selectedFirm.permission && !!selectedFirm.permission[`${role}Download`]) ?
                  permissions.hasPermission(
                    selectedFirm,
                    parentFolder,
                    selectedFile,
                    `${role}Download`,
                  ) ? (
                    <button
                      disabled={selectedFileIds.length < 1 || onProcess}
                      className="yt-btn x-small link info -download-option"
                      onClick={this._handleDownloadFiles}
                    >
                      Download{' '}
                      {selectedFileIds && selectedFileIds.length > 0 ? (
                        <span> &mdash; {selectedFileIds.length}</span>
                      ) : null}
                    </button>
                  ) : (
                    <div
                      data-tip
                      data-for="flDisableDownload"
                    >
                      <button
                        disabled={true}
                        className="yt-btn x-small link info -download-option"
                        onClick={null}
                      >
                        Download
                      </button>
                      <ReactTooltip
                        id="flDisableDownload"
                        place="top"
                        type="warning"
                        effect="solid"
                      >
                        <span className="tooltipMessage">
                          You don't have permission to <br /> download files and
                          folders
                        </span>
                      </ReactTooltip>
                    </div>
                  )
                }
                {permissions.hasPermission(
                  selectedFirm,
                  parentFolder,
                  selectedFile,
                  `${role}Delete`,
                ) ? (
                  <button
                    disabled={selectedFileIds.length < 1 || onProcess}
                    className="yt-btn x-small link info -archive-option"
                    onClick={this._handleContextMenuSubmit.bind(
                      this,
                      'archive',
                      showWarningModal,
                    )}
                  >
                    {archiveProcess ? (
                      <p className="-archive-saving">
                        Archiving<span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </p>
                    ) : (
                      'Archive'
                    )}
                    {archiveProcess ? null : selectedFileIds &&
                      selectedFileIds.length > 0 ? (
                      <span> &mdash; {selectedFileIds.length}</span>
                    ) : null}
                  </button>
                ) : (
                  <div
                    data-tip
                    data-for="FL_DisableArchive"
                  >
                    <button
                      disabled={true}
                      className="yt-btn x-small link info -archive-option"
                      onClick={null}
                    >
                      Archive
                    </button>
                    <ReactTooltip
                      id="FL_DisableArchive"
                      place="top"
                      type="warning"
                      effect="solid"
                    >
                      <span className="tooltipMessage">
                        You don't have permission to <br /> archive/delete files
                        and folders
                      </span>
                    </ReactTooltip>
                  </div>
                )}
                {basicPermissions ? (
                  <div
                    data-tip
                    data-for="FL_DisableNewLink"
                  >
                    <DropdownButton
                      label="New Link"
                      selectedCount={0}
                      select={() => {}}
                      displayKey="label"
                      items={[]}
                      selected={null}
                      valueKey="value"
                      disabled={true}
                    />
                    <ReactTooltip
                      id="FL_DisableNewLink"
                      place="top"
                      type="warning"
                      effect="solid"
                    >
                      <span className="tooltipMessage">
                        You don't have permission to create a request link
                      </span>
                    </ReactTooltip>
                  </div>
                ) : (
                  <DropdownButton
                    label="New Link"
                    selectedCount={selectedFileIds.length}
                    select={value =>
                      value === 'file_share_file'
                        ? this._handleContextMenuSubmit(
                            'share',
                            showWarningModal,
                          )
                        : value === 'file_signature'
                          ? eSigAccess && selectedFileIds.length === 1
                            ? handleChangeRoleModal(
                                value,
                                fileMap[selectedFileIds[0]],
                              )
                            : null
                          : handleChangeRoleModal(value)
                    }
                    displayKey="label"
                    items={linkActionListItems(
                      eSigAccess,
                      selectedFileIds,
                      fileMap,
                    )}
                    selected={null}
                    valueKey="value"
                    disabled={onProcess}
                  />
                )}
                {
                  // (selectedFile && selectedFile.permission && !!selectedFile.permission[`${role}Create`]) ||
                  // (selectedFirm && selectedFirm.permission && !!selectedFirm.permission[`${role}Create`]) ?
                  permissions.hasPermission(
                    selectedFirm,
                    parentFolder,
                    selectedFile,
                    `${role}Create`,
                  ) ? (
                    <DropdownButton
                      label="New Folder"
                      select={value => handleChangeRoleModal(value)}
                      displayKey="label"
                      items={folderActionListItems}
                      selected={null}
                      valueKey="value"
                      disabled={onProcess}
                    />
                  ) : (
                    <div
                      data-tip
                      data-for="FL_DisableCreate"
                    >
                      <DropdownButton
                        label="New Folder"
                        select={null}
                        displayKey="label"
                        items={folderActionListItems}
                        selected={null}
                        valueKey="value"
                        disabled={true}
                      />
                      <ReactTooltip
                        id="FL_DisableCreate"
                        place="top"
                        type="warning"
                        effect="solid"
                      >
                        <p className="tooltipMessage">
                          You don't have permission to <br /> create folder
                        </p>
                      </ReactTooltip>
                    </div>
                  )
                }
                {
                  // (selectedFile && selectedFile.permission && !!selectedFile.permission[`${role}Upload`]) ||
                  // (selectedFirm && selectedFirm.permission && !!selectedFirm.permission[`${role}Upload`]) ?
                  permissions.hasPermission(
                    selectedFirm,
                    parentFolder,
                    selectedFile,
                    `${role}Upload`,
                  ) ? (
                    // <DropdownButton
                    //   label="New File"
                    //   select={(value) => handleChangeRoleModal(value)}
                    //   displayKey="label"
                    //   items={fileActionListItems}
                    //   valueKey="value"
                    //   disabled={onProcess}

                    // />
                    <button
                      className="yt-btn x-small info"
                      onClick={() => handleChangeRoleModal('file_upload')}
                    >
                      New File
                    </button>
                  ) : (
                    <div
                      data-tip
                      data-for="FL_DisableUpload"
                    >
                      <DropdownButton
                        label="New File"
                        select={null}
                        displayKey="label"
                        items={fileActionListItems}
                        selected={null}
                        valueKey="value"
                        disabled={true}
                      />
                      <ReactTooltip
                        id="FL_DisableUpload"
                        place="top"
                        type="warning"
                        effect="solid"
                      >
                        <p className="tooltipMessage">
                          You don't have permission to <br /> upload files
                        </p>
                      </ReactTooltip>
                    </div>
                  )
                }
                <div
                  className="-options -pointer 1"
                  onClick={() => this.setState({ fileOptionsOpen: true })}
                >
                  <div className="-relative">
                    <CloseWrapper
                      isOpen={this.state.fileOptionsOpen}
                      closeAction={this._handleCloseFileListOptions}
                    />
                    <i className="far fa-ellipsis-v"></i>
                    {fileOptionsOpen ? (
                      <FileListOptions
                        isOpen={this.state.fileOptionsOpen}
                        closeAction={() =>
                          this.setState({ fileOptionsOpen: false })
                        }
                        selectedFileIds={selectedFileIds}
                        showWarningModal={showWarningModal}
                        handleContextMenuSubmit={this._handleContextMenuSubmit}
                        handleOpenShareModal={handleOpenShareModal}
                        handleOpenRequestModal={handleOpenRequestModal}
                        handleOpenUploadModal={handleOpenUploadModal}
                        handleOpenFolderModal={handleOpenFolderModal}
                        handleOpenTemplateModal={handleOpenTemplateModal}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <hr className="-mobile-yt-hide" />
        <div
          className="yt-table table firm-table -workspace-table truncate-cells -yt-edit-table -files-table"
          style={{ opacity: onProcess ? '0.5' : 1 }}
        >
          <div className="table-caption">
            <PageTabber
              totalItems={utilFileStore.totalFiles}
              totalPages={Math.ceil(
                parseInt(utilFileStore.totalFiles) /
                  (utilFileStore.pagination &&
                    parseInt(utilFileStore.pagination.per)),
              )}
              pagination={utilFileStore.pagination}
              setPagination={handleSetPagination}
              setPerPage={this.props.setPerPage}
              viewingAs="top"
              itemName="files"
              handleQuery={handleQuery}
              handleSearch={handleSearch}
              query={fileQuery}
              searchText="Search..."
              firmId={match.params.firmId}
              clientId={match.params.clientId}
              userId={match.params.userId}
              folderId={match.params.folderId}
              isChanged={true}
              enableSearch={true}
            />
          </div>
          <div className="-table-horizontal-scrolling">
            <div
              className={`table-head ${
                (isFilterActive && '-header-filter-active') || ''
              }`}
            >
              <div className="table-cell">
                {handleToggleSelectAll ? (
                  <CheckboxInput
                    name="file"
                    value={allFilesSelected}
                    change={() =>
                      handleToggleSelectAll(paginatedList, allFilesSelected)
                    }
                    checked={allFilesSelected}
                  />
                ) : null}
              </div>
              <div className="table-cell"></div>
              <TableHeaderColumn
                objectName="filename"
                objectValue={this.searchHeaderColumns.filename}
                handleSort={handleSort}
                sortBy={sortBy}
                handleSearch={this._handleSearch}
                tableCellClassName="table-cell -title sortable _30"
                columnLabel="Filename"
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
              />
              {selectedFirm && selectedFirm.fileVersionType === 'enable' ? (
                <div className="table-cell _10">
                  <div className="-table-header-title">Versions</div>
                  <div className="-table-header-search -hidden">
                    <div className="input-group"></div>
                  </div>
                </div>
              ) : null}
              <div className="table-cell _10">
                <div className="-table-header-title">Size</div>
                <div className="-table-header-search -hidden">
                  <div className="input-group"></div>
                </div>
              </div>
              <div className="table-cell _10">
                <div className="-table-header-title">Folders</div>
                <div className="-table-header-search -hidden">
                  <div className="input-group"></div>
                </div>
              </div>
              <div className="table-cell _10">
                <div className="-table-header-title">Files</div>
                <div className="-table-header-search -hidden">
                  <div className="input-group"></div>
                </div>
              </div>
              <TableHeaderColumn
                objectName="tags"
                objectValue={this.searchHeaderColumns.tags}
                isSortable={false}
                handleSearch={this._handleSearch}
                tableCellClassName="table-cell _10"
                selectedTagIds={selectedTagIds}
                allTags={allTags}
                columnLabel="Tags"
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
              />
              <div className="table-cell _10">
                <div className="-table-header-title">Activity</div>
                <div className="-table-header-search -hidden">
                  <div className="input-group"></div>
                </div>
              </div>
              <div className="table-cell sortable _10">
                <div
                  className="-table-header-title"
                  onClick={() => handleSort('status')}
                >
                  Visibility
                  {sortBy && sortBy == 'status' ? (
                    <i className="fad fa-sort-up"></i>
                  ) : sortBy && sortBy == '-status' ? (
                    <i className="fad fa-sort-down"></i>
                  ) : (
                    <i className="fad fa-sort"></i>
                  )}
                </div>
                <div className="-table-header-search -hidden">
                  <div className="input-group"></div>
                </div>
              </div>
              <TableHeaderColumn
                objectName="uploadName"
                objectValue={this.searchHeaderColumns.uploadName}
                handleSearch={this._handleSearch}
                tableCellClassName="table-cell sortable _10"
                isSortable={false}
                columnLabel="Created By"
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
              />
              <TableHeaderColumn
                objectName="updated_at"
                objectValue={this.searchHeaderColumns.updated_at}
                handleSort={handleSort}
                sortBy={sortBy}
                handleSearch={this._handleSearch}
                tableCellClassName="table-cell -date sortable _20"
                inputType="date"
                columnLabel="Last Updated"
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
              />
              <div className="table-cell _20">
                <div className="-table-header-title">Upload Origin</div>
                <div className="-table-header-search -hidden">
                  <div className="input-group"></div>
                </div>
              </div>
            </div>
            {isFilterActive ? (
              <div className="table-head">
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                {selectedFirm && selectedFirm.fileVersionType === 'enable' ? (
                  <div className="table-cell"></div>
                ) : null}
                <div className="table-cell">
                  <button
                    className="yt-btn x-small info"
                    onClick={this._handleSearch}
                    style={{ width: '100%' }}
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            ) : null}
            {isEmpty ? (
              isFetching ? (
                <div className="-loading-hero hero">
                  <div className="u-centerText">
                    <div className="loading"></div>
                  </div>
                </div>
              ) : (
                <div className="hero -empty-hero">
                  <div className="u-centerText">
                    <p>Looks like you don't have any files yet. </p>
                    <p>Let's add some.</p>
                  </div>
                </div>
              )
            ) : paginatedList && paginatedList.length > 0 ? (
              paginatedList.map((file, i) => (
                <FileTableListItem
                  key={'file_' + file._id + '_' + i}
                  client={
                    file._client && clientStore.byId[file._client]
                      ? clientStore.byId[file._client]
                      : null
                  }
                  file={file}
                  checked={this.props.selectedFileIds.includes(file._id)}
                  handleSelectFile={this.props.handleSelectFile}
                  handleOpenQuickTaskModal={() =>
                    handleOpenQuickTaskModal(file)
                  }
                  handleOpenFolderPermissionModal={() =>
                    handleOpenFolderPermissionModal(file)
                  }
                  showOptions={true}
                  viewingAs={viewingAs}
                  listArgs={listArgs}
                  sortedTagListItems={sortedTagListItems}
                  tagNameList={tagNameList}
                  clearSelectedFileIds={clearSelectedFileIds}
                  folderListItems={folderListItems}
                  objFileActivityListItems={this.props.objFileActivityListItems}
                  _handleMoveSelectedChange={this._handleMoveSelectedChange}
                  _handleLocationChange={this._handleLocationChange}
                  selectedFileIds={this.props.selectedFileIds}
                  _isOldFile={true}
                  handleContextMenuSubmit={this._handleContextMenuSubmit}
                  showWarningModal={showWarningModal}
                  isFirmStaff={isFirmStaff}
                  selectedFirm={selectedFirm}
                  loggedInStaff={loggedInStaff}
                  isFirmOwner={isFirmOwner}
                  role={role}
                  parentFolder={parentFolder}
                  handleMultipleFiles={(type, file) =>
                    this.setState({
                      progressOpen: true,
                      progressText: type,
                      selectedFile: file,
                    })
                  }
                  handleOpenFileVersionModal={() =>
                    handleOpenFileVersionModal(file)
                  }
                  handleUpdateList={this.props.handleUpdateList}
                  handleActivityModal={this._handleActivityModal}
                  handleOpenMoveModal={handleOpenMoveModal}
                  handleOpenShareModal={handleOpenShareModal}
                  handleDownloadFiles={this._handleDownloadFiles}
                  allFilesFromListArgs={allFilesFromListArgs}
                  handleBulkAction={type =>
                    this.setState({ progressOpen: true, progressText: type })
                  }
                  listArgsObj={listArgsObj}
                  basicPermissions={basicPermissions}
                  resetSearch={this._resetSearch}
                />
              ))
            ) : (
              <div className="table-head empty-state">
                <div
                  className="table-cell"
                  colSpan="5"
                >
                  <em>No files</em>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal
          isOpen={isActivityModalOpen}
          closeAction={() => this._handleActivityModal(false, null)}
          cardSize="standard"
          showButtons={false}
          modalHeader="Activity List"
        >
          <div className="-content">
            <div style={{ opacity: isFetching ? 0.5 : 1 }}>
              <div className="-body">
                <div
                  className="-user-info"
                  style={{ margin: '1em 0' }}
                >
                  <span className="-icon">
                    <img
                      src={`/img/icons/${icon}.png`}
                      style={{ width: '60px' }}
                    />
                  </span>
                  <div
                    className="-text"
                    style={{ lineHeight: '1.2' }}
                  >
                    <Link
                      className="-filename"
                      to={`/firm/${match.params.firmId}/files/${
                        selectedFileData && selectedFileData._id
                      }`}
                    >
                      {selectedFileData && selectedFileData.filename}
                    </Link>
                    <br />
                    <small>
                      {userMap[selectedFileData && selectedFileData._user] ? (
                        <span>
                          {`by ${userMap[selectedFileData && selectedFileData._user].firstname} ${userMap[selectedFileData && selectedFileData._user].lastname}`}
                        </span>
                      ) : selectedFileData && selectedFileData.uploadName ? (
                        <span>
                          by{' '}
                          <em>
                            {selectedFileData && selectedFileData.uploadName}(not logged in)
                          </em>
                        </span>
                      ) : null}
                    </small>
                    {selectedFileData && selectedFileData.uploadEmailAddress ? (
                      <>
                        <br />
                        <small>
                          <span>
                            email:{' '}
                            <em>
                              {selectedFileData &&
                                selectedFileData.uploadEmailAddress}
                            </em>
                          </span>
                        </small>
                      </>
                    ) : null}
                    {selectedFileData && selectedFileData.uploadCompanyName ? (
                      <>
                        <br />
                        <small>
                          <span>
                            company:{' '}
                            <em>
                              {selectedFileData &&
                                selectedFileData.uploadCompanyName}
                            </em>
                          </span>
                        </small>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="file-preview-activity-list">
                  {Object.keys(filesActivitiesGroupedByDate).map(key => (
                    <div
                      key={key}
                      className="activity-day-group"
                    >
                      <div className="-day">
                        {DateTime.fromISO(key).toFormat('D') ===
                        DateTime.local().toFormat('D')
                          ? 'Today'
                          : DateTime.fromISO(key).toFormat('D')}
                      </div>
                      {filesActivitiesGroupedByDate[key].map((activity, i) => (
                        <FileActivityListItem
                          key={`${activity._id}_${i}`}
                          activity={activity}
                          loggedInUser={loggedInUser}
                          user={userMap[activity._user] || {}}
                          client={clientStore.byId[activity._client] || {}}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <AlertModal
          alertTitle="More than ten files have been selected"
          alertMessage={`While ${brandingName.title} allows you to download unlimited files simultaneously, certain browsers may limit you to downloading 10 separate files at one time. If you experience this, please select a maximum of 10 files per download attempt.`}
          closeAction={() => this.setState({ downloadWarningModalOpen: false })}
          confirmAction={this._handleDownloadFiles}
          confirmText="Try downloading anyway"
          isOpen={this.state.downloadWarningModalOpen}
          type="warning"
        />
        <AlertModal
          isOpen={progressOpen && progressText ? true : false} // app.js.jsx?93ea:56 Warning: Failed prop type: Invalid prop `isOpen` of type `string` supplied to `AlertModal`,
          type="warning"
          confirmText={`Try ${progressPresentText} anyway`}
          alertTitle={showWarningModal ? showWarningModal : 'Warning Modal'}
          alertMessage={
            showWarningModal === 'A folder has been selected '
              ? `All files associated with this folder will also be ${progressText}d.`
              : `While ${brandingName.title} allows you to ${progressText} unlimited files simultaneously, certain browsers may limit you to ${progressPresentText} 10 separate files at one time. If you experience this, please select a maximum of 10 files per ${progressText} attempt.`
          }
          closeAction={() =>
            this.setState({ progressOpen: false, progressText: '' })
          }
          confirmAction={
            progressText === 'move'
              ? this._handleOpenMoveModal
              : progressText === 'archive'
                ? this._handleSetMultipleStatus
                : progressText === 'share'
                  ? this._handleOpenShareModal
                  : null
          }
        />
      </div>
    )
  }
}

export class TableHeaderColumn extends Binder {
  constructor(props) {
    super(props)
    const objectValue = props.objectValue
    this.state = { objectValue }
    this._bind('_toggleHeaderSearch', '_changeHeaderSearch')
  }

  _toggleHeaderSearch(e) {
    const objectValue = _cloneDeep(this.state.objectValue)
    const objectName = _cloneDeep(this.props.objectName)
    objectValue.disableSearch = !e.target.value
    this.setState({ objectValue }, () => {
      this.props.toggleHeaderSearch(objectName, objectValue)
    })
  }

  _changeHeaderSearch(e) {
    const objectValue = _cloneDeep(this.state.objectValue)
    const objectName = _cloneDeep(this.props.objectName)
    if (
      objectName === 'tags' &&
      e.target.value &&
      e.target.value.length &&
      objectValue.disableSearch
    ) {
      objectValue.disableSearch = false
    }

    objectValue.searchText = e.target.value

    this.setState({ objectValue }, () => {
      this.props.changeHeaderSearch(objectName, objectValue)
    })
  }

  render() {
    const objectValue = _cloneDeep(this.state.objectValue)
    const {
      objectName,
      tableCellClassName,
      sortBy,
      handleSort,
      handleSearch,
      allTags,
      selectedTagIds,
      isSortable,
      inputType,
      columnLabel,
    } = this.props
    return (
      <div className={tableCellClassName}>
        <div
          className="-table-header-title"
          onClick={() => handleSort(objectName)}
        >
          {columnLabel}
          {!isSortable ? null : sortBy === objectName ? (
            <i className="fad fa-sort-down"></i>
          ) : sortBy === '-' + objectName ? (
            <i className="fad fa-sort-up"></i>
          ) : (
            <i className="fad fa-sort"></i>
          )}
        </div>
        {objectName === 'tags' ? (
          <div className="-table-header-search">
            <FilterBy
              applyFilter={this._changeHeaderSearch}
              displayKey="name"
              items={allTags || []}
              label="Tags"
              name="tags"
              selected={selectedTagIds}
              valueKey="_id"
              disabled={objectValue.disableSearch}
            />
          </div>
        ) : (
          <div className="-table-header-search">
            <CheckboxInput
              name={objectName}
              value={!objectValue.disableSearch}
              checked={!objectValue.disableSearch}
              change={this._toggleHeaderSearch}
            />
            <TextInput
              blur={() => console.log('blur')}
              change={this._changeHeaderSearch}
              name={objectName}
              value={objectValue.searchText}
              disabled={objectValue.disableSearch}
              placeholder={`Search ${columnLabel.substr(0, 3).toLowerCase()}`}
              onSubmit={handleSearch}
              type={inputType}
            />
          </div>
        )}
      </div>
    )
  }
}

FileList.propTypes = {
  allFilesSelected: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  utilFileStore: PropTypes.object.isRequired,
  allTags: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleOpenRequestModal: PropTypes.func,
  handleOpenShareModal: PropTypes.func,
  handleOpenUploadModal: PropTypes.func,
  handleQuery: PropTypes.func.isRequired,
  handleSelectFile: PropTypes.func.isRequired,
  handleSetPagination: PropTypes.func.isRequired,
  handleToggleSelectAll: PropTypes.func,
  handleSort: PropTypes.func.isRequired,
  selectedFileIds: PropTypes.array,
  selectedTagIds: PropTypes.array,
  showActions: PropTypes.bool,
  sortedAndFilteredList: PropTypes.array,
  viewingAs: PropTypes.oneOf([
    'workspace',
    'general',
    'admin',
    'client',
    'staff',
    'personal',
    'public',
  ]),
}

FileList.defaultProps = {
  allFilesSelected: false,
  handleOpenRequestModal: null,
  handleOpenShareModal: null,
  handleOpenUploadModal: null,
  handleToggleSelectAll: null,
  selectedFileIds: [],
  selectedTagIds: [],
  showActions: true,
  sortedAndFilteredList: [],
  viewingAs: 'workspace',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  /**
   * REGARDING PAGINATION: Pagination would normally be handled on the parent component WorkspaceFiles.
   * The listArgs in WorkspaceFiles.state are not accessible from that component's mapStoreToProps
   * function. We have to paginate the list here instead since it is passed to this component as a prop
   * with no need to be aware of the listArgs.
   */
  let { utilFileStore, sortedAndFilteredList, listArgsObj } = props
  let paginatedList = []
  // let orderedList = [];
  const loggedInUser = store.user.loggedIn.user

  const sortBy =
    listArgsObj && listArgsObj.ordervalue === 'asc'
      ? listArgsObj.ordername
      : `-${listArgsObj.ordername}`
  const staffListItem = store.staff.util.getList(
    '_firm',
    props.match.params.firmId
  )
  const isFirmStaff =
    staffListItem && loggedInUser
      ? staffListItem.some(
          x => x._user == loggedInUser._id && x.status == 'active'
        )
      : false

  let { fIds } = queryString.parse(props.location.search)
  fIds = fIds ? fIds.split(',') : []
  paginatedList = sortedAndFilteredList

  return {
    loggedInUser,
    clientStore: store.client,
    paginatedList: paginatedList,
    fileStore: store.file,
    tagStore: store.tag,
    staffClientStore: store.staffClient,
    staffStore: store.staff,
    clientUserStore: store.clientUser,
    userMap: store.user.byId,
    firmStore: store.firm,
    staffListItem,
    isFirmStaff,
    sortBy,
    fIds,
    newSortedAndFilteredList: sortedAndFilteredList,
    fileMap: store.file.byId,
    fileActivityStore: store.fileActivity,
    userStore: store.user,
  }
}

export default withRouter(connect(mapStoreToProps)(FileList))
