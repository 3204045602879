/**
 * All folderTemplate CRUD actions
 *
 * Actions are payloads of information that send data from the application
 * (i.e. Yote server) to the store. They are the _only_ source of information
 * for the store.
 *
 * FOLDER_TEMPLATE: In Yote, we try to keep actions and reducers dealing with CRUD payloads
 * in terms of 'item' or 'items'. This keeps the action payloads consistent and
 * aides various scoping issues with list management in the reducers.
 */

// import api utility
import { map } from 'lodash'
import apiUtils from '../../global/utils/api'

const shouldFetchSingle = (state, id) => {
  /**
   * This is helper method to determine whether we should fetch a new single
   * user object from the server, or if a valid one already exists in the store
   *
   * FOLDER_TEMPLATE: Uncomment console logs to help debugging
   */
  // console.log("shouldFetch single");
  const { byId, selected } = state.folderTemplate
  if (!id) {
    // passed in null or undefined for the id parameter.  so we should NOT fetch
    return false
  } else if (selected.id != id) {
    // the "selected" id changed, so we _should_ fetch
    // console.log("Y shouldFetch - true: id changed");
    return true
  } else if (selected.isFetching) {
    // "selected" is already fetching, don't do anything
    // console.log("Y shouldFetch - false: isFetching");
    return false
  } else if (!byId[id] && !selected.error) {
    // the id is not in the map, fetch from server
    // however, if the api returned an error, then it SHOULDN'T be in the map
    // so re-fetching it will result in an infinite loop
    // console.log("Y shouldFetch - true: not in map");
    return true
  } else if (new Date().getTime() - selected.lastUpdated > 1000 * 60 * 5) {
    // it's been longer than 5 minutes since the last fetch, get a new one
    // console.log("Y shouldFetch - true: older than 5 minutes");
    // also, don't automatically invalidate on server error. if server throws an error,
    // that won't change on subsequent requests and we will have an infinite loop
    return true
  } else {
    // if "selected" is invalidated, fetch a new one, otherwise don't
    // console.log("Y shouldFetch - " + selected.didInvalidate + ": didInvalidate");
    return selected.didInvalidate
  }
}

export const INVALIDATE_SELECTED_FOLDER_TEMPLATE =
  'INVALIDATE_SELECTED_FOLDER_TEMPLATE'
export function invalidateSelected() {
  return {
    type: INVALIDATE_SELECTED_FOLDER_TEMPLATE,
  }
}

export const fetchSingleIfNeeded = id => (dispatch, getState) => {
  if (shouldFetchSingle(getState(), id)) {
    return dispatch(fetchSingleFolderTemplateById(id))
  } else {
    return dispatch(returnSingleFolderTemplatePromise(id)) // return promise that contains folderTemplate
  }
}

export const returnSingleFolderTemplatePromise = id => (dispatch, getState) => {
  /**
   * This returns the object from the map so that we can do things with it in
   * the component.
   *
   * For the "fetchIfNeeded()" functionality, we need to return a promised object
   * EVEN IF we don't need to fetch it. this is because if we have any .then()'s
   * in the components, they will fail when we don't need to fetch.
   */
  return new Promise(resolve => {
    resolve({
      id: id,
      item: getState().folderTemplate.byId[id],
      success: true,
      type: 'RETURN_SINGLE_FOLDER_TEMPLATE_WITHOUT_FETCHING',
    })
  })
}

export const REQUEST_SINGLE_FOLDER_TEMPLATE = 'REQUEST_SINGLE_FOLDER_TEMPLATE'
function requestSingleFolderTemplate(id) {
  return {
    id,
    type: REQUEST_SINGLE_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_SINGLE_FOLDER_TEMPLATE = 'RECEIVE_SINGLE_FOLDER_TEMPLATE'
function receiveSingleFolderTemplate(json) {
  return {
    error: json.message,
    id: json.folderTemplate ? json.folderTemplate._id : null,
    item: json.folderTemplate,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_SINGLE_FOLDER_TEMPLATE,
  }
}

export function fetchSingleFolderTemplateById(id) {
  return dispatch => {
    dispatch(requestSingleFolderTemplate(id))
    return apiUtils
      .callAPI(`/api/folder-template/${id}`)
      .then(json => dispatch(receiveSingleFolderTemplate(json)))
  }
}

export const ADD_SINGLE_FOLDER_TEMPLATE_TO_MAP =
  'ADD_SINGLE_FOLDER_TEMPLATE_TO_MAP'
export function addSingleFolderTemplateToMap(item) {
  return {
    item,
    type: ADD_SINGLE_FOLDER_TEMPLATE_TO_MAP,
  }
}

export const SET_SELECTED_FOLDER_TEMPLATE = 'SET_SELECTED_FOLDER_TEMPLATE'
export function setSelectedFolderTemplate(item) {
  return {
    type: SET_SELECTED_FOLDER_TEMPLATE,
    item,
  }
}

export const REQUEST_DEFAULT_FOLDER_TEMPLATE = 'REQUEST_DEFAULT_FOLDER_TEMPLATE'
function requestDefaultFolderTemplate(id) {
  return {
    type: REQUEST_DEFAULT_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_DEFAULT_FOLDER_TEMPLATE = 'RECEIVE_DEFAULT_FOLDER_TEMPLATE'
function receiveDefaultFolderTemplate(json) {
  return {
    error: json.message,
    defaultObj: json.defaultObj,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_DEFAULT_FOLDER_TEMPLATE,
  }
}

export function fetchDefaultFolderTemplate() {
  return dispatch => {
    dispatch(requestDefaultFolderTemplate())
    return apiUtils
      .callAPI(`/api/folder-template/default`)
      .then(json => dispatch(receiveDefaultFolderTemplate(json)))
  }
}

export const REQUEST_FOLDER_TEMPLATE_SCHEMA = 'REQUEST_FOLDER_TEMPLATE_SCHEMA'
function requestFolderTemplateSchema(id) {
  return {
    type: REQUEST_FOLDER_TEMPLATE_SCHEMA,
  }
}
export const RECEIVE_FOLDER_TEMPLATE_SCHEMA = 'RECEIVE_FOLDER_TEMPLATE_SCHEMA'
function receiveFolderTemplateSchema(json) {
  return {
    error: json.message,
    schema: json.schema,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_FOLDER_TEMPLATE_SCHEMA,
  }
}
export function fetchFolderTemplateSchema() {
  return dispatch => {
    dispatch(requestFolderTemplateSchema())
    return apiUtils
      .callAPI(`/api/folder-template/schema`)
      .then(json => dispatch(receiveFolderTemplateSchema(json)))
  }
}

export const REQUEST_CREATE_FOLDER_TEMPLATE = 'REQUEST_CREATE_FOLDER_TEMPLATE'
function requestCreateFolderTemplate(folderTemplate) {
  return {
    folderTemplate,
    type: REQUEST_CREATE_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_CREATE_FOLDER_TEMPLATE = 'RECEIVE_CREATE_FOLDER_TEMPLATE'
function receiveCreateFolderTemplate(json) {
  return {
    error: json.message,
    id: json.folderTemplate ? json.folderTemplate._id : null,
    item: json.folderTemplate,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_CREATE_FOLDER_TEMPLATE,
    activity: json.activity,
  }
}

export function sendCreateFolderTemplate(data) {
  return dispatch => {
    dispatch(requestCreateFolderTemplate(data))
    return apiUtils
      .callAPI('/api/folder-template', 'POST', data)
      .then(json => dispatch(receiveCreateFolderTemplate(json)))
  }
}

export const REQUEST_APPLY_FOLDER_TEMPLATE = 'REQUEST_APPLY_FOLDER_TEMPLATE'
function requestApplyFolderTemplate(folderTemplate) {
  return {
    type: REQUEST_APPLY_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_APPLY_FOLDER_TEMPLATE = 'RECEIVE_APPLY_FOLDER_TEMPLATE'
function receiveApplyFolderTemplate(json) {
  return {
    error: json.message,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_APPLY_FOLDER_TEMPLATE,
    files: json.files,
  }
}

export function sendApplyFolderTemplate(data) {
  return dispatch => {
    dispatch(requestApplyFolderTemplate(data))
    return apiUtils
      .callAPI('/api/folder-template/apply', 'POST', data)
      .then(json => dispatch(receiveApplyFolderTemplate(json)))
  }
}

export const REQUEST_BULK_APPLY_FOLDER_TEMPLATE =
  'REQUEST_BULK_APPLY_FOLDER_TEMPLATE'
function requestBulkApplyFolderTemplate(folderTemplate) {
  return {
    type: REQUEST_BULK_APPLY_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_BULK_APPLY_FOLDER_TEMPLATE =
  'RECEIVE_BULK_APPLY_FOLDER_TEMPLATE'
function receiveBulkApplyFolderTemplate(json) {
  return {
    error: json.message,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_BULK_APPLY_FOLDER_TEMPLATE,
    files: json.files,
  }
}

export function sendBulkApplyFolderTemplate(data) {
  return dispatch => {
    dispatch(requestBulkApplyFolderTemplate(data))
    return apiUtils
      .callAPI('/api/folder-template/bulk-apply', 'POST', data)
      .then(json => dispatch(receiveBulkApplyFolderTemplate(json)))
  }
}

export function sendCreateRequestChanges(data) {
  return dispatch => {
    dispatch(requestCreateFolderTemplate(data))
    return apiUtils
      .callAPI('/api/folder-template/request-changes', 'POST', data)
      .then(json => dispatch(receiveCreateFolderTemplate(json)))
  }
}

export const REQUEST_UPDATE_FOLDER_TEMPLATE = 'REQUEST_UPDATE_FOLDER_TEMPLATE'
function requestUpdateFolderTemplate(folderTemplate) {
  return {
    id: folderTemplate ? folderTemplate._id : null,
    folderTemplate,
    type: REQUEST_UPDATE_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_UPDATE_FOLDER_TEMPLATE = 'RECEIVE_UPDATE_FOLDER_TEMPLATE'
function receiveUpdateFolderTemplate(json) {
  return {
    error: json.message,
    id: json.folderTemplate ? json.folderTemplate._id : null,
    item: json.folderTemplate,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_UPDATE_FOLDER_TEMPLATE,
  }
}

export function sendUpdateFolderTemplate(data) {
  return dispatch => {
    dispatch(requestUpdateFolderTemplate(data))
    return apiUtils
      .callAPI(`/api/folder-template/${data._id}`, 'PUT', data)
      .then(json => dispatch(receiveUpdateFolderTemplate(json)))
  }
}

export function sendBulkUpdateFolderTemplate(data) {
  return dispatch => {
    // dispatch(requestBulkUpdateFolderTemplate(data))
    return apiUtils
      .callAPI(`/api/folder-template/bulk-update/${data._client}`, 'PUT', data)
      .then(json => {
        if (json.success) {
          json.folderTemplates.map(folderTemplate => {
            const newJson = { success: true, folderTemplate }
            dispatch(receiveUpdateFolderTemplate(newJson))
          })
        } else {
          const newJson = { success: false, message: json.message }
          dispatch(receiveUpdateFolderTemplate(newJson))
        }
        return json
      })
  }
}

export const REQUEST_DELETE_FOLDER_TEMPLATE = 'REQUEST_DELETE_FOLDER_TEMPLATE'
function requestDeleteFolderTemplate(id) {
  return {
    id,
    type: REQUEST_DELETE_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_DELETE_FOLDER_TEMPLATE = 'RECEIVE_DELETE_FOLDER_TEMPLATE'
function receiveDeleteFolderTemplate(id, json) {
  return {
    error: json.message,
    id,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_DELETE_FOLDER_TEMPLATE,
  }
}

export function sendDelete(id) {
  return dispatch => {
    dispatch(requestDeleteFolderTemplate(id))
    return apiUtils
      .callAPI(`/api/folder-template/${id}`, 'DELETE')
      .then(json => dispatch(receiveDeleteFolderTemplate(id, json)))
  }
}

/**
 * LIST ACTIONS
 */

const findListFromArgs = (state, listArgs) => {
  /**
   * Helper method to find appropriate list from listArgs.
   *
   * Because we nest folderTemplateLists to arbitrary locations/depths,
   * finding the correct list becomes a little bit harder
   */
  // let list = Object.assign({}, state.folderTemplate.lists, {});
  let list = { ...state.folderTemplate.lists }
  for (let i = 0; i < listArgs.length; i++) {
    list = list[listArgs[i]]
    if (!list) {
      return false
    }
  }
  return list
}

const shouldFetchList = (state, listArgs) => {
  /**
   * Helper method to determine whether to fetch the list or not from arbitrary
   * listArgs
   *
   * FOLDER_TEMPLATE: Uncomment console logs to help debugging
   */
  // console.log("shouldFetchList with these args ", listArgs, "?");
  const list = findListFromArgs(state, listArgs)
  // console.log("LIST in question: ", list);
  if (!list || !list.items) {
    // yes, the list we're looking for wasn't found
    // console.log("X shouldFetch - true: list not found");
    return true
  } else if (list.isFetching) {
    // no, this list is already fetching
    // console.log("X shouldFetch - false: fetching");
    return false
  } else if (new Date().getTime() - list.lastUpdated > 1000 * 60 * 5) {
    // yes, it's been longer than 5 minutes since the last fetch
    // console.log("X shouldFetch - true: older than 5 minutes");
    return true
  } else {
    // maybe, depends on if the list was invalidated
    // console.log("X shouldFetch - " + list.didInvalidate + ": didInvalidate");
    return list.didInvalidate
  }
}

export const fetchListIfNeeded =
  (...listArgs) =>
  (dispatch, getState) => {
    if (listArgs.length === 0) {
      // If no arguments passed, make the list we want "all"
      listArgs = ['all']
    }
    if (shouldFetchList(getState(), listArgs)) {
      return dispatch(fetchList(...listArgs))
    } else {
      return dispatch(returnFolderTemplateListPromise(...listArgs))
    }
  }

export const returnFolderTemplateListPromise =
  (...listArgs) =>
  (dispatch, getState) => {
    /**
     * This returns the list object from the reducer so that we can do things with it in
     * the component.
     *
     * For the "fetchIfNeeded()" functionality, we need to return a promised object
     * EVEN IF we don't need to fetch it. This is because if we have any .then()'s
     * in the components, they will fail when we don't need to fetch.
     */

    // return the array of objects just like the regular fetch
    const state = getState()
    const listItemIds = findListFromArgs(state, listArgs).items
    const listItems = listItemIds.map(id => state.folderTemplate.byId[id])

    return new Promise(resolve => {
      resolve({
        list: listItems,
        listArgs: listArgs,
        success: true,
        type: 'RETURN_FOLDER_TEMPLATE_LIST_WITHOUT_FETCHING',
      })
    })
  }

export const REQUEST_FOLDER_TEMPLATE_LIST = 'REQUEST_FOLDER_TEMPLATE_LIST'
function requestFolderTemplateList(listArgs) {
  return {
    listArgs,
    type: REQUEST_FOLDER_TEMPLATE_LIST,
  }
}

export const RECEIVE_FOLDER_TEMPLATE_LIST = 'RECEIVE_FOLDER_TEMPLATE_LIST'
function receiveFolderTemplateList(json, listArgs) {
  return {
    error: json.message,
    list: json.folderTemplates,
    listArgs,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_FOLDER_TEMPLATE_LIST,
  }
}

export const ADD_FOLDER_TEMPLATE_TO_LIST = 'ADD_FOLDER_TEMPLATE_TO_LIST'
export function addFolderTemplateToList(item, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  // allow user to either send the entire object or just the _id
  if (typeof item === 'string' || typeof item === 'number') {
    return {
      type: ADD_FOLDER_TEMPLATE_TO_LIST,
      id: item,
      listArgs,
    }
  } else {
    return {
      type: ADD_FOLDER_TEMPLATE_TO_LIST,
      id: item._id,
      listArgs,
    }
  }
}

export const REMOVE_FOLDER_TEMPLATE_FROM_LIST =
  'REMOVE_FOLDER_TEMPLATE_FROM_LIST'
export function removeFolderTemplateFromList(id, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    type: REMOVE_FOLDER_TEMPLATE_FROM_LIST,
    id,
    listArgs,
  }
}

export function fetchList(...listArgs) {
  return dispatch => {
    if (listArgs.length === 0) {
      // default to "all" list if we don't pass any listArgs
      listArgs = ['all']
    }
    dispatch(requestFolderTemplateList(listArgs))
    /**
     * determine what api route we want to hit
     *
     * FOLDER_TEMPLATE: use listArgs to determine what api call to make.
     * if listArgs[0] == null or "all", return list
     *
     * if listArgs has 1 arg, return "/api/folder-template/by-[ARG]"
     *
     * if 2 args, additional checks required.
     *  if 2nd arg is a string, return "/api/folder-template/by-[ARG1]/[ARG2]".
     *    ex: /api/folder-template/by-category/:category
     *  if 2nd arg is an array, though, return "/api/folder-template/by-[ARG1]-list" with additional query string
     *
     * TODO:  make this accept arbitrary number of args. Right now if more
     * than 2, it requires custom checks on server
     */
    let apiTarget = '/api/folder-template'
    if (listArgs.length == 1 && listArgs[0] !== 'all') {
      apiTarget += `/by-${listArgs[0]}`
    } else if (listArgs.length == 2 && Array.isArray(listArgs[1])) {
      // length == 2 has it's own check, specifically if the second param is an array
      // if so, then we need to call the "listByValues" api method instead of the regular "listByRef" call
      // this can be used for querying for a list of folderTemplates given an array of folderTemplate id's, among other things
      apiTarget += `/by-${listArgs[0]}-list?`
      // build query string
      for (let i = 0; i < listArgs[1].length; i++) {
        apiTarget += `${listArgs[0]}=${listArgs[1][i]}&`
      }
    } else if (listArgs.length == 2) {
      // ex: ("author","12345")
      apiTarget += `/by-${listArgs[0]}/${listArgs[1]}`
    } else if (listArgs.length > 2) {
      apiTarget += `/by-${listArgs[0]}/${listArgs[1]}`
      for (let i = 2; i < listArgs.length; i++) {
        apiTarget += `/${listArgs[i]}`
      }
    }
    console.log('apiTarget', apiTarget)
    return apiUtils
      .callAPI(apiTarget)
      .then(json => dispatch(receiveFolderTemplateList(json, listArgs)))
  }
}

/**
 * LIST UTIL METHODS
 */
export const SET_FOLDER_TEMPLATE_FILTER = 'SET_FOLDER_TEMPLATE_FILTER'
export function setFilter(filter, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    filter,
    listArgs,
    type: SET_FOLDER_TEMPLATE_FILTER,
  }
}

export const SET_FOLDER_TEMPLATE_PAGINATION = 'SET_FOLDER_TEMPLATE_PAGINATION'
export function setPagination(pagination, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    listArgs,
    pagination,
    type: SET_FOLDER_TEMPLATE_PAGINATION,
  }
}

export const INVALIDATE_FOLDER_TEMPLATE_LIST = 'INVALIDATE_FOLDER_TEMPLATE_LIST'
export function invalidateList(...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    listArgs,
    type: INVALIDATE_FOLDER_TEMPLATE_LIST,
  }
}

export const SET_FOLDER_TEMPLATE_QUERY = 'SET_FOLDER_TEMPLATE_QUERY'
export function setQuery(query, ...listArgs) {
  if (listArgs.length === 0) {
    listArgs = ['all']
  }
  return {
    type: SET_FOLDER_TEMPLATE_QUERY,
    query,
    listArgs,
  }
}

export const REQUEST_DELETE_ROOT_FOLDER_TEMPLATE =
  'REQUEST_DELETE_ROOT_FOLDER_TEMPLATE'
function requestDeleteStaff(id) {
  return {
    id,
    type: REQUEST_DELETE_ROOT_FOLDER_TEMPLATE,
  }
}

export const RECEIVE_DELETE_ROOT_FOLDER_TEMPLATE =
  'RECEIVE_DELETE_ROOT_FOLDER_TEMPLATE'
function receiveDeleteStaff(id, json) {
  return {
    error: json.message,
    id,
    receivedAt: Date.now(),
    success: json.success,
    type: RECEIVE_DELETE_ROOT_FOLDER_TEMPLATE,
  }
}

export function sendDeleteRootFolderTemplate(id) {
  return dispatch => {
    dispatch(requestDeleteStaff(id))
    return apiUtils
      .callAPI(`/api/folder-template/delete/${id}`, 'DELETE')
      .then(json => dispatch(receiveDeleteStaff(id, json)))
  }
}

export const SET_HEADER_FILTER = 'SET_HEADER_FILTER'
export function setHeaderFilter(filterHeaders) {
  return {
    filterHeaders,
    type: SET_HEADER_FILTER,
  }
}
