import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const PortalPinnedMessage = props => {

  const [fade, setFade] = useState('fade-in');

  useEffect(() => {
    if (props.fadeOut) {
      setFade('fade-out');
      
    } else {
      setFade('fade-in');
    }
  }, [props.fadeOut, props.onAnimationEnd])


  const {firmStore} = props
  if (!firmStore.selected || !firmStore.selected.getItem() || !firmStore.selected.getItem().enableContactBannerMessage) {
    return null
  }

  return (
    <div className={`flex-row items-center justify-center p-1 portal-pinned-box ${fade}`}>
      <div
        className="flex-row items-center justify-around"
        style={{ width: '90vw'}}
      >
        <div className='mr-6'>
            <i class="fa-solid fa-thumbtack" style={{transform: 'rotate(30deg)'}}></i>
        </div>
        <div className="m-1 w-full portal-pinned-box-message" dangerouslySetInnerHTML={{ __html: firmStore.selected.getItem().contactBannerMessage || '' }}></div>
      </div>
    </div>
  )
}

const mapStoreToProps = (store) => {
    return {
      firmStore: store.firm
    }
  }

export default withRouter(
    connect(
      mapStoreToProps
    )(PortalPinnedMessage)
  );
