import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

const columns = [
  {
    field: 'firm',
    headerName: 'Firm Name',
    sortable: false,
    flex: 1,
  },
  {
    field: 'client',
    headerName: 'Client Name',
    sortable: false,
    flex: 1,
  },
  {
    field: 'type',
    sortable: false,
    headerName: 'Type',
    flex: 1,
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: false,
    flex: 1,
  },
  {
    field: 'update',
    sortable: false,
    headerName: '',
    headerClassName: 'no-separator',
    cellClassName: 'no-separator',
    flex: 1,
    renderCell: params => (
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <Link to={`/admin/share-links/${params.row.id}`}>Update</Link>
      </Box>
    ),
  },
]

// import resource components
import AdminShareLinkLayout from '../components/AdminShareLinkLayout.js.jsx'
import * as firmActions from '../../../firm/firmActions'
import * as clientActions from '../../../client/clientActions'
import { useLocation } from 'react-router'
import { useGetShareLinksQuery } from '../../../../config/api/shareLink/shareLink'
import { Box } from '@mui/material'
import { DateTime } from 'luxon'
import { DataGrid } from '@mui/x-data-grid'

const AdminShareLinkList = ({ clientStore, firmStore, dispatch, history }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const page = parseInt(searchParams.get('page')) || 0
  const pageSize = parseInt(searchParams.get('pageSize')) || 50
  const { data, isLoading, isFetching } = useGetShareLinksQuery({
    page,
    pageSize,
  })

  useEffect(() => {
    dispatch(firmActions.fetchListIfNeeded('all'))
    dispatch(clientActions.fetchListIfNeeded('all'))
  }, [dispatch])

  const rows = data?.shareLinks.map(shareLink => ({
    id: shareLink._id,
    firm: firmStore.byId[shareLink._firm]?.name,
    client: clientStore.byId[shareLink._client]?.name,
    type: shareLink.type,
    created_at: DateTime.fromISO(shareLink.created_at).toLocaleString(
      DateTime.DATETIME_SHORT,
    ),
  }))

  const totalCount = data?.pager?.totalCount ? Number(data.pager.totalCount) : 0

  const updateSearch = () => {
    history.push({ search: searchParams.toString() })
  }

  return (
    <AdminShareLinkLayout>
      <Helmet>
        <title>Admin Share Link List</title>
      </Helmet>
      <Breadcrumbs links={location.state?.breadcrumbs || []} />
      <h1>Share Link List</h1>
      <hr />
      <br />
      <Box sx={{ opacity: isFetching || isLoading ? 0.5 : 1 }}>
        <div className="admin-table-wrapper">
          <Box>
            <DataGrid
              sx={{
                '& .MuiDataGrid-scrollbar': {
                  display: 'none',
                },
              }}
              rows={rows}
              columns={columns}
              rowCount={totalCount}
              paginationMode="server"
              paginationModel={{ page, pageSize }}
              onPaginationModelChange={({ page, pageSize }) => {
                searchParams.set('page', page)
                searchParams.set('pageSize', pageSize)
                updateSearch()
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnResize
              disableColumnSelector
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </Box>
    </AdminShareLinkLayout>
  )
}

AdminShareLinkList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    firmStore: store.firm,
    clientStore: store.client,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminShareLinkList))
