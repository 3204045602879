import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

export default function SettingsDropdown({
  archived,
  client,
  setDeleteModal,
  setStatus,
  handleFavoriteToggle,
}) {
  const [anchorElement, setAnchorElement] = useState(null)

  const handleSettingsMenuOpen = event => setAnchorElement(event.currentTarget)
  const handleSettingsMenuClose = () => setAnchorElement(null)

  return (
    <>
      <Tooltip title="Settings">
        <IconButton onClick={handleSettingsMenuOpen}>
          <SettingsIcon color="disabled" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleSettingsMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {archived ? (
          <div>
            <MenuItem
              onClick={() => {
                setDeleteModal({ isOpen: true, client })
                handleSettingsMenuClose()
              }}
            >
              Delete Client
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatus('visible', client)
                handleSettingsMenuClose()
              }}
            >
              Reinstate Client
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem
              onClick={() => {
                setStatus('archived', client)
                handleSettingsMenuClose()
              }}
            >
              Archive Client
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleFavoriteToggle(!client?.isFavorite, client?._id)
                handleSettingsMenuClose()
              }}
            >
              {client?.isFavorite
                ? 'Remove from Favorites'
                : 'Mark as Favorite'}
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  )
}
