
import React,{ useEffect } from 'react';
import { useCookies } from "react-cookie";

const classes = ['imagineshare-ui', 'lexshare-ui'];
const uiSettingCookieName = 'selected-ui';

const createCookieExpDate = () => {
    const dateObj = new Date();
    dateObj.setFullYear(dateObj.getFullYear() + 1);
    return dateObj;
}

//The component checks that all users use the old UI and there are no users that switched to the new UI
const UISwitchChecker = () => {

    // Cookies for storage since localstorage gets wiped on login/logout
    const [uiCookie, setUiCookie] = useCookies([uiSettingCookieName]);

    useEffect(() => {
        document.documentElement.classList.remove('has-ui-switcher');
        setUiCookie(uiSettingCookieName, classes[0], { path: '/', expires: createCookieExpDate()}); 
        document.documentElement.classList.add('imagineshare-ui');
        document.documentElement.classList.remove(classes.find(x => x == 'lexshare-ui'));
    }, []);

    return (
        null
    )
}

export default UISwitchChecker;

export const UILink = () => {

    const [selectedUiCookie, setSelectedUiCookie] = useCookies([uiSettingCookieName]);
    /**
     * Gathers the needed values from localstorage and sets init state
     */
    useEffect(() => {
         // eslint-disable-next-line no-undefined
        if(selectedUiCookie[uiSettingCookieName] == undefined || selectedUiCookie[uiSettingCookieName] == "") {
            setSelectedUiCookie(uiSettingCookieName, classes[0], { path: '/', expires: createCookieExpDate()});            
        } 
        // eslint-disable-next-line no-undefined
    }, []);

    useEffect(() => {
        if (selectedUiCookie[uiSettingCookieName]) {
            document.documentElement.classList.add(selectedUiCookie[uiSettingCookieName]);
            document.documentElement.classList.remove(classes.find(x => x !== selectedUiCookie[uiSettingCookieName]));
        }
    },[selectedUiCookie]);

    const handleClick = () => {
        const newClass = classes.find(x => x != selectedUiCookie[uiSettingCookieName]);
        setSelectedUiCookie(uiSettingCookieName, newClass, { path: '/', expires: createCookieExpDate()});
    }

    return (
        <li className="ui-link">
            { selectedUiCookie[uiSettingCookieName] == 'imagineshare-ui' ? ( 
                <a className="imagineshare-link" onClick={handleClick}>
                    <span className="-icon" style={{ color: '#9F4FF5' }}><i style={{ fontWeight: '600' }} class="fas fa-sparkles"></i></span>
                    <span className="-text">See the new UI!</span>
                </a>
            ) : ( 
                <a className="lexshare-link" onClick={handleClick}>
                    <span className="-icon"><i class="fas fa-desktop-alt"></i></span>
                    <span className="-text">Classic UI</span>
                </a>
            )}
        </li>
    )
}
