import React from 'react'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const bgColor = 'rgb(245, 245, 245, 0.5)'
const ResponseDisplay = ({ response, isLoading, isCopied, onCopy }) => {
  return (
    <Box
      sx={{
        backgroundColor: response ? bgColor : 'transparent',
        borderRadius: 2,
        p: 2,
        display: 'flex',
        flexDirection: 'column', // Ensure items stack vertically
        position: 'relative', // To position the copy icon absolutely
        maxHeight: '300px', // Set your max height here
        overflowY: 'auto', // Make the content scrollable when it exceeds maxHeight
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress size={24} />
          <Typography variant="body1">Retrieving information...</Typography>
        </Box>
      ) : response ? (
        <>
          <Typography
            sx={{
              flexGrow: 1,
              marginBottom: '24px',
              textAlign: 'justify',
            }}
          >
            {response}
          </Typography>
          <IconButton
            onClick={onCopy}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              background: 'rgb(244,244,244)',
            }}
          >
            {isCopied ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <ContentCopyIcon />
            )}
          </IconButton>
        </>
      ) : null}
    </Box>
  )
}

export default ResponseDisplay
