import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../../global/components/Binder.js.jsx'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'

class SingleFolderTemplateOptions extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      isOpen,
      closeAction,
      folderTemplate,
      match,
      dispatch,
      deleteFolderTemplate,
      archiveFolderTemplate,
      reinstateFolderTemplate,
      handleDeleteWarningModal,
      handleArchiveWarningModal,
      deleteShowWarning,
      archiveShowWarning,
      isArchive,
    } = this.props

    return (
      <span
        className="single-file-options"
        style={{ position: 'absolute' }}
      >
        <AlertModal
          alertMessage={
            <div>
              <p>
                Delete folder <b>{folderTemplate && folderTemplate.name}</b>?
              </p>
              <p>
                This will permanently delete the template and all its instances used in workspaces will loose their connection to this template.
              </p>
            </div>
          }
          alertTitle="Delete"
          closeAction={handleDeleteWarningModal}
          confirmAction={deleteFolderTemplate}
          confirmText="Okay"
          isOpen={deleteShowWarning}
          type="danger"
        />
        <AlertModal
          alertMessage={
            <div>
              <p>
                Archive folder <b>{folderTemplate && folderTemplate.name}</b>?
              </p>
              <p>
                This will remove the template from the active list and archive all instances of this template used in workspaces.
              </p>
            </div>
          }
          alertTitle="Archive"
          closeAction={handleArchiveWarningModal}
          confirmAction={archiveFolderTemplate}
          confirmText="Okay"
          isOpen={archiveShowWarning}
          type="danger"
        />
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={250}
            >
              <ul
                className="dropMenu -options-menu"
                style={{ left: '-1.5em' }}
              >
                {isArchive ? (
                  <>
                    <li className="-option">
                      <a onClick={handleDeleteWarningModal}>Delete</a>
                    </li>
                    <li className="-option">
                      <a onClick={reinstateFolderTemplate}>Reinstate</a>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="-option">
                      <Link
                        onClick={closeAction}
                        to={`${match.url}/${folderTemplate._id}/update`}
                      >
                        Update
                      </Link>
                    </li>
                    <li className="-option">
                      <a onClick={handleArchiveWarningModal}>Archive</a>
                    </li>
                  </>
                )}
              </ul>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </span>
    )
  }
}

SingleFolderTemplateOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openQuickTaskModal: PropTypes.func,
}

SingleFolderTemplateOptions.defaultProps = {}

export default withRouter(SingleFolderTemplateOptions)
