import React, { useCallback, useMemo } from 'react'

import { HexColorPicker } from 'react-colorful'

const ColorPicker = ({ value, onChange }) => {
  const onPickerChange = useCallback(
    color => {
      onChange(color.substring(1).toUpperCase())
    },
    [onChange]
  )

  const onInputChange = useCallback(
    e => {
      if (/^#?[0-9a-fA-F]*$/.test(e.target.value)) {
        onChange(e.target.value.toUpperCase().replace('#', '').substring(0, 6))
      }
    },
    [onChange]
  )

  const hexCode = useMemo(() => {
    return `#${value}`
  }, [value])

  return (
    <div className="input-group">
      <label>Color</label>
      <HexColorPicker
        color={hexCode}
        onChange={onPickerChange}
      />
      <input
        value={hexCode}
        onChange={onInputChange}
      />
    </div>
  )
}

export default ColorPicker
