/**
 * View component for /admin/notes/new
 *
 * Creates a new note from a copy of the defaultItem in the note reducer
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as noteActions from '../../noteActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminNoteForm from '../components/AdminNoteForm.js.jsx'
import AdminNoteLayout from '../components/AdminNoteLayout.js.jsx'

class AdminCreateNote extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      note: _cloneDeep(this.props.defaultNote.obj),
      formHelpers: {},
    }
    this._bind('_handleFormChange', '_handleFormSubmit')
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(noteActions.fetchDefaultNote())
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      note: _cloneDeep(nextProps.defaultNote.obj),
    })
  }
  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(this.state, e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  _handleFormSubmit(e) {
    const { dispatch, history } = this.props
    e.preventDefault()
    dispatch(noteActions.sendCreateNote(this.state.note)).then(noteRes => {
      if (noteRes.success) {
        dispatch(noteActions.invalidateList())
        history.push(`/admin/notes/${noteRes.item._id}`)
      } else {
        alert('ERROR - Check logs')
      }
    })
  }

  render() {
    const { location, match } = this.props
    const { note, formHelpers } = this.state
    const isEmpty = !note || note.name === null || note.name === undefined
    return (
      <AdminNoteLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        {isEmpty ? (
          <h2> Loading...</h2>
        ) : (
          <AdminNoteForm
            note={note}
            cancelLink="/admin/notes"
            formHelpers={formHelpers}
            formTitle="Create Note"
            formType="create"
            handleFormChange={this._handleFormChange}
            handleFormSubmit={this._handleFormSubmit}
          />
        )}
      </AdminNoteLayout>
    )
  }
}

AdminCreateNote.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    defaultNote: store.note.defaultItem,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminCreateNote))
