let baseUrl = window.appUrl;
let domain = 'imaginetime';
import _cloneDeep from 'lodash/cloneDeep';

// import
import imagineTimeImage from './imagineTimeImage.js.jsx';

if (baseUrl && baseUrl.indexOf('lexshare.io') > -1) {
    domain = 'lexshare';
} else if (baseUrl && baseUrl.indexOf('mangoshare.com') > -1) {
    domain = 'mangoshare';
}

// img from server mangoshare
imagineTimeImage.poweredby = '/img/powered-by.png';
imagineTimeImage.itAdminLogo = '/img/it-admin-logo.png';
imagineTimeImage.icon = '/img/icon.png';
imagineTimeImage.logoWhite = '/img/logo-white.png';
imagineTimeImage.logoBlack = '/img/logo-black.png';

// img from server lexshare
const lexShareImage = _cloneDeep(imagineTimeImage);
lexShareImage.poweredby = '/img/lexshare-powered-by.png';
lexShareImage.itAdminLogo = '/img/lexshare-it-admin-logo.png';
lexShareImage.icon = '/img/lexshare-icon.png';
lexShareImage.logoWhite = '/img/lexshare-logo-white-blue.png';
lexShareImage.logoBlack = '/img/lexshare-logo-black.png';

const obj = {
    imaginetime: {
        title: 'MangoShare'
        , url: 'https://mangopractice.com'
        , host: 'mangoshare.com'
        , desk: {
            supportUrl: "mailto:mangoshare@mangopractice.com?subject=MangoShare%20Web%20Support%20Case%20Request"
        }
        , email: {
            support: 'mangoshare@mangopractice.com'
            , sale: 'care@mangopractice.com'
            , noreply: 'no-reply@mangoshare.com'
        }
        , image: imagineTimeImage
    }
    , lexshare: {
        title: 'LexShare'
        , url: 'https://www.lexshare.io'
        , host: 'lexshare.io'
        , desk: {
            supportUrl: null // 'https://lexshare.freshdesk.com/support/home' need to build this lin
        }
        , email: {
            support: 'support@lexshare.io'
            , sale: 'sales@lexshare.io'
            , noreply: 'no-reply@lexshare.io'
        }
        , image: lexShareImage
    }
    , mangoshare: {
        title: 'MangoShare'
        , url: 'https://mangoshare.com'
        , host: 'mangoshare.com'
        , desk: {
            supportUrl: "mailto:mangoshare@mangopractice.com?subject=MangoShare%20Web%20Support%20Case%20Request"
        }
        , email: {
            support: 'support@mangoshare.com'
            , sale: 'care@mangopractice.com'
            , noreply: 'no-reply@mangoshare.com'
        }
        , image: imagineTimeImage
    }
}

const brandingName = obj[domain];

console.log('baseUrl', window.appUrl);
console.log('brandingName', brandingName);
console.log('domain', domain);

export default brandingName;
export const getBrandingSettings = () => window.brandingSettings;