// import primary libraries
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

import * as userActions from '../userActions'
import { useIdleTimer } from 'react-idle-timer'
import AlertModal from '../../../global/components/modals/AlertModal.js'

const promptBeforeIdle = 15_000

const InactivityChecker = props => {
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const [timeout, setTimeout] = useState(
    props.inactivityLogoutTime
      ? props.inactivityLogoutTime * 60 * 1000
      : 15 * 60 * 1000, //set to 15 minutes by default, but if no logged in user is provided, the idleTimer will be paused
  )

  const onIdle = () => {
    handleLogout()
    setOpen(false)
  }

  const onActive = () => {
    setOpen(false)
  }

  const onPrompt = () => {
    setOpen(true)
  }

  const onMessage = (data) => {
    const {history} = props
    if (props.isEnabled) {
      if (data.timeout) {
        setTimeout(data.timeout)
      }
      if (data.logout) {
        history.push('/user/login')
      }
    }
  }

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onMessage: onMessage,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    startManually: true
  })

  useEffect(() => {
    if (props.isEnabled) {
      idleTimer.message({timeout: props.inactivityLogoutTime * 60 * 1000}, true)//set inactivity logout time through messages, to cover multiple open tabs
    }
  }, [props.isEnabled, props.inactivityLogoutTime])

  useEffect(() => {
    let interval
    if (props.isEnabled) {
      idleTimer.start()
      interval = setInterval(() => {
        setRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000))
      }, 500)
    }
    return () => {
      clearInterval(interval)
    }
  }, [timeout])

  useEffect(()=>{
    if (!props.isEnabled) {
      if (!props.loggedInUser || !props.loggedInUser._id) {
        idleTimer.message({logout: true})//when user loggs out manually, send a message to all instances so no instance remains active
      }
    }
  }, [props.loggedInUser])

  const handleStillHere = () => {
    idleTimer.activate()
  }

  const handleLogout = () => {
    const { dispatch, history, isEnabled } = props
    if (!isEnabled) {
      return
    }
    dispatch(userActions.sendLogout()).then(action => {
      if (action.success) {
        localStorage.clear()
        // redirect to index
        history.push('/')
      } else {
        history.push('/user/login')//means the user was logged out from another opened tab, so just redirect the user to login page
      }
    })
  }

  if (!props.isEnabled) {
    return null
  }

  return (
    <>
      <AlertModal
        alertMessage={
          <div>
            <h4>Are still here?</h4>
            {`You'll be logged out in ${remaining} seconds if you don't confirm.`}
          </div>
        }
        alertTitle="Inactivity Checker"
        closeAction={() => {
          setOpen(false)
        }}
        confirmAction={handleStillHere}
        declineAction={() => {
          setOpen(false)
        }}
        confirmText="Yes, still here"
        declineText="Close"
        isOpen={open}
        type="warning"
      />
    </>
  )
}

const mapStoreToProps = store => {

  const userStore = store.user
  const firmStore = store.firm
  const staffStore = store.staff

  const selectedFirm = firmStore.selected
  const loggedInStaff = staffStore.loggedInByFirm[selectedFirm.id]
  
  let useInactivityChecker = 
  userStore.loggedIn.user  //user is logged in
  && userStore.loggedIn.user._id
  && selectedFirm.getItem() //selected firm is not null or undefined
  && selectedFirm.getItem().enableAutoLogout
  && selectedFirm.getItem().inactivityLogoutTime
  && selectedFirm.getItem().inactivityLogoutTime > 0
  && staffStore.loggedInByFirm // logged in by firm is not null or undefined
  && loggedInStaff
  && !loggedInStaff.error // make sure no error was thrown, logged in by firm exists(when a logged in user is not in firm staff, error is 'staff not found')
  && loggedInStaff.staff
  && Object.keys(loggedInStaff.staff).length > 0 // staff object is not empty
  && loggedInStaff.staff._user === userStore.loggedIn.user._id
  return {
    firm: selectedFirm.getItem(),
    isEnabled: useInactivityChecker,
    loggedInUser: userStore.loggedIn.user,
    inactivityLogoutTime: selectedFirm.getItem()?.inactivityLogoutTime
  }
}

export default withRouter(connect(mapStoreToProps)(InactivityChecker))
