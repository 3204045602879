/**
 * Reusable component for setting users on a signature request. Either select from existing users or enter freeform user information.
 */

// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';

// import global components
import Binder from '../Binder.js.jsx';

// import form components
import { 
  EmailInput, 
  SelectFromObject, 
  TextInput } from '../forms';
  
class UsersInput extends Binder {
  constructor(props) {
    super(props);
    this.state = {
        userType: props.selectedClient ? 'existing' : 'new' // 'new'
        , selectedClient: props.selectedClient
    }
    this._bind(
        '_handleChangeUserType'
    )
  }

  componentDidUpdate(prevProps) {
    let prevProp = _.cloneDeep(prevProps);
    let prevState = _.cloneDeep(this.state);

    if (!prevProp.selectedClient && !prevState.selectedClient) {
      // do nothing
    } else if (!prevProp.selectedClient && prevState.selectedClient || prevProp.selectedClient && !prevState.selectedClient) {
      this.setState({ userType: "existing", selectedClient: prevProp.selectedClient });
    } else if (prevProp.selectedClient._id !== prevState.selectedClient._id) {
      this.setState({ userType: "existing", selectedClient: prevProp.selectedClient });
    }
  }

  _handleChangeUserType(type) {
    const { currentIndex, user, authType,fieldName } = this.props;
    this.setState({
      userType: type
    })
    // Clear the user info when the type changes.
    const event = {
      target: {
        name: `${fieldName}[${currentIndex}]`
        , value: {
          firstname: ''
          , lastname: ''
          , username: ''
        }
      }
    }
    this.props.change(event)
  }

  render() {
    const {
      allowSharedEmail
      , change
      , currentIndex
      , handleUserChange
      , user
      , userListItems
      , usersId
      , selectedClient
      , fieldName
      , removeUser
      , users
    } = this.props;
    
    // const userType = selectedClient ? this.state.userType : "new";
    const userType = selectedClient ? this.state.userType : "new";

    return (
      <div>
        {
          userType === 'existing' ?
          <div>
            <div style={{ width: "100%", display: "inline-flex" }}>
              <label style={{ marginRight: "auto" }}>{`User #${currentIndex + 1}`}</label>
              <button className="yt-btn xx-small link u-pullRight" style={{maxHeight: '2.5em'}} onClick={() => removeUser(currentIndex)}><i className="far fa-times" /></button>
            </div>
            <SelectFromObject 
              change={handleUserChange}
              display={'displayName'}
              filterable={false}
              label={null}
              name={`${fieldName}[${currentIndex}]`}
              value={'_id'}
              items={userListItems}
              required={true}
              selected={user._id}
              placeholder='Select a user'
              signersId={usersId || null}
            />
            <button className="yt-btn xx-small link info" onClick={() => this._handleChangeUserType('new')}>
              <i className="fal fa-plus"/> Or create new
            </button>
          </div>
          :
          userType === 'new' ?
          <div className="input-group">
            <div style={{ width: "100%", display: "inline-flex" }}>
              <label style={{ marginRight: "auto" }}>{`User #${currentIndex + 1}`}</label>
              <button className="yt-btn xx-small link u-pullRight" style={{maxHeight: '2.5em'}} onClick={() => removeUser(currentIndex)}><i className="far fa-times" /></button>
            </div>
            <TextInput
              autoFocus={true}
              change={change}
              placeholder='First name'
              name={`${fieldName}[${currentIndex}]['firstname']`}
              required={true}
              value={user['firstname']}
            />
            <TextInput
              change={change}
              name={`${fieldName}[${currentIndex}]['lastname']`}
              placeholder='Last name'
              required={true}
              value={user['lastname']}
            />
            <EmailInput
              allowComment={allowSharedEmail}
              change={change}
              helpText={user.sharedEmail ? 'For shared email addresses, please enter a comment. e.g. name(spouse)@domain.com' : ''}
              name={`${fieldName}[${currentIndex}]['username']`}
              placeholder='Email'
              required={true}
              value={user['username']}
              error={user['emailError']}
              errorName={`${fieldName}[${currentIndex}]['emailError']`}
            />
            {
              selectedClient ?
              <button className="yt-btn xx-small link danger" onClick={() => this._handleChangeUserType('existing')}>Or choose from existing users</button>
              : null
            }
          </div>
          :
          null
        }
      </div>
    )
  }
}

UsersInput.propTypes = {
  allowSharedEmail: PropTypes.bool
  , change: PropTypes.func.isRequired
  , currentIndex: PropTypes.number.isRequired
  , handleUserChange: PropTypes.func.isRequired
  , user: PropTypes.object.isRequired
  // , userListItems: PropTypes.arrayOf(PropTypes.object).isRequired
}

UsersInput.defaultProps = {
  allowSharedEmail: false
}

export default UsersInput;
