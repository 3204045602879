const base64UrlEncode = arr => {
	return btoa(String.fromCharCode.apply(null, arr))
		.replaceAll('=', '')
		.replaceAll('+', '-')
		.replaceAll('/', '_')
}

const randomValue = (length = 32) => {
	const arr = new Uint8Array(length)
	crypto.getRandomValues(arr)

	return base64UrlEncode(arr)
}

const createPKCEVerifier = () => {
	return randomValue(32)
}

const createPKCEChallenge = verifier => {
	const utf8 = new TextEncoder().encode(verifier)

	return crypto.subtle.digest('SHA-256', utf8).then(buffer => {
		const view = new Uint8Array(buffer)
		return base64UrlEncode(view)
	})
}

const PKCE = {
	base64UrlEncode,
	randomValue,
	createPKCEVerifier,
	createPKCEChallenge,
}

export default PKCE
