import React, { useMemo } from 'react'

import Color from 'color'

const TagPill = ({ tag, children }) => {
  const hexCode = useMemo(() => {
    return `#${tag.color}`
  }, [tag.color])

  const isLight = useMemo(() => {
    return Color(hexCode).isLight()
  }, [hexCode])

  return (
    <span
      className={`tag-pill ${isLight ? 'light' : ''}`}
      style={{ backgroundColor: `#${tag.color}` }}
    >
      {children ?? tag.name}
    </span>
  )
}

export default TagPill
