/**
 * View component for /firm/:firmId/workspaces/:clientId/files
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// import third-party libraries
import { Helmet } from 'react-helmet'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { DateTime } from 'luxon'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import FileInput from '../../../global/components/forms/FileInput.js.jsx'

import * as fileActions from '../../file/fileActions'
import * as requestTaskActions from '../requestTaskActions'
import * as taskActivityActions from '../../taskActivity/taskActivityActions'
import * as userActions from '../../user/userActions'
import {
  createFolders,
  notifyUpload,
  createItemUploads,
} from '../../file/fileActions'

class SingleRequestTask extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      refetch: null,
      submitted: false,
      submitting: false,
      uploadItems: {},
    }
    this._bind('_handleFilesChange', '_handleSubmitFiles')
  }

  componentDidMount() {
    const { dispatch, match, requestTask } = this.props

    const clientId = match.params.clientId
      ? match.params.clientId
      : requestTask && requestTask._client
      ? requestTask._client
      : null

    if (clientId) {
      dispatch(userActions.fetchListIfNeeded('_client', clientId)).then(
        response => {
          console.log('this the response', response)
        }
      )
    }
  }

  _handleFilesChange(files) {
    // console.log('-------- files -----');
    // console.log(files);
    this.setState({ files })
  }

  async _handleSubmitFiles(e) {
    const {
      dispatch,
      match,
      requestTask,
      selectedFirm = {},
      userEmail,
      fromShareLink,
      loggedInUser,
    } = this.props
    if (e) {
      e.preventDefault()
    }

    if (requestTask && requestTask.status !== 'published') {
      return
    }

    // convert to a FormData object to allow uploading files
    let { files } = this.state
    files = files.filter(file => file.isValidFileName)
    if (files.length < 1) {
      alert('No files present')
    } else {
      const params = {
        _client: requestTask._client,
        _firm: selectedFirm._id,
        status: 'visible',
        type: 'request-task',
        hex: requestTask.hex,
      }

      if (
        userEmail &&
        fromShareLink &&
        !(loggedInUser && loggedInUser.username) &&
        requestTask &&
        requestTask.assignee &&
        requestTask.assignee.length
      ) {
        let user = requestTask.assignee.filter(
          assignee => _.lowerCase(assignee.username) == _.lowerCase(userEmail)
        )
        console.log('user 1', user)
        user = user && user.length ? user[0] : null
        if (user && user.firstname && user.lastname) {
          params.uploadName = `${user.firstname} ${user.lastname}`
        } else if (user && user.username) {
          params.uploadName = `${user.username}`
        } else {
          params.uploadName = `(not verified user)`
        }
      }

      this.setState({ submitting: true })

      try {
        const createdFolders = []
        const uploads = dispatch(
          createItemUploads({
            files,
            folders: createdFolders,
            params,
          })
        ).map(async upload => {
          for await (const { type, value } of upload) {
            if (type === `progress`) {
              const {
                root: { _id, name },
                percent,
              } = value
              this.setState(prevState => ({
                uploadItems: {
                  ...prevState.uploadItems,
                  [_id]: {
                    ...prevState.uploadItems[_id],
                    name,
                    percent,
                  },
                },
              }))
            }

            if (type === `failedMessages`) {
              const {
                root: { _id, name },
                failedMessages,
              } = value

              this.setState(prevState => ({
                uploadItems: {
                  ...prevState.uploadItems,
                  [_id]: {
                    ...prevState.uploadItems[_id],
                    name,
                    failedMessages,
                  },
                },
              }))
            }

            if (type === `done`) {
              return value
            }
          }
        })

        const results = await Promise.allSettled(uploads)
        const { roots, createdFiles, failedMessages } = results
          .map(({ value }) => value)
          .reduce(
            (acc, { root, createdFiles, failedMessages }) => {
              acc.roots = [...acc.roots, root]
              acc.createdFiles = [...acc.createdFiles, ...createdFiles]
              acc.failedMessages = [...acc.failedMessages, ...failedMessages]
              return acc
            },
            {
              roots: [],
              createdFiles: [],
              failedMessages: [],
            }
          )

        if (createdFiles.length) {
          requestTask['_firm'] = selectedFirm._id
          createdFiles.map(file => {
            if (
              requestTask &&
              requestTask._returnedFiles &&
              !requestTask._returnedFiles.includes(file._id)
            ) {
              requestTask._returnedFiles.push(file._id)
            }
          })
          if (fromShareLink && !(loggedInUser && loggedInUser.username)) {
            requestTask['user'] = { username: userEmail }
          }
          dispatch(
            requestTaskActions.sendUpdateRequestTaskbyClientUser(requestTask)
          ).then(json => {
            if (!json.success) {
              alert(json.error)
            } else {
              dispatch(
                fileActions.fetchNewFileList(
                  { files: createdFiles, success: true },
                  ['~client', match.params.clientId, 'status', 'visible']
                )
              )
              dispatch(
                fileActions.fetchListIfNeeded(
                  '~client',
                  match.params.clientId,
                  'status',
                  'visible'
                )
              )

              if (json.taskActivity) {
                dispatch(
                  taskActivityActions.addSingleTaskActivityToMap(
                    json.taskActivity
                  )
                )
                dispatch(
                  taskActivityActions.addTaskActivityToList(
                    json.taskActivity,
                    ...['_requestTask', requestTask._id]
                  )
                )
              }
            }
          })
        }
      } catch (e) {
        alert(e.message)
      }

      this.setState({
        submitting: false,
        submitted: true,
        uploadItems: {},
      })
    }
  }

  render() {
    const { requestTask, match, selectedFirm, fromShareLink, userMap } =
      this.props
    const { files, submitted, submitting, uploadItems } = this.state

    const statusText = requestTask
      ? requestTask.status === 'published'
        ? 'In Progress'
        : requestTask.status === 'completed'
        ? 'Completed'
        : 'Please wait...'
      : ''
    const statusClassIcon = requestTask
      ? requestTask.status === 'published'
        ? 'fal fa-check-circle fa-2x'
        : 'fas fa-check-circle fa-2x'
      : 'fal fa-circle fa-2x'

    if (!requestTask) {
      return null
    }
    // const viewActivityLink = fromShareLink ? `/request/request-task/${requestTask.hex}/${requestTask._id}/activity` : `/portal/${match.params.clientId}/request-task/${requestTask._id}/activity`;

    const failedMessages = Object.values(uploadItems)
      .filter(({ failedMessages }) => failedMessages)
      .flatMap(({ failedMessages }) => failedMessages)

    return (
      <div
        className="-overview-content"
        style={{ margin: 0 }}
      >
        <div className="content-detail">
          {fromShareLink ? null : (
            <div className="yt-row center-vert">
              <Link
                className="-back-link"
                to={{
                  pathname: `/portal/${match.params.clientId}/request-task/${requestTask._id}/activity`,
                  isViewing: 'portal',
                }}
              >
                View Task Activity
              </Link>
            </div>
          )}
          <div className="yt-row center-vert">
            <label className="-ov-title">Status:</label>
            <i
              className={statusClassIcon}
              aria-hidden="true"
            ></i>
            <label>{statusText}</label>
          </div>
          {requestTask.status === 'published' ? (
            <div className="yt-row center-vert">
              <label className="-ov-title">Request Date:</label>
              <label>
                {DateTime.fromISO(requestTask.requestDate).toLocaleString(
                  DateTime.DATE_SHORT
                )}
              </label>
            </div>
          ) : null}
          <div className="yt-row center-vert">
            <label className="-ov-title">Category:</label>
            <label>{requestTask.category}</label>
          </div>
          <div className="yt-row center-vert">
            <label className="-ov-title">Due Date:</label>
            <label>
              {DateTime.fromISO(requestTask.dueDate).toLocaleString(
                DateTime.DATE_SHORT
              )}
            </label>
          </div>
          <div className="yt-row center-vert">
            <label className="-ov-title">Description:</label>
            <label>{requestTask.description}</label>
          </div>
          <div className="yt-row center-vert">
            <label className="-ov-title -hide">Assignee:</label>
            <div className="left-title-list">
              <label className="-ov-title">Assignee:</label>
              {requestTask.assignee.map((assignee, i) =>
                assignee && assignee._id ? (
                  userMap[assignee._id] ? (
                    <label key={i}>
                      {userMap[assignee._id].firstname}{' '}
                      {userMap[assignee._id].lastname}
                    </label>
                  ) : (
                    <label key={i}>
                      {assignee.firstname} {assignee.lastname}
                    </label>
                  )
                ) : null
              )}
            </div>
          </div>
        </div>
        {requestTask &&
        requestTask.status === 'completed' ? null : !submitted ? (
          !submitting ? (
            <div className="-request-file-input">
              <FileInput
                change={this._handleFilesChange}
                multiple={true}
                required={true}
              />
              <button
                className="yt-btn small block info"
                onClick={this._handleSubmitFiles}
                disabled={
                  !files ||
                  files.length < 1 ||
                  submitting ||
                  files.every(f => !f.isValidFileName)
                }
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          ) : (
            Object.entries(uploadItems).map(
              ([id, { name, percent, failedMessages }]) => (
                <div
                  key={id}
                  style={{ padding: '1em' }}
                >
                  <p>
                    <small>
                      <strong>{name}</strong>
                    </small>

                    {failedMessages && failedMessages.length
                      ? ` - ${failedMessages[0]}`
                      : ` - ${percent}%`}
                  </p>
                  <div className={`progress-bar-${percent}`}>
                    <div className="-progress">
                      <div className="-complete"></div>
                    </div>
                  </div>
                </div>
              )
            )
          )
        ) : (
          <div
            className="hero"
            style={{ paddingTop: '50px' }}
          >
            {!failedMessages.length ? (
              <div className="u-centerText">
                <h3>Submitted successfully.</h3>
                <button
                  className="yt-btn small info"
                  onClick={() => this.setState({ submitted: false, files: [] })}
                >
                  Upload more files
                </button>
              </div>
            ) : (
              <div className="u-centerText">
                <h3>Something went wrong.</h3>
                {Object.entries(uploadItems).map(
                  ([id, { name, failedMessages }], idx) => (
                    <div
                      key={`${name}_${idx}`}
                      style={{ textAlign: 'left' }}
                    >
                      {failedMessages && failedMessages.length ? (
                        <p className="u-danger">
                          <small>
                            <strong>{name}</strong>
                          </small>
                          {` - ${failedMessages[0]}`}
                        </p>
                      ) : (
                        <p>
                          <small>
                            <strong>{name}</strong>
                          </small>
                          {` - Successfully uploaded`}
                        </p>
                      )}
                    </div>
                  )
                )}
                <button
                  className="yt-btn small warning"
                  onClick={() => this.setState({ submitted: false, files: [] })}
                >
                  Try again
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

SingleRequestTask.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

SingleRequestTask.defaultProps = {}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    loggedInUser: store.user.loggedIn.user,
    userMap: store.user.byId,
  }
}

export default withRouter(connect(mapStoreToProps)(SingleRequestTask))
