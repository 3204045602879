import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../../global/components/Binder.js.jsx'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'

class SingleClientUserOptions extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      showAlertModal: false,
      submitting: false,
    }
    this._bind('_toggleAlertModal', '_handleSubmit')
  }

  _toggleAlertModal() {
    this.setState({ showAlertModal: !this.state.showAlertModal })
  }

  _handleSubmit() {
    const { setStatus } = this.props
    this.setState({ submitting: true }, () => {
      setStatus('deleted')
    })
  }

  render() {
    const {
      isOpen,
      setStatus,
      handleRemoveFromClient,
      singleClientUser,
      archived,
      match,
      closeAction,
    } = this.props

    const { showAlertModal, submitting } = this.state

    return (
      <span
        className="single-file-options"
        style={
          singleClientUser
            ? { position: 'absolute' }
            : { position: 'absolute', right: '120px' }
        }
      >
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={250}
            >
              {singleClientUser ? (
                <ul className="dropMenu -options-menu">
                  {archived ? (
                    <li className="-option">
                      <a
                        className="-delete-link"
                        onClick={() => this.setState({ showAlertModal: true })}
                      >
                        Remove from Client
                      </a>
                    </li>
                  ) : null}
                  <li className="-option">
                    <a
                      onClick={() =>
                        setStatus(archived ? 'active' : 'archived')
                      }
                    >
                      {archived ? 'Reinstate Contact' : 'Archive Contact'}
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="dropMenu -options-menu">
                  {archived ? (
                    <li className="-option">
                      <Link
                        onClick={closeAction}
                        to={`/firm/${match.params.firmId}/workspaces/${match.params.clientId}/contacts`}
                      >
                        View Contacts
                      </Link>
                    </li>
                  ) : (
                    <li className="-option">
                      <Link
                        onClick={closeAction}
                        to={`/firm/${match.params.firmId}/workspaces/${match.params.clientId}/contacts/archived`}
                      >
                        View Archive
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </CSSTransition>
          ) : null}
        </TransitionGroup>
        <AlertModal
          alertMessage={'Are you sure? This cannot be undone.'}
          alertTitle={'Remove from Client?'}
          closeAction={this._toggleAlertModal}
          confirmAction={this._handleSubmit}
          confirmText={'Delete'}
          declineAction={this._toggleAlertModal}
          declineText={'Cancel'}
          isOpen={showAlertModal}
          type={'danger'}
          disableConfirm={submitting}
        ></AlertModal>
      </span>
    )
  }
}

SingleClientUserOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openQuickTaskModal: PropTypes.func,
}

SingleClientUserOptions.defaultProps = {}

export default withRouter(SingleClientUserOptions)
