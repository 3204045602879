import React, { useCallback } from 'react'

import CommonView from './CommonView.js.jsx'

const SsoError = () => {
	const query = new URLSearchParams(window.location.search)

	const error = query.get('message')

	const onAction = useCallback(() => {
		window.location = '/'
	}, [])

	return (
		<CommonView
			isError={true}
			message={error || 'Something went wrong. Please try again later.'}
			actionText="Go Back"
			onClick={onAction}
		/>
	)
}

export default SsoError
