/**
 * View component for /user/forgot-password
 *
 * allows user to submit a password reset request to their email on record.
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import { Helmet } from 'react-helmet'
import { RECAPTCHA_SITE_KEY } from '../../../config/constants.js';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// import actions
import * as userActions from '../userActions'

// import global components
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import Binder from '../../../global/components/Binder.js.jsx'

// import form components
import EmailInput from '../../../global/components/forms/EmailInput.js.jsx'

// import user components
import UserLayout from '../components/UserLayout.js.jsx'

class ForgotPassword extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      isErrorModalOpen: false,
      isSuccessModalOpen: false,
      username: '',
      emailError: null,
      captchaToken: '',
      showCaptcha: false,
    }
    this._bind(
      '_closeSuccessModal',
      '_handleFormChange',
      '_handleFormSubmit',
      '_handleCaptchaVerify',
      '_handleSendForgotPassword'
    )
    this.captchaRef = React.createRef()
  }

  _handleFormChange(e) {
    var nextState = this.state
    nextState[e.target.name] = e.target.value
    this.setState(nextState)
  }

  _handleFormSubmit(e) {
    e.preventDefault()
    if (this.state.emailError) {
      return
    }
    this.setState({ showCaptcha: true })
  }

  _closeSuccessModal() {
    this.setState({ isSuccessModalOpen: false })
    const { history } = this.props
    history.push('/')
  }

  _handleCaptchaVerify(token) {
    // console.log('token', token);
    this.setState({captchaToken: token});

    //verify captcha token
    if (token) {
      console.log('verify captcha token')
      setTimeout(() => {
        this._handleSendForgotPassword()
      }, 1500)
    }
  }

  _handleSendForgotPassword() {
    const { dispatch } = this.props
    const { captchaToken } = this.state

    dispatch(
      userActions.sendForgotPasswordWithCaptcha(
        this.state.username,
        captchaToken
      )
    ).then(action => {
      if (action.success) {
        this.setState({ isSuccessModalOpen: true })
      } else {
        this.setState({
          errorMessage: action.error,
          isErrorModalOpen: true,
        })
      }
    })
  }

  render() {
    const { user } = this.props
    const { showCaptcha } = this.state

    return (
      <UserLayout>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <div className="yt-container">
          <div className="yt-row center-horiz">
            <div className="form-container -skinny">
              <form
                name="forgotPassowrdForm"
                className="user-form"
                onSubmit={this._handleFormSubmit}
              >
                <h2> Forgot Password </h2>
                <hr />
                <EmailInput
                  name="username"
                  label="Email Address"
                  value={this.state.username}
                  change={this._handleFormChange}
                  required={true}
                  error={this.state.emailError}
                  errorName="emailError"
                />
                <div className="input-group">
                  <div className="yt-row right">
                    <Link
                      to={'/user/login'}
                      className="yt-btn link"
                    >
                      {' '}
                      Back To Login{' '}
                    </Link>
                    <button
                      className="yt-btn "
                      type="submit"
                      disabled={
                        user.isFetching ||
                        this.state.emailError ||
                        !this.state.username
                      }
                    >
                      {user.isFetching ? (
                        <span>Sending...</span>
                      ) : (
                        <span>Reset Password</span>
                      )}
                    </button>
                    {showCaptcha ? (
                      <div className="recaptcha-container">
                        <GoogleReCaptchaProvider
                          reCaptchaKey={RECAPTCHA_SITE_KEY}
                          useEnterprise={true}
                          scriptProps={{
                            async: true,
                            defer: true,
                            appendTo: 'head',
                            nonce: undefined,
                          }}
                        >
                          <GoogleReCaptcha
                            onVerify={this._handleCaptchaVerify}
                          />
                        </GoogleReCaptchaProvider>
                      </div>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <AlertModal
            alertMessage="You should receive an email shortly with password reset instructions."
            alertTitle="Success"
            closeAction={this._closeSuccessModal}
            confirmAction={this._closeSuccessModal}
            confirmText="Got it"
            isOpen={this.state.isSuccessModalOpen}
            type="info"
          />
          <AlertModal
            alertMessage={
              <div>
                <strong>{this.state.errorMessage}</strong>
                <br />
                <div>You may need to contact support.</div>
              </div>
            }
            alertTitle="Error"
            closeAction={() => this.setState({ isErrorModalOpen: false })}
            confirmAction={() => this.setState({ isErrorModalOpen: false })}
            confirmText="Try again"
            isOpen={this.state.isErrorModalOpen}
            type="danger"
          />
        </div>
      </UserLayout>
    )
  }
}

ForgotPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return { user: store.user.loggedIn }
}

export default withRouter(connect(mapStoreToProps)(ForgotPassword))
