// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import ACTIONS
import * as staffClientActions from '../../staffClientActions'
import * as clientActions from '../../../client/clientActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'
import routeUtils from '../../../../global/utils/routeUtils'

// import resource components
import PracticeStaffClientTableListItem from './PracticeStaffClientTableListItem.js.jsx'
import { TextInput } from '../../../../global/components/forms'
import ExportList from '../../../../global/components/helpers/ExportList.js.jsx'

class PracticeStaffClientList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      viewingAs: this.props.viewingAs,
      showMobileActionOption: false,
      checked: false,
      selectedStaffId: [],
      unassignStaffModalOpen: false,
      deleteProcess: false,
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        email: { disableSearch: true, searchText: '' },
        permission: { disableSearch: true, searchText: '' },
        staffStatus: { disableSearch: true, searchText: '' },
        status: { disableSearch: true, searchText: '' },
        dateAssign: { disableSearch: true, searchText: '' },
      },
    }
    this._bind(
      '_handleSelectedTagsChange',
      '_handleCloseMobileOption',
      '_handleSelectedAllStaffs',
      '_handleSelectedStaff',
      '_handleUnassignStaff',
      '_handleFilter',
      '_toggleHeaderSearch',
      '_changeHeaderSearch'
    )
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const listArgsObj = {
      _firm: match.params.firmId,
      status: 'visible',
    }
    dispatch(
      clientActions.fetchListIfNeeded(
        ...routeUtils.listArgsFromObject(listArgsObj)
      )
    )
  }

  _handleSelectedTagsChange(e) {
    console.log('handleSelectedTagsChange', e)
    // additional logic here if we want to break out tags into multiple filters, ie years
    // for now e.target.value contains all of the filters, but may only contain a subset
    // the output to the parent should be the entire list of tags
    this.props.handleFilter(e)
  }

  _handleCloseMobileOption(e) {
    e.stopPropagation()
    this.setState({ showMobileActionOption: false })
  }

  _handleSelectedAllStaffs() {
    const { paginatedList } = this.props
    if (!this.state.checked) {
      const staffIds = paginatedList.map(staff => staff._id)
      this.setState({ selectedStaffId: staffIds, checked: true })
    } else {
      this.setState({ selectedStaffId: [], checked: false })
    }
  }

  _handleSelectedStaff(staffId) {
    const { paginatedList } = this.props
    let newStaffIds = _.cloneDeep(this.state.selectedStaffId)
    let checked = false
    if (newStaffIds.indexOf(staffId) === -1) {
      newStaffIds.push(staffId)
      checked = paginatedList.length === newStaffIds.length
    } else {
      newStaffIds.splice(newStaffIds.indexOf(staffId), 1)
    }
    this.setState({
      selectedStaffId: newStaffIds,
      checked,
    })
  }

  _handleUnassignStaff() {
    const { dispatch, match } = this.props
    const { selectedStaffId } = this.state
    this.setState({ unassignStaffModalOpen: false, deleteProcess: true })

    dispatch(
      staffClientActions.sendBulkDelete(selectedStaffId, match.params.firmId)
    ).then(json => {
      if (json.success) {
        this.setState({
          deleteProcess: false,
          selectedStaffId: [],
          checked: false,
        })
      }
    })
  }

  _handleFilter(sortBy) {
    const { utilStaffClientStore, dispatch, staffClientListArgsObj } =
      this.props
    const listArgsObj = routeUtils.listArgsFromObject(staffClientListArgsObj)
    let newFilter = utilStaffClientStore.filter
    if (
      utilStaffClientStore.filter &&
      utilStaffClientStore.filter.sortBy &&
      utilStaffClientStore.filter.sortBy.indexOf('-') < 0
    ) {
      sortBy = '-' + sortBy
    } else {
      sortBy = sortBy.substring(0)
    }
    newFilter.sortBy = sortBy
    dispatch(staffClientActions.setFilter(newFilter, ...listArgsObj))
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { staffStore, dispatch } = this.props
    const { filterHeaders } = staffStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(staffClientActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { staffStore, dispatch } = this.props
    const { filterHeaders } = staffStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(staffClientActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  render() {
    const {
      allTags,
      handleSetPagination,
      paginatedList,
      utilStaffClientStore,
      selectedTagIds,
      staffMap,
      userMap,
      match,
      handleQuery,
      sortBy,
      orderedList,
      selectedClient,
    } = this.props

    const {
      showMobileActionOption,
      checked,
      selectedStaffId,
      unassignStaffModalOpen,
      deleteProcess,
      searchHeaderColumns,
    } = this.state

    const disableButton = !selectedStaffId.length

    const defaultHeaders = [
      {
        label: 'Name',
        key: 'userFullName',
      },
      {
        label: 'Email',
        key: 'userEmail',
      },
      {
        label: 'Permissions',
        key: 'permission',
      },
      {
        label: 'Staff Status',
        key: 'staffStatus',
      },
      {
        label: 'Visibility',
        key: 'status',
      },
      {
        label: 'Date Assigned',
        key: 'created_at',
      },
    ]

    return (
      <div className="file-list-wrapper">
        <AlertModal
          alertMessage={
            <div>
              <h4>Are you sure?</h4>
              {`Do you want to unassign ${
                selectedStaffId.length > 1 ? 'these staffs' : 'this staff'
              } from client?`}
            </div>
          }
          alertTitle="Unassign staff"
          closeAction={() => this.setState({ unassignStaffModalOpen: false })}
          confirmAction={() => this._handleUnassignStaff()}
          confirmText="Yes"
          declineText="Never mind"
          isOpen={unassignStaffModalOpen}
          type="warning"
        />
        {paginatedList.length > 0 ? (
          <div className="yt-toolbar">
            <div className="yt-tools space-between">
              <div className="-filters -left">
                {/* TODO: Set up filters. */}
                {/* <strong>Filter by: </strong>
                <FilterBy
                  applyFilter={this._handleSelectedTagsChange}
                  displayKey="name"
                  items={allTags || []}
                  label="Tags"
                  name="_tags"
                  selected={selectedTagIds}
                  valueKey="_id"
                /> */}
                <ExportList
                  data={[...orderedList]}
                  gridName={`${
                    selectedClient && selectedClient.name
                  }_assigned_staff`}
                  columns={[...defaultHeaders]}
                  hasExportAccess={true}
                />
              </div>
              <div className="-options -right">
                {/* <DisplayAsButtons
                  displayAs={this.state.viewingAs}
                  displayGrid={() => this.setState({viewingAs: 'grid'})}
                  displayTable={() => this.setState({viewingAs: 'table'})}
                /> */}
                <button
                  className="yt-btn x-small link info"
                  disabled={disableButton}
                  style={{ display: 'inline-flex' }}
                  onClick={() =>
                    this.setState({ unassignStaffModalOpen: true })
                  }
                >
                  {deleteProcess ? (
                    <p className="-unassign">
                      Removing<span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </p>
                  ) : (
                    'Unassigned staff'
                  )}
                  {deleteProcess ? null : selectedStaffId.length ? (
                    <span> — {selectedStaffId.length}</span>
                  ) : null}
                </button>
                <button
                  className="yt-btn x-small rounded info"
                  onClick={this.props.handleOpenAddStaffModal}
                >
                  Assign staff
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <hr className="-mobile-yt-hide" />
        <table
          className="yt-table firm-table -workspace-table truncate-cells"
          style={{ marginTop: 0 }}
        >
          <caption
            className="-border-bottom"
            style={{ borderBottom: '1px solid #aaa' }}
          >
            <PageTabber
              totalItems={
                utilStaffClientStore &&
                utilStaffClientStore.items &&
                utilStaffClientStore.items.length
              }
              totalPages={Math.ceil(
                utilStaffClientStore &&
                  utilStaffClientStore.items &&
                  utilStaffClientStore.items.length /
                    utilStaffClientStore.pagination &&
                  utilStaffClientStore.pagination.per
              )}
              pagination={
                utilStaffClientStore && utilStaffClientStore.pagination
              }
              setPagination={handleSetPagination}
              setPerPage={this.props.setPerPage}
              viewingAs="top"
              itemName="assigned staff"
              handleQuery={handleQuery}
              searchText="Search..."
              firmId={match.params.firmId}
              clientId={match.params.clientId}
              isChanged={true}
            />
          </caption>
          <thead>
            <TableHeaderColumn
              trClasses=""
              trStyles={{}}
              checked={checked}
              sortBy={sortBy}
              handleSelectedAllStaffs={this._handleSelectedAllStaffs}
              handleFilter={this._handleFilter}
              toggleHeaderSearch={this._toggleHeaderSearch}
              changeHeaderSearch={this._changeHeaderSearch}
              searchHeaderColumns={searchHeaderColumns}
            />
          </thead>
          <tbody>
            <TableHeaderColumn
              trClasses="-table-header-mobile-layout"
              trStyles={{ display: 'none' }}
              checked={checked}
              sortBy={sortBy}
              handleSelectedAllStaffs={this._handleSelectedAllStaffs}
              handleFilter={this._handleFilter}
              toggleHeaderSearch={this._toggleHeaderSearch}
              changeHeaderSearch={this._changeHeaderSearch}
              searchHeaderColumns={searchHeaderColumns}
            />
            {paginatedList.length > 0 ? (
              paginatedList.map((staffClient, i) => (
                <PracticeStaffClientTableListItem
                  staffClient={staffClient}
                  key={staffClient._id}
                  staff={staffClient && staffClient.staff}
                  user={staffClient && staffClient.user}
                  handleSelectedStaff={this._handleSelectedStaff}
                  checked={selectedStaffId.includes(staffClient._id)}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7">
                  <div className="hero -empty-hero">
                    <div className="u-centerText">
                      <h3>
                        <em>No staff assigned to this client</em>
                      </h3>
                      <br />
                      <button
                        className="yt-btn rounded info"
                        onClick={this.props.handleOpenAddStaffModal}
                      >
                        Assign staff members
                      </button>
                      <br />
                      <br />
                      <p>
                        Staff members without owner/admin privileges will only
                        be able to view Client Workspaces assigned to them.{' '}
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

const TableHeaderColumn = ({
  trClasses,
  trStyles,
  checked,
  sortBy,
  handleFilter,
  handleSelectedAllClientUser,
  toggleHeaderSearch,
  changeHeaderSearch,
  searchHeaderColumns,
}) => {
  return (
    <tr
      className={trClasses}
      style={trStyles}
    >
      <th>
        <div style={{ width: '25px', display: 'inline-flex' }}>
          <CheckboxInput
            name="staffs"
            value={checked}
            checked={checked}
            change={handleSelectedAllClientUser}
          />
        </div>
      </th>
      <th className="sortable _30">
        <div
          className="-table-header-title"
          onClick={() => handleFilter('staff')}
        >
          Name &nbsp;
          {sortBy && sortBy == 'staff' ? (
            <i className="fad fa-sort-down"></i>
          ) : sortBy && sortBy == '-staff' ? (
            <i className="fad fa-sort-up"></i>
          ) : (
            <i className="fad fa-sort"></i>
          )}
        </div>
        <div className="-table-header-search">
          <CheckboxInput
            name="name"
            value={!searchHeaderColumns.name.disableSearch}
            checked={!searchHeaderColumns.name.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="name"
            value={searchHeaderColumns.name.searchText}
            disabled={searchHeaderColumns.name.disableSearch}
            placeholder="Search name"
          />
        </div>
      </th>
      <th className="sortable _30">
        <div
          className="-table-header-title"
          onClick={() => handleFilter('email')}
        >
          Email &nbsp;
          {sortBy && sortBy == 'email' ? (
            <i className="fad fa-sort-down"></i>
          ) : sortBy && sortBy == '-email' ? (
            <i className="fad fa-sort-up"></i>
          ) : (
            <i className="fad fa-sort"></i>
          )}
        </div>
        <div className="-table-header-search">
          <CheckboxInput
            name="email"
            value={!searchHeaderColumns.email.disableSearch}
            checked={!searchHeaderColumns.email.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="email"
            value={searchHeaderColumns.email.searchText}
            disabled={searchHeaderColumns.email.disableSearch}
            placeholder="Search email"
          />
        </div>
      </th>
      <th className="sortable _10">
        <div className="-table-header-title">Permissions</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="permission"
            value={!searchHeaderColumns.permission.disableSearch}
            checked={!searchHeaderColumns.permission.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="permission"
            value={searchHeaderColumns.permission.searchText}
            disabled={searchHeaderColumns.permission.disableSearch}
            placeholder="Search permission"
          />
        </div>
      </th>
      <th className="sortable _30">
        <div className="-table-header-title">Status</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="staffStatus"
            value={!searchHeaderColumns.staffStatus.disableSearch}
            checked={!searchHeaderColumns.staffStatus.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="staffStatus"
            value={searchHeaderColumns.staffStatus.searchText}
            disabled={searchHeaderColumns.staffStatus.disableSearch}
            placeholder="Search status"
          />
        </div>
      </th>
      <th
        className="sortable _20"
        style={{ minWidth: '120px' }}
      >
        <div className="-table-header-title">Visibility</div>
        <div className="-table-header-search -hidden">
          <CheckboxInput
            name="status"
            value={!searchHeaderColumns.status.disableSearch}
            checked={!searchHeaderColumns.status.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="status"
            value={searchHeaderColumns.status.searchText}
            disabled={searchHeaderColumns.status.disableSearch}
            placeholder="Search visibility"
          />
        </div>
      </th>
      <th className="sortable _40">
        <div className="-table-header-title">Date Assigned</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="dateAssign"
            value={!searchHeaderColumns.dateAssign.disableSearch}
            checked={!searchHeaderColumns.dateAssign.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="dateAssign"
            value={searchHeaderColumns.dateAssign.searchText}
            disabled={searchHeaderColumns.dateAssign.disableSearch}
            placeholder="Search date assigned"
          />
        </div>
      </th>
      <th></th>
    </tr>
  )
}

PracticeStaffClientList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allTags: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleQuery: PropTypes.func.isRequired,
  handleOpenAddStaffModal: PropTypes.func,
  handleSetPagination: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  selectedTagIds: PropTypes.array,
  sortedAndFilteredList: PropTypes.array,
  utilStaffClientStore: PropTypes.object.isRequired,
  viewingAs: PropTypes.string,
}

PracticeStaffClientList.defaultProps = {
  handleOpenAddStaffModal: null,
  sortedAndFilteredList: [],
  viewingAs: 'table',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  const { utilStaffClientStore, staffClientListItems } = props
  const staffStore = store.staff
  let paginatedList = []
  let orderedList = []
  let sortBy = ''

  if (staffClientListItems) {
    const pagination = utilStaffClientStore.pagination || { page: 1, per: 50 }
    sortBy = utilStaffClientStore.filter
      ? utilStaffClientStore.filter.sortBy
      : 'staff'

    const newStaffClientListItems = []

    staffClientListItems.forEach(item => {
      // praparing data -------------------------------------------------------------------------------------
      item.userFullName = ''

      if (
        store.user.byId &&
        item &&
        item._user &&
        store.user.byId[item._user]
      ) {
        const user = store.user.byId[item._user]
        item.user = user
        item.userFullName = user.firstname || ''
        item.userFullName += (user.firstname ? ' ' : '') + user.lastname || ''
        item.userEmail = user.username
      }

      if (
        store.staff.byId &&
        item &&
        item._staff &&
        store.staff.byId[item._staff]
      ) {
        const staff = store.staff.byId[item._staff]
        item.staff = staff
        item.permission = (staff.owner && 'Owner') || 'Standard'
        item.staffStatus = staff.status
      }

      // filtering data -------------------------------------------------------------------------------------------------------------------
      let showMe = true
      if (
        showMe &&
        !!staffStore.filterHeaders &&
        !!staffStore.filterHeaders.name &&
        staffStore.filterHeaders.name.trim()
      ) {
        const name = staffStore.filterHeaders.name.toLowerCase()
        showMe =
          item.userFullName &&
          item.userFullName.toLowerCase().indexOf(name) > -1
      }

      if (
        showMe &&
        !!staffStore.filterHeaders &&
        !!staffStore.filterHeaders.email &&
        staffStore.filterHeaders.email.trim()
      ) {
        const email = staffStore.filterHeaders.email.toLowerCase()
        showMe =
          item.userEmail && item.userEmail.toLowerCase().indexOf(email) > -1
      }

      if (
        showMe &&
        !!staffStore.filterHeaders &&
        !!staffStore.filterHeaders.permission &&
        staffStore.filterHeaders.permission.trim()
      ) {
        const permission = staffStore.filterHeaders.permission.toLowerCase()
        showMe =
          item.permission &&
          item.permission.toLowerCase().indexOf(permission) > -1
      }

      if (
        showMe &&
        !!staffStore.filterHeaders &&
        !!staffStore.filterHeaders.staffStatus &&
        staffStore.filterHeaders.staffStatus.trim()
      ) {
        const staffStatus = staffStore.filterHeaders.staffStatus.toLowerCase()
        showMe =
          item.staffStatus &&
          item.staffStatus.toLowerCase().indexOf(staffStatus) > -1
      }

      if (
        showMe &&
        !!staffStore.filterHeaders &&
        !!staffStore.filterHeaders.dateAssign &&
        staffStore.filterHeaders.dateAssign.trim()
      ) {
        const dateAssign = staffStore.filterHeaders.dateAssign.toLowerCase()
        const createdAt =
          item.created_at &&
          new Date(item.created_at).toLocaleDateString('en-US')
        showMe = createdAt && createdAt.toLowerCase().indexOf(dateAssign) > -1
      }

      if (showMe) newStaffClientListItems.push(item)
    })

    // SORT THE LIST
    switch (sortBy) {
      case 'staff':
        orderedList = _.orderBy(
          newStaffClientListItems,
          [item => item.user && item.user.firstname],
          ['asc']
        )
        break
      case '-staff':
        orderedList = _.orderBy(
          newStaffClientListItems,
          [item => item.user && item.user.firstname],
          ['desc']
        )
        break
      case 'email':
        orderedList = _.orderBy(
          newStaffClientListItems,
          [item => item.user && item.user.username],
          ['asc']
        )
        break
      case '-email':
        orderedList = _.orderBy(
          newStaffClientListItems,
          [item => item.user && item.user.username],
          ['desc']
        )
        break
      default:
        orderedList = _.orderBy(
          newStaffClientListItems,
          [item => item.user && item.user.firstname],
          ['asc']
        )
    }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(orderedList, start, end)
  }

  // unique key
  let uniques = []
  paginatedList = paginatedList.filter(obj => {
    const unique = `${obj && obj._client}_${obj && obj._staff}_${
      obj && obj._user
    }`
    if (!uniques.includes(unique)) {
      uniques.push(unique)
      return obj
    } else {
      return null
    }
  })

  return {
    paginatedList: paginatedList,
    orderedList,
    staffMap: store.staff.byId,
    userMap: store.user.byId,
    sortBy,
    staffStore: store.staff,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticeStaffClientList))
