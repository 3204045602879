/**
 * Upload progress meter dispalyed in the top nav. Currently only used for bulk client imports. Could be expanded to work for file uploads.
 */

// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';

// import third-party libraries
import classNames from 'classnames';

// import global components
import Binder from '../../../global/components/Binder.js.jsx';

import withSocket from '../../components/WithSocket.jsx';

class PracticeProgress extends Binder {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
      , progressPercent: 0
      , actionText: ''
    }
    this._bind(
      '_close'
    )
    const { socket } = props;

    socket.on('progress_status', progressPercent => {
      console.log('progressPercent', progressPercent);
      this.setState({
        progressPercent: progressPercent
      })
    });

    socket.on('finish_progress', (actionText) => {
      // console.log('FINSIHED UPLOAD', responseData);
      this.setState({ actionText }, () => {
        this.props.close();
      });
    });

    socket.on('progress_error', error => {
      // console.log('UPLOAD ERROR', error);
      this.setState({
        errorMessage: error
      })
      this.props.close();
    })
  }

  componentWillUnmount() {
    // Remove event listeners
    const { socket } = this.props;
    socket.off('progress_status')
    socket.off('finish_progress')
    socket.off('progress_error')
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen: prevIsOpen } = prevProps
    const { isOpen, socket } = this.props

    if(prevIsOpen && !isOpen) {
      socket.disconnect()
    }

    if(!prevIsOpen && isOpen) {
      socket.connect()
    }
  }

  _close() {
    this.setState({
      progressPercent: 0
      , responseData: null
    }, () => this.props.close())
  }

  render() {
    const { isOpen } = this.props;

    const { 
      progressPercent
      , actionText
    } = this.state

    let progressClass = classNames(
      `progress-bar-${progressPercent || 0}`
    )

    return (
      isOpen ?
      <div>
        <div className="action-link upload-progress-container">
          <small>{`${actionText} ${progressPercent}%`}</small>
          <div className={progressClass} >
            <div className="-progress">
              <div className="-complete">
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      null
    )
  }
}

export default withRouter(withSocket(PracticeProgress, false))