// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

// import moment from 'moment';
import { DateTime } from 'luxon'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'

import * as staffClientActions from '../../staffClientActions'

class PracticeStaffClientTableListItem extends Binder {
  constructor(props) {
    super(props)
    this._bind('_handleUpdate')
  }

  _handleUpdate(status) {
    const { dispatch, staffClient } = this.props
    const data = { _id: staffClient._id, status }
    dispatch(staffClientActions.sendUpdateStaffClient(data))
  }

  render() {
    const {
      match,
      staffClient,
      staff,
      user,
      handleSelectedStaff,
      checked,
      handleUpdate,
    } = this.props
    const isEmpty = !staff || !user
    return isEmpty ? (
      <tr>
        <td colSpan="4">
          <i className="fal fa-spinner fa-spin" />
          Loading...
        </td>
      </tr>
    ) : (
      <tr className="-staff-item">
        <td>
          <div style={{ width: '25px', display: 'inline-flex' }}>
            <CheckboxInput
              name="staff"
              value={checked}
              checked={checked}
              change={() => handleSelectedStaff(staffClient._id)}
            />
          </div>
        </td>
        <td>
          <div className="-staff-info">
            <Link
              className="-name"
              to={`${match.url}/${staff._id}`}
            >
              {staffClient.userFullName}
            </Link>
          </div>
        </td>
        <td>{staffClient.userEmail}</td>
        <td>{staffClient.permission}</td>
        <td>{staffClient.staffStatus}</td>
        <td>
          {staffClient.status === 'visible' ? (
            <i
              onClick={this._handleUpdate.bind(this, 'hidden')}
              className=" fas fa-eye -pointer"
            />
          ) : (
            <i
              onClick={this._handleUpdate.bind(this, 'visible')}
              className="u-danger fad fa-eye-slash -pointer"
            />
          )}
        </td>
        <td>
          {DateTime.fromISO(staffClient.created_at).toLocaleString(
            DateTime.DATE_SHORT
          )}
        </td>
        <td></td>
        <td></td>
      </tr>
    )
  }
}

PracticeStaffClientTableListItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  staff: PropTypes.object.isRequired,
}

PracticeStaffClientTableListItem.defaultProps = {
  user: null,
}

const mapStoreToProps = store => {
  return {
    noteMap: store.note.byId,
    loggedInUser: store.user.loggedIn.user,
    tagStore: store.tag,
    clientWorkflowStore: store.clientWorkflow,
    userStore: store.user,
  }
}

export default withRouter(
  connect(mapStoreToProps)(PracticeStaffClientTableListItem)
)
