/**
 * Set up routing for all File views
 *
 * For an example with protected routes, refer to /product/ProductRouter.js.jsx
 */

// import primary libraries
import React from 'react'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import YTRoute from '../../../global/components/routing/YTRoute.js.jsx'

// import view
import PortalRequestTask from './view/PortalRequestTask.js.jsx'

class RequestTaskPortalRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    const { breadcrumbs } = this.props

    return (
      <Switch>
        <YTRoute
          breadcrumbs={[{ fromPortal: true }]}
          exact
          clientUser={true}
          path="/portal/:clientId/request-task/:requestTaskId/:viewingAs"
          component={PortalRequestTask}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          exact
          clientUser={true}
          path="/portal/:clientId/request-task/:requestTaskId"
          component={PortalRequestTask}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          exact
          clientUser={true}
          path="/portal/:clientId/request-task"
          component={PortalRequestTask}
        />
      </Switch>
    )
  }
}

const mapStoreToProps = (store, props) => {
  let breadcrumbs = []

  return {
    breadcrumbs,
  }
}

export default withRouter(connect(mapStoreToProps)(RequestTaskPortalRouter))
