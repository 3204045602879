import React from 'react'
import Switch from 'react-router-dom/Switch'

import YTRoute from '../../global/components/routing/YTRoute.js.jsx'

import SpaCallback from './views/SpaCallback.js.jsx'
import SsoError from './views/SsoError.js.jsx'

const SsoRouter = () => {
	return (
		<Switch>
			<YTRoute
				exact
				path="/sso/spa-callback"
				component={SpaCallback}
			/>
			<YTRoute
				exact
				path="/sso/error"
				component={SsoError}
			/>
		</Switch>
	)
}

export default SsoRouter
