/**
 * Boilerplate code for a new Redux-connected view component.
 * Nice for copy/pasting
 */

// import primary libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import form components
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as clientActions from '../../../client/clientActions'
import * as clientUserActions from '../../../clientUser/clientUserActions'

const PracticeClientImportResult = props => {
  const { dispatch } = props

  const [dropDownMenu, setDropDownMenu] = useState(false)
  const [reInstate, setReInstate] = useState({
    status: false,
    disable: false,
    loading: false,
  })
  const [renameSuccess, setRenameSuccess] = useState(false)
  const [reNameShowModal, setReNameShowModal] = useState(false)
  const [client, setClient] = useState(null)
  const [reNameSubmit, setReNameSubmit] = useState(false)

  const { result, index, match, bulkClientObj } = props
  const { clientNotification, uploadOnly } = bulkClientObj

  const _handleExistingClient = () => {
    const reInstateCopy = _cloneDeep(reInstate)
    let newClient = _cloneDeep(result.client)
    reInstateCopy.loading = true
    setReInstate(reInstateCopy)
    newClient.status = 'visible'
    newClient.accountType = result.accountType
    newClient.identifier = result.clientIdentifier
    newClient.sN_upload = clientNotification.sN_upload
    newClient.sN_viewed = clientNotification.sN_viewed
    newClient.sN_downloaded = clientNotification.sN_downloaded
    newClient.sN_sendMessage = clientNotification.sN_sendMessage
    newClient.sN_leaveComment = clientNotification.sN_leaveComment

    dispatch(clientActions.sendUpdateClient(newClient)).then(clientJson => {
      reInstateCopy.loading = false
      reInstateCopy.disable = true
      reInstateCopy.status = clientJson.success

      if (clientJson.success) {
        newClient = clientJson.item
        setRenameSuccess(true)
        newClient.renameMessage = 'Success reinstate'
        setReInstate(reInstateCopy)
        setClient(newClient)
        setDropDownMenu(false)
        _handleInviteContact(newClient)
      } else {
        setRenameSuccess(false)
        newClient.renameMessage = clientJson.success
          ? ''
          : 'Failed to reinstate'
        setClient(newClient)
        setReInstate(reInstateCopy)
        setDropDownMenu(false)
      }
    })
  }

  const _handleClientNameChange = e => {
    setClient({ ...client, name: e.target.value })
  }

  const _handleSubmitClient = () => {
    setReNameSubmit(true)

    let newClient = client ? _cloneDeep(client) : {}
    const sendData = {
      name: newClient ? newClient.name.trim() : '',
      _firm: match.params.firmId,
      accountType: result.accountType,
      identifier: result.clientIdentifier,
      sN_upload: clientNotification.sN_upload,
      sN_viewed: clientNotification.sN_viewed,
      sN_downloaded: clientNotification.sN_downloaded,
      sN_sendMessage: clientNotification.sN_sendMessage,
      sN_leaveComment: clientNotification.sN_leaveComment,
    }

    dispatch(clientActions.sendCreateClient(sendData)).then(clientJson => {
      if (clientJson.success) {
        newClient = clientJson.item
        setRenameSuccess(true)
        newClient.renameMessage = 'New client created'
        setClient(newClient)
        setReNameShowModal(false)
        setDropDownMenu(false)
        _handleInviteContact(newClient)
      } else {
        setRenameSuccess(false)
        newClient.renameMessage = clientJson.success
          ? 'Client name already exists'
          : 'Failed to save'
        setClient(newClient)
        setReNameSubmit(false)
      }
    })
  }

  const _handleInviteContact = client => {
    const invitations = result.primaryContact.map(contact => ({
      email: contact.email,
      firstname: contact.firstname,
      lastname: contact.lastname,
      uploadOnly,
    }))

    if (invitations.length) {
      const contactSendData = {
        invitations,
        personalNote: '',
        firmId: match.params.firmId,
        uploadOnly,
      }

      dispatch(
        clientUserActions.sendInviteClientUsers(client._id, contactSendData)
      ).then(clientUserJson => {
        if (clientUserJson.success) {
          dispatch(clientUserActions.invalidateList('_client', client._id))

          if (!client._primaryContact) {
            let updateClient = _cloneDeep(client)
            delete updateClient.renameMessage
            delete updateClient.renameSuccess
            updateClient._primaryContact =
              clientUserJson.data.results[0].user._id
            dispatch(clientActions.sendUpdateClient(updateClient)).then(
              json => {
                setClient(updateClient)
              }
            )
          }
        }
      })
    }
  }

  const ableToRename = result.action.includes('rename')
  const ableToReinstate = result.action.includes('reinstate')

  const reNameDisable = reInstate.disable || reInstate.loading
  const reinsateDisable = reInstate.disable

  return (
    <tr key={'result_' + index}>
      <td className="-status-action">
        {result.action.length === 0 || (client && renameSuccess) ? (
          <i className="u-success fas fa-check" />
        ) : (
          <div>
            <i
              className="far fa-ellipsis-v"
              aria-hidden="true"
              onClick={() => setDropDownMenu(true)}
            ></i>
            {dropDownMenu && (
              <span className="single-file-options">
                <div
                  className="close-wrapper"
                  onClick={() => setDropDownMenu(false)}
                ></div>
                <ul className="dropMenu -options-menu">
                  {ableToRename && (
                    <li className="-option">
                      <a
                        className={reNameDisable ? '-disabled-link' : ''}
                        disabled={reNameDisable}
                        onClick={() => {
                          setReNameShowModal(true)
                          setClient(result.client)
                          setDropDownMenu(false)
                        }}
                      >
                        Rename Client Name
                      </a>
                    </li>
                  )}
                  {ableToReinstate && (
                    <li className="-option">
                      <a
                        className={reinsateDisable ? '-disabled-link' : ''}
                        disabled={reinsateDisable}
                        onClick={_handleExistingClient}
                      >
                        {reInstate.disable ? (
                          reInstate.status ? (
                            <i className="u-success fas fa-check" />
                          ) : null
                        ) : reInstate.loading ? (
                          <p className="loading"></p>
                        ) : null}
                        Reinstate Existing Client
                      </a>
                    </li>
                  )}
                </ul>
              </span>
            )}
          </div>
        )}
      </td>
      <td>{result.clientIdentifier}</td>
      <td>
        {reNameShowModal ? (
          <div className="-rename-upload-field-container">
            <TextInput
              disabled={reNameSubmit}
              change={_handleClientNameChange}
              name="client.name"
              value={client ? client.name : result.clientName}
              helpText={client ? client.renameMessage : ''}
            />
            <button
              className="yt-btn x-small link"
              disabled={reNameSubmit}
              onClick={() => setReNameShowModal(false)}
            >
              cancel
            </button>
            <button
              className="yt-btn x-small success"
              disabled={
                (client.name
                  ? client.name.trim() === result.clientName.trim()
                  : true) || reNameSubmit
              }
              onClick={_handleSubmitClient}
            >
              upload
            </button>
          </div>
        ) : client && renameSuccess ? (
          client.name
        ) : (
          result.clientName
        )}
      </td>
      <td>{result.engagementTypes[0]}</td>
      <td>{result.folderTemplateName}</td>
      <td>
        <p>
          {!result.street1 &&
          !result.city &&
          !result.state &&
          !result.postal &&
          !result.country
            ? '-'
            : `${result.street1} ${result.city} ${result.state} ${result.postal} ${result.country}`}
        </p>
      </td>
      <td>
        <p>{!result.number ? '-' : `${result.number}`}</p>
      </td>
      <td>
        {result.primaryContact.map((contact, j) => (
          <p key={j}>
            {!contact.firstname && !contact.lastname
              ? '-'
              : `${contact.firstname} ${contact.lastname}`}
          </p>
        ))}
      </td>
      <td>
        {result.primaryContact.map((contact, j) => (
          <p key={j}>{contact.email || '-'}</p>
        ))}
      </td>
      <td>
        {result.staffs.map((staff, i) => (
          <p key={i}>{staff.email || '-'}</p>
        ))}
      </td>
      <td>
        {client && renameSuccess ? client.renameMessage : result.result_message}
      </td>
      <td>{client && renameSuccess ? '' : result.error_message}</td>
    </tr>
  )
}

PracticeClientImportResult.propTypes = {
  dispatch: PropTypes.func.isRequired,
  result: PropTypes.object,
}

PracticeClientImportResult.defaultProps = {}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    addressStore: store.address,
    userStore: store.user,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticeClientImportResult))
