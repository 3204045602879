// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import _ from 'lodash'

// import form components
import Binder from '../../../global/components/Binder.js.jsx'
import EmailInput from '../../../global/components/forms/EmailInput.js.jsx'
import PasswordInput from '../../../global/components/forms/PasswordInput.js.jsx'
import brandingName from '../../../global/enum/brandingName.js.jsx'
import apiUtils from '../../../global/utils/api.js'

class UserLoginForm extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      // usePassword: false
      usePassword: true,
      openidProviders: []
    }
    this._bind(
      '_redirectToOpenidLogin'
    )
  }

  componentDidMount() {
    apiUtils.callAPI('/api/oauth2/providers').then(resp => {
      this.setState({
        openidProviders: resp.providers
      })
    })
  }

  _redirectToOpenidLogin(provider) {
    window.location = `/api/oauth2/start/${provider}`;
  }

  render() {
    const {
      handleFormChange,
      handleFormSubmit,
      history,
      location,
      user,
      logoUrl,
      magicLinkSent,
      sendMagicLink,
      showPoweredBy,
      selectedFirm = {},
    } = this.props

    let firmLogo = brandingName.image.logoBlack

    return (
      <div
        className="form-container -skinny"
        style={{ marginTop: '64px' }}
      >
        <form
          name="userForm"
          className="user-form"
          onSubmit={handleFormSubmit}
        >
          {logoUrl ? (
            <div style={{ maxWidth: '66%', margin: 'auto' }}>
              <img src={logoUrl} />
            </div>
          ) : null}
          <h2> Sign In </h2>
          <hr />
          {magicLinkSent ? (
            <div>
              <div className="u-centerText">
                <h3>We sent you a magic link!</h3>
                <p>
                  We sent an email to <strong>{user.username}</strong>
                </p>
                <p>It contains a magic link that'll log you in.</p>
                <button
                  className="yt-btn block link"
                  type="button"
                  onClick={() => history.replace(location.pathname)}
                >
                  <i className="far fa-angle-left" /> Back
                </button>
              </div>
            </div>
          ) : (
            <div>
              <EmailInput
                name="username"
                label="Email Address"
                value={user.username}
                change={handleFormChange}
                required={true}
                error={user.emailError}
                errorName="emailError"
              />
              {this.state.usePassword ? (
                <PasswordInput
                  name="password"
                  label="Password"
                  value={user.password}
                  change={handleFormChange}
                  required={true}
                  password={true}
                />
              ) : null}
              {this.state.usePassword ? (
                <div className="input-group">
                  <button
                    className="yt-btn info block"
                    type="submit"
                  >
                    {' '}
                    Sign in{' '}
                  </button>
                  <p
                    style={{
                      margin: '20px 0',
                      textAlign: 'center',
                    }}
                  >
                    Or log in with:
                  </p>
                  <div className="flex-wrapper">
                    <div className="openid-providers">
                      {
                        this.state.openidProviders.length == 0
                          ? <p>Loading...</p>
                          : <React.Fragment>
                            {
                              this.state.openidProviders.map(provider => (
                                <div key={provider.name} className="openid-button" onClick={() => this._redirectToOpenidLogin(provider.name)}>
                                  <img className="openid-icon" src={`/img/openid/${provider.name}.png`} alt={`${provider.name} icon`} />
                                  <p className="openid-text">
                                    Sign in with {_.capitalize(provider.name)}
                                  </p>
                                </div>
                              ))
                            }
                          </React.Fragment>
                      }
                    </div>
                  </div>
                  {/* <button className="yt-btn block link" type="button" onClick={() => this.setState({usePassword: false})}><i className="far fa-angle-left"/> Back</button> */}
                  <br />
                  <Link to="/user/forgot-password">
                    <em>Forgot Password?</em>
                  </Link>
                </div>
              ) : (
                <div className="input-group">
                  <button
                    className="yt-btn info block"
                    type="button"
                    onClick={sendMagicLink}
                    disabled={!user.username}
                  >
                    <i className="far fa-magic" /> Send magic link
                  </button>
                  <button
                    className="yt-btn block link"
                    type="button"
                    onClick={() => this.setState({ usePassword: true })}
                  >
                    Use password instead
                  </button>
                </div>
              )}
            </div>
          )}
        </form>
        {showPoweredBy ? (
          <div
            className="yt-container"
            style={{ padding: '20px' }}
          >
            <div className="yt-row right center-vert">
              <span style={{ marginRight: '8px' }}>
                <em>powered by </em>
              </span>
              <a
                className="footer-logo"
                href={brandingName.url}
                target="_blank"
                rel="noreferrer"
              >
                <img src={firmLogo} />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

UserLoginForm.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object,
  logoUrl: PropTypes.string,
  magicLinkSent: PropTypes.bool.isRequired,
  sendMagicLink: PropTypes.func.isRequired,
  showPoweredBy: PropTypes.bool,
}

UserLoginForm.defaultProps = {
  logoUrl: null,
  showPoweredBy: false,
}

export default withRouter(UserLoginForm)
