import React, { useCallback, useState } from 'react'
import {
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ResponseDisplay from './components/ResponseDisplay.js'
import ToneSelector from './components/ToneSelector.js'
import AIAuthentication from './components/AIAuthentication.js'
import AIError from './components/AIError.js'

const AI_BASE_URL = `https://profitsolv-ai-uat.azurewebsites.net`

export default function AIModal({ fileURL, firmId, open, handleClose }) {
  let prompt =
    'What is the following document all about? Create a summary no longer than 500 words.'
  const [response, setResponse] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [generateCount, setGenerateCount] = useState(0)
  const [tone, setTone] = useState('professional')
  const [authDetails, setAuthDetails] = useState(null)
  const [error, setError] = useState(null)

  const handleGenerateResponse = useCallback(
    async _ => {
      if (isLoading) {
        return
      }
      setGenerateCount(prev => prev + 1)
      setIsLoading(true)

      const response = await fetch(fileURL)
      const fileBlob = await response.blob()
      const fileName = fileURL.split('/').pop()

      const formData = new FormData()
      formData.append('file', fileBlob, fileName)
      const aiSummaryResponse = await fetch(
        `${AI_BASE_URL}/ChatBot/file-chat?Prompt=${prompt} and make sure you are using a ${tone} tone.`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authDetails.generalAccessToken}`,
          },
          body: formData,
        },
      )
      const promptResponse = await aiSummaryResponse.json()

      setResponse(promptResponse.message)
      setIsLoading(false)
    },
    [
      authDetails,
      fileURL,
      prompt,
      tone,
      setGenerateCount,
      setIsLoading,
      setResponse,
    ],
  )

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(response)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }, [setIsCopied, response])

  const getRecomposePrompt = useCallback((action, tone, response) => {
    let actionText = ''
    switch (action) {
      case 'shorten':
        actionText = 'Shorten the text even more.'
        break
      case 'expand':
        actionText =
          "Enhance the message a little bit so it's somewhere between 500 and 800 characters long."
        break
      case 'rephrase':
        actionText = 'Rephrase the message in a different manner.'
        break
      default:
        actionText = '' // Optional: Handle unexpected actions.
    }

    return `Please refine the following message for clarity and check for errors. Text tone: ${tone}. ${actionText} Do not include any additional explanations or acknowledgments, and do not wrap responses in quotes. Message: ${response}`
  }, [])

  const recomposeResponse = useCallback(
    async action => {
      if (!action || isLoading) {
        return
      }
      setIsLoading(true)
      const refinedPrompt = getRecomposePrompt(action, tone, response)

      const aiSummaryResponse = await fetch(`${AI_BASE_URL}/ChatBot/chat`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authDetails.generalAccessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: refinedPrompt,
        }),
      })
      const promptResponse = await aiSummaryResponse.json()

      setResponse(promptResponse.message)
      setIsLoading(false)
    },
    [
      setIsLoading,
      setResponse,
      isLoading,
      authDetails,
      response,
      fileURL,
      prompt,
      tone,
    ],
  )

  const handleAuthenticationSuccess = details => {
    if (!authDetails) setAuthDetails(details) // Only set once
  }

  const handleAuthenticationError = err => setError(err)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1,
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2 }}>
        <Typography
          variant="h4"
          component="span"
          sx={{ fontWeight: 500 }}
        >
          AI Assistant
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        {!authDetails && !error && open ? (
          <AIAuthentication
            open={open}
            firmId={firmId}
            onAuthenticated={handleAuthenticationSuccess}
            onError={handleAuthenticationError}
          />
        ) : null}

        {!authDetails && !error ? null : error ? (
          <AIError
            message={'There was an error initializing the AI assistant.'}
          />
        ) : (
          <>
            <Typography
              variant="h5"
              sx={{ mb: 4 }}
            >
              Retrieve Information from document in a
            </Typography>
            <ToneSelector
              tone={tone}
              isLoading={isLoading}
              onToneChange={setTone}
              generateCount={generateCount}
              onGenerate={handleGenerateResponse}
            />
            <ResponseDisplay
              response={response}
              isLoading={isLoading}
              isCopied={isCopied}
              onCopy={handleCopyToClipboard}
            />
            {response && !isLoading && (
              <Stack
                direction="row"
                spacing={1}
                mt={3}
                justifyContent="flex-start"
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => recomposeResponse('shorten')}
                >
                  Shorten
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => recomposeResponse('expand')}
                >
                  Expand
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => recomposeResponse('rephrase')}
                >
                  Rephrase
                </Button>
              </Stack>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
