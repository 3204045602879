/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'

// import actions
import * as requestActions from '../requestActions'
import * as activityActions from '../../activity/activityActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'
import SelectFromObject from '../../../global/components/forms/SelectFromObject.js.jsx'

// import components

class RequestListForm extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      request: {},
      submitting: false,
    }
    this._bind(
      '_handleFormChange',
      '_handleCreateRequestList',
      '_handleUpdateRequestList',
      '_handleClose'
    )
  }

  componentDidMount() {
    this.setState({
      request: {
        name: this.props.selectedRequest.name,
      },
    })
  }

  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  _handleCreateRequestList() {
    const { dispatch, match, selectedClient, listArgs } = this.props
    const request = _cloneDeep(this.state.request)

    this.setState({ submitting: true })

    request._firm = match.params.firmId
    request.type = 'list'

    if (selectedClient._id) {
      request._client = selectedClient._id
    }

    dispatch(requestActions.sendCreateRequest(request)).then(json => {
      console.log('json', json)
      if (json.success) {
        dispatch(requestActions.addSingleRequestToMap(json.item))
        dispatch(requestActions.addRequestToList(json.item, ...listArgs))
        if (json.activity) {
          dispatch(activityActions.invalidateList(...listArgs))
        }
        this._handleClose()
      } else {
        this.setState({ submitting: false })
        alert(json.error)
      }
    })
  }

  _handleUpdateRequestList() {
    const { dispatch, selectedRequest } = this.props
    const request = _cloneDeep(this.state.request)

    this.setState({ submitting: true })
    const updatedData = {
      ...request,
      _id: selectedRequest._id,
      _firm: selectedRequest._firm,
    }

    dispatch(requestActions.sendUpdateRequest(updatedData)).then(json => {
      console.log('update', json)
      if (json.success) {
        this._handleClose()
      } else {
        this.setState({ submitting: false })
        alert(json.error)
      }
    })
  }

  _handleClose() {
    this.setState(
      {
        request: {},
        submitting: false,
      },
      () => {
        this.props.close()
      }
    )
  }

  render() {
    const {
      isOpen,
      selectedClient,
      clientUserListItems = [],
      selectedRequest,
    } = this.props

    const { submitting, request = {} } = this.state

    const modalHeaderText = selectedRequest._id
      ? 'Update Request List'
      : 'Create Request List'
    const modalConfirmtext = submitting
      ? selectedRequest._id
        ? 'Updating...'
        : 'Creating...'
      : selectedRequest._id
      ? 'Update Request List'
      : 'Create Request List'
    const disabledButton =
      _isEqual(request, selectedRequest) || !request.name ? true : false
    const workspaceItems = selectedClient._id ? [selectedClient] : []

    return (
      <Modal
        cardSize="large"
        isOpen={isOpen}
        closeAction={this._handleClose}
        closeText="Cancel"
        confirmAction={
          selectedRequest._id
            ? this._handleUpdateRequestList
            : this._handleCreateRequestList
        }
        confirmText={modalConfirmtext}
        disableConfirm={submitting || disabledButton}
        modalHeader={modalHeaderText}
        showButtons={true}
      >
        <div>
          <div className="-share-link-configuration">
            <div className="-header">
              <i className="fas fa-eye" /> Link settings
            </div>
            <div className="-body">
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  <p>
                    <strong>
                      Workspace
                      {selectedClient._id ? (
                        <sup className="-required">*</sup>
                      ) : (
                        ''
                      )}
                    </strong>
                  </p>
                  <p>Select workspace to request from</p>
                </div>
                <div className="-inputs yt-col">
                  <SelectFromObject
                    change={() => console.log('temporary fixed')}
                    items={workspaceItems}
                    disabled={true}
                    display="name"
                    displayStartCase={false}
                    filterable={true}
                    isClearable={false}
                    name="clientId"
                    placeholder="Firm request lists"
                    selected={selectedClient._id}
                    value="_id"
                  />
                </div>
              </div>
              <hr />
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  <p>
                    <strong>
                      Name<sup className="-required">*</sup>
                    </strong>
                  </p>
                  <p>Name your request list</p>
                </div>
                <div className="-inputs yt-col">
                  <TextInput
                    change={this._handleFormChange}
                    name="request.name"
                    placeholder={'Enter request list name'}
                    value={request.name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

RequestListForm.propTypes = {}

RequestListForm.defaultProps = {
  selectedUsers: [],
  selectedClient: {},
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  return {
    loggedInUser: store.user.loggedIn.user,
  }
}

export default withRouter(connect(mapStoreToProps)(RequestListForm))
