import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import EmailInput from '../../../global/components/forms/EmailInput.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'
import SelectFromObject from '../../../global/components/forms/SelectFromObject.js.jsx'
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'

const InviteClientUserForm = props => {
  const {
    index,
    invite,
    remove,
    userListItems,
    changeType,
    change,
    inviteType,
    handleSetPrimary,
    selectChange,
    allUsers,
  } = props
  const displayType = inviteType[index]
  const [userId, setUserId] = useState(null)
  const [invitationType, setInvitationType] = useState(inviteType[index])
  const [isExistingUser, setIsExistingUser] = useState(false)

  useEffect(() => {
    setInvitationType(inviteType[index])
  }, [inviteType, index])

  const handleFormChange = useCallback(
    e => {
      if (
        isExistingUser &&
        e.target.name === 'email' &&
        e.target.value !== invite.email
      ) {
        setIsExistingUser(false)
      }
      change(e, index)
    },
    [index, change, isExistingUser, setIsExistingUser]
  )

  const handleSelectChange = useCallback(
    e => {
      let [clientUser] = userListItems.filter(
        user => user._id == e.target.value
      )
      if (clientUser) {
        selectChange(clientUser, index)
      }
      setUserId(e.target.value)
    },
    [setUserId]
  )

  const toggleDisplayType = () => {
    const newDisplayType = invitationType === 0 ? 1 : 0
    setInvitationType(newDisplayType)
    changeType(newDisplayType, index)
    setIsExistingUser(false)
  }

  const updateExistingUser = value => {
    const existingUser = allUsers.find(
      user => user.username.toLowerCase() === value.trim().toLowerCase()
    )

    if (existingUser) {
      setIsExistingUser(true)
      handleFormChange({
        target: {
          name: 'fullname',
          value: `${existingUser.firstname} ${existingUser.lastname}`,
        },
      })
    }
  }

  return (
    <div className="invitation-form">
      <div className="yt-row space-between">
        <div>
          <strong> Invitation #{index + 1}</strong>
          {isExistingUser && (
            <span style={{ marginLeft: '4px' }}>
              This email address exists in the system
            </span>
          )}
        </div>
        <button
          className="yt-btn x-small link"
          onClick={() => remove(index)}
        >
          <i className="far fa-times" />
        </button>
      </div>
      {displayType ? (
        <SelectFromObject
          change={handleSelectChange}
          items={userListItems || []}
          // disabled={!!this.props.client}
          display="displayName"
          displayStartCase={false}
          filterable={true}
          // isClearable={false}
          name="userId"
          placeholder="Select existing user"
          selected={userId}
          value="_id"
        />
      ) : (
        <div style={{ margin: 0, padding: 0 }}>
          <EmailInput
            name="email"
            label="Email Address"
            value={invite.email}
            change={handleFormChange}
            required={true}
            onBlur={e => updateExistingUser(e.target.value)}
            error={invite.emailError}
            errorName="emailError"
          />
          <TextInput
            change={handleFormChange}
            label="Full Name"
            name="fullname"
            required={true}
            value={invite.fullname}
            disabled={isExistingUser}
            errorName="fullnameError"
            error={invite.fullnameError}
            validationRegex={/^[a-zA-Z0-9 ]+$/}
          />
        </div>
      )}
      <div className="yt-row space-between">
        <div className={'-invite-primary-contact'}>
          <CheckboxInput
            name="invite.primary"
            value={invite.primary}
            checked={invite.primary}
            change={handleSetPrimary}
            label="Set as Primary Contact"
          />
        </div>
      </div>
      <button
        className="yt-btn xx-small link info"
        onClick={toggleDisplayType}
      >
        {displayType
          ? `Click here to create a new contact`
          : `Choose from existing contacts`}
      </button>
    </div>
  )
}

InviteClientUserForm.propTypes = {
  change: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  invite: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    owner: PropTypes.isRequired,
  }),
  remove: PropTypes.func.isRequired,
}

InviteClientUserForm.defaultProps = {
  formHelpers: {},
  formTitle: '',
  showButtons: true,
}

export default InviteClientUserForm
