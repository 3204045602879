import WebViewer from '@pdftron/webviewer'
import { PDF_WebViewer_KEY } from '../../config/licenseKeys'

const watermarkUtils = {
  getPdftronWatermarkObject(watermark) {
    //parses db watermark object into an object that can be used by pdftron
    const common = {
      fontSize: watermark.fontSize || 50, // or even smaller size
      fontFamily: 'sans-serif',
      color: `#${watermark.color ? watermark.color : 'D86F39'}`,
      opacity: (watermark.opacity || 0.2) * 100, // from 0 to 100
    }
    //|"topCenter"|"topRight"|"diagonal"|"bottomLeft"|"bottomCenter"|"bottomRight"
    const text = watermark.text || 'Watermark'
    switch (watermark.position) {
      case 'topLeft': {
        return {
          header: {
            ...common,
            left: text,
          },
        }
      }
      case 'topCenter': {
        return {
          header: {
            ...common,
            center: text,
          },
        }
      }
      case 'topRight': {
        return {
          header: {
            ...common,
            right: text,
          },
        }
      }
      case 'diagonal': {
        return {
          diagonal: {
            ...common,
            text: text,
          },
        }
      }
      case 'bottomLeft': {
        return {
          footer: {
            ...common,
            left: text,
          },
        }
      }
      case 'bottomCenter': {
        return {
          footer: {
            ...common,
            center: text,
          },
        }
      }
      case 'bottomRight': {
        return {
          footer: {
            ...common,
            right: text,
          },
        }
      }
    }
  },
  addWatermarkAndGenerateBlob(blob, file, watermark) {
    return new Promise(async resolve => {
      const viewer = document.createElement('div')
      viewer.style.height = '100px'
      viewer.style.width = '100px'
      viewer.style.overflow = 'hidden'
      viewer.style.opacity = '0'
      document.body.appendChild(viewer)
      let viewInstance
      try {
        viewInstance = await WebViewer(
          {
            licenseKey: PDF_WebViewer_KEY,
            path: '/webviewer',
            enableAnnotations: true, //enables/disables all anotations
            isReadOnly: true,
            disableLogs: true,
          },
          viewer,
        )
        const { Core, UI } = viewInstance
        const { documentViewer } = Core

        UI.loadDocument(blob, { filename: file.filename })

        documentViewer.setWatermark(
          watermarkUtils.getPdftronWatermarkObject(watermark),
        )

        documentViewer.addEventListener('documentLoaded', async () => {
          const doc = documentViewer.getDocument()
          const data = await doc.getFileData() // Get the watermarked PDF data
          const arr = new Uint8Array(data)
          const watermarkedBlob = new Blob([arr], { type: doc.type })

          // Clean up the dummy viewer
          viewer.remove()
          viewInstance.UI.dispose()
          resolve(watermarkedBlob)
        })
      } catch (error) {
        console.error(error)
        if (viewInstance) {
          viewInstance.UI.dispose()
        } else {
          viewInstance = null
        }
      }
    })
  },
  canWatermarkBeApplied(file, shareLink) {
    return ['.pdf'].includes(file.fileExtension)
    && shareLink.watermark
    && shareLink.firm.enableWatermarks
  }
}

export default watermarkUtils
