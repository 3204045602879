import React, { useState } from 'react'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import {
  Box,
  IconButton,
  Button,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Link } from 'react-router-dom'

const popperSettings = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -16],
      },
    },
  ],
}

const folderActionListItems = [
  {
    label: 'Create new folder',
    name: 'file_create_folder',
    value: 'file_create_folder',
  },
  {
    label: 'Apply folder template',
    name: 'file_folder_template_apply',
    value: 'file_folder_template_apply',
  },
]

const FileListToolbar = props => {
  const {
    selectedFileIds,
    onProcess,
    searchText,
    handleSearch,
    selectCopyAction,
    selectMoveAction,
    selectDownloadAction,
    selectArchiveAction,
    match,
    linkActionListItems,
    selectLinkAction,
    selectFolderAction,
    selectNewFileAction,
  } = props

  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null)
  const [newLinkAnchorEl, setNewLinkAnchorEl] = useState(null)
  const [newFolderAnchorEl, setNewFolderAnchorEl] = useState(null)

  const toggleOptionsMenu = event => {
    setOptionsAnchorEl(event ? event.currentTarget : null)
  }

  const toggleNewLinkMenu = event => {
    setNewLinkAnchorEl(event ? event.currentTarget : null)
  }

  const toggleNewFolderMenu = event => {
    setNewFolderAnchorEl(event ? event.currentTarget : null)
  }

  return (
    <GridToolbarContainer
      sx={{
        overflowX: 'hidden',
        minHeight: 70,
        '@media (max-width:920px)': {
          minHeight: 140,
        },
        '@media (max-width:435px)': {
          overflowX: 'scroll',
        },
        scrollbarWidth: 'none'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          maxHeight: 40,
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <TextField
          autoFocus={!!searchText}
          defaultValue={searchText}
          onChange={handleSearch}
          id="fileQuickSearch"
          label="Search"
          size="small"
          variant="standard"
          sx={{ minWidth: 100 }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          gap: 1,
          display: 'flex',
          alignItems: 'center',
          maxHeight: 40,
        }}
      >
        <Tooltip
          title={`Copy${selectedFileIds && selectedFileIds.length > 0 ? ' ' + selectedFileIds.length : ''}`}
          slotProps={{
            popper: popperSettings,
          }}
        >
          <span>
            <IconButton
              disabled={selectedFileIds.length < 1 || onProcess}
              onClick={selectCopyAction}
            >
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={`Move${selectedFileIds && selectedFileIds.length > 0 ? ' ' + selectedFileIds.length : ''}`}
          slotProps={{
            popper: popperSettings,
          }}
        >
          <span>
            <IconButton
              disabled={selectedFileIds.length < 1 || onProcess}
              onClick={selectMoveAction}
            >
              <DriveFileMoveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={`Download${selectedFileIds && selectedFileIds.length > 0 ? ' ' + selectedFileIds.length : ''}`}
          slotProps={{
            popper: popperSettings,
          }}
        >
          <span>
            <IconButton
              disabled={selectedFileIds.length < 1 || onProcess}
              onClick={selectDownloadAction}
            >
              <DownloadIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={`Archive${selectedFileIds && selectedFileIds.length > 0 ? ' ' + selectedFileIds.length : ''}`}
          slotProps={{
            popper: popperSettings,
          }}
        >
          <span>
            <IconButton
              disabled={selectedFileIds.length < 1 || onProcess}
              onClick={selectArchiveAction}
            >
              <ArchiveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Button
          variant="contained"
          size="small"
          onClick={toggleNewLinkMenu}
          sx={{ whiteSpace: 'nowrap' }}
        >
          New Link
        </Button>
        <Menu
          anchorEl={newLinkAnchorEl}
          open={!!newLinkAnchorEl}
          onClose={() => toggleNewLinkMenu()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {linkActionListItems.map(item => (
            <MenuItem
              key={item.value}
              onClick={() => {
                selectLinkAction(item.value)
                toggleNewLinkMenu()
              }}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <Button
          variant="contained"
          size="small"
          onClick={toggleNewFolderMenu}
          sx={{ whiteSpace: 'nowrap' }}
        >
          New Folder
        </Button>
        <Menu
          anchorEl={newFolderAnchorEl}
          open={!!newFolderAnchorEl}
          onClose={() => toggleNewFolderMenu()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {folderActionListItems.map(item => (
            <MenuItem
              key={item.value}
              onClick={() => {
                selectFolderAction(item.value)
                toggleNewFolderMenu()
              }}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            selectNewFileAction('file_upload')
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          New File
        </Button>
        <Tooltip
          title="Options"
          slotProps={{
            popper: popperSettings,
          }}
        >
          <span>
            <IconButton onClick={toggleOptionsMenu}>
              <MoreVertIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Menu
          anchorEl={optionsAnchorEl}
          open={!!optionsAnchorEl}
          onClose={() => toggleOptionsMenu()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => toggleOptionsMenu()}>
            <Link to={`${match.url}/archived`}>View Archive</Link>
          </MenuItem>
        </Menu>
      </Box>
    </GridToolbarContainer>
  )
}

export default FileListToolbar
