import { baseApi } from '../base'
import qs from 'qs'

const shareLinkApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getShareLinks: build.query({
      query: params => {
        const queryParams = qs.stringify(params)

        return `/share-links?${queryParams}`
      },
    }),
    overrideExisting: false,
  }),
})

export const { useGetShareLinksQuery } = shareLinkApi
