import React from 'react'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { Box, Button, Typography } from '@mui/material'

const ContactsListToolbar = ({
  hideExport,
  onSendBulkInviteClick,
  selectedUserIds,
  submittingResendInvite,
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        tooltipTitle="Export data"
        slotProps={{
          tooltip: {
            title: hideExport
              ? "You don't have permissions to export data"
              : 'Export data',
          },
          button: {
            variant: 'contained',
            color: 'secondary',
            disabled: hideExport,
          },
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Button
        variant="outlined"
        size="small"
        color="primary"
        disableElevation
        onClick={onSendBulkInviteClick}
        disabled={!selectedUserIds.length || submittingResendInvite}
      >
        <Typography
          noWrap={true}
          variant="button"
        >
          Resend Invite{' '}
          {selectedUserIds.length ? (
            <span> — {selectedUserIds.length}</span>
          ) : null}
        </Typography>
      </Button>
    </GridToolbarContainer>
  )
}

export default ContactsListToolbar
