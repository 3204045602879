import React from 'react'
import { Box, Button, MenuItem, Select } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

const options = [
  { value: 'funny', label: 'Funny tone' },
  { value: 'professional', label: 'Professional tone' },
  { value: 'casual', label: 'Casual tone' },
]

const ToneSelector = ({
  isLoading,
  tone,
  onToneChange,
  generateCount,
  onGenerate,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
    <Select
      value={tone}
      onChange={e => onToneChange(e.target.value)}
      size="small"
      sx={{ minWidth: 200 }}
      variant="filled"
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
    <Box sx={{ flexGrow: 1 }} />
    <Button
      startIcon={generateCount > 0 ? <RefreshIcon /> : <AutoAwesomeIcon />}
      variant="contained"
      onClick={onGenerate}
      disabled={isLoading}
    >
      {generateCount > 0 ? 'Regenerate' : 'Generate'}
    </Button>
  </Box>
)

export default ToneSelector
