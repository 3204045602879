/**
 * Reusable dropdown to change workflow template status.
 */

// import primary libararies
import React from 'react'
import PropTypes from 'prop-types'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

const ClientWorkflowTemplateStatusMenu = ({ handleUpdateStatus, isOpen }) => {
  // console.log('isOpen': isOpen)
  return (
    <TransitionGroup>
      {isOpen ? (
        <CSSTransition
          classNames="dropdown-anim"
          timeout={250}
        >
          <ul className="-task-options">
            <li className="-header">
              <strong>Choose a status</strong>
            </li>
            <li className="-option">
              <span
                className="-select"
                onClick={() => handleUpdateStatus('published')}
              >
                {' '}
                Publish Template{' '}
              </span>
            </li>
            <li className="-option">
              <span
                className="-select"
                onClick={() => handleUpdateStatus('archived')}
              >
                {' '}
                Archive Template{' '}
              </span>
            </li>
          </ul>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

ClientWorkflowTemplateStatusMenu.propTypes = {
  handleUpdateStatus: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

ClientWorkflowTemplateStatusMenu.defaultProps = {
  isOpen: false,
}

export default ClientWorkflowTemplateStatusMenu
