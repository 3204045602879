import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, Button, Grid } from '@mui/material';
import validationUtils from '../../../global/utils/validationUtils';

const EditFileNameForm = ({ initialFileName, onSave, onCancel }) => {
    const [fileName, setFileName] = useState(initialFileName.replace(/\.[^/.]+$/, ""));
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (fileName.length < 1) {
            setIsValid(false);
            return;
        }
        setIsValid(validationUtils.checkFilenameIsValid(fileName));
    }, [fileName]);

    const handleChange = (event) => {
        let name = event.target.value;
        setFileName(name);
    }

    const handleSave = () => {
        if (isValid) {
            onSave(fileName);
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none', width: '100%', WebkitOverflowScrolling: 'touch' }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={fileName}
                        onChange={handleChange}
                        error={!isValid}
                        InputProps={{
                            endAdornment: initialFileName.includes('.') ? (
                                <InputAdornment position="end">
                                    .{initialFileName.split('.').pop()}
                                </InputAdornment>
                            ) : null,
                            style: {
                                color: !isValid ? 'red' : 'inherit'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancel}
                        style={{ marginLeft: '8px' }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default EditFileNameForm;