// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import actions
import * as fileActions from '../../fileActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminFileLayout from '../components/AdminFileLayout.js.jsx'
import AdminFileListItem from '../components/AdminFileListItem.js.jsx'

class FileList extends Binder {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // fetch a list of your choice
    this.props.dispatch(fileActions.fetchListIfNeeded('all')) // defaults to 'all'
  }

  render() {
    const { location, fileStore } = this.props
    // get the fileList meta info here so we can reference 'isFetching'
    const fileList = fileStore.lists ? fileStore.lists.all : null
    const fileListItems = fileStore.util.getList('all')

    const isEmpty = !fileListItems || !fileList

    const isFetching = !fileListItems || !fileList || fileList.isFetching

    return (
      <AdminFileLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        <h1> File List </h1>
        <hr />
        <br />
        {isEmpty ? (
          isFetching ? (
            <h2>Loading...</h2>
          ) : (
            <h2>Empty.</h2>
          )
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="admin-table-wrapper">
              <Link to={'/admin/files/new'}> New File</Link>
              <table className="yt-table striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Last modified</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {fileListItems.map((file, i) => (
                    <AdminFileListItem
                      key={file._id + i}
                      file={file}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </AdminFileLayout>
    )
  }
}

FileList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    fileStore: store.file,
  }
}

export default withRouter(connect(mapStoreToProps)(FileList))
