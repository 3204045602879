/**
 * View component for /admin/notes/:noteId/update
 *
 * Updates a single note from a copy of the selcted note
 * as defined in the note reducer
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as noteActions from '../../noteActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminNoteForm from '../components/AdminNoteForm.js.jsx'
import AdminNoteLayout from '../components/AdminNoteLayout.js.jsx'

class AdminUpdateNote extends Binder {
  constructor(props) {
    super(props)
    const { match, noteStore } = this.props
    this.state = {
      note: noteStore.byId[match.params.noteId]
        ? _cloneDeep(noteStore.byId[match.params.noteId])
        : {},
      formHelpers: {},
    }
    this._bind('_handleFormChange', '_handleFormSubmit')
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(noteActions.fetchSingleIfNeeded(match.params.noteId))
  }

  componentWillReceiveProps(nextProps) {
    const { match, noteStore } = nextProps
    this.setState({
      note: noteStore.byId[match.params.noteId]
        ? _cloneDeep(noteStore.byId[match.params.noteId])
        : {},
      // NOTE: ^ we don't want to actually change the store's note, just use a copy
    })
  }

  _handleFormChange(e) {
    let newState = _update(this.state, e.target.name, () => {
      return e.target.value
    })
    this.setState({ newState })
  }

  _handleFormSubmit(e) {
    const { dispatch, history } = this.props
    e.preventDefault()
    dispatch(noteActions.sendUpdateNote(this.state.note)).then(noteRes => {
      if (noteRes.success) {
        history.push(`/admin/notes/${noteRes.item._id}`)
      } else {
        alert('ERROR - Check logs')
      }
    })
  }

  render() {
    const { location, match, noteStore } = this.props
    const { note, formHelpers } = this.state

    const selectedNote = noteStore.selected.getItem()

    const isEmpty = !note || !note._id

    const isFetching = !noteStore.selected.id || noteStore.selected.isFetching

    return (
      <AdminNoteLayout>
        <Breadcrumbs links={location.state.breadcrumbs} />
        {isEmpty ? (
          isFetching ? (
            <h2>Loading...</h2>
          ) : (
            <h2>Empty.</h2>
          )
        ) : (
          <AdminNoteForm
            note={note}
            cancelLink={`/admin/notes/${note._id}`}
            formHelpers={formHelpers}
            formTitle="Update Note"
            formType="update"
            handleFormChange={this._handleFormChange}
            handleFormSubmit={this._handleFormSubmit}
          />
        )}
      </AdminNoteLayout>
    )
  }
}

AdminUpdateNote.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    noteStore: store.note,
  }
}

export default withRouter(connect(mapStoreToProps)(AdminUpdateNote))
