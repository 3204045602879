import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../global/components/Binder.js.jsx'

import withRouter from 'react-router-dom/withRouter'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

class SingleEngagementOptions extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    const { isOpen, handleUpdateEngagement } = this.props

    return (
      <span
        className="single-file-options"
        style={{ position: 'absolute' }}
      >
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={250}
            >
              <ul
                className="dropMenu -options-menu"
                style={{ left: '-1.5em' }}
              >
                <li className="-option">
                  <a onClick={handleUpdateEngagement}>Update</a>
                </li>
              </ul>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </span>
    )
  }
}

SingleEngagementOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openQuickTaskModal: PropTypes.func,
}

SingleEngagementOptions.defaultProps = {}

export default withRouter(SingleEngagementOptions)
