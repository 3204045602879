/**
 * Will act as the default wrapper for all module states within the application
 * that call it within their own Layouts
 */

// import primary libraries
import React from 'react'
import queryString from 'query-string'
import axios from 'axios'

// import third-party libraries
import classNames from 'classnames'

// import components
import Binder from '../Binder.js.jsx'
import DefaultTopNav from '../navigation/DefaultTopNav.js.jsx'
import Auth from '../../../global/utils/auth'
import UserTokenChecker from '../../../resources/user/components/UserTokenChecker.js.jsx'
import InactivityChecker from '../../../resources/user/components/InactivityChecker.js.jsx'

class DefaultLayout extends Binder {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { forwardURI } = queryString.parse(
      decodeURIComponent(window.location.search),
    )

    if (forwardURI) {
      const urls = forwardURI.split('?')

      if (urls.length > 0) {
        const hostname = urls[0]
        const qfolderpath = `?${urls[1]}`

        const { folderpath } = queryString.parse(
          decodeURIComponent(qfolderpath),
        )

        if (folderpath && !Auth.notLoggedIn()) {
          const paths = folderpath.split('/')

          let clientname = ''
          let foldername = ''

          if (paths.length > 0) {
            clientname = paths[0]
            foldername = paths[1]
          } else {
            clientname = paths
          }

          axios({
            method: 'GET',
            url: `/api/com/getClientFolder?clientname=${clientname}&foldername=${foldername}`,
          }).then(({ data }) => {
            if (data.success) {
              const c = data.client
              const f = data.file

              let clientUrl =
                hostname.charAt(hostname.length - 1) == '/' ? '' : '/'

              clientUrl += !c ? '' : `firm/${c._firm}/workspaces/${c._id}/files`
              const folderUrl = !f ? '' : `/${f._id}/folder`

              if (hostname) {
                if (hostname.includes('localhost')) {
                  window.location = `http://${hostname}${clientUrl}${folderUrl}`
                } else {
                  window.location = `https://${hostname}${clientUrl}${folderUrl}`
                }
              } else {
                if (window.appUrl.includes('localhost')) {
                  window.location = `http://${window.appUrl}${clientUrl}${folderUrl}`
                } else {
                  window.location = `https://${window.appUrl}${clientUrl}${folderUrl}`
                }
              }
            } else {
              //do not redirect
            }
          })
        }
      } else {
        window.location = `https://${window.appUrl}`
      }
    }
  }

  render() {
    let bodyClass = classNames(
      'body',
      'with-header',
      // , { 'dark': initialNavClass == 'dark' }
    )

    const { isLinkConfigLayout = false } = this.props

    return (
      <div className="master-layout">
        {/* <UserAutoLogoutForm /> */}
        <UserTokenChecker />
        <InactivityChecker />
        <DefaultTopNav isLinkConfigLayout={isLinkConfigLayout} />
        <div className={bodyClass}>
          {!isLinkConfigLayout ? (
            <div className="yt-container">{this.props.children}</div>
          ) : (
            this.props.children
          )}
        </div>
      </div>
    )
  }
}

export default DefaultLayout
