import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../global/components/Binder.js.jsx'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

class MergeFieldOptions extends Binder {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { isOpen, mergeFieldListItems, handleInsertMergeField } = this.props

    return (
      <span
        className="single-file-options"
        style={{ position: 'absolute' }}
      >
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={250}
            >
              <ul className="dropMenu -options-menu">
                {mergeFieldListItems.map((item, i) => (
                  <li key={i}>
                    <a onClick={() => handleInsertMergeField(item._id)}>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </span>
    )
  }
}

MergeFieldOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

MergeFieldOptions.defaultProps = {}

export default withRouter(MergeFieldOptions)
