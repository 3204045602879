import React from 'react';
import useStyles from './styles';
import { Box, CircularProgress, Modal } from '@material-ui/core';

const Loading = props => {
  const { isLoading } = props;
  const classes = useStyles();

  return (
    <Modal open={isLoading}>
      <Box className={classes.modal}>
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default Loading;
