exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".theme-imaginetime-styles-components-____imageCropper__editor-container___Ps2kq {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.theme-imaginetime-styles-components-____imageCropper__editor-wrapper___1yC-N {\n  width: 300px;\n  height: 300px;\n  position: relative;\n  margin-right: 20px;\n}\n\n.theme-imaginetime-styles-components-____imageCropper__placeholder-text___75TNi {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 20px;\n  font-weight: bold;\n  width: 100%;\n  height: 100%;\n  border: 2px dashed #ccc;\n  text-align: center;\n  color: #ccc;\n}\n\n.theme-imaginetime-styles-components-____imageCropper__input-wrapper___2TeAv {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.theme-imaginetime-styles-components-____imageCropper__slider-wrapper___2lcxP {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.theme-imaginetime-styles-components-____imageCropper__image-cropper___3Am7S button {\n  padding: 8px 16px;\n  margin-top: 10px;\n  font-size: 16px;\n  border: none;\n  border-radius: 5px;\n  background-color: #1e88e5;\n  color: #fff;\n  cursor: pointer;\n}\n\n.theme-imaginetime-styles-components-____imageCropper__image-cropper___3Am7S button:hover {\n  background-color: #0d47a1;\n}\n", ""]);

// Exports
exports.locals = {
	"editor-container": "theme-imaginetime-styles-components-____imageCropper__editor-container___Ps2kq",
	"editor-wrapper": "theme-imaginetime-styles-components-____imageCropper__editor-wrapper___1yC-N",
	"placeholder-text": "theme-imaginetime-styles-components-____imageCropper__placeholder-text___75TNi",
	"input-wrapper": "theme-imaginetime-styles-components-____imageCropper__input-wrapper___2TeAv",
	"slider-wrapper": "theme-imaginetime-styles-components-____imageCropper__slider-wrapper___2lcxP",
	"image-cropper": "theme-imaginetime-styles-components-____imageCropper__image-cropper___3Am7S"
};