/**
 * Sets up the routing for all Admin views.
 *
 * NOTE: All imported [Module]PortalRouter files must be wrapped in a Route wrapper
 * inside the switch in order to resolve correctly.  See <UserAdminRouter/>
 * below as an example.
 */

// import primary libraries
import React from 'react';
import Redirect from 'react-router-dom/Redirect';
import Switch from 'react-router-dom/Switch';

// import global components
import Binder from '../../global/components/Binder.js.jsx';
import YTRoute from '../../global/components/routing/YTRoute.js.jsx';

// import admin views
import PortalDashboard from './views/PortalDashboard.js.jsx';

class PortalRouter extends Binder {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <YTRoute login={true} exact path="/portal" render={() => <Redirect to="/user/forward"/> } />
        <YTRoute clientUser={true} exact path="/portal/:clientId" render={props => <Redirect to={`/portal/${props.match.params.clientId}/files`}/> } />
        <YTRoute clientUser={true} path="/portal/:clientId/dashboard" component={PortalDashboard} />
      </Switch>
    )
  }
}

export default PortalRouter;
