import React from 'react'
import PropTypes from 'prop-types'
import Binder from '../../../../global/components/Binder.js.jsx'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import ReactTooltip from 'react-tooltip'
import permissions from '../../../../global/utils/permissions.js'

class SingleFileOptions extends Binder {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      handleOpenQuickTaskModal,
      handleOpenFolderPermissionModal,
      isOpen,
      handleSetStatus,
      sendDeleteFile,
      eSigAccess,
      toggleUpdateFilename,
      match,
      file = {},
      closeAction,
      isFolderFromTemplate,
      viewingAs,
      selectedFirm,
      handleOpenMoveFileModal,
      role,
      parentFolder,
      isSubfolderFromTemplate,
      handleDownload,
      handleOpenMoveModal,
      handleOpenShareModal,
      selectedFileIds,
      multiple,
      basicPermissions,
    } = this.props

    const fileCategory = multiple
      ? ''
      : file.category === 'folder'
      ? 'Folder'
      : 'File'

    const endIndexOf =
      match && match.url ? match.url.indexOf('file-activity') : -1
    let fileActivityUrl =
      endIndexOf > 0 ? match.url.substr(0, endIndexOf) : `${match.url}/`
    fileActivityUrl +=
      viewingAs === 'archived' ? '' : `file-activity/${file._id}`

    let contentType = file && file.contentType
    if (file && file.category != 'folder' && file.fileExtension) {
      if (file.fileExtension.toLowerCase().indexOf('.pdf') > -1) {
        contentType = 'application/pdf'
      } else if (file.fileExtension.toLowerCase().indexOf('.doc') > -1) {
        contentType = 'application/doc'
      } else {
        contentType = file.fileExtension
      }
    }

    const DisableButton = ({ uniqueId, label, text }) => {
      if (multiple) {
        label = label.replace('%CATEGORY%', '')
      } else {
        label = label.replace('%CATEGORY%', fileCategory)
      }

      return (
        <li
          className="-option"
          data-tip
          data-for={'SFO_WorkspaceDisable' + uniqueId}
        >
          <a
            className="-disabled-link"
            disabled={true}
            onClick={null}
          >
            <i className="fas fa-lock" /> {label}{' '}
            {multiple ? <span> &mdash; {selectedFileIds.length}</span> : ''}
          </a>
          <ReactTooltip
            id={'SFO_WorkspaceDisable' + uniqueId}
            place="top"
            type="warning"
            effect="solid"
          >
            <span className="tooltipMessage">
              You don't have permission to <br /> {text}
            </span>
          </ReactTooltip>
        </li>
      )
    }

    return (
      <span
        className="single-file-options"
        style={{ position: 'absolute' }}
      >
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames="dropdown-anim"
              timeout={250}
            >
              {viewingAs === 'archived' ? (
                <ul
                  className="dropMenu -options-menu"
                  style={{ top: 'calc(100% + 8px)' }}
                >
                  {
                    //selectedFirm && selectedFirm.allowDeleteFiles ?
                    permissions.hasPermission(
                      selectedFirm,
                      parentFolder,
                      file,
                      `${role}Delete`
                    ) ? (
                      <li className="-option">
                        <a
                          className="-delete-link"
                          onClick={sendDeleteFile}
                        >
                          Delete File
                        </a>
                      </li>
                    ) : (
                      <li
                        className="-option"
                        data-tip
                        data-for="SFO_DisableDelete"
                      >
                        <a
                          className="-disabled-link"
                          disabled={true}
                          onClick={null}
                        >
                          <i className="fas fa-lock" /> Delete File
                        </a>
                        <ReactTooltip
                          id="SFO_DisableDelete"
                          place="top"
                          type="warning"
                          effect="solid"
                        >
                          <span className="tooltipMessage">
                            You don't have permission to <br /> archive/delete
                            files and folders
                          </span>
                        </ReactTooltip>
                      </li>
                    )
                  }
                  <li className="-option">
                    <a onClick={() => handleSetStatus('visible')}>
                      Reinstate File
                    </a>
                  </li>
                </ul>
              ) : viewingAs === 'portal' ? (
                <ul
                  className="dropMenu -options-menu"
                  style={{ top: 'calc(100% + 8px)' }}
                >
                  {isSubfolderFromTemplate ? null : selectedFirm.allowDeleteFiles ? (
                    <li className="-option">
                      <a
                        className="-delete-link"
                        onClick={sendDeleteFile}
                      >
                        Delete {file.category !== 'folder' ? 'File' : 'Folder'}
                      </a>
                    </li>
                  ) : (
                    <li
                      className="-option"
                      data-tip
                      data-for="SFO_PortalDisableDelete"
                    >
                      <a
                        className="-disabled-link"
                        disabled={true}
                        onClick={null}
                      >
                        <i className="fas fa-lock" /> Delete{' '}
                        {file.category !== 'folder' ? 'File' : 'Folder'}
                      </a>
                      <ReactTooltip
                        id="SFO_PortalDisableDelete"
                        place="top"
                        type="warning"
                        effect="solid"
                      >
                        <span className="tooltipMessage">
                          You don't have permission to <br /> archive/delete
                          files and folders
                        </span>
                      </ReactTooltip>
                    </li>
                  )}
                  {isSubfolderFromTemplate ? null : selectedFirm &&
                    selectedFirm.allowMoveFiles ? (
                    <li className="-option">
                      <a onClick={handleOpenMoveFileModal}>
                        Move {file.category == 'folder' ? 'Folder' : 'File'}
                      </a>
                    </li>
                  ) : null}
                  {selectedFirm.allowRenameFiles ? (
                    <li className="-option">
                      <a onClick={toggleUpdateFilename}>
                        Rename {file.category == 'folder' ? 'Folder' : 'File'}
                      </a>
                    </li>
                  ) : (
                    <li
                      className="-option"
                      data-tip
                      data-for="SFO_PortalDisableUpdate"
                    >
                      <a
                        className="-disabled-link"
                        disabled={true}
                        onClick={null}
                      >
                        <i className="fas fa-lock" /> Rename{' '}
                        {file.category == 'folder' ? 'Folder' : 'File'}
                      </a>
                      <ReactTooltip
                        id="SFO_PortalDisableUpdate"
                        place="top"
                        type="warning"
                        effect="solid"
                      >
                        <span className="tooltipMessage">
                          You don't have permission to <br /> update files and
                          folders
                        </span>
                      </ReactTooltip>
                    </li>
                  )}
                </ul>
              ) : (
                <ul
                  className="dropMenu -options-menu"
                  style={{ top: 'calc(100% + 8px)' }}
                >
                  {!isSubfolderFromTemplate &&
                  permissions.hasPermission(
                    selectedFirm,
                    parentFolder,
                    file,
                    `${role}Delete`,
                  ) ? (
                    <li className="-option">
                      <a onClick={handleSetStatus}>
                        Archive{' '}
                        {multiple ? (
                          <span> &mdash; {selectedFileIds.length}</span>
                        ) : (
                          fileCategory
                        )}
                      </a>
                    </li>
                  ) : (
                    <DisableButton
                      uniqueId="Archive"
                      label={`Archived %CATEGORY%`}
                      text="archive/delete files and folders"
                    />
                  )}
                  {permissions.hasPermission(
                    selectedFirm,
                    parentFolder,
                    file,
                    `${role}Download`,
                  ) ? (
                    <li className="-option">
                      <a onClick={handleDownload}>
                        Download{' '}
                        {multiple ? (
                          <span> &mdash; {selectedFileIds.length}</span>
                        ) : (
                          fileCategory
                        )}
                      </a>
                    </li>
                  ) : (
                    <DisableButton
                      uniqueId="Download"
                      label={`Download %CATEGORY%`}
                      text="download files and folders"
                    />
                  )}
                  {!multiple && file.category != 'folder' ? (
                    <li>
                      <Link
                        onClick={closeAction}
                        to={fileActivityUrl}
                      >
                        File Activity
                      </Link>
                    </li>
                  ) : file.category == 'folder' ? null : (
                    <DisableButton
                      uniqueId="FileActivity"
                      label={`Activity`}
                      text="view activity"
                    />
                  )}
                  {!isSubfolderFromTemplate ? (
                    <li className="-option">
                      <a
                        disabled={!handleOpenMoveModal}
                        onClick={handleOpenMoveModal}
                      >
                        Move{' '}
                        {multiple ? (
                          <span> &mdash; {selectedFileIds.length}</span>
                        ) : (
                          fileCategory
                        )}
                      </a>
                    </li>
                  ) : (
                    <DisableButton
                      uniqueId="Move"
                      label={`Move %CATEGORY%`}
                      text="move sub folders created from folder templates"
                    />
                  )}
                  {!multiple &&
                  permissions.hasPermission(
                    selectedFirm,
                    parentFolder,
                    file,
                    `${role}Update`,
                  ) ? (
                    <li className="-option">
                      <a onClick={toggleUpdateFilename}>
                        Rename{' '}
                        {multiple ? (
                          <span> &mdash; {selectedFileIds.length}</span>
                        ) : (
                          fileCategory
                        )}
                      </a>
                    </li>
                  ) : (
                    <DisableButton
                      uniqueId="Rename"
                      label={`Rename %CATEGORY%`}
                      text="rename files and folders"
                    />
                  )}
                  {!multiple &&
                  !basicPermissions &&
                  file.category != 'folder' &&
                  eSigAccess &&
                  contentType &&
                  (contentType.indexOf('pdf') > -1 ||
                    contentType.indexOf('doc') > -1) ? (
                    <li className="-option">
                      <a
                        disabled={!handleOpenQuickTaskModal}
                        onClick={handleOpenQuickTaskModal}
                      >
                        Request signature
                      </a>
                    </li>
                  ) : file.category == 'folder' ? null : (
                    <DisableButton
                      uniqueId="RequestSignature"
                      label="Request Signature"
                      text="request signature of files and folders"
                    />
                  )}
                  {/* {
                  permissions.hasPermission(selectedFirm, parentFolder, file, `${role}Share`) ?
                    <li className="-option">
                      <a disabled={!handleOpenShareModal} onClick={handleOpenShareModal}>Share {multiple ? <span> &mdash; {selectedFileIds.length}</span> : fileCategory}</a>
                    </li>
                  : 
                  <DisableButton
                    uniqueId="Share"
                    label={`Share %CATEGORY%`}
                    text="share files and folders"
                  />
                } */}
                  {basicPermissions ? (
                    <DisableButton
                      uniqueId="ShareFile"
                      label="Share File"
                      text="share the files and folders"
                    />
                  ) : (
                    <li className="-option">
                      <a
                        disabled={!handleOpenShareModal}
                        onClick={handleOpenShareModal}
                      >
                        Share{' '}
                        {multiple ? (
                          <span> &mdash; {selectedFileIds.length}</span>
                        ) : (
                          fileCategory
                        )}
                      </a>
                    </li>
                  )}
                  {!multiple &&
                  !isFolderFromTemplate &&
                  !isSubfolderFromTemplate &&
                  file.category == 'folder' &&
                  (file.status != 'archived' || file.status == 'deleted') ? (
                    <li className="-option">
                      <a onClick={handleOpenFolderPermissionModal}>
                        Permissions
                      </a>
                    </li>
                  ) : file.category != 'folder' ? null : (
                    <DisableButton
                      uniqueId="Permissions"
                      label="Permissions"
                      text="modify the permissions of files and folders"
                    />
                  )}
                </ul>
              )}
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </span>
    )
  }
}

SingleFileOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openQuickTaskModal: PropTypes.func,
}

SingleFileOptions.defaultProps = {}

export default withRouter(SingleFileOptions)
