/**
 * View component for /portal/:clientId/client-workflows
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries

// import actions

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import FilterBy from '../../../../global/components/helpers/FilterBy.js.jsx'
import DisplayAsButtons from '../../../../global/components/helpers/DisplayAsButtons.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'

// import resource components
import ClientWorkflowGridListItem from '../../components/ClientWorkflowGridListItem.js.jsx'

class PortalClientWorkflowList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      viewingAs: this.props.viewingAs,
    }
    this._bind('_handleSelectedTagsChange')
  }

  _handleSelectedTagsChange(e) {
    console.log('handleSelectedTagsChange', e)
    this.props.handleFilter(e)
  }

  render() {
    const {
      allTags,
      clientWorkflowList,
      handleSetPagination,
      paginatedList,
      selectedTagIds,
      totalListInfo,
    } = this.props

    const isFiltered =
      totalListInfo &&
      clientWorkflowList &&
      totalListInfo.items.length > clientWorkflowList.items.length

    return (
      <div className="-client-workflow-list-wrapper">
        <div className="clientWorkflow-grid">
          {paginatedList.length > 0 ? (
            paginatedList.map((clientWorkflow, i) => (
              <ClientWorkflowGridListItem
                key={clientWorkflow._id + '_' + i}
                clientWorkflow={clientWorkflow}
              />
            ))
          ) : (
            <div className="empty-state">
              <em>No clientWorkflows</em>
            </div>
          )}
        </div>
      </div>
    )
  }
}

PortalClientWorkflowList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // , allTags: PropTypes.array.isRequired
  selectedTagIds: PropTypes.array,
  handleFilter: PropTypes.func.isRequired,
  handleOpenUploadModal: PropTypes.func,
  handleQuery: PropTypes.func.isRequired,
  handleSetPagination: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  sortedAndFilteredList: PropTypes.array,
  clientWorkflowList: PropTypes.object.isRequired,
  viewingAs: PropTypes.string,
}

PortalClientWorkflowList.defaultProps = {
  handleOpenUploadModal: null,
  sortedAndFilteredList: [],
  viewingAs: 'table',
}

const mapStoreToProps = (store, props) => {
  const { clientWorkflowList, sortedAndFilteredList } = props
  let paginatedList = []
  if (sortedAndFilteredList) {
    const pagination = clientWorkflowList.pagination || { page: 1, per: 50 }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(sortedAndFilteredList, start, end)
  }

  return {
    paginatedList: paginatedList,
  }
}

export default withRouter(connect(mapStoreToProps)(PortalClientWorkflowList))
