// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

import { Helmet } from 'react-helmet'

// import actions
import * as clientWorkflowTemplateActions from '../../clientWorkflowTemplateActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'

// import resource components
import AdminClientWorkflowTemplateLayout from '../components/AdminClientWorkflowTemplateLayout.js.jsx'
import AdminClientWorkflowTemplateListItem from '../components/AdminClientWorkflowTemplateListItem.js.jsx'

class ClientWorkflowTemplateList extends Binder {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // fetch a list of your choice
    this.props.dispatch(clientWorkflowTemplateActions.fetchListIfNeeded('all')) // defaults to 'all'
  }

  render() {
    const { location, clientWorkflowTemplateStore } = this.props

    // get the clientWorkflowTemplateList meta info here so we can reference 'isFetching'
    const clientWorkflowTemplateList = clientWorkflowTemplateStore.lists
      ? clientWorkflowTemplateStore.lists.all
      : null
    const clientWorkflowTemplateListItems =
      clientWorkflowTemplateStore.util.getList('all')

    const isEmpty =
      !clientWorkflowTemplateListItems || !clientWorkflowTemplateList

    const isFetching =
      !clientWorkflowTemplateListItems ||
      !clientWorkflowTemplateList ||
      clientWorkflowTemplateList.isFetching

    return (
      <AdminClientWorkflowTemplateLayout>
        <Helmet>
          <title>Admin Workflow Template List</title>
        </Helmet>
        <Breadcrumbs links={location.state.breadcrumbs} />
        <h1> ClientWorkflow Template List </h1>
        <hr />
        <br />
        {isEmpty ? (
          isFetching ? (
            <h2>Loading...</h2>
          ) : (
            <h2>Empty.</h2>
          )
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            <div className="admin-table-wrapper">
              <Link to={'/admin/client-workflow-templates/new'}>
                {' '}
                New ClientWorkflow Template
              </Link>
              <table className="yt-table striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Last modified</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {clientWorkflowTemplateListItems.map(
                    (clientWorkflowTemplate, i) => (
                      <AdminClientWorkflowTemplateListItem
                        key={clientWorkflowTemplate._id + i}
                        clientWorkflowTemplate={clientWorkflowTemplate}
                      />
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </AdminClientWorkflowTemplateLayout>
    )
  }
}

ClientWorkflowTemplateList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  return {
    clientWorkflowTemplateStore: store.clientWorkflowTemplate,
  }
}

export default withRouter(connect(mapStoreToProps)(ClientWorkflowTemplateList))
