// import primary libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

import classNames from 'classnames'
import AddressEditor from './AddressEditor.js.jsx'
import * as addressActions from '../addressActions'

const AddressCard = ({
  address,
  editable,
  isPrimary,
  onDeleteAddress,
  makePrimary,
  width,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const isEmpty = !address

  const cardClass = classNames('address-card-wrapper', {
    '-editable': editable,
  })

  const handleEditAddress = () => {
    setIsEditing(true)
  }

  return isEmpty ? (
    <div className="u-centerText">
      <div className="loading -small"></div>
    </div>
  ) : isEditing ? (
    <AddressEditor
      addressId={address._id}
      fromCard={true}
      onSubmit={() => setIsEditing(false)}
      editorClasses="-quick-view"
    />
  ) : (
    <div className={cardClass}>
      <div className="-address">
        <p>
          {`${address.street1}${address.street2 ? `, ${address.street2}` : ''}`}
        </p>
        <p>{`${address.city ? address.city : ''}, ${
          address.state ? address.state : ''
        } ${address.postal ? address.postal : ''}`}</p>
        <p>{address.country ? address.country : ''}</p>
        {isPrimary ? ' (Primary)' : null}
      </div>
      {editable ? (
        <div
          className="-edit-btns"
          style={{ width: width }}
        >
          <small
            className="action-link -edit-phone-link"
            onClick={handleEditAddress}
          >
            Edit
          </small>
          {onDeleteAddress && (
            <small
              className="action-link -edit-phone-link"
              onClick={() => onDeleteAddress(address._id)}
            >
              Delete
            </small>
          )}
          {!isPrimary && makePrimary ? (
            <small
              className="action-link -edit-phone-link"
              onClick={() => makePrimary(address._id)}
            >
              Make Primary
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

AddressCard.propTypes = {
  address: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  isPrimary: PropTypes.bool,
  makePrimary: PropTypes.func, // should be a function that accepts address._id
}

AddressCard.defaultProps = {
  editable: false,
  isPrimary: false,
  makePrimary: null,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {}
}

export default withRouter(connect(mapStoreToProps)(AddressCard))
