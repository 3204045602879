/**
 * Reusable stateless form component for Tag
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'

// import form components
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'

import ColorPicker from '../../components/ColorPicker.js.jsx'

const AdminTagForm = ({
  cancelLink,
  formHelpers,
  formTitle,
  formType,
  handleFormChange,
  handleFormSubmit,
  tag,
}) => {
  // set the button text
  const buttonText = formType === 'create' ? 'Create Tag' : 'Update Tag'

  // set the form header
  const header = formTitle ? (
    <div className="formHeader">
      <h2> {formTitle} </h2>
      <hr />
    </div>
  ) : (
    <div />
  )

  return (
    <div className="yt-container">
      <div className="yt-row center-horiz">
        <div className="form-container -slim">
          <form
            name="tagForm"
            className="tag-form"
            onSubmit={handleFormSubmit}
          >
            {header}
            <TextInput
              change={handleFormChange}
              label="Name"
              name="tag.name"
              placeholder="Name (required)"
              required={true}
              value={tag.name || ''}
            />
            <ColorPicker
              value={tag.color}
              onChange={color =>
                handleFormChange({ target: { name: 'tag.color', value: color } })
              }
            />
            <div className="input-group">
              <div className="yt-row space-between">
                <Link
                  className="yt-btn link"
                  to={cancelLink}
                >
                  Cancel
                </Link>
                <button
                  className="yt-btn "
                  type="submit"
                >
                  {' '}
                  {buttonText}{' '}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

AdminTagForm.propTypes = {
  cancelLink: PropTypes.string.isRequired,
  formHelpers: PropTypes.object,
  formTitle: PropTypes.string,
  formType: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
}

AdminTagForm.defaultProps = {
  formHelpers: {},
  formTitle: '',
}

export default AdminTagForm
