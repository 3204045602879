import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, Link, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Helmet } from 'react-helmet'
import {
  DataGrid,
  useGridApiRef,
  getGridStringOperators,
} from '@mui/x-data-grid'
import { Box, Paper, Link as MUILink } from '@mui/material'
import ContactsListToolbar from '../components/ContactsListToolbar.js.jsx'

import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import PracticeLayout from '../../../../global/practice/components/PracticeLayout.js.jsx'
import ContactQuickView from './ContactQuickView.js.jsx'
import YTRoute from '../../../../global/components/routing/YTRoute.js.jsx'

import * as addressActions from '../../../address/addressActions'
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../userActions'
import * as phoneNumberActions from '../../../phoneNumber/phoneNumberActions'
import * as clientActions from '../../../client/clientActions'
import * as clientUserActions from '../../../clientUser/clientUserActions'

import displayUtils from '../../../../global/utils/displayUtils'
import permissions from '../../../../global/utils/permissions'
import routeUtils from '../../../../global/utils/routeUtils'

const PracticeContactsList = ({
  dispatch,
  loggedInUser,
  contacts,
  staffStore,
  userList,
  location,
  match,
}) => {
  const [paginationModel, setPaginationModel] = useState({ page: 1, pageSize: 50 })
  const [submitResendInvite, setSubmitResendInvite] = useState(false)
  const firmId = match.params.firmId // Replacing useParams
  const gridApiRef = useGridApiRef()

  const customStringOperators = getGridStringOperators().filter(
    operator => operator.value !== 'isAnyOf',
  )
  useEffect(() => {
    dispatch(firmActions.fetchListIfNeeded('_user', loggedInUser._id))
    dispatch(clientActions.fetchListIfNeeded('_user', loggedInUser._id))

    if (loggedInUser.admin) {
      dispatch(phoneNumberActions.fetchListIfNeeded('_firm', firmId))
      dispatch(addressActions.fetchListIfNeeded('_firm', firmId))
      dispatch(userActions.fetchListIfNeeded('_firm', firmId))
    } else {
      dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(firmId)).then(
        staffRes => {
          if (staffRes.success) {
            dispatch(
              phoneNumberActions.fetchListIfNeeded('_staff', staffRes.item._id),
            )
            dispatch(
              addressActions.fetchListIfNeeded('_staff', staffRes.item._id),
            )
            dispatch(userActions.fetchListIfNeeded('_staff', staffRes.item._id))
          }
        },
      )
    }

    dispatch(firmActions.fetchSingleIfNeeded(firmId))

    return () => {}
  }, [dispatch, loggedInUser, firmId, location.search])

  const handleBulkResendInvite = useCallback(() => {
    const selectedRows = gridApiRef.current.getSelectedRows()
    const selectedUsers = Array.from(selectedRows.values())

    setSubmitResendInvite(true)
    const getInvitation = cb => {
      if (selectedUsers.length) {
        cb(selectedUsers)
      } else {
        cb(false)
      }
    }

    getInvitation(invitations => {
      if (invitations) {
        const sendData = { invitations, firmId }
        dispatch(clientUserActions.sendResendInviteClientUsers(sendData)).then(
          clientUserRes => {
            if (clientUserRes.success) {
              setSubmitResendInvite(false)
            } else {
              setSubmitResendInvite(false)
              alert('ERROR - Check logs')
            }
          },
        )
      } else {
        setSubmitResendInvite(false)
        alert('ERROR - Check logs')
      }
    })
  }, [gridApiRef, firmId, setSubmitResendInvite, dispatch])

  const columns = useMemo(() => {
    return [
      {
        field: 'contactFullName',
        headerName: 'Contact',
        width: 200,
        renderCell: params => (
          <Link
            to={`/firm/${firmId}/contacts/quick-view/${params.row._id}`}
            onClick={e => e.stopPropagation()}
          >
            {params.value}
          </Link>
        ),
        filterOperators: customStringOperators,
      },
      {
        field: 'contactEmail',
        headerName: 'Email',
        width: 200,
        renderCell: params => <span>{params.value}</span>,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone',
        width: 130,
        sortable: false,
        filterOperators: customStringOperators,
        renderCell: params =>
          params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          ),
      },
      {
        field: 'address',
        headerName: 'Address',
        width: 130,
        sortable: false,
        filterOperators: customStringOperators,
        renderCell: params => {
          return params.value ? (
            params.value
          ) : (
            <Box sx={{ opacity: 0.1 }}>&mdash;</Box>
          )
        },
      },
    ]
  }, [firmId])

  const isEmpty = !userList
  const isFetching = !userList || userList.isFetching
  const hasExportAccess = permissions.hasExportAccess(staffStore, firmId)

  const Toolbar = useMemo(() => {
    return () => (
      <ContactsListToolbar
        hideExport={!hasExportAccess}
        onSendBulkInviteClick={handleBulkResendInvite}
        selectedUserIds={Array.from(gridApiRef.current.getSelectedRows()).map(
          row => row._id,
        )}
        submittingResendInvite={submitResendInvite}
      />
    )
  }, [hasExportAccess, submitResendInvite, handleBulkResendInvite])

  return (
    <PracticeLayout>
      <Helmet>
        <title>Contact List </title>
      </Helmet>
      <div className="-practice-subnav">
        <div className="yt-container fluid">
          <div className="yt-row center-vert space-between">
            <Breadcrumbs links={location.state.breadcrumbs} />
          </div>
        </div>
      </div>
      <div>
        {isFetching ? (
          <div className="-loading-hero hero">
            <div className="u-centerText">
              <div className="loading"></div>
            </div>
          </div>
        ) : isEmpty ? (
          <div className="hero -empty-hero">
            <div className="u-centerText">
              <p>Looks like you don't have any contacts yet. </p>
              <p>Let's add some.</p>
            </div>
          </div>
        ) : (
          <Box pr={'20px'}>
            <Paper
              style={{
                height: '75vh',
                width: 'calc(100% - 20px)',
                overflow: 'hidden',
                opacity: isFetching ? 0.5 : 1,
              }}
              sx={{
                margin: '10px 20px',
              }}
            >
              <DataGrid
                apiRef={gridApiRef}
                rows={contacts}
                columns={columns}
                loading={isFetching}
                paginationModel={paginationModel}
                onPaginationModelChange={newModel => {
                  if (newModel.pageSize !== paginationModel.pageSize) {
                    newModel.page = 0
                  }
                  setPaginationModel(newModel)
                }}
                pageSizeOptions={[25, 50, 100, { value: -1, label: 'All' }]}
                checkboxSelection
                disableSelectionOnClick
                keepNonExistentRowsSelected
                getRowId={row => row._id}
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'white',
                  },
                  overflow: 'auto',
                }}
                slots={{
                  toolbar: Toolbar,
                }}
              />
            </Paper>
          </Box>
        )}
      </div>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="slide-from-right"
          timeout={300}
        >
          <Switch location={location}>
            <YTRoute
              breadcrumbs={[
                {
                  display: 'Contacts',
                  path: `/firm/${match.params.firmId}/contacts`,
                },
                { display: 'Details', path: null },
              ]}
              exact
              path="/firm/:firmId/contacts/quick-view/:userId"
              staff={true}
              component={ContactQuickView}
            />
            <Route render={() => <div />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </PracticeLayout>
  )
}

PracticeContactsList.propTypes = {
  contacts: PropTypes.array,
  paginatedList: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  orderedList: PropTypes.array,
  phoneNumberStore: PropTypes.object.isRequired,
  addressStore: PropTypes.object.isRequired,
  userList: PropTypes.object.isRequired,
  userMap: PropTypes.object.isRequired,
  staffStore: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired, // Added for withRouter
  match: PropTypes.object.isRequired, // Added for withRouter
  history: PropTypes.object.isRequired, // Added for withRouter
}

const mapStoreToProps = (store, props) => {
  const loggedInUser = store.user.loggedIn.user
  const userMap = store.user.byId
  const userStore = store.user
  const loggedInStaff = store.staff.loggedInByFirm[props.match.params.firmId]
    ? store.staff.loggedInByFirm[props.match.params.firmId].staff
    : null
  let userListArgsObj

  if (loggedInUser && loggedInUser.admin) {
    userListArgsObj = { _firm: props.match.params.firmId }
  } else if (loggedInStaff) {
    userListArgsObj = { _staff: loggedInStaff._id }
  }

  const userListArgs = userListArgsObj
    ? routeUtils.listArgsFromObject(userListArgsObj)
    : null
  const userListItems = userListArgs
    ? store.user.util.getList(...userListArgs)
    : null
  const userList = userListItems
    ? userListArgs.reduce((obj, nextKey) => obj[nextKey], store.user.lists)
    : null

  const filter = userList ? userList.filter : null
  const sortBy = filter ? filter.sortBy : 'contact'

  let listItems = []
  let paginatedList = []
  let filteredByQuery = []
  let orderedList = []

  if (userList && userListItems && userListItems.length) {
    // const filter = userList.filter;
    const pagination = userList.pagination || { page: 1, per: 50 }

    userListItems.forEach(item => {
      const data = _.cloneDeep(item)
      let showMe = true

      // praparing data ----------------------------------------------------------------------
      data.contactFullName = ''
      data.address = ''
      data.contactFullName = data.firstname || ''
      data.contactFullName += (data.firstname ? ' ' : '') + data.lastname || ''
      data.contactEmail = data.username
      data.phoneNumber =
        data.phonenumber &&
        data.phonenumber.number &&
        displayUtils.formatPhoneNumber(data.phonenumber.number, 'National')
          ? displayUtils.formatPhoneNumber(data.phonenumber.number, 'National')
          : null
      if (data.phonenumber && data.phonenumber.extNumber) {
        data.phoneNumber += ' ' + data.phonenumber.extNumber
      }
      if (data.objaddress) {
        const address = data.objaddress
        data.address = address.street1 || ''
        data.address += (data.address ? ' ' : '') + address.city || ''
        data.address +=
          (data.address ? ' ' : '') + ` ${address.state ? address.state : ''}`
        data.address += (data.address ? ' ' : '') + address.country || ''
      }

      // filtering data --------------------------------------------------------------------------------
      if (data && data.username.match(/hideme.ricblyz/g)) showMe = false

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.contactFullName &&
        userStore.filterHeaders.contactFullName.trim()
      ) {
        const contactFullName =
          userStore.filterHeaders.contactFullName.toLowerCase()
        showMe =
          data.contactFullName &&
          data.contactFullName.toLowerCase().indexOf(contactFullName) > -1
      }

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.contactEmail &&
        userStore.filterHeaders.contactEmail.trim()
      ) {
        const contactEmail = userStore.filterHeaders.contactEmail.toLowerCase()
        showMe =
          data.contactEmail &&
          data.contactEmail.toLowerCase().indexOf(contactEmail) > -1
      }

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.address &&
        userStore.filterHeaders.address.trim()
      ) {
        const address = userStore.filterHeaders.address.toLowerCase()
        showMe =
          data.address && data.address.toLowerCase().indexOf(address) > -1
      }

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.phoneNumber &&
        userStore.filterHeaders.phoneNumber.trim()
      ) {
        const phoneNumber = userStore.filterHeaders.phoneNumber
        showMe = data.phoneNumber && data.phoneNumber.indexOf(phoneNumber) > -1
      }

      if (showMe) listItems.push(data)
    })

    if (listItems) {
      // SORT THE LIST
      switch (sortBy) {
        case 'contact':
          orderedList = _.orderBy(
            listItems,
            [item => item.firstname.toLowerCase()],
            ['asc'],
          )
          break
        case '-contact':
          orderedList = _.orderBy(
            listItems,
            [item => item.firstname.toLowerCase()],
            ['desc'],
          )
          break
        case 'email':
          orderedList = _.orderBy(
            listItems,
            [item => item.username.toLowerCase()],
            ['asc'],
          )
          break
        case '-email':
          orderedList = _.orderBy(
            listItems,
            [item => item.username.toLowerCase()],
            ['desc'],
          )
          break
        default:
          orderedList = _.orderBy(
            listItems,
            [item => item.firstname.toLowerCase()],
            ['asc'],
          )
      }
    }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(orderedList, start, end)
  }
  return {
    userList,
    paginatedList,
    orderedList,
    phoneNumberStore: store.phoneNumber,
    addressStore: store.address,
    loggedInUser,
    staffStore: store.staff,
    contacts: listItems,
    userMap: store.user.byId,
    userStore,
  }
}
export default connect(mapStoreToProps)(withRouter(PracticeContactsList)) // Wrap component with withRouter
